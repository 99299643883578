import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createClasses, classNames, Colors, Typography } from '@kp/react-ui';

import { buildHeatingTemplate } from '../builders';
import { CreateDashboardData } from '../../../../api/building-insights';

const classes = createClasses({
  root: {
    padding: 0,
    border: 0,
    background: 'unset',
  },
  container: {
    cursor: 'pointer',
    display: 'flex',
    borderRadius: '6px',
    width: '242px',
    height: '216px',
    backgroundColor: Colors.Neutral.background,
    flexDirection: 'column',
    border: `1px solid ${Colors.Neutral.borderMedium}`,
    '& div:nth-child(1)': {
      backgroundColor: Colors.Neutral.backgroundStrong,
      borderBottom: `1px solid ${Colors.Neutral.borderMedium}`,
    },
  },
  disabled: {
    pointerEvents: 'none',
    filter: 'grayscale(1)',
    opacity: '0.5',
  },
  imageContainer: {
    borderRadius: '5px 5px 0 0',
    display: 'flex',
    height: '172px',
    padding: '12px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameContainer: {
    height: '44px',
    padding: '12px 24px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
});

export interface DashboardTemplateHeatingButtonProps {
  onClick: (data: CreateDashboardData) => void;
  disabled?: boolean;
}

export const DashboardTemplateHeatingButton: React.FC<
  DashboardTemplateHeatingButtonProps
> = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  const { buildingId } = useParams();

  const handleOnButtonClick = useCallback(() => {
    const createData = buildHeatingTemplate(t, { buildingId });
    onClick(createData);
  }, [t, buildingId, onClick]);

  return (
    <button
      data-testid="heating-template-button"
      disabled={disabled}
      onClick={handleOnButtonClick}
      className={classes.root}
    >
      <div
        className={classNames(classes.container, disabled && classes.disabled)}
      >
        <div className={classes.imageContainer}>
          <img
            src="/assets/dashboard-heating-circuits.svg"
            alt={'heating dashboard'}
          />
        </div>
        <Typography
          component="div"
          className={classes.nameContainer}
          variant="labelMedium"
        >
          {t('dashboards.template.heating.name')}
        </Typography>
      </div>
    </button>
  );
};
