import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createClasses, LogoB2B, Button } from '@kp/react-ui';
import { Color } from '../../constants/Colors';
import { Typography } from '../../components/Typography';
import unknownErrorUrl from '../../images/unknown-error.svg';

const classes = createClasses({
  root: {
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '376px',
    paddingBottom: '70px',
  },
  header: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: '34px',
    textDecoration: 'none',
  },
  headerLabel: {
    marginLeft: '12px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px 24px 40px 24px',
    backgroundColor: Color.white,
    boxShadow: '0px 0px 1px rgba(40, 45, 55, 0.5)',
    borderRadius: '8px',
  },
  text: {
    textAlign: 'center',
    marginTop: '29px',
    marginBottom: '31px',
    color: '#000000',
  },
  link: {
    textDecoration: 'none',
    color: '#FFFFFF',
    margin: '8px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  },
});

type Action = {
  label: string;
  onAction: () => void;
};
export type UnknownErrorProps = {
  error?: Error;
  actions: Action[];
};
export const UnknownError: React.FC<UnknownErrorProps> = ({
  error,
  actions,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <NavLink to="/" className={classes.header}>
        <LogoB2B />
      </NavLink>
      <div className={classes.content}>
        <img src={unknownErrorUrl} alt="Unknown Error" />
        <Typography variant="subhead" component="p" className={classes.text}>
          {t('errors.unknownError.message')}
          <Typography variant="footnote" component="p">
            {error?.message}
          </Typography>
        </Typography>
        <div className={classes.buttons}>
          {actions?.map(
            ({ label, onAction }) =>
              label &&
              onAction && (
                <Button key={label} onClick={onAction} className={classes.link}>
                  {label}
                </Button>
              ),
          )}
        </div>
      </div>
    </div>
  );
};
