import React from 'react';
import { Colors, Typography, createClasses } from '@kp/react-ui';
import noMatchesIcon from '../../images/no-matches-big.svg';

const classes = createClasses({
  root: {
    fontSize: 0,
  },
  cell: {
    padding: 0,
  },
  content: {
    position: 'absolute',
    padding: '0',
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    top: '141px',
    bottom: 0,
  },
  text: {
    display: 'block',
    width: '180px',
    marginTop: '12px',
    color: Colors.Neutral.textWeak,
  },
});

interface TableRowNoResultsProps {
  label: string;
}

export const TableRowNoResults: React.FC<TableRowNoResultsProps> = ({
  label,
}) => (
  <tr className={classes.root}>
    <td className={classes.cell}>
      <div className={classes.content}>
        <img alt="no-results" src={noMatchesIcon} />
        <Typography variant="labelSmall" className={classes.text}>
          {label}
        </Typography>
      </div>
    </td>
  </tr>
);
