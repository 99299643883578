import * as React from 'react';
import { Card, Typography, createClasses, Colors } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';

const classes = createClasses({
  root: {
    width: '33%',
    height: '208px',
  },

  body: {
    padding: 0,
  },

  header: {
    padding: '17px 22px',
  },

  container: {
    margin: '0 24px',
    display: 'grid',
    border: `1px solid  ${Colors.Neutral.borderMedium}`,
    borderRadius: '2px',
    gridTemplateColumns: '127px 1fr',
  },

  cell: {
    borderCollapse: 'collapse',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    borderBottom: `1px solid ${Colors.Neutral.borderMedium}`,
    padding: '7px 11px 8px 11px',
    ':nth-last-child(-n+2)': {
      borderWidth: 0,
    },
    ':nth-last-child(2n)': {
      backgroundColor: Colors.Neutral.backgroundMedium,
      borderRight: `1px solid ${Colors.Neutral.borderMedium}`,
    },
  },
});

interface DevicePreviewDetailProps {
  data: KeyValuePair[];
}

interface KeyValuePair {
  key: string;
  value?: string;
}

export const DevicePreviewDetail: React.FC<DevicePreviewDetailProps> = ({
  data,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      className={classes.root}
      bodyClassName={classes.body}
      data-testid={'preview-details'}
    >
      <Typography
        variant="labelMedium"
        className={classes.header}
        component="div"
      >
        {t('device.preview.details')}
      </Typography>
      <div className={classes.container}>
        {data.map((row, idx) => (
          <React.Fragment key={row.key}>
            <Typography
              variant="bodySmall"
              component="div"
              className={classes.cell}
            >
              {row.key}
            </Typography>
            <Typography
              variant="bodySmall"
              component="div"
              className={classes.cell}
            >
              {row.value}
            </Typography>
          </React.Fragment>
        ))}
      </div>
    </Card>
  );
};
