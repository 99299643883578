import { Response } from '../../hooks/useRequest';
import { DashboardCard, url } from './common';
import { EntityResponse } from '../common';
import { request } from '../utils/request';

/**
 * Update a card configuration
 *
 * @param dashboardId   The id of the dashboard
 * @param cardId        The id of the card that will be updated
 * @param data          The updates
 */
export const updateCard = async (
  dashboardId: string,
  cardId: string,
  data: DashboardCard,
): Promise<Response<EntityResponse<DashboardCard>>> => {
  return request<EntityResponse<DashboardCard>>(
    'PUT',
    url(`/dashboards/${dashboardId}/cards/${cardId}`),
    data,
  );
};
