import React, { useCallback, useMemo } from 'react';
import { useAuth } from '@kp/react-sdk';
import { useInfoQuery } from '../../../__generated__/types';
import { useNotifications } from '../../../contexts/notifications-context';
import { InteractiveModelEnabled } from '../../../constants/FeatureFlags';
import { Info } from './Info';
import { useBuilding } from '../../../contexts/building-context';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { usePlatform } from '../../../contexts/platform-context';

export const InfoContainer: React.FC = () => {
  const { id: buildingId, name: buildingName } = useBuilding();
  const { user } = useAuth();
  const { add } = useNotifications();
  const {
    isFavouriteBuildingId,
    addFavouriteBuildingId,
    removeFavouriteBuildingId,
  } = usePlatform();
  const { data, loading } = useInfoQuery({
    variables: { buildingId: buildingId || '' },
    skip: !buildingId,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
    errorPolicy: 'all',
  });

  const favourite = useMemo(
    () => (buildingId ? isFavouriteBuildingId(buildingId) || false : false),
    [buildingId, isFavouriteBuildingId],
  );

  const toggleFavourite = useCallback(() => {
    if (!buildingId) {
      return;
    }
    const request = favourite
      ? removeFavouriteBuildingId
      : addFavouriteBuildingId;
    request(buildingId).catch((error) => {
      add({
        type: 'danger',
        id: error.message,
        content: error.message,
      });
    });
  }, [
    add,
    buildingId,
    favourite,
    addFavouriteBuildingId,
    removeFavouriteBuildingId,
  ]);

  const showInteractive = InteractiveModelEnabled.some(
    ({ buildingId: bId, tenantId: tId }) =>
      bId === buildingId && tId === String(user?.info?.tenantId),
  );

  useBreadcrumb([
    {
      title: buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    {
      title: 'Insights',
      location: `/buildings/${buildingId}/info`,
      selected: true,
    },
  ]);

  return (
    <Info
      loading={loading}
      id={buildingId || ''}
      name={buildingName || ''}
      description={data?.building?.description || ''}
      street={data?.building?.location?.street || ''}
      no={data?.building?.location?.no || ''}
      zip={data?.building?.location?.zip || ''}
      city={data?.building?.location?.city || ''}
      country={data?.building?.location?.country || ''}
      coordinates={
        data?.building?.location?.lat && data?.building?.location?.lon
          ? [data.building.location.lat, data.building.location.lon]
          : undefined
      }
      showInteractive={showInteractive}
      favourite={favourite}
      onClickFavourite={toggleFavourite}
    />
  );
};
