import React, { useCallback, useMemo } from 'react';
import { Button, Icons, Tabs, TabIcon } from '@kp/react-ui';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDevice } from '../device-context';
import { DeviceExport } from './DeviceExport';
import { Device, DeviceLocation } from '../Device.types';

export interface DeviceToolbarActionsProps {
  capabilityId?: string;
  tabs?: boolean;
  device?: Device;
  deviceLocation?: DeviceLocation;
  loading?: boolean;
}

export const DeviceToolbarActions: React.FC<DeviceToolbarActionsProps> = ({
  capabilityId,
  tabs = true,
  device,
  deviceLocation,
  loading,
}) => {
  const location = useLocation();
  const { buildingId, deviceId } = useParams();
  const navigate = useNavigate();
  const isTable = location.pathname.includes('capabilities');
  const { setInfoSidebarOpen, infoSidebarOpen, setSettingsSidebarOpen } =
    useDevice();

  const devicePath = useMemo(
    () => `/buildings/${buildingId}/devices/${deviceId}`,
    [deviceId, buildingId],
  );

  const handleChange = useCallback(
    (value: string) => {
      if (value === 'chart') {
        navigate(`${devicePath}`);
      } else {
        navigate(`${devicePath}/capabilities/${capabilityId}`);
        setSettingsSidebarOpen(false);
      }
    },
    [devicePath, navigate, capabilityId, setSettingsSidebarOpen],
  );

  const handleInfoSidebarClick = useCallback(() => {
    setInfoSidebarOpen((value) => !value);
    setSettingsSidebarOpen(false);
  }, [setInfoSidebarOpen, setSettingsSidebarOpen]);

  return (
    <>
      {tabs && capabilityId && (
        <DeviceExport
          device={device}
          deviceLocation={deviceLocation}
          loading={loading}
        />
      )}
      <Button
        variant="secondary"
        data-testid="info-button"
        selected={infoSidebarOpen}
        onClick={handleInfoSidebarClick}
        icon={Icons.IconInfoOutline}
      />
      {tabs && capabilityId && (
        <Tabs
          variant="icons"
          value={isTable ? 'table' : 'chart'}
          onChange={handleChange}
        >
          <TabIcon value="chart" icon={Icons.IconStatistics} />
          <TabIcon value="table" icon={Icons.IconList} />
        </Tabs>
      )}
    </>
  );
};
