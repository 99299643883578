import { Response } from '../../hooks/useRequest';
import {
  CreateDashboardData,
  DashboardDetailEntity,
  DashboardEntity,
  UpdateDashboardData,
  url,
} from './common';
import { EntityResponse } from '../common';
import { request } from '../utils/request';

/**
 * List all dashboards
 *
 * @param buildingId        Filter by buildingId
 */
export const getDashboards = async (
  buildingId?: string,
): Promise<Response<EntityResponse<DashboardEntity[]>>> =>
  request<EntityResponse<DashboardEntity[]>>('GET', url(`/dashboards`), {
    buildingId,
  });

/**
 * Get details of a dashboard
 *
 * @param dashboardId       The id of a dashboard that will be fetched
 */
export const getDashboard = async (
  dashboardId: string,
): Promise<Response<EntityResponse<DashboardDetailEntity>>> =>
  request<EntityResponse<DashboardDetailEntity>>(
    'GET',
    url(`/dashboards/${dashboardId}`),
  );

/**
 * Create a new dashboard
 *
 * @param data           The data of the new dashboard
 */
export const createDashboard = async (
  data: CreateDashboardData,
): Promise<Response<EntityResponse<DashboardEntity>>> => {
  return request<EntityResponse<DashboardEntity>>(
    'POST',
    url(`/dashboards`),
    data,
  );
};

/**
 * Update a dashboard
 *
 * @param dashboardId  The id of the dashboard that will be updated
 * @param data         The updates
 */
export const updateDashboard = async (
  dashboardId: string,
  data: UpdateDashboardData,
): Promise<Response<EntityResponse<DashboardDetailEntity>>> => {
  return request<EntityResponse<DashboardDetailEntity>>(
    'PUT',
    url(`/dashboards/${dashboardId}`),
    data,
  );
};

/**
 * Delete a dashboard
 *
 * @param dashboardId  The id of the dashboard that will be deleted
 */
export const deleteDashboard = async (
  dashboardId: string,
): Promise<Response<null>> => {
  return request('DELETE', url(`/dashboards/${dashboardId}`));
};
