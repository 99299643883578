import React, { useMemo, useCallback } from 'react';
import {
  Colors,
  Typography,
  createClasses,
  classNames,
  Dropdown,
  ListItem,
  ToggleCheckbox,
  Divider,
  Label,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { DEVICE_RIGHT_SIDEBAR_WIDTH } from '../../../constants/UI';
import { useDevice } from '../device-context';
import { AggregationIntervals } from '../../../__generated__/types';

const classes = createClasses({
  root: {
    position: 'absolute',
    borderLeft: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.background,
    right: 0,
    transform: `translateX(${DEVICE_RIGHT_SIDEBAR_WIDTH}px)`,
    transition: 'transform 0.2s',
    width: DEVICE_RIGHT_SIDEBAR_WIDTH,
    bottom: 0,
    height: '100%',
    zIndex: 40,
    padding: '12px 24px',
  },
  open: {
    transform: 'translateX(0)',
  },
  title: {
    margin: '4px 0 16px 0',
  },
  subtitle: {
    padding: '4px 0',
  },
  control: {
    margin: '16px 0',
  },
  dropdown: {
    width: '100%',
  },
  divider: {
    margin: '18px 0',
    background: Colors.Neutral.backgroundHoverStrong,
  },
  fieldContainer: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
});

const makeOptions = (t: TFunction) => [
  { label: t('widgets.intervalSize.None'), value: AggregationIntervals.None },
  {
    label: t('widgets.intervalSize.Ms_500'),
    value: AggregationIntervals.Ms_500,
  },
  { label: t('widgets.intervalSize.S_01'), value: AggregationIntervals.S_01 },
  { label: t('widgets.intervalSize.S_02'), value: AggregationIntervals.S_02 },
  { label: t('widgets.intervalSize.S_03'), value: AggregationIntervals.S_03 },
  { label: t('widgets.intervalSize.S_04'), value: AggregationIntervals.S_04 },
  { label: t('widgets.intervalSize.S_05'), value: AggregationIntervals.S_05 },
  { label: t('widgets.intervalSize.S_06'), value: AggregationIntervals.S_06 },
  { label: t('widgets.intervalSize.S_10'), value: AggregationIntervals.S_10 },
  { label: t('widgets.intervalSize.S_12'), value: AggregationIntervals.S_12 },
  { label: t('widgets.intervalSize.S_15'), value: AggregationIntervals.S_15 },
  { label: t('widgets.intervalSize.S_20'), value: AggregationIntervals.S_20 },
  { label: t('widgets.intervalSize.S_30'), value: AggregationIntervals.S_30 },
  { label: t('widgets.intervalSize.M_01'), value: AggregationIntervals.M_01 },
  { label: t('widgets.intervalSize.M_02'), value: AggregationIntervals.M_02 },
  { label: t('widgets.intervalSize.M_03'), value: AggregationIntervals.M_03 },
  { label: t('widgets.intervalSize.M_04'), value: AggregationIntervals.M_04 },
  { label: t('widgets.intervalSize.M_05'), value: AggregationIntervals.M_05 },
  { label: t('widgets.intervalSize.M_06'), value: AggregationIntervals.M_06 },
  { label: t('widgets.intervalSize.M_10'), value: AggregationIntervals.M_10 },
  { label: t('widgets.intervalSize.M_12'), value: AggregationIntervals.M_12 },
  { label: t('widgets.intervalSize.M_15'), value: AggregationIntervals.M_15 },
  { label: t('widgets.intervalSize.M_20'), value: AggregationIntervals.M_20 },
  { label: t('widgets.intervalSize.M_30'), value: AggregationIntervals.M_30 },
  { label: t('widgets.intervalSize.H_01'), value: AggregationIntervals.H_01 },
  { label: t('widgets.intervalSize.H_02'), value: AggregationIntervals.H_02 },
  { label: t('widgets.intervalSize.H_03'), value: AggregationIntervals.H_03 },
  { label: t('widgets.intervalSize.H_04'), value: AggregationIntervals.H_04 },
  { label: t('widgets.intervalSize.H_06'), value: AggregationIntervals.H_06 },
  { label: t('widgets.intervalSize.H_08'), value: AggregationIntervals.H_08 },
  { label: t('widgets.intervalSize.H_12'), value: AggregationIntervals.H_12 },
  { label: t('widgets.intervalSize.D_01'), value: AggregationIntervals.D_01 },
  { label: t('widgets.intervalSize.D_02'), value: AggregationIntervals.D_02 },
  { label: t('widgets.intervalSize.D_03'), value: AggregationIntervals.D_03 },
  { label: t('widgets.intervalSize.D_04'), value: AggregationIntervals.D_04 },
  { label: t('widgets.intervalSize.D_05'), value: AggregationIntervals.D_05 },
  { label: t('widgets.intervalSize.D_06'), value: AggregationIntervals.D_06 },
  { label: t('widgets.intervalSize.D_07'), value: AggregationIntervals.D_07 },
];

export interface DeviceChartSettingsSidebarProps {
  availableIntervals: AggregationIntervals[];
  intervalSize: AggregationIntervals;
  setIntervalSize: (intervalSize: AggregationIntervals) => void;
  showPoints: boolean;
  setShowPoints: (compare: boolean) => void;
}

export const DeviceChartSettingsSidebar: React.FC<
  DeviceChartSettingsSidebarProps
> = ({
  availableIntervals,
  intervalSize,
  setIntervalSize,
  showPoints,
  setShowPoints,
}) => {
  const { settingsSidebarOpen } = useDevice();
  const { t } = useTranslation();
  const options = useMemo(() => makeOptions(t), [t]);
  const handleIntervalSizeChange = useCallback(
    (value: string) => {
      setIntervalSize(value as AggregationIntervals);
    },
    [setIntervalSize],
  );

  const handleShowPointsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowPoints(event.target.checked);
    },
    [setShowPoints],
  );

  return (
    <div
      className={classNames(classes.root, settingsSidebarOpen && classes.open)}
      data-testid="settings-sidebar"
      data-open={settingsSidebarOpen}
    >
      <Typography
        variant="labelLarge"
        className={classes.title}
        component="div"
      >
        {t('device.chart.settings.sidebar.title')}
      </Typography>
      <Typography
        variant="labelMedium"
        className={classes.subtitle}
        component="div"
      >
        {t('device.chart.settings.sidebar.chart')}
      </Typography>
      <div className={classes.control}>
        <Dropdown
          className={classes.dropdown}
          label={t('device.chart.settings.intervalSize.label') || ''}
          value={intervalSize}
          onChange={handleIntervalSizeChange}
          data-testid="interval-size-dropdown"
        >
          {options
            .filter(({ value }) => availableIntervals.includes(value))
            .map((option) => (
              <ListItem
                value={option.value}
                title={option.label}
                key={option.value}
              />
            ))}
        </Dropdown>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.control}>
        <Label title={t('device.chart.settings.view.label') || ''} />
        <div className={classes.fieldContainer}>
          <Typography variant={'bodyMedium'} component={'div'}>
            {t('device.chart.settings.view.showPoints')}
          </Typography>
          <ToggleCheckbox
            checked={showPoints}
            data-testid="show-points-input"
            onChange={handleShowPointsChange}
          />
        </div>
      </div>
    </div>
  );
};
