import React, { useMemo } from 'react';
import { WidgetFormDevicesAdded } from './WidgetFormDevicesAdded';
import { WidgetFormDevicesQuery } from '../../../../__generated__/types';
import {
  FormCapability,
  FormCapabilityIdentifier,
} from '../widgetFormDevicesTypes';

export interface WidgetFormDevicesAddedContainerProps {
  onChange: (
    capability: FormCapabilityIdentifier,
    updates: Partial<FormCapability>,
  ) => void;
  onRemove?: (capability: FormCapabilityIdentifier) => void;
  onDuplicate?: (capability: FormCapabilityIdentifier) => void;
  onMove: (from: number, to: number) => void;
  capabilities: FormCapability[];
  data?: WidgetFormDevicesQuery;
  loading?: boolean;
}

export const WidgetFormDevicesAddedContainer: React.FC<
  WidgetFormDevicesAddedContainerProps
> = ({
  onRemove,
  onChange,
  onDuplicate,
  onMove,
  capabilities,
  loading,
  data,
}) => {
  const entries = useMemo(
    () =>
      capabilities?.map((capability) => {
        const result = (data?.devices?.items || []).find(
          (item) => item?.id === capability.deviceId,
        );

        const deviceZone = data?.mappingDeviceZones?.items?.find(
          (item) => item.device.id === capability.deviceId,
        );

        const capabilitiesInUse = capabilities
          .filter(({ deviceId }) => deviceId === capability.deviceId)
          .map(({ capabilityId }) => capabilityId);
        const capabilityOptions = (
          result?.deviceModel?.deviceModelCapabilities ?? []
        ).map(({ id, capability: { name } }) => ({
          id,
          name,
          disabled:
            capabilitiesInUse.includes(id) && capability.capabilityId !== id,
        }));

        return {
          id: result?.id || '-1',
          serialNo: result?.serialNo || '',
          color: capability.color,
          name: result?.name ?? '',
          type: result?.deviceModel?.deviceType?.name ?? '',
          storeyName: deviceZone?.zone.storey.name ?? '',
          description: result?.description ?? '',
          capabilityId: capability.capabilityId,
          capabilities: capabilityOptions,
          selected: capability.selected,
        };
      }),
    [capabilities, data],
  );

  return (
    <WidgetFormDevicesAdded
      onMove={onMove}
      onRemove={onRemove}
      onChange={onChange}
      onDuplicate={onDuplicate}
      entries={entries}
      loading={loading}
    />
  );
};
