export enum Color {
  bambus = '#96AF05',
  black = '#282D37',
  blue = '#003C64',
  coral = '#E1001E',
  grey1 = '#4B5464',
  grey2 = '#69758B',
  grey3 = '#969EAF',
  grey4 = '#E3E6EB',
  grey5 = '#F6F7F9',
  orange = '#F07D00',
  petrol = '#007D91',
  white = '#FFFFFF',
}

export type ColorType =
  | 'bambus'
  | 'black'
  | 'blue'
  | 'coral'
  | 'grey1'
  | 'grey2'
  | 'grey3'
  | 'grey4'
  | 'grey5'
  | 'orange'
  | 'petrol'
  | 'white';

const enumKeys = <
  O extends Record<string, unknown>,
  K extends keyof O = keyof O,
>(
  obj: O,
): K[] => {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
};

export const ColorKeys = enumKeys(Color);

export const colorTypeToEnum = (color: ColorType): Color => {
  const colorType = ColorKeys.find((c) => c === color) || 'black';
  return Color[colorType];
};
