interface DirectionsLinkOptions {
  coordinates: [number, number];
  zoom: number;
  location: string;
}

export const directionsLink = ({
  coordinates,
  location,
  zoom,
}: DirectionsLinkOptions) =>
  `https://www.google.com/maps/dir//${location}/@${coordinates[0]},${coordinates[1]},${zoom}z`;
