import React, { useCallback } from 'react';
import noop from '@stdlib/utils-noop';
import { Checkbox, IconButton, Icons, createClasses } from '@kp/react-ui';

const classes = createClasses({
  root: {
    display: 'flex',
    padding: '4px 8px',
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: '32px',
  },
  button: {
    '& + &': {
      marginLeft: '4px',
    },
  },
});

export interface Storey {
  id: string;
  label: string;
  selected: boolean;
}

interface InteractiveHeaderStoreySelectStoreyProps extends Storey {
  onSelect?: (id: string) => void;
  onCapture?: (id: string) => void;
}

export const InteractiveHeaderStoreySelectStorey: React.FC<
  InteractiveHeaderStoreySelectStoreyProps
> = ({ id, label, selected, onSelect = noop, onCapture = noop }) => {
  const handleCheckboxChange = useCallback(() => {
    onSelect(id);
  }, [id, onSelect]);

  const handleCaptureButtonClick = useCallback(() => {
    onCapture(id);
  }, [id, onCapture]);

  return (
    <div className={classes.root}>
      <Checkbox
        key={id}
        label={label}
        value={id}
        checked={selected}
        onChange={handleCheckboxChange}
      />
      <div className={classes.actions}>
        <IconButton
          icon={Icons.IconFocus}
          variant="secondary"
          size="default"
          className={classes.button}
          onClick={handleCaptureButtonClick}
        />
      </div>
    </div>
  );
};
