import { Card, Typography, createClasses, Colors, css } from '@kp/react-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocaleDateFns } from '../../hooks/date-fns';
import { animations } from '../../utils/css';
import { DeviceModelCapabilityValue } from '../../features/devices/Devices.types';

const classes = createClasses({
  root: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    height: '208px',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    padding: '17px 22px',
    flexDirection: 'column',
  },

  subtitle: {
    paddingTop: '6px',
    color: Colors.Neutral.textWeak,
  },

  value: {
    bottom: '-70px',
    left: '-2px',
    padding: '0 24px',
    position: 'relative',
  },

  subtitleSkeleton: {
    height: '12px',
    width: '120px',
    margin: '4px 0',
    borderRadius: 2,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `
      linear-gradient(
        90deg, 
        rgba(0, 0, 0, 0) 0, 
        rgba(0, 0, 0, .04) 50%, 
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(${Colors.Neutral.backgroundHoverStrong} 100%, transparent 0);
    `,
    backgroundSize: `
      80% 12px,
      100% 100%;
    `,
    backgroundPosition: `
      -550% 0,
      0 0;
    `,
    animation: `${animations.slideRight} 1.5s infinite`,
  },
});

interface DevicePreviewCapabilityProps {
  loading?: boolean;
  capabilityValue?: DeviceModelCapabilityValue;
}

export const DevicePreviewCapability: React.FC<
  DevicePreviewCapabilityProps
> = ({ loading = false, capabilityValue }) => {
  const { format } = useLocaleDateFns();
  const { t } = useTranslation();
  const formatVal = (capability: DeviceModelCapabilityValue): string => {
    if (capability.capabilityValue) {
      const val =
        capability.capabilityValue.indexOf('.') > 0
          ? capability.capabilityValue.slice(
              0,
              capability.capabilityValue.indexOf('.') + 3,
            )
          : capability.capabilityValue;
      return `${val} ${capability.unit.unitSymbol}`;
    }
    return `- ${capability.unit.unitSymbol}`;
  };
  return (
    <Card
      className={classes.root}
      bodyClassName={css({ padding: 0 })}
      data-testid={'preview-capability'}
    >
      <div className={classes.header}>
        <Typography variant="labelMedium">
          {capabilityValue
            ? capabilityValue.capability.name
            : t('device.preview.noData')}
        </Typography>
        {loading ? (
          <div className={classes.subtitleSkeleton} />
        ) : (
          capabilityValue && (
            <Typography variant="bodySmall" className={classes.subtitle}>
              {capabilityValue && capabilityValue.capabilityLastUpdate
                ? format(new Date(capabilityValue.capabilityLastUpdate), 'P p')
                : t('device.preview.noData')}
            </Typography>
          )
        )}
      </div>
      {!loading && capabilityValue && (
        <Typography variant="displaySmall" className={classes.value}>
          {formatVal(capabilityValue)}
        </Typography>
      )}
    </Card>
  );
};
