import React from 'react';
import { Colors, Typography, classNames, createClasses } from '@kp/react-ui';
import { DEVICE_RIGHT_SIDEBAR_WIDTH } from '../../../constants/UI';
import { useDevice } from '../device-context';
import { DeviceRangeSlider } from './DeviceRangeSlider';

const classes = createClasses({
  root: {
    height: 'calc(100vh - 104px)',
    background: Colors.Neutral.background,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px 0 18px',
    background: Colors.Neutral.background,
    // Compensate 1px for the border
    height: '47px',
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
  },
  actions: {
    display: 'flex',
    gap: '12px',
    zIndex: '70',
    alignItems: 'center',
  },
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: 'max-width 0.2s',
    maxWidth: '100%',
  },
  sidebarOpen: {
    maxWidth: `calc(100% - ${DEVICE_RIGHT_SIDEBAR_WIDTH}px)`,
  },
});

interface DeviceContentLayoutProps {
  title: string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  sidebars?: React.ReactNode;
  slider?: boolean;
}

export const DeviceContentLayout: React.FC<DeviceContentLayoutProps> = ({
  title,
  actions,
  children,
  sidebars,
  slider = true,
}) => {
  const { hasSidebarOpen } = useDevice();
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <Typography variant="titleLarge">{title}</Typography>
        <div className={classes.actions}>{actions}</div>
      </header>
      <div className={classes.wrapper}>
        <div
          className={classNames(
            classes.content,
            hasSidebarOpen && classes.sidebarOpen,
          )}
        >
          {slider && <DeviceRangeSlider />}
          {children}
        </div>
        {sidebars}
      </div>
    </div>
  );
};
