import React from 'react';
import { useTranslation } from 'react-i18next';
import { countries } from 'countries-list';
import {
  Card,
  Colors,
  Icons,
  LogoKP,
  Typography,
  Button,
  StarButton,
  createClasses,
  classNames,
} from '@kp/react-ui';
import { Map } from '../../../components/Map';
import { PlaceholderMap } from '../../../components/PlaceholderMap';
import { directionsLink } from '../../../utils/maps';
import { InfoWidgetsContainer } from './widgets/InfoWidgetsContainer';
import { InfoInteractive } from './InfoInteractive';
import { useLayout } from '../../../contexts/layout-context';
import { BREADCRUMB_HEIGHT, HEADER_HEIGHT } from '../../../constants/UI';

const CONTENT_TITLE_HEIGHT = 49;

const classes = createClasses({
  header: {
    backgroundColor: Colors.Neutral.background,
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
    padding: '10px 24px',
    position: 'fixed',
    zIndex: 98,
    left: '240px',
    right: 0,
    transition: 'left 0.2s',
  },
  headerCollapsedSidebar: {
    left: '72px',
  },
  content: {
    minHeight: `calc(100vh - ${HEADER_HEIGHT + BREADCRUMB_HEIGHT}px)`,
    padding: `${CONTENT_TITLE_HEIGHT + 24}px 24px 24px 24px`,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    marginBottom: '32px',
  },
  logoContainer: {
    width: '16,5%',
    flexGrow: 1,
    display: 'flex',
  },
  logo: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: '100%',
      height: 'auto',
    },
  },
  addressContainer: {
    width: '33%',
    flexGrow: 1,
    display: 'flex',
    minHeight: '102px',
  },
  address: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    // Compensate 1px for the border
    padding: '15px',
    alignItems: 'flex-start',
  },
  addressContent: {
    padding: '4px 8px',
  },
  mapContainer: {
    width: '50%',
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',
  },
  map: {
    padding: 0,
    display: 'flex',
    flexGrow: 1,
  },
  mapContent: {
    flexGrow: 1,
  },
  mapDirectionsButton: {
    position: 'absolute',
    zIndex: '10',
    right: '16px',
    top: '16px',
    textDecoration: 'none',
  },
});

export interface InfoProps {
  loading?: boolean;
  id: string;
  name: string;
  description: string;
  street: string;
  no: string;
  zip: string;
  city: string;
  country: string;
  coordinates?: [number, number];
  showInteractive?: boolean;
  favourite?: boolean;
  onClickFavourite: () => void;
}

export const Info: React.FC<InfoProps> = ({
  loading,
  id,
  name,
  description,
  street,
  no,
  zip,
  city,
  country,
  coordinates,
  showInteractive = false,
  favourite,
  onClickFavourite,
}) => {
  const { t } = useTranslation();
  const { isSidebarCollapsed } = useLayout();

  const countryName =
    country && Object.keys(countries).includes(country)
      ? countries[country as keyof typeof countries]?.name
      : country || '';

  return (
    <>
      <header
        className={classNames(
          classes.header,
          isSidebarCollapsed && classes.headerCollapsedSidebar,
        )}
      >
        <Typography variant="titleLarge">{name}</Typography>
      </header>
      <div className={classes.content}>
        <div className={classes.details}>
          <Card className={classes.logoContainer} bodyClassName={classes.logo}>
            <LogoKP />
          </Card>

          <Card
            className={classes.addressContainer}
            bodyClassName={classes.address}
          >
            <div className={classes.addressContent}>
              <Typography variant="titleMedium" component="div">
                {description}
              </Typography>
              <Typography variant="bodyMedium" component="div">
                {street} {no}
              </Typography>
              {(zip || city || countryName) && (
                <Typography variant="bodyMedium" component="div">
                  {zip} {city}, {countryName}
                </Typography>
              )}
            </div>
            <StarButton
              checked={favourite || false}
              onChange={onClickFavourite}
              data-testid="favourite"
            />
          </Card>

          <Card className={classes.mapContainer} bodyClassName={classes.map}>
            {coordinates && (
              <Button
                className={classes.mapDirectionsButton}
                icon={Icons.IconDirections}
                variant="secondary"
                component="a"
                href={directionsLink({
                  coordinates,
                  location: `${street} ${no} ${city} ${zip}`,
                  zoom: 16,
                })}
                target="_blank"
              >
                {t('info.button.directions')}
              </Button>
            )}
            {coordinates ? (
              <Map
                className={classes.mapContent}
                center={coordinates}
                marker
                interactive={false}
              />
            ) : (
              <PlaceholderMap className={classes.mapContent} />
            )}
          </Card>
        </div>

        <InfoWidgetsContainer loading={loading} />

        {showInteractive && !loading && <InfoInteractive buildingId={id} />}
      </div>
    </>
  );
};
