export enum WidgetFormDevicesSearchFilterCategory {
  DeviceType = 'deviceType',
  Floor = 'floor',
  Zone = 'zone',
}

export interface WidgetFormDevicesSearchFilter {
  id: string;
  categoryId: WidgetFormDevicesSearchFilterCategory;
  label: string;
  selected?: boolean;
}
