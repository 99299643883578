import React from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '@kp/react-ui';
import mapImgUrl from '../../images/map.png';
import { Color } from '../../constants/Colors';

const classes = createClasses({
  emptyMap: {
    '&:before': {
      background: `url('${mapImgUrl}')`,
      backgroundSize: 'cover',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.8,
      content: '""',
      filter: 'grayscale(100)',
    },
  },
  emptyMapLabel: {
    background: Color.white,
    borderRadius: 4,
    padding: '6px 8px',
    color: Color.grey2,
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: '12px',
    position: 'absolute',
    right: 14,
    bottom: 14,
  },
});

export interface PlaceholderMapProps {
  className?: string;
}

export const PlaceholderMap: React.FC<PlaceholderMapProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div
        className={classes.emptyMap}
        style={{ height: '100%', flexGrow: 1, position: 'relative' }}
      >
        <div className={classes.emptyMapLabel}>{t('general.noAddress')}</div>
      </div>
    </div>
  );
};
