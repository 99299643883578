import { TimeInterval } from '../../../../api/building-insights';

type EmptyTemplatebuilderProps = {
  buildingId: string;
};

export const buildEmptyTemplate = (
  t: Function,
  { buildingId }: EmptyTemplatebuilderProps,
) => {
  return {
    name: '',
    buildingId,
    timeInterval: TimeInterval.lastDay,
    cards: [],
  };
};
