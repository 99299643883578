import React from 'react';
import { Dropdown, DropdownProps } from '@kp/react-ui';
import { FieldProps } from 'formik';

export interface DropdownFieldProps
  extends FieldProps,
    Omit<DropdownProps, 'value' | 'onChange' | 'form'> {}

const fieldToDropdown = ({
  field: { onChange: _onChange, onBlur: _onBlur, ...field },
  form,
  ...props
}: DropdownFieldProps): DropdownProps => ({
  onChange: (newValue) => {
    form.setFieldValue(field.name, newValue);
  },
  ...field,
  ...props,
});

export const DropdownField = (props: DropdownFieldProps) => (
  <Dropdown {...fieldToDropdown(props)} />
);

DropdownField.displayName = 'FormikDropdown';
