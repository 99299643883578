import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { WidgetFormToolbarData } from './WidgetFormToolbar';
import {
  Capability,
  TimeInterval,
  timeIntervalToGraphQL,
} from '../../../api/building-insights';
import { WidgetForm } from './WidgetForm';
import {
  useWidgetFormDataQuery,
  useWidgetFormDevicesQuery,
} from '../../../__generated__/types';
import { useNotifications } from '../../../contexts/notifications-context';
import {
  DEFAULT_INTERVAL_SIZE,
  defaultIntervalSizeByTimeSpan,
} from '../../../constants/Defaults';

export type WidgetFormData = WidgetFormToolbarData & {
  capabilities?: Capability[];
  intervalSizeDefault?: boolean;
};

export interface WidgetFormProps {
  loading?: boolean;
  type?: 'edit' | 'create' | 'view';
  onCancel?: () => void;
  onDelete?: () => void;
}

export const WidgetFormContainer: React.FC<WidgetFormProps> = ({
  loading,
  onCancel,
  onDelete,
  type,
}) => {
  const { t } = useTranslation();
  const { add } = useNotifications();
  const {
    submitForm,
    setFieldValue,
    values: {
      dateFrom,
      dateUntil,
      capabilities,
      timeInterval,
      intervalSize,
      intervalSizeDefault,
    },
  } = useFormikContext<WidgetFormData>();

  const [minDate, setMinDate] = useState(new Date());
  const [selectedCapabilities, deviceIds] = useMemo(() => {
    return [
      (capabilities || []).filter((capability) => capability.selected),
      (capabilities || [])
        .map(({ deviceId }) => deviceId)
        .filter((id, index, array) => array.indexOf(id) === index),
    ];
  }, [capabilities]);

  const intervalRequestSize = useMemo(() => {
    if (intervalSizeDefault) return defaultIntervalSizeByTimeSpan(timeInterval);

    return intervalSize;
  }, [intervalSize, timeInterval, intervalSizeDefault]);

  const {
    data,
    loading: loadingData,
    previousData,
  } = useWidgetFormDataQuery({
    variables: {
      devices: deviceIds
        .filter(
          (deviceId) =>
            selectedCapabilities.filter(
              (capability) => capability.deviceId === deviceId,
            ).length > 0,
        )
        .map((deviceId) => ({
          deviceId,
          deviceModelCapabilityIds: selectedCapabilities
            .filter((capability) => capability.deviceId === deviceId)
            .map(({ capabilityId }) => ({
              deviceModelCapabilityId: capabilityId,
            })),
        })),
      timeSpan: timeIntervalToGraphQL(timeInterval),
      intervalSize: intervalRequestSize,
      readFrom: dateFrom
        ? format(dateFrom, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
        : null,
      readUntil: dateUntil
        ? format(dateUntil, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
        : null,
      readUntilNow: dateUntil === undefined,
    },
    skip: selectedCapabilities.length === 0,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
    errorPolicy: 'all',
  });

  const [timeIntervalQueue, setTimeIntervalQueue] = useState<TimeInterval[]>([
    timeInterval,
  ]);

  const { data: devicesData, loading: loadingDevices } =
    useWidgetFormDevicesQuery({
      skip: deviceIds.length === 0,
      variables: { deviceIds },
      onError: (err) =>
        add({
          type: 'danger',
          id: err.message,
          content: err.message,
        }),
      errorPolicy: 'all',
    });

  const availableIntervals = useMemo(
    () =>
      data?.multipleDeviceSensorDataByViews?.[0]?.aggregationIntervalsList || [
        DEFAULT_INTERVAL_SIZE,
      ],
    [data],
  );

  useEffect(() => {
    setTimeIntervalQueue([...timeIntervalQueue, timeInterval]);
  }, [timeInterval]);

  useEffect(() => {
    const readFromUtcMin =
      data?.multipleDeviceSensorDataInfo?.minUtcTimeMeasured;
    if (readFromUtcMin) {
      setMinDate(parseISO(readFromUtcMin));
    }

    const realIntervalSize =
      data?.multipleDeviceSensorDataByViews?.[0]?.aggregationIntervalActive;
    const defaultIntervalSize =
      data?.multipleDeviceSensorDataByViews?.[0]?.aggregationIntervalDefault;

    if (!previousData && data && realIntervalSize === defaultIntervalSize) {
      setFieldValue('intervalSizeDefault', true);
    }
  }, [data, previousData, setFieldValue]);

  useEffect(() => {
    const realIntervalSize =
      data?.multipleDeviceSensorDataByViews?.[0]?.aggregationIntervalActive;

    const previousIntervalSize =
      previousData?.multipleDeviceSensorDataByViews?.[0]
        ?.aggregationIntervalActive;

    const currentTimeSpan = timeIntervalQueue[timeIntervalQueue.length - 1];
    const previousTimeSpan = timeIntervalQueue[timeIntervalQueue.length - 2];

    if (previousIntervalSize === realIntervalSize || !previousIntervalSize) {
      return;
    }

    if (realIntervalSize && realIntervalSize !== intervalSize) {
      setFieldValue('intervalSize', realIntervalSize);
    }

    if (
      data &&
      previousTimeSpan !== currentTimeSpan &&
      !intervalSizeDefault &&
      intervalSize !== realIntervalSize
    ) {
      setFieldValue('intervalSizeDefault', true);
      add({
        type: 'default',
        id: `intervalSizeChanged-${new Date()}`,
        content: t('widgets.intervalSize.forcedChange'),
        dismissTimeout: 5000,
        noIcon: true,
      });
    }
  }, [
    data,
    previousData,
    setFieldValue,
    intervalSize,
    timeIntervalQueue,
    intervalSizeDefault,
    t,
    add,
  ]);

  const csv = useMemo(
    () => [
      [
        'deviceId',
        'deviceName',
        'serialNo',
        'floorId',
        'floorName',
        'zoneId',
        'zoneName',
        'description',
        'unitId',
        'unitName',
        'capabilityId',
        'capabilityName',
        'timestamp',
        'value',
      ],
      ...(
        data?.multipleDeviceSensorDataByViews?.[0]?.deviceAndCapabilityInfos ||
        []
      ).reduce<Array<Array<string>>>((rows, entry) => {
        const device = (devicesData?.mappingDeviceZones?.items || []).find(
          (mapping) => mapping?.device?.id === entry?.deviceId,
        );
        return rows.concat(
          (entry?.telemetryRecords || []).map((record) => [
            entry?.deviceId || '',
            entry?.device?.name || '',
            entry?.device?.serialNo || '',
            device?.zone?.storey?.id || '',
            device?.zone?.storey?.name || '',
            device?.zone?.id || '',
            device?.zone?.name || '',
            entry?.device?.description || '',
            entry?.deviceModelCapability?.unit.id || '',
            entry?.deviceModelCapability?.unit.name || '',
            entry?.deviceModelCapability?.id || '',
            entry?.deviceModelCapability?.capability.name || '',
            record?.utcTimeMeasured
              ? format(parseISO(record.utcTimeMeasured), 'yyyy-MM-dd HH:mm:ss')
              : '',
            record?.valueString || '',
          ]),
        );
      }, []),
    ],
    [data, devicesData],
  );

  return (
    <WidgetForm
      csv={csv}
      chartData={data}
      minDate={minDate}
      devicesData={devicesData}
      availableIntervals={availableIntervals}
      onSubmit={submitForm}
      loading={loading}
      loadingData={loadingData}
      loadingDevices={loadingDevices}
      type={type}
      onCancel={onCancel}
      onDelete={onDelete}
    />
  );
};
