import { TimeScaleDataset } from '@kp/react-ui';
import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  getDateFromOfTimeInterval,
  TimeInterval,
  timeIntervalToREST,
} from '../../../../api/building-insights';
import { useNotifications } from '../../../../contexts/notifications-context';
import {
  AggregationIntervals,
  TimeSpans,
  usePointPreviewDeviceCapabilityChartQuery,
} from '../../../../__generated__/types';
import { DevicePreviewChart } from '../../../../components/DevicePreview/DevicePreviewChart';
import { PreviewDevice, PreviewDeviceCapability } from './PreviewDevice.types';

interface PointPreviewChartContainerProps {
  device: PreviewDevice;
  capability: PreviewDeviceCapability;
}

export const PointPreviewChartContainer: React.FC<
  PointPreviewChartContainerProps
> = ({ device, capability }) => {
  const { add } = useNotifications();

  const getIntervalSize = (ts: TimeSpans) => {
    if (ts === TimeSpans.Last_30Day) return AggregationIntervals.D_01;
    if (ts === TimeSpans.Last_7Day) return AggregationIntervals.H_06;
    return AggregationIntervals.H_01;
  };

  const [timeSpan, setTimeSpan] = useState<TimeSpans>(TimeSpans.Last_24Hour);

  const { data, loading } = usePointPreviewDeviceCapabilityChartQuery({
    variables: {
      deviceId: device.id,
      capabilityId: capability.id,
      timeSpan,
      intervalSize: getIntervalSize(timeSpan),
    },
    skip: false,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const chartData: TimeScaleDataset[] = useMemo(() => {
    if (!data?.multipleDeviceSensorDataByViews?.[0]?.deviceAndCapabilityInfos) {
      return [];
    }
    const { deviceAndCapabilityInfos } =
      data.multipleDeviceSensorDataByViews[0];

    const info = deviceAndCapabilityInfos.find(
      (entry) => entry?.deviceModelCapabilityId === capability.id,
    );

    return [
      {
        color: 'blue',
        unit: capability.unit.unitSymbol || '',
        label: capability.capability.name,
        data: (info?.telemetryRecords || []).map((record) => ({
          timestamp: record?.utcTimeMeasured || new Date(),
          value: record?.valueString ? parseFloat(record.valueString) : 0,
        })),
      },
    ];
  }, [data, capability]);

  const [min, max] = useMemo(
    () => [
      getDateFromOfTimeInterval(
        timeIntervalToREST(timeSpan) || TimeInterval.lastDay,
      ),
      new Date(),
    ],
    [timeSpan],
  );

  return (
    <DevicePreviewChart
      loading={loading}
      deviceModelCapability={capability}
      timeSpan={timeSpan}
      onChange={setTimeSpan}
      chartData={chartData}
      min={min}
      max={max}
    />
  );
};
