import {
  Button,
  Colors,
  DatePicker,
  Input,
  TextLink,
  Typography,
  createClasses,
} from '@kp/react-ui';
import React, {
  FocusEvent,
  ChangeEvent,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { startOfMinute } from 'date-fns';
import { useLocaleDateFns } from '../../hooks/date-fns';
import { Interval, IntervalDateUntil } from '../../hooks/date-interval';

const classes = createClasses({
  content: {
    padding: '11px 11px 8px 11px',
  },
  inputWrapper: {
    paddingBottom: '6px',
    paddingTop: '3px',
  },
  inputs: {
    display: 'flex',
    gap: '12px',
  },
  title: {
    paddingTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actions: {
    borderTop: `1px solid ${Colors.Neutral.borderMedium}`,
    padding: '11px',
    display: 'flex',
    gap: '12px',
    justifyContent: 'flex-end',
  },
  button: {
    width: '88px',
  },
  error: {
    color: Colors.Danger.text,
  },
});

export interface IntervalCustomProps {
  dateFrom: Date;
  dateUntil: IntervalDateUntil;
  onCancel: () => void;
  onChange: (interval: Interval) => void;
  wrapperClassName?: string;
}

export const IntervalCustom: React.FC<IntervalCustomProps> = ({
  onCancel,
  onChange,
  wrapperClassName,
  ...props
}) => {
  const { t } = useTranslation();
  const { format, parse, dateDiffMinute } = useLocaleDateFns();

  const [dateFrom, setDateFrom] = useState<Date>(props.dateFrom);
  const [dateUntil, setDateUntil] = useState<Date | 'max'>(props.dateUntil);

  const [timeFrom, setTimeFrom] = useState(format(props.dateFrom, 'p'));
  const [timeUntil, setTimeUntil] = useState(
    format(props.dateUntil === 'max' ? new Date() : props.dateUntil, 'p'),
  );

  const [dateFromError, setDateFromError] = useState<string>();
  const [dateUntilError, setDateUntilError] = useState<string>();

  const handleChangeTimeFrom = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setTimeFrom(event.target.value),
    [],
  );

  const handleChangeTimeUntil = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setTimeUntil(event.target.value),
    [],
  );

  const handleSetLatest = useCallback(() => {
    setDateUntil('max');
    setTimeUntil(format(new Date(), 'p'));
  }, [format]);

  const dateDiff = useCallback(
    (fromDate: Date, untilDate: Date | 'max') =>
      dateDiffMinute(untilDate === 'max' ? new Date() : untilDate, fromDate),
    [dateDiffMinute],
  );

  const validateDateFrom = useCallback(
    (date: Date) => {
      if (dateDiff(date, dateUntil) <= 0) {
        setDateFromError(t('common.customInterval.dateFromError') ?? '');
      } else {
        setDateFromError(undefined);
      }
    },
    [dateUntil, dateDiff, t],
  );

  const validateDateUntil = useCallback(
    (date: Date) => {
      if (dateDiff(dateFrom, date) <= 0) {
        setDateUntilError(t('common.customInterval.dateUntilError') ?? '');
      } else {
        setDateUntilError(undefined);
      }
    },
    [dateFrom, dateDiff, t],
  );

  const handleBlurTimeFrom = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const parsedDate = parse(event.target.value, 'p', dateFrom);
      if (parsedDate && !isNaN(parsedDate.getTime())) {
        setDateFrom(parsedDate);
        validateDateFrom(parsedDate);
      } else {
        setDateFromError(t('common.customInterval.timeFormatError') ?? '');
      }
    },
    [parse, dateFrom, t, validateDateFrom],
  );

  const handleBlurTimeUntil = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const dtUntil =
        dateUntil === 'max' ? startOfMinute(new Date()) : dateUntil;
      const parsedDate = parse(event.target.value, 'p', dtUntil);
      if (parsedDate && !isNaN(parsedDate.getTime())) {
        setDateUntil(parsedDate);
        validateDateUntil(parsedDate);
      } else {
        setDateUntilError(t('common.customInterval.timeFormatError') ?? '');
      }
    },
    [parse, dateUntil, t, validateDateUntil],
  );

  const applyInterval = () => {
    onChange({ dateFrom, dateUntil });
    onCancel();
  };

  const titleRender = (date: Date) => format(date, 'MMMM yyyy');

  const isApplyDisabled: boolean = useMemo(
    () => !!dateUntilError || !!dateFromError,
    [dateUntilError, dateFromError],
  );

  const handleChangeDateFrom = useCallback(
    (date: Date) => {
      setDateFrom(date);
      validateDateFrom(date);
    },
    [validateDateFrom],
  );

  const handleChangeDateUntil = useCallback(
    (date: Date) => {
      setDateUntil(date);
      validateDateUntil(date);
    },
    [validateDateUntil],
  );

  useEffect(() => {
    if (dateDiff(dateFrom, dateUntil) > 0) {
      setDateUntilError(undefined);
      setDateFromError(undefined);
    }
  }, [dateFrom, dateUntil, dateDiff]);

  return (
    <div className={wrapperClassName} data-testid="custom-interval">
      <div className={classes.content}>
        <Typography variant="labelSmall">
          {t('common.customInterval.startTime')}
        </Typography>
        <div className={classes.inputWrapper}>
          <div className={classes.inputs}>
            <DatePicker
              selected={dateFrom}
              onChange={handleChangeDateFrom}
              maxDate={dateUntil === 'max' ? new Date() : dateUntil}
              data-testid="date-picker-from"
              dateFormat={'P'}
              calendarHeaderTitleRender={titleRender}
            />
            <Input
              onBlur={handleBlurTimeFrom}
              value={timeFrom}
              onChange={handleChangeTimeFrom}
              data-testid="time-picker-from"
            />
          </div>
          <Typography variant="labelSmall" className={classes.error}>
            {dateFromError}
          </Typography>
        </div>
        <div className={classes.title}>
          <Typography variant="labelSmall">
            {t('common.customInterval.endTime')}
          </Typography>
          <TextLink
            component="button"
            size="small"
            disabled={dateUntil === 'max'}
            onClick={handleSetLatest}
            data-testid="date-until-latest"
          >
            {t('common.customInterval.latest')}
          </TextLink>
        </div>
        <div className={classes.inputWrapper}>
          <div className={classes.inputs}>
            <DatePicker
              selected={dateUntil === 'max' ? new Date() : dateUntil}
              onChange={handleChangeDateUntil}
              selectsEnd
              minDate={dateFrom}
              data-testid="date-picker-until"
              dateFormat={'P'}
              calendarHeaderTitleRender={titleRender}
            />
            <Input
              onBlur={handleBlurTimeUntil}
              value={timeUntil}
              onChange={handleChangeTimeUntil}
              data-testid="time-picker-until"
            />
          </div>

          <Typography variant="labelSmall" className={classes.error}>
            {dateUntilError}
          </Typography>
        </div>
      </div>
      <div className={classes.actions}>
        <Button
          variant="secondary"
          className={classes.button}
          onClick={onCancel}
        >
          {t('common.customInterval.cancel')}
        </Button>
        <Button
          data-testid="custom-interval-apply-button"
          className={classes.button}
          onClick={applyInterval}
          disabled={isApplyDisabled}
        >
          {t('common.customInterval.apply')}
        </Button>
      </div>
    </div>
  );
};
