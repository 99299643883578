import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import hexRgb from 'hex-rgb';
import { XeoKitContext } from './xeokit-context';
import { LightMapping } from '../../constants/FeatureFlags';

interface LampState {
  Color: string;
  Name: string;
  X: number;
  Y: number;
}

interface Step {
  Step: number;
  LampState: LampState[];
  TimeToNext: number;
}

type LightsTheme = Step[];

interface ParsedState {
  ifcId: string;
  color: [number, number, number, number];
}

interface ParsedStep {
  timeToNext: number;
  states: ParsedState[];
}

/**
 * Used to manipulate visibility of xeokit objects
 */
export const useXeoKitLights = (
  mapping: LightMapping[],
): {
  setTheme: (theme: LightsTheme) => void;
  start: () => void;
  stop: () => void;
  running: boolean;
} => {
  const { scene } = useContext(XeoKitContext);
  const [theme, setTheme] = useState<LightsTheme>([]);
  const [currentStep, setCurrentStep] = useState<number | null>(null);

  const parsedTheme: ParsedStep[] = useMemo(
    () =>
      theme.map((step) => ({
        timeToNext: step.TimeToNext,
        states: step.LampState.map((state) => {
          const entry = mapping.find(
            (definition) =>
              definition.x === state.X && definition.y === state.Y,
          );
          if (!entry) {
            return null;
          }
          const color = state.Color.replace(/^#/, '');
          const [r, g, b] = hexRgb(`#${color}`, { format: 'array' });
          return {
            ifcId: entry.ifcId,
            color: [r / 255, g / 255, b / 255],
          };
        }).filter((state) => !!state) as ParsedState[],
      })),
    [theme, mapping],
  );

  useEffect(() => {
    setCurrentStep(null);
  }, [parsedTheme]);

  const start = useCallback(() => setCurrentStep(0), []);

  const stop = useCallback(() => setCurrentStep(null), []);

  useEffect(() => {
    if (currentStep === null) {
      scene?.colorizedObjectIds.forEach((objectId) => {
        const object = scene?.objects[objectId];
        if (object) {
          object.colorize = [1, 1, 1];
        }
      });
    }
  }, [scene, currentStep]);

  useEffect(() => {
    if (currentStep === null) {
      return () => {};
    }
    const step = parsedTheme[currentStep];
    if (!step) {
      setCurrentStep(null);
      return () => {};
    }
    step.states.forEach((state) => {
      const object = scene?.objects[state.ifcId];
      if (object) {
        object.colorize = [state.color[0], state.color[1], state.color[2]];
      }
    });
    const timeout = setTimeout(() => {
      setCurrentStep(currentStep + 1);
    }, step.timeToNext);
    return () => {
      clearTimeout(timeout);
    };
  }, [parsedTheme, currentStep, scene]);

  return { setTheme, start, stop, running: currentStep !== null };
};
