import * as React from 'react';
import { useNotifications } from '../../../contexts/notifications-context';
import { useLastCapabilityValueForDeviceQuery } from '../../../__generated__/types';
import {
  DeviceModelCapability,
  DeviceModelCapabilityValue,
} from '../Devices.types';
import { DevicePreviewCapability } from '../../../components/DevicePreview/DevicePreviewCapability';

interface PreviewDeviceCapabilityContainerProps {
  deviceId: string;
  deviceModelCapabilities: DeviceModelCapability[];
}

export const PreviewDeviceCapabilityContainer: React.FC<
  PreviewDeviceCapabilityContainerProps
> = ({ deviceId, deviceModelCapabilities }) => {
  const { add } = useNotifications();

  const { data, loading } = useLastCapabilityValueForDeviceQuery({
    variables: {
      deviceId,
      deviceModelCapabilityId: deviceModelCapabilities[0].id,
    },
    skip: deviceModelCapabilities.length === 0,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const [capabilityValue] = React.useMemo<[DeviceModelCapabilityValue]>(
    () => [
      data &&
      data.sensorMeasurements &&
      data.sensorMeasurements.items &&
      data.sensorMeasurements?.items?.length > 0 &&
      data.sensorMeasurements?.items[0]?.valueString
        ? {
            ...deviceModelCapabilities[0],
            capabilityValue: data.sensorMeasurements?.items[0]?.valueString,
            capabilityLastUpdate: new Date(
              data.sensorMeasurements?.items[0]?.utcTimeMeasured,
            ),
          }
        : { ...deviceModelCapabilities[0] },
    ],
    [data, deviceModelCapabilities],
  );

  return (
    <DevicePreviewCapability
      loading={loading}
      capabilityValue={capabilityValue}
    />
  );
};
