import React, { useCallback, useEffect, useState } from 'react';
import { Colors, SearchField, classNames, createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { INTERACTIVE_RIGHT_SIDEBAR_WIDTH } from '../../../constants/UI';
import { useXeoKitControls } from '../../../contexts/xeokit';
import { InteractiveTree } from './InteractiveTree';

const classes = createClasses({
  root: {
    position: 'absolute',
    borderLeft: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.backgroundMedium,
    right: 0,
    transform: `translateX(${INTERACTIVE_RIGHT_SIDEBAR_WIDTH}px)`,
    transition: 'transform 0.2s',
    width: INTERACTIVE_RIGHT_SIDEBAR_WIDTH,
    bottom: 0,
    height: '100%',
    zIndex: 40,
    padding: '8px 0',
  },
  content: {
    overflowX: 'scroll',
    height: '100%',
  },
  open: {
    transform: 'translateX(0)',
  },
  search: {
    marginBottom: '8px',
    padding: '8px 14px 8px 24px',
    position: 'absolute',
    top: 0,
    left: 0,
    // So we show the scrollbar on the right, since it's part of the container
    right: 10,
    zIndex: 1000,
    background: Colors.Neutral.backgroundMedium,
  },
  tree: {
    padding: '0 24px 12px 24px',
    marginTop: '54px',
  },
  popup: {
    'div &': {
      width: `${INTERACTIVE_RIGHT_SIDEBAR_WIDTH - 24 - 24}px`,
      height: '400px',
    },
  },
});

export interface InteractiveSidebarProps {
  open?: boolean;
}

export interface InteractiveSidebarFilter {
  id: string;
  categoryId: 'ifcType';
  label: string;
  selected?: boolean;
}

const filterCategories = [{ id: 'ifcType', label: 'IfcType' }];
const ifcTypes = [
  'IfcRoof',
  'IfcSlab',
  'IfcWall',
  'IfcWallStandardCase',
  'IfcCovering',
  'IfcDoor',
  'IfcStair',
  'IfcStairFlight',
  'IfcProxy',
  'IfcRamp',
  'IfcColumn',
  'IfcBeam',
  'IfcCurtainWall',
  'IfcPlate',
  'IfcTransportElement',
  'IfcFooting',
  'IfcRailing',
  'IfcFurnishingElement',
  'IfcFurniture',
  'IfcSystemFurnitureElement',
  'IfcFlowSegment',
  'IfcFlowitting',
  'IfcFlowTerminal',
  'IfcFlowController',
  'IfcFlowFitting',
  'IfcDuctSegment',
  'IfcDistributionFlowElement',
  'IfcDuctFitting',
  'IfcLightFixture',
  'IfcAirTerminal',
  'IfcOpeningElement',
  'IfcSpace',
  'IfcWindow',
  'IfcBuildingElementProxy',
  'IfcSite',
  'IfcMember',
];

export const InteractiveSidebar: React.FC<InteractiveSidebarProps> = ({
  open,
}) => {
  const [query, setQuery] = useState('');
  const { t } = useTranslation();
  const { model } = useXeoKitControls();
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>([]);
  const [filters, setFilters] = useState<InteractiveSidebarFilter[]>([]);

  const handleRemoveFilter = useCallback(
    (id: string) =>
      setSelectedFilterIds((filterIds) =>
        filterIds.filter((target) => target !== id),
      ),
    [setSelectedFilterIds],
  );

  const handleAddFilter = useCallback(
    (id: string) => setSelectedFilterIds((filterIds) => [...filterIds, id]),
    [setSelectedFilterIds],
  );

  const handleResetFilters = useCallback(() => {
    setSelectedFilterIds([]);
  }, []);

  useEffect(() => {
    setFilters(
      ifcTypes.map((type) => ({
        id: type,
        label: type,
        categoryId: 'ifcType',
        selected: selectedFilterIds.includes(type),
      })),
    );
  }, [selectedFilterIds]);

  if (!model) {
    return null;
  }

  return (
    <div className={classNames(classes.root, open && classes.open)}>
      <div className={classes.content}>
        <div className={classes.search}>
          <SearchField
            onChange={setQuery}
            value={query}
            popupClassName={classes.popup}
            filterButtonLabel={t('widgets.filters.button.filter') || ''}
            fullWidth
            categories={filterCategories}
            onAddFilter={handleAddFilter}
            onRemoveFilter={handleRemoveFilter}
            onResetFilters={handleResetFilters}
            filters={filters}
          />
        </div>
        <div className={classes.tree}>
          {query || selectedFilterIds.length > 0 ? (
            Object.values(model.metaScene?.metaObjects ?? {})
              .filter((metaObject) =>
                query ? metaObject.name.includes(query) : true,
              )
              .filter((metaObject) =>
                selectedFilterIds.length > 0
                  ? selectedFilterIds.includes(metaObject.type)
                  : true,
              )
              .map((metaObject) => (
                <InteractiveTree
                  key={metaObject.id}
                  object={metaObject}
                  orphan
                />
              ))
          ) : (
            <InteractiveTree object={model.rootMetaObject} />
          )}
        </div>
      </div>
    </div>
  );
};
