import { Button, createClasses, Icons } from '@kp/react-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface LastValueCardEmptyProps {
  onAddDataPointClick?: () => void;
}

const classes = createClasses({
  root: {
    width: '100%',
  },
  dataPointButton: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export const LastValueCardEmpty: React.FC<LastValueCardEmptyProps> = ({
  onAddDataPointClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Button
        className={classes.dataPointButton}
        variant="tertiary"
        icon={Icons.IconAdd}
        onClick={onAddDataPointClick}
        disabled={!onAddDataPointClick}
      >
        {t('dashboards.card.addDataPoint')}
      </Button>
    </div>
  );
};
