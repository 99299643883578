import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import {
  injectGlobal,
  TimeScaleStackedLinearScale,
  TimeScaleStackedLinearCompactScale,
  TimeScaleBarElement,
  TimeScaleBarController,
} from '@kp/react-ui';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth } from '@kp/react-sdk';
import {
  AppInsightsErrorBoundary,
  initAppInsights,
} from '@kp/react-sdk/app-insights';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'normalize.css';
import '@kp/react-ui/dist/styles.css';
import './utils/i18n';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './App';
import { requiredPermissions } from './constants/Permissions';
import { Loader } from './components/Loader';
import { GraphQLProvider } from './contexts/graphql-context';
import { NotificationsProvider } from './contexts/notifications-context';
import { Color } from './constants/Colors';
import { ErrorFallback } from './components/Errors';
import { XeoKitProvider } from './contexts/xeokit';
import { LayoutProvider } from './contexts/layout-context';
import {
  AUTH_URI,
  CLIENT_ID,
  APPLICATIONINSIGHTS_CONNECTION_STRING,
} from './utils/env';
import { SettingsProvider } from './contexts/settings-provider';
import { GoogleMapsLoader } from './components/GoogleMapsLoader';
import { BreadcrumbProvider } from './contexts/breadcrumb-context';
import { PlatformProvider } from './contexts/platform-context';
import { GenericPageProvider } from './contexts/generic-page-context';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  TimeScaleStackedLinearScale,
  TimeScaleStackedLinearCompactScale,
  TimeScaleBarElement,
  TimeScaleBarController,
);

declare module 'react-router-dom' {
  export function useParams<
    P extends Record<string, string> = {
      [key: string]: string;
    },
  >(): P;
}

injectGlobal({
  html: {
    boxSizing: 'border-box',
  },
  '*, *::before, *::after': {
    boxSizing: 'inherit',
  },
  body: {
    backgroundColor: Color.white,
    fontFamily: "'Fira Sans', sans-serif",
  },
  button: {
    fontFamily: "'Fira Sans', sans-serif",
  },
  input: {
    fontFamily: "'Fira Sans', sans-serif",
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  ol: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
});

if (APPLICATIONINSIGHTS_CONNECTION_STRING) {
  initAppInsights('building-insights-app', {
    connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
    correlationHeaderExcludedDomains: ['*.googleapis.com', 'id.*'],
  });
}

const Root: React.FC = () => (
  <Suspense fallback={<Loader />}>
    <Router>
      <AppInsightsErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={console.warn}
      >
        <XeoKitProvider>
          <Auth
            clientId={CLIENT_ID}
            url={AUTH_URI}
            forbiddenUrl="/forbidden"
            missingPermissionsUrl="/missing-permissions"
            requiredPermissions={requiredPermissions}
            LoadingComponent={Loader}
          >
            <React.StrictMode>
              <SettingsProvider>
                <BreadcrumbProvider>
                  <GraphQLProvider>
                    <GoogleMapsLoader>
                      <NotificationsProvider>
                        <PlatformProvider>
                          <LayoutProvider>
                            <DndProvider backend={HTML5Backend}>
                              <GenericPageProvider>
                                <App />
                              </GenericPageProvider>
                            </DndProvider>
                          </LayoutProvider>
                        </PlatformProvider>
                      </NotificationsProvider>
                    </GoogleMapsLoader>
                  </GraphQLProvider>
                </BreadcrumbProvider>
              </SettingsProvider>
            </React.StrictMode>
          </Auth>
        </XeoKitProvider>
      </AppInsightsErrorBoundary>
    </Router>
  </Suspense>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);
