import React, { useCallback, useMemo } from 'react';
import { useNotifications } from '../../../../../contexts/notifications-context';
import { useLastValueEditPanelDeviceQuery } from '../../../../../__generated__/types';
import { DashboardCardLastValue } from '../../../../../api/building-insights';
import { LastValueEditPanel } from './LastValueEditPanel';
import { DashboardFormSubmit } from '../../Dashboard.types';

export interface LastValueEditPanelContainerProps {
  card: DashboardCardLastValue;
  onUpdate: (card: Partial<DashboardCardLastValue>) => void;
  onSubmit: DashboardFormSubmit;
}

export const LastValueEditPanelContainer: React.FC<
  LastValueEditPanelContainerProps
> = ({ card, onUpdate, onSubmit }) => {
  const { add } = useNotifications();

  const { data, loading } = useLastValueEditPanelDeviceQuery({
    variables: {
      deviceId: card.configuration?.deviceId || '',
    },
    skip: !card.configuration?.deviceId,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const capability = useMemo(() => {
    const device = data?.device;
    const capabilityId = card.configuration?.capabilityId;
    const deviceId = card.configuration?.deviceId;

    if (!device || !capabilityId || !deviceId) {
      return undefined;
    }

    const deviceCapability = device.deviceModel.deviceModelCapabilities.find(
      (deviceModelCapability) => capabilityId === deviceModelCapability.id,
    );

    return {
      capabilityId,
      deviceId,
      deviceName: data?.device?.name || '',
      capabilityName: deviceCapability?.capability.name || '',
      unitName: deviceCapability?.unit?.name || '',
      unitSymbol: deviceCapability?.unit?.unitSymbol || '',
    };
  }, [data, card]);

  const handleChangeTitle = useCallback(
    (title: string) => onUpdate({ title }),
    [onUpdate],
  );

  const handleChangeSubtitle = useCallback(
    (subtitle: string) => onUpdate({ subtitle }),
    [onUpdate],
  );

  const handleChangeCapability = useCallback(
    (configuration: { deviceId: string; capabilityId: string }) => {
      onUpdate({ configuration });
      onSubmit().catch(console.warn);
    },
    [onUpdate, onSubmit],
  );

  return (
    <LastValueEditPanel
      title={card.title}
      subtitle={card.subtitle}
      capability={capability}
      onChangeTitle={handleChangeTitle}
      onChangeSubtitle={handleChangeSubtitle}
      onChangeCapability={handleChangeCapability}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};
