import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextLink,
  Colors,
  Typography,
  Divider,
  createClasses,
  classNames,
} from '@kp/react-ui';
import { WIDGETS_RIGHT_SIDEBAR_WIDTH } from '../../../constants/UI';
import { WidgetFormIntervalSize } from './WidgetFormIntervalSize';
import { WidgetFormView } from './WidgetFormView';
import { AggregationIntervals } from '../../../__generated__/types';

const classes = createClasses({
  root: {
    position: 'absolute',
    borderLeft: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.background,
    right: 0,
    transform: `translateX(${WIDGETS_RIGHT_SIDEBAR_WIDTH}px)`,
    transition: 'transform 0.2s',
    width: WIDGETS_RIGHT_SIDEBAR_WIDTH,
    bottom: 0,
    height: '100%',
    zIndex: 40,
    padding: '0px 24px',
  },
  open: {
    transform: 'translateX(0)',
  },
  title: {
    padding: '16px 0px',
  },
  divider: {
    'div > &': {
      margin: '18px 0',
      background: Colors.Neutral.backgroundHoverStrong,
    },
  },
  lastDivider: {
    margin: '13px 0',
    background: Colors.Neutral.backgroundHoverStrong,
  },
  deleteButton: {
    padding: '0px',
  },
});

export interface WidgetFormSidebarProps {
  open?: boolean;
  onDelete?: () => void;
  availableIntervals: AggregationIntervals[];
}

export const WidgetFormSidebar: React.FC<WidgetFormSidebarProps> = ({
  open,
  onDelete,
  availableIntervals,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.root, open && classes.open)}>
      <Typography
        variant="labelLarge"
        className={classes.title}
        component="div"
      >
        {t('widgets.sidebar.title')}
      </Typography>
      <WidgetFormIntervalSize availableIntervals={availableIntervals} />
      <Divider className={classes.divider} />
      <WidgetFormView />
      <Divider className={classes.lastDivider} />
      {onDelete && (
        <TextLink
          className={classes.deleteButton}
          component="button"
          danger
          onClick={onDelete}
        >
          {t('widgets.actions.delete')}
        </TextLink>
      )}
    </div>
  );
};
