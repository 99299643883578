import { keyframes } from '@kp/react-ui';

export const animations = {
  slideRight: keyframes({
    to: {
      backgroundPosition: `
      550% 0,
      0 0;
      `,
    },
  }),
};
