import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AggregationInterval,
  aggregationIntervalToGraphQL,
  aggregationIntervalToREST,
  TimeInterval,
  timeIntervalToGraphQL,
} from '../../../../../api/building-insights';
import { useNotifications } from '../../../../../contexts/notifications-context';
import { useDashboardAggregationIntervalsQuery } from '../../../../../__generated__/types';
import { DashboardForm } from '../../Dashboard.types';
import { AggregationDropdown } from './AggregationDropdown';

interface AggregationDropdownContainerProps {
  submit: () => void;
}

export const AggregationDropdownContainer: React.FC<
  AggregationDropdownContainerProps
> = ({ submit }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<DashboardForm>();
  const timeInterval = watch('timeInterval', TimeInterval.lastDay);
  const { add } = useNotifications();
  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');
  const aggregationInterval = watch('aggregationInterval');

  const { data, previousData } = useDashboardAggregationIntervalsQuery({
    variables: {
      timeSpan: timeIntervalToGraphQL(timeInterval),
      readFrom: dateFrom,
      readUntil: dateTo,
      intervalSize: aggregationIntervalToGraphQL(aggregationInterval),
      readUntilNow:
        timeInterval === TimeInterval.customInterval
          ? dateTo === undefined
          : true,
    },
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  // Set the interval size in case it's not the same one from the backend
  useEffect(() => {
    const realIntervalSize =
      data?.multipleDeviceSensorDataByViews?.[0]?.aggregationIntervalActive;
    const previousIntervalSize =
      previousData?.multipleDeviceSensorDataByViews?.[0]
        ?.aggregationIntervalActive;

    if (previousIntervalSize === realIntervalSize) {
      return;
    }
    if (
      realIntervalSize &&
      aggregationIntervalToREST(realIntervalSize) !== aggregationInterval
    ) {
      setValue(
        'aggregationInterval',
        aggregationIntervalToREST(realIntervalSize),
      );
      submit();
    }
  }, [data, previousData, setValue, aggregationInterval, submit]);

  const availableIntervals = useMemo(
    () =>
      data?.multipleDeviceSensorDataByViews?.[0]?.aggregationIntervalsList?.map(
        (interval) => aggregationIntervalToREST(interval),
      ) || [aggregationInterval],
    [data, aggregationInterval],
  );

  const handleChangeInterval = useCallback(
    (interval: string) => {
      setValue('aggregationInterval', interval as AggregationInterval);
      submit();
    },
    [setValue, submit],
  );

  return (
    <AggregationDropdown
      aggregationIntervals={availableIntervals}
      selectedInterval={aggregationInterval}
      label={t('widgets.intervalSize.label')}
      onChangeInterval={handleChangeInterval}
    />
  );
};
