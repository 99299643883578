import React from 'react';
import { Colors, classNames, createClasses } from '@kp/react-ui';
import { DashboardEntity } from '../../api/building-insights';
import {
  DASHBOARD_LEFT_SIDEBAR_WIDTH,
  DASHBOARD_RIGHT_SIDEBAR_COLLAPSED_WIDTH,
  DASHBOARD_RIGHT_SIDEBAR_WIDTH,
} from './constants';
import { DashboardLayoutHeader } from './DashboardsLayoutHeader';
import { DashboardsLayoutSidebar } from './DashboardsLayoutSidebar';
import { useDashboard } from './dashboard-context';

const classes = createClasses({
  root: {
    height: 'calc(100vh - 104px)',
  },
  wrapper: {
    flexGrow: 1,
    display: 'flex',
  },
  content: {
    background: Colors.Neutral.backgroundMedium,
    position: 'absolute',
    top: '47px',
    right: 0,
    left: 0,
    bottom: 0,
    transition: 'left 0.2s',
  },
  leftSidebarOpen: {
    left: `${DASHBOARD_LEFT_SIDEBAR_WIDTH}px`,
  },
  rightSidebarOpen: {
    right: `${DASHBOARD_RIGHT_SIDEBAR_WIDTH}px`,
  },
  hasRightSidebar: {
    right: `${DASHBOARD_RIGHT_SIDEBAR_COLLAPSED_WIDTH}px`,
  },
});

interface DashboardsLayoutProps {
  children?: React.ReactNode;
  dashboards: DashboardEntity[];
  title: React.ReactNode;
  actions?: React.ReactNode;
  loading?: boolean;
  rightSidebar?: React.ReactNode;
}

export const DashboardsLayout: React.FC<DashboardsLayoutProps> = ({
  children,
  dashboards,
  title,
  actions,
  loading,
  rightSidebar,
}) => {
  const { rightSidebarOpen, leftSidebarOpen } = useDashboard();

  return (
    <div className={classes.root}>
      <DashboardLayoutHeader title={title} actions={actions} />
      <div className={classes.wrapper}>
        <DashboardsLayoutSidebar dashboards={dashboards} loading={loading} />
        <div
          className={classNames(
            classes.content,
            leftSidebarOpen && classes.leftSidebarOpen,
            Boolean(rightSidebar) && classes.hasRightSidebar,
            rightSidebarOpen && classes.rightSidebarOpen,
          )}
        >
          {children}
        </div>
        {rightSidebar}
      </div>
    </div>
  );
};
