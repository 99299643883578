import type { TFunction } from 'i18next';
import { TimeInterval } from '../api/building-insights';

export const timeIntervals = (
  t: TFunction,
): { [key in TimeInterval]: string } => ({
  [TimeInterval.customInterval]: t(
    'info.monitoring.timeInterval.customInterval',
  ),
  [TimeInterval.last15Minutes]: t('info.monitoring.timeInterval.last15Minutes'),
  [TimeInterval.last30Minutes]: t('info.monitoring.timeInterval.last30Minutes'),
  [TimeInterval.lastHour]: t('info.monitoring.timeInterval.lastHour'),
  [TimeInterval.last2Hours]: t('info.monitoring.timeInterval.last2Hours'),
  [TimeInterval.last4Hours]: t('info.monitoring.timeInterval.last4Hours'),
  [TimeInterval.last12Hours]: t('info.monitoring.timeInterval.last12Hours'),
  [TimeInterval.lastDay]: t('info.monitoring.timeInterval.lastDay'),
  [TimeInterval.last7Days]: t('info.monitoring.timeInterval.last7Days'),
  [TimeInterval.last30Days]: t('info.monitoring.timeInterval.last30Days'),
  [TimeInterval.last90Days]: t('info.monitoring.timeInterval.last90Days'),
});
