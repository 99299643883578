import { TimeScaleDataset } from '@kp/react-ui';
import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  getDateFromOfTimeInterval,
  TimeInterval,
  timeIntervalToREST,
} from '../../../api/building-insights';
import { useNotifications } from '../../../contexts/notifications-context';
import {
  AggregationIntervals,
  TimeSpans,
  useChartDataForDeviceQuery,
} from '../../../__generated__/types';
import { DeviceModelCapability } from '../Devices.types';
import { DevicePreviewChart } from '../../../components/DevicePreview/DevicePreviewChart';

interface PreviceDeviceChartContainerProps {
  deviceId: string;
  deviceModelCapabilities: DeviceModelCapability[];
}

export const PreviewDeviceChartContainer: React.FC<
  PreviceDeviceChartContainerProps
> = ({ deviceId, deviceModelCapabilities }) => {
  const { add } = useNotifications();

  const getIntervalSize = (ts: TimeSpans) => {
    if (ts === TimeSpans.Last_30Day) return AggregationIntervals.D_01;
    if (ts === TimeSpans.Last_7Day) return AggregationIntervals.H_06;
    return AggregationIntervals.H_01;
  };

  const [timeSpan, setTimeSpan] = useState<TimeSpans>(TimeSpans.Last_24Hour);

  const { data, loading } = useChartDataForDeviceQuery({
    variables: {
      deviceId,
      deviceModelCapabilityIds: deviceModelCapabilities.map(
        (daviceModelCapability) => ({
          deviceModelCapabilityId: daviceModelCapability.id,
        }),
      ),
      timeSpan,
      intervalSize: getIntervalSize(timeSpan),
    },
    skip: deviceModelCapabilities.length === 0,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const chartData: TimeScaleDataset[] = useMemo(() => {
    if (!data?.multipleDeviceSensorDataByViews?.[0]?.deviceAndCapabilityInfos) {
      return [];
    }
    const { deviceAndCapabilityInfos } =
      data.multipleDeviceSensorDataByViews[0];
    return deviceModelCapabilities.map((deviceModelCapability) => {
      const info = deviceAndCapabilityInfos.find(
        (entry) =>
          entry?.deviceModelCapabilityId === deviceModelCapabilities[0].id,
      );
      return {
        color: 'blue',
        unit: deviceModelCapability.unit.unitSymbol || '',
        label: deviceModelCapability.capability.name,
        data: (info?.telemetryRecords || []).map((record) => ({
          timestamp: record?.utcTimeMeasured || new Date(),
          value: record?.valueString ? parseFloat(record.valueString) : 0,
        })),
      };
    });
  }, [data, deviceModelCapabilities]);

  const [min, max] = useMemo(
    () => [
      getDateFromOfTimeInterval(
        timeIntervalToREST(timeSpan) || TimeInterval.lastDay,
      ),
      new Date(),
    ],
    [timeSpan],
  );

  return (
    <DevicePreviewChart
      loading={loading}
      deviceModelCapability={deviceModelCapabilities[0]}
      timeSpan={timeSpan}
      onChange={setTimeSpan}
      chartData={chartData}
      min={min}
      max={max}
    />
  );
};
