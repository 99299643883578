import React from 'react';
import { Colors, classNames, createClasses } from '@kp/react-ui';

const classes = createClasses({
  root: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    background: Colors.Neutral.background,
    position: 'relative',
  },
  scrollable: {
    '& thead th': {
      position: 'sticky',
      zIndex: 1,
      top: 0,
    },
  },
});

export interface WidgetTableProps extends React.ComponentProps<'table'> {
  className?: string;
  scrollable?: boolean;
}

export const WidgetTable: React.FC<WidgetTableProps> = ({
  className,
  scrollable,
  ...props
}) => (
  <table
    className={classNames(
      classes.root,
      scrollable && classes.scrollable,
      className,
    )}
    {...props}
  />
);
