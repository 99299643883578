import { subDays, subHours, subMinutes } from 'date-fns';
import { AggregationInterval, TimeInterval } from './common';
import { AggregationIntervals, TimeSpans } from '../../__generated__/types';

export const getDateFromOfTimeInterval = (
  timeInterval: TimeInterval,
  dateUntil = new Date(),
): Date => {
  switch (timeInterval) {
    case TimeInterval.last15Minutes:
      return subMinutes(dateUntil, 15);
    case TimeInterval.last30Minutes:
      return subMinutes(dateUntil, 30);
    case TimeInterval.lastHour:
      return subHours(dateUntil, 1);
    case TimeInterval.last2Hours:
      return subHours(dateUntil, 2);
    case TimeInterval.last4Hours:
      return subHours(dateUntil, 4);
    case TimeInterval.last12Hours:
      return subHours(dateUntil, 12);
    case TimeInterval.lastDay:
      return subDays(dateUntil, 1);
    case TimeInterval.last7Days:
      return subDays(dateUntil, 7);
    case TimeInterval.last30Days:
      return subDays(dateUntil, 30);
    case TimeInterval.last90Days:
      return subDays(dateUntil, 90);
    default:
      return subHours(dateUntil, 1);
  }
};

export const timeIntervalToGraphQL = (
  timeInterval: TimeInterval,
): TimeSpans | null => {
  switch (timeInterval) {
    case TimeInterval.last15Minutes:
      return TimeSpans.Last_15Min;
    case TimeInterval.last30Minutes:
      return TimeSpans.Last_30Min;
    case TimeInterval.lastHour:
      return TimeSpans.Last_1Hour;
    case TimeInterval.last2Hours:
      return TimeSpans.Last_2Hour;
    case TimeInterval.last4Hours:
      return TimeSpans.Last_4Hour;
    case TimeInterval.last12Hours:
      return TimeSpans.Last_12Hour;
    case TimeInterval.lastDay:
      return TimeSpans.Last_24Hour;
    case TimeInterval.last7Days:
      return TimeSpans.Last_7Day;
    case TimeInterval.last30Days:
      return TimeSpans.Last_30Day;
    case TimeInterval.last90Days:
      return TimeSpans.Last_90Day;
    default:
      return null;
  }
};

export const timeIntervalToREST = (
  timeInterval: TimeSpans,
): TimeInterval | null => {
  switch (timeInterval) {
    case TimeSpans.Last_15Min:
      return TimeInterval.last15Minutes;
    case TimeSpans.Last_30Min:
      return TimeInterval.last30Minutes;
    case TimeSpans.Last_1Hour:
      return TimeInterval.lastHour;
    case TimeSpans.Last_2Hour:
      return TimeInterval.last2Hours;
    case TimeSpans.Last_4Hour:
      return TimeInterval.last4Hours;
    case TimeSpans.Last_12Hour:
      return TimeInterval.last12Hours;
    case TimeSpans.Last_24Hour:
      return TimeInterval.lastDay;
    case TimeSpans.Last_7Day:
      return TimeInterval.last7Days;
    case TimeSpans.Last_30Day:
      return TimeInterval.last30Days;
    case TimeSpans.Last_90Day:
      return TimeInterval.last90Days;
    default:
      return null;
  }
};

export const aggregationIntervalToGraphQL = (
  aggregationInterval: AggregationInterval,
): AggregationIntervals => {
  switch (aggregationInterval) {
    case AggregationInterval.none:
      return AggregationIntervals.None;
    case AggregationInterval.ms500:
      return AggregationIntervals.Ms_500;
    case AggregationInterval.s01:
      return AggregationIntervals.S_01;
    case AggregationInterval.s02:
      return AggregationIntervals.S_02;
    case AggregationInterval.s03:
      return AggregationIntervals.S_03;
    case AggregationInterval.s04:
      return AggregationIntervals.S_04;
    case AggregationInterval.s05:
      return AggregationIntervals.S_05;
    case AggregationInterval.s06:
      return AggregationIntervals.S_06;
    case AggregationInterval.s10:
      return AggregationIntervals.S_10;
    case AggregationInterval.s12:
      return AggregationIntervals.S_12;
    case AggregationInterval.s15:
      return AggregationIntervals.S_15;
    case AggregationInterval.s20:
      return AggregationIntervals.S_20;
    case AggregationInterval.s30:
      return AggregationIntervals.S_30;
    case AggregationInterval.m01:
      return AggregationIntervals.M_01;
    case AggregationInterval.m02:
      return AggregationIntervals.M_02;
    case AggregationInterval.m03:
      return AggregationIntervals.M_03;
    case AggregationInterval.m04:
      return AggregationIntervals.M_04;
    case AggregationInterval.m05:
      return AggregationIntervals.M_05;
    case AggregationInterval.m06:
      return AggregationIntervals.M_06;
    case AggregationInterval.m10:
      return AggregationIntervals.M_10;
    case AggregationInterval.m12:
      return AggregationIntervals.M_12;
    case AggregationInterval.m15:
      return AggregationIntervals.M_15;
    case AggregationInterval.m20:
      return AggregationIntervals.M_20;
    case AggregationInterval.m30:
      return AggregationIntervals.M_30;
    case AggregationInterval.h01:
      return AggregationIntervals.H_01;
    case AggregationInterval.h02:
      return AggregationIntervals.H_02;
    case AggregationInterval.h03:
      return AggregationIntervals.H_03;
    case AggregationInterval.h04:
      return AggregationIntervals.H_04;
    case AggregationInterval.h06:
      return AggregationIntervals.H_06;
    case AggregationInterval.h08:
      return AggregationIntervals.H_08;
    case AggregationInterval.h12:
      return AggregationIntervals.H_12;
    case AggregationInterval.d01:
      return AggregationIntervals.D_01;
    case AggregationInterval.d02:
      return AggregationIntervals.D_02;
    case AggregationInterval.d03:
      return AggregationIntervals.D_03;
    case AggregationInterval.d04:
      return AggregationIntervals.D_04;
    case AggregationInterval.d05:
      return AggregationIntervals.D_05;
    case AggregationInterval.d06:
      return AggregationIntervals.D_06;
    case AggregationInterval.d07:
      return AggregationIntervals.D_07;
    default:
      return AggregationIntervals.None;
  }
};

export const aggregationIntervalToREST = (
  aggregationInterval: AggregationIntervals,
): AggregationInterval => {
  switch (aggregationInterval) {
    case AggregationIntervals.None:
      return AggregationInterval.none;
    case AggregationIntervals.Ms_500:
      return AggregationInterval.ms500;
    case AggregationIntervals.S_01:
      return AggregationInterval.s01;
    case AggregationIntervals.S_02:
      return AggregationInterval.s02;
    case AggregationIntervals.S_03:
      return AggregationInterval.s03;
    case AggregationIntervals.S_04:
      return AggregationInterval.s04;
    case AggregationIntervals.S_05:
      return AggregationInterval.s05;
    case AggregationIntervals.S_06:
      return AggregationInterval.s06;
    case AggregationIntervals.S_10:
      return AggregationInterval.s10;
    case AggregationIntervals.S_12:
      return AggregationInterval.s12;
    case AggregationIntervals.S_15:
      return AggregationInterval.s15;
    case AggregationIntervals.S_20:
      return AggregationInterval.s20;
    case AggregationIntervals.S_30:
      return AggregationInterval.s30;
    case AggregationIntervals.M_01:
      return AggregationInterval.m01;
    case AggregationIntervals.M_02:
      return AggregationInterval.m02;
    case AggregationIntervals.M_03:
      return AggregationInterval.m03;
    case AggregationIntervals.M_04:
      return AggregationInterval.m04;
    case AggregationIntervals.M_05:
      return AggregationInterval.m05;
    case AggregationIntervals.M_06:
      return AggregationInterval.m06;
    case AggregationIntervals.M_10:
      return AggregationInterval.m10;
    case AggregationIntervals.M_12:
      return AggregationInterval.m12;
    case AggregationIntervals.M_15:
      return AggregationInterval.m15;
    case AggregationIntervals.M_20:
      return AggregationInterval.m20;
    case AggregationIntervals.M_30:
      return AggregationInterval.m30;
    case AggregationIntervals.H_01:
      return AggregationInterval.h01;
    case AggregationIntervals.H_02:
      return AggregationInterval.h02;
    case AggregationIntervals.H_03:
      return AggregationInterval.h03;
    case AggregationIntervals.H_04:
      return AggregationInterval.h04;
    case AggregationIntervals.H_06:
      return AggregationInterval.h06;
    case AggregationIntervals.H_08:
      return AggregationInterval.h08;
    case AggregationIntervals.H_12:
      return AggregationInterval.h12;
    case AggregationIntervals.D_01:
      return AggregationInterval.d01;
    case AggregationIntervals.D_02:
      return AggregationInterval.d02;
    case AggregationIntervals.D_03:
      return AggregationInterval.d03;
    case AggregationIntervals.D_04:
      return AggregationInterval.d04;
    case AggregationIntervals.D_05:
      return AggregationInterval.d05;
    case AggregationIntervals.D_06:
      return AggregationInterval.d06;
    case AggregationIntervals.D_07:
      return AggregationInterval.d07;
    default:
      return AggregationInterval.none;
  }
};
