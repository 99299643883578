import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Colors, createClasses, injectGlobal } from '@kp/react-ui';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useResizeObserverRef } from 'rooks';
import { useFormContext } from 'react-hook-form';
import { t } from 'i18next';
import {
  DragOverEvent,
  Layout,
  Responsive as GridLayout,
  WidthProvider,
} from 'react-grid-layout';
import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';
import { DashboardForm, DashboardFormSubmit } from './Dashboard.types';
import {
  DashboardCard,
  DashboardCardType,
} from '../../../api/building-insights';
import {
  ChartCardContainer,
  ClockCardContainer,
  LastValueCardContainer,
} from '../cards';
import { CARD_SIZE_LIMITS } from '../constants';
import { ImageCard } from '../cards/ImageCard';

injectGlobal({
  body: {
    '.react-grid-item.react-grid-placeholder': {
      backgroundColor: Colors.Selected.backgroundStrongHover,
      borderRadius: '6px',
    },
    '.react-grid-item > .react-resizable-handle::after': {
      all: 'unset',
    },
    '.react-grid-item > .react-resizable-handle.react-resizable-handle-se': {
      backgroundImage:
        "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgNyA3IiBmaWxsPSJub25lIgogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik01IDdDNi4xMDQ1NyA3IDcgNi4xMDQ1NyA3IDVWMUM3IDAuNDQ3NzE1IDYuNTUyMjggMCA2IDBDNS40NDc3MiAwIDUgMC40NDc3MTUgNSAxVjVIMUMwLjQ0NzcxNSA1IDAgNS40NDc3MiAwIDZDMCA2LjU1MjI4IDAuNDQ3NzE1IDcgMSA3SDVaIiBmaWxsPSIjOUNBMUFCIi8+Cjwvc3ZnPgo=')",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom 3px right 3px',
      cursor: 'nwse-resize',
    },
  },
});

const classes = createClasses({
  root: {
    userSelect: 'none',
    height: '100%',
  },
  gridLayout: {
    minHeight: '100%',
  },
  card: {
    '&:has(.spacer)': {
      zIndex: 99999,
    },
  },
});

const ResponsiveGridLayout = WidthProvider(GridLayout);

interface DashboardGridProps {
  loading?: boolean;
  submit: DashboardFormSubmit;
  selectedCardId?: string;
  className?: string;
  onSelectCard: (cardId: string | undefined) => void;
}

const cardToLayoutTransition = (cards: DashboardCard[]): Layout[] =>
  cards.map(({ id, x, y, w, h, type }) => {
    return {
      i: id?.toString() || '',
      x,
      y,
      w,
      h,
      ...CARD_SIZE_LIMITS[type],
    };
  }) || [];

type LayoutToCardTransitionResultType = {
  hasChange: boolean;
  cards: DashboardCard[];
};
const layoutToCardTransition = (
  layout: Layout[],
  cards: DashboardCard[],
): LayoutToCardTransitionResultType => {
  const result: LayoutToCardTransitionResultType = {
    hasChange: false,
    cards: [],
  };

  layout.forEach((layoutCard) => {
    const card = cards.find((item) => item.id === layoutCard.i);
    if (card) {
      if (
        card.x !== layoutCard.x ||
        card.y !== layoutCard.y ||
        card.w !== layoutCard.w ||
        card.h !== layoutCard.h
      )
        result.hasChange = true;

      result.cards.push({
        ...card,
        x: layoutCard.x,
        y: layoutCard.y,
        w: layoutCard.w,
        h: layoutCard.h,
      } as unknown as DashboardCard);
    }
  });

  return result;
};

export const DashboardGrid: React.FC<DashboardGridProps> = ({
  loading,
  submit,
  selectedCardId,
  onSelectCard,
}) => {
  const [layoutWidth, setLayoutWidth] = useState(1200);
  const { watch, setValue } = useFormContext<DashboardForm>();
  const { hasPermission } = useAuth();

  const cards = watch('cards', []);
  const layouts = useMemo(() => cardToLayoutTransition(cards || []), [cards]);

  const handleResize = useCallback<ResizeObserverCallback>(
    (entries) => {
      const target = entries?.[0]?.target;
      if (target) {
        setLayoutWidth(target.getBoundingClientRect().width);
      }
    },
    [setLayoutWidth],
  );

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [layoutWidth]);

  const [ref] = useResizeObserverRef(handleResize);

  const handleDropDragOver = (
    e: DragOverEvent & { dataTransfer: DataTransfer },
  ) => {
    const dragCardType =
      Object.values(DashboardCardType).find((type) =>
        e.dataTransfer.types.includes(type),
      ) || DashboardCardType.lastValue;
    return {
      h: CARD_SIZE_LIMITS[dragCardType].minH,
      w: CARD_SIZE_LIMITS[dragCardType].minW,
    };
  };

  const handleDropCard = useCallback(
    (
      layout: Layout[],
      item: Layout,
      e: Event & { dataTransfer: DataTransfer },
    ) => {
      const newCardType =
        Object.values(DashboardCardType).find((type) =>
          e.dataTransfer.types.includes(type),
        ) || DashboardCardType.lastValue;

      const newCard = {
        x: item.x,
        y: item.y,
        w: CARD_SIZE_LIMITS[newCardType].minW,
        h: CARD_SIZE_LIMITS[newCardType].minH,
        type: newCardType,
      } as unknown as DashboardCard;

      // TEMPORARY IMAGE CARD CONFIGURATION
      if (newCardType === DashboardCardType.image) {
        newCard.configuration = {
          items: [{ url: 'https://placeholder.com/300x100' }],
        };
      }

      const transitionResult = layoutToCardTransition(layout, cards);
      setValue('cards', [...transitionResult.cards, newCard]);

      submit().catch(console.warn);
    },
    [setValue, submit, cards],
  );

  const handleMovementCard = useCallback(
    (layout: Layout[]) => {
      const transitionResult = layoutToCardTransition(layout, cards);
      if (transitionResult.hasChange) {
        setValue('cards', transitionResult.cards);
        submit().catch(console.warn);
      }
    },
    [setValue, submit, cards],
  );

  const handleDeleteCard = useCallback(
    (id: string) => {
      setValue(
        'cards',
        cards.filter((card) => card.id !== id),
      );
      submit().catch(console.warn);
    },
    [setValue, cards, submit],
  );

  return (
    <div ref={ref} data-testid={'grid-layout-wrapper'} className={classes.root}>
      <ResponsiveGridLayout
        layouts={{ lg: layouts }}
        breakpoints={{ lg: 0 }}
        cols={{ lg: 12 }}
        width={layoutWidth}
        rowHeight={64}
        isDraggable={hasPermission(Permissions.WidgetsWrite) && !loading}
        isResizable={hasPermission(Permissions.WidgetsWrite)}
        margin={[12, 12]}
        isDroppable
        className={classes.gridLayout}
        onDrop={handleDropCard}
        onResizeStop={handleMovementCard}
        onDragStop={handleMovementCard}
        onDropDragOver={handleDropDragOver}
      >
        {cards
          .filter((card) => Boolean(card.id))
          .map((card) => (
            <div
              key={card.id}
              data-testid={`card-${card.id}`}
              className={classes.card}
            >
              {card.type === DashboardCardType.chart && (
                <ChartCardContainer
                  selected={selectedCardId === card.id}
                  id={card.id}
                  title={card.title || t('dashboards.card.untitled')}
                  subtitle={card.subtitle}
                  configuration={card.configuration}
                  onEdit={
                    hasPermission(Permissions.WidgetsWrite)
                      ? onSelectCard
                      : undefined
                  }
                  onDelete={
                    hasPermission(Permissions.WidgetsWrite)
                      ? handleDeleteCard
                      : undefined
                  }
                />
              )}

              {card.type === DashboardCardType.lastValue && (
                <LastValueCardContainer
                  id={card.id}
                  selected={selectedCardId === card.id}
                  title={card.title || t('dashboards.card.untitled')}
                  subtitle={card.subtitle}
                  configuration={card.configuration}
                  onEdit={
                    hasPermission(Permissions.WidgetsWrite)
                      ? onSelectCard
                      : undefined
                  }
                  onDelete={
                    hasPermission(Permissions.WidgetsWrite)
                      ? handleDeleteCard
                      : undefined
                  }
                />
              )}

              {card.type === DashboardCardType.image && (
                <ImageCard
                  id={card.id}
                  selected={selectedCardId === card.id}
                  title={card.title || t('dashboards.card.untitled')}
                  configuration={card.configuration}
                  onEdit={
                    hasPermission(Permissions.WidgetsWrite)
                      ? onSelectCard
                      : undefined
                  }
                  onDelete={
                    hasPermission(Permissions.WidgetsWrite)
                      ? handleDeleteCard
                      : undefined
                  }
                />
              )}

              {card.type === DashboardCardType.clock && (
                <ClockCardContainer
                  id={card.id}
                  selected={selectedCardId === card.id}
                  title={card.title || t('dashboards.card.untitled')}
                  subtitle={card.subtitle}
                  onEdit={
                    hasPermission(Permissions.WidgetsWrite)
                      ? onSelectCard
                      : undefined
                  }
                  onDelete={
                    hasPermission(Permissions.WidgetsWrite)
                      ? handleDeleteCard
                      : undefined
                  }
                />
              )}
            </div>
          ))}
      </ResponsiveGridLayout>
    </div>
  );
};
