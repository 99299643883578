import React, { useMemo } from 'react';
import { useAuth } from '@kp/react-sdk';
import { InteractiveModelEnabled } from '../../../constants/FeatureFlags';
import { Interactive } from './Interactive';
import { useBuilding } from '../../../contexts/building-context';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useGenericPages } from '../../../contexts/generic-page-context';

export const InteractiveContainer: React.FC = () => {
  const { id: buildingId, name: buildingName } = useBuilding();
  const { user } = useAuth();

  useBreadcrumb([
    {
      title: buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    {
      title: 'Interactive',
      location: `/buildings/${buildingId}/interactive`,
      selected: true,
    },
  ]);

  const model = useMemo(
    () =>
      InteractiveModelEnabled.find(
        ({ buildingId: bId, tenantId: tId }) =>
          bId === buildingId && tId === String(user?.info?.tenantId),
      ),
    [buildingId, user],
  );
  const { activateNotFound } = useGenericPages();
  if (!model) {
    activateNotFound();
    return null;
  }

  return <Interactive xktFile={model.xktFile} metaFile={model.metaFile} />;
};
