import React, { ChangeEventHandler, useCallback, useState } from 'react';
import {
  Button,
  ButtonBlock,
  ColorPicker,
  createClasses,
  Icons,
  Input,
} from '@kp/react-ui';
import { DashboardSelectCapabilitiesContainer } from '../../../DashboardSelectCapabilities/DashboardSelectCapabilitiesContainer';
import { DashboardCardChartItem } from '../../../../../api/building-insights';

const classes = createClasses({
  root: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: '8px',
  },
  device: {
    maxWidth: '600px',
    width: '40%',
  },
  capability: {
    maxWidth: '280px',
    width: '30%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
  },
  gap: {
    marginRight: '12px',
  },
});

interface ChartEditPanelCapabilityProp {
  loading: boolean;
  capability: DashboardCardChartItem & {
    id: string;
    deviceName: string;
    name: string;
    capabilityName: string;
    unitName: string;
    unitSymbol?: string;
  };
  selectedPreviewCapabilityId?: string;
  onChange: (id: string, capability: Partial<DashboardCardChartItem>) => void;
  onDelete: (id: string) => void;
  onPreview: (id: string) => void;
}

export const ChartEditPanelCapability: React.FC<
  ChartEditPanelCapabilityProp
> = ({
  loading,
  capability,
  selectedPreviewCapabilityId,
  onChange,
  onDelete,
  onPreview,
}) => {
  const [selectCapabilityModalOpen, setSelectCapabilityModalOpen] =
    useState(false);

  const handleSelectCapability = useCallback(
    (selectedCapability: { deviceId: string; capabilityId: string }) => {
      setSelectCapabilityModalOpen(false);
      onChange(capability.id, {
        deviceId: selectedCapability.deviceId,
        capabilityId: selectedCapability.capabilityId,
      });
    },
    [onChange, capability],
  );

  const handleChangeName = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(capability.id, { name: event.target.value });
    },
    [capability, onChange],
  );

  const handleChangeColor = useCallback(
    (color: string) => {
      onChange(capability.id, { color });
    },
    [capability, onChange],
  );

  const handleRemove = useCallback(() => {
    onDelete(capability.id);
  }, [capability, onDelete]);

  const handleShowPreview = useCallback(() => {
    onPreview(capability.id);
  }, [capability, onPreview]);

  return (
    <div className={classes.root}>
      <DashboardSelectCapabilitiesContainer
        onAdd={handleSelectCapability}
        selectedCapability={capability}
        onClose={() => setSelectCapabilityModalOpen(false)}
        open={selectCapabilityModalOpen}
      />
      <div className={classes.device}>
        <ButtonBlock
          disabled={loading}
          label={capability.deviceName}
          chipLabel={
            capability &&
            `${capability.capabilityName}, ${capability.unitSymbol}`
          }
          onClick={() => setSelectCapabilityModalOpen(true)}
        />
      </div>
      <div className={classes.capability}>
        <Input
          value={capability.name}
          disabled={loading}
          fullWidth
          onChange={handleChangeName}
        />
      </div>
      <div className={classes.actions}>
        <Button
          icon={Icons.IconPreview}
          variant="secondary"
          size="default"
          onClick={handleShowPreview}
          selected={selectedPreviewCapabilityId === capability.id}
          className={classes.gap}
        />
        <ColorPicker
          data-testid={`color-picker-capability-${capability.capabilityId}`}
          value={capability.color}
          onChange={handleChangeColor}
        />
        <Button
          icon={Icons.IconTrash}
          variant="tertiary"
          size="default"
          onClick={handleRemove}
        />
      </div>
    </div>
  );
};
