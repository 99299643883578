import React, { useCallback } from 'react';
import { createClasses, Typography, TextLink, Colors } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { AggregationDropdownContainer } from './AggregationDropdownContainer';
import { useDashboard } from '../../../dashboard-context';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  deleteButton: {
    paddingLeft: 0,
    textAlign: 'left',
    borderTop: `1px solid ${Colors.Neutral.border}`,
    paddingTop: '20px',
  },
});

interface DashboardRightSidebarSettingsProps {
  onDelete: () => void;
  submit: () => void;
}

export const DashboardRightSidebarSettings: React.FC<
  DashboardRightSidebarSettingsProps
> = ({ onDelete, submit }) => {
  const { t } = useTranslation();

  const { setRightSidebarOpen, setSelectedRightSidebarTab } = useDashboard();

  const handleOnDelete = useCallback(() => {
    onDelete();
    setRightSidebarOpen(false);
    setSelectedRightSidebarTab(undefined);
  }, [onDelete, setRightSidebarOpen, setSelectedRightSidebarTab]);

  return (
    <div className={classes.root}>
      <Typography variant="labelLarge" component="div">
        {t('dashboards.rightSidebar.settings.title')}
      </Typography>
      <AggregationDropdownContainer submit={submit} />
      <TextLink
        component="button"
        danger
        onClick={handleOnDelete}
        className={classes.deleteButton}
      >
        {t('dashboards.rightSidebar.settings.actions.delete')}
      </TextLink>
    </div>
  );
};
