import { createClasses, Icons, Input, InputButton } from '@kp/react-ui';
import React, { ChangeEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },

  input: {
    maxWidth: '296px',
  },
});

interface ClockEditPanelProps {
  title?: string;
  subtitle?: string;
  onChangeTitle: (title: string) => void;
  onChangeSubtitle: (subtitle: string) => void;
  onSubmit: () => void;
}

export const ClockEditPanel: React.FC<ClockEditPanelProps> = ({
  title,
  subtitle,
  onChangeTitle,
  onChangeSubtitle,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const handleTitleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => onChangeTitle(event.target.value),
    [onChangeTitle],
  );

  const handleSubtitleSelect = useCallback(() => {
    onChangeSubtitle('[date]');
    onSubmit();
  }, [onChangeSubtitle, onSubmit]);

  const handleSubtitleReset = useCallback(() => {
    onChangeSubtitle('');
  }, [onChangeSubtitle]);

  const handleSubtitleChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((event) => onChangeSubtitle(event.target.value), [onChangeSubtitle]);

  return (
    <div className={classes.root}>
      <div className={classes.input}>
        <Input
          placeholder={t('dashboards.card.untitled') || ''}
          label={t('dashboards.card.editPanel.fields.title') || ''}
          value={title}
          onChange={handleTitleChange}
          onBlur={onSubmit}
          fullWidth
        />
      </div>
      <div className={classes.input}>
        <InputButton
          selected={subtitle === '[date]'}
          label={t('dashboards.card.editPanel.fields.subtitle') || ''}
          icon={Icons.IconCalendarTime}
          value={
            subtitle === '[date]'
              ? t('dashboards.card.editPanel.fields.showDate') || ''
              : subtitle
          }
          onSelect={handleSubtitleSelect}
          onReset={handleSubtitleReset}
          onChange={handleSubtitleChange}
          placeholder={t('dashboards.card.editPanel.fields.placeholder') || ''}
          onBlur={onSubmit}
        />
      </div>
    </div>
  );
};
