import React from 'react';
import { createClasses, classNames } from '@kp/react-ui';

const classes = createClasses({
  root: {},
});

interface WidgetTableRowProps extends React.ComponentProps<'tr'> {
  className?: string;
  loading?: boolean;
}

export const WidgetTableRow: React.FC<WidgetTableRowProps> = ({
  className,
  loading,
  ...props
}) => (
  <tr
    className={classNames(classes.root, loading && 'loading', className)}
    {...props}
  />
);
