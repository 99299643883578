import React, { useCallback } from 'react';
import {
  TableRow,
  ColorPicker,
  TableCell,
  Checkbox,
  createClasses,
} from '@kp/react-ui';
import { createRangeString } from '../../../utils/helpers';

const classes = createClasses({
  actions: {
    display: 'flex',
  },
});

export interface DeviceChartTableRowProps {
  id: string;
  selected?: boolean;
  color: string;
  name: string;
  unitName: string;
  unitSymbol?: string;
  technicalMin?: number;
  technicalMax?: number;
  onChangeSelected: (capabilityId: string, selected: boolean) => void;
  onChangeColor: (capabilityId: string, color: string) => void;
}

export const DeviceChartTableRow: React.FC<DeviceChartTableRowProps> = ({
  id,
  selected,
  color,
  name,
  unitName,
  unitSymbol,
  technicalMin,
  technicalMax,
  onChangeSelected,
  onChangeColor,
}) => {
  const handleChangeSelected = useCallback(() => {
    onChangeSelected(id, !selected);
  }, [onChangeSelected, id, selected]);

  const handleChangeColor = useCallback(
    (value: string) => {
      onChangeColor(id, value);
    },
    [onChangeColor, id],
  );

  return (
    <TableRow>
      <TableCell>
        <div className={classes.actions}>
          <Checkbox
            onChange={handleChangeSelected}
            checked={selected}
            data-testid={`change-capability-${id}-selected`}
          />
          <ColorPicker
            value={color}
            onChange={handleChangeColor}
            data-testid={`change-capability-${id}-color`}
          />
        </div>
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        {unitName} {unitSymbol ? `(${unitSymbol})` : ''}
      </TableCell>
      <TableCell>
        {createRangeString({ unitSymbol, technicalMin, technicalMax })}
      </TableCell>
    </TableRow>
  );
};
