import React from 'react';
import { Colors, Typography, classNames, createClasses } from '@kp/react-ui';
import { animations } from '../../utils/css';

const classes = createClasses({
  root: {
    color: Colors.Neutral.text,
    borderBottom: `1px solid ${Colors.Neutral.borderMedium}`,
    borderLeft: `1px solid ${Colors.Neutral.borderMedium}`,
    height: '32px',
    width: '200px',
    padding: '0 12px',
    '&:first-child': {
      width: '164px',
    },
    '&:last-child': {
      borderRight: `1px solid ${Colors.Neutral.borderMedium}`,
    },
  },
  loading: {
    borderBottomColor: 'transparent',
  },
  skeleton: {
    borderRadius: '2px',
    width: '100%',
    background: Colors.Neutral.backgroundHoverStrong,
    height: '12px',
    animationDuration: '2s',
    animationName: animations.slideRight,
    animationIterationCount: 'infinite',
  },
});

interface WidgetTableCellProps extends React.ComponentProps<'td'> {
  className?: string;
  loading?: boolean;
}

export const WidgetTableCell: React.FC<WidgetTableCellProps> = ({
  className,
  loading,
  children,
  ...props
}) => (
  <Typography
    variant="labelSmall"
    component="td"
    className={classNames(classes.root, loading && classes.loading, className)}
    {...props}
  >
    {loading ? <div className={classes.skeleton} /> : children}
  </Typography>
);
