import React, { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Colors,
  Typography,
  TickListItem,
  classNames,
  createClasses,
} from '@kp/react-ui';
import { useBuilding } from '../../contexts/building-context';
import { DEVICES_LEFT_SIDEBAR_WIDTH } from '../../constants/UI';

const classes = createClasses({
  root: {
    position: 'absolute',
    borderRight: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.background,
    left: 0,
    transform: `translateX(-${DEVICES_LEFT_SIDEBAR_WIDTH}px)`,
    transition: 'transform 0.2s',
    width: DEVICES_LEFT_SIDEBAR_WIDTH,
    bottom: 0,
    height: 'calc(100% - 48px)',
    zIndex: 40,
  },
  open: {
    transform: 'translateX(0)',
  },
  title: {
    padding: '10px 24px 9px',
    borderBottom: `1px solid ${Colors.Neutral.borderMedium}`,
  },
});

export interface DevicesSidebarProps {
  open?: boolean;
  onStoryFilter: (filterId?: string) => void;
  storeyId?: string | null;
}

export const DevicesSidebar: React.FC<DevicesSidebarProps> = ({
  open,
  onStoryFilter,
  storeyId,
}) => {
  const { t } = useTranslation();
  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const id = (event.target as HTMLInputElement).value || undefined;
      onStoryFilter(id === 'all' ? undefined : id);
    },
    [onStoryFilter],
  );

  const { storeys } = useBuilding();

  return (
    <div
      className={classNames(classes.root, open && classes.open)}
      data-testid="floor-selection-sidebar"
    >
      <Typography
        variant="titleSmall"
        className={classes.title}
        component="div"
      >
        {t('devices.floorSelection.sidebar.title')}
      </Typography>
      <TickListItem
        value={'all'}
        label={t('devices.floorSelection.sidebar.allFloors')}
        selected={!storeyId}
        onClick={handleClick}
        data-testid="floor-selection-sidebar-all-button"
      />
      {storeys
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ id, name }) => (
          <TickListItem
            value={id}
            label={name}
            selected={storeyId === id}
            onClick={handleClick}
            key={id}
            data-testid={`floor-selection-sidebar-storeyId-${id}`}
          />
        ))}
    </div>
  );
};
