import urljoin from 'url-join';
import { BUILDING_INSIGHTS_SERVICE_URI } from '../../utils/env';

/**
 * Chart type
 */
export enum ChartType {
  line = 'line',
  table = 'table',
  bar = 'bar',
}

/**
 * Time interval
 */
export enum TimeInterval {
  customInterval = 'customInterval',
  last15Minutes = 'last15Minutes',
  last30Minutes = 'last30Minutes',
  lastHour = 'lastHour',
  last2Hours = 'last2Hours',
  last4Hours = 'last4Hours',
  last12Hours = 'last12Hours',
  lastDay = 'lastDay',
  last7Days = 'last7Days',
  last30Days = 'last30Days',
  last90Days = 'last90Days',
}

/**
 * Aggregation interval
 */
export enum AggregationInterval {
  none = 'none',
  ms500 = 'ms500',
  s01 = 's01',
  s02 = 's02',
  s03 = 's03',
  s04 = 's04',
  s05 = 's05',
  s06 = 's06',
  s10 = 's10',
  s12 = 's12',
  s15 = 's15',
  s20 = 's20',
  s30 = 's30',
  m01 = 'm01',
  m02 = 'm02',
  m03 = 'm03',
  m04 = 'm04',
  m05 = 'm05',
  m06 = 'm06',
  m10 = 'm10',
  m12 = 'm12',
  m15 = 'm15',
  m20 = 'm20',
  m30 = 'm30',
  h01 = 'h01',
  h02 = 'h02',
  h03 = 'h03',
  h04 = 'h04',
  h06 = 'h06',
  h08 = 'h08',
  h12 = 'h12',
  d01 = 'd01',
  d02 = 'd02',
  d03 = 'd03',
  d04 = 'd04',
  d05 = 'd05',
  d06 = 'd06',
  d07 = 'd07',
}

/**
 * Widget list types
 */
export enum WidgetListType {
  TWO_COLUMNS = 'twoColumns',
  THREE_COLUMNS = 'threeColumns',
}

/**
 * Capability entity
 */
export interface Capability {
  id?: string;
  deviceId: string;
  capabilityId: string;
  widgetId?: string;
  selected: boolean;
  color: string;
}

/**
 * Widget entity
 */
export interface WidgetEntity {
  id: string;
  name: string;
  chartType: ChartType;
  timeInterval: TimeInterval;
  dateFrom?: string;
  dateTo?: string;
  collapsed: boolean;
  position: number;
  buildingId: string;
  capabilities?: Capability[];
  createdAt: string;
  aggregationInterval: AggregationInterval;
  favourite: boolean;
  showPoints?: boolean;
  comparisonLine?: boolean;
}

/**
 * Widget Settings Entity
 */
export interface WidgetSettings {
  widgetListType?: WidgetListType;
}

export interface BuildingEntity {
  buildingId: string;
}

export interface CreateWidgetData
  extends Omit<WidgetEntity, 'id' | 'position' | 'createdAt' | 'capabilities'> {
  capabilities?: Omit<Capability, 'id' | 'widgetId'>[];
}

export type UpdateWidgetData = Omit<
  WidgetEntity,
  'position' | 'createdAt | buildingId'
>;

/**
 * Dashboard entity
 */
export enum DashboardCardType {
  lastValue = 'lastValue',
  chart = 'chart',
  image = 'image',
  clock = 'clock',
}

export interface DashboardCardChartItem {
  id?: string;
  name: string;
  color: string;
  deviceId: string;
  capabilityId: string;
}

export interface DashboardCardImageItem {
  id?: string;
  url: string;
}

interface GenericDashboardCard<T extends DashboardCardType, C extends {}> {
  id: string;
  title?: string;
  subtitle?: string;
  type: T;
  x: number;
  y: number;
  w: number;
  h: number;
  configuration?: C;
}

export type DashboardCardChart = GenericDashboardCard<
  DashboardCardType.chart,
  {
    type: ChartType;
    items: DashboardCardChartItem[];
  }
>;

export type DashboardCardLastValue = GenericDashboardCard<
  DashboardCardType.lastValue,
  {
    deviceId: string;
    capabilityId: string;
  }
>;

export type DashboardCardImage = GenericDashboardCard<
  DashboardCardType.image,
  {
    items: DashboardCardImageItem[];
  }
>;

export type DashboardCardClock = GenericDashboardCard<
  DashboardCardType.clock,
  {}
>;

export type DashboardCard =
  | DashboardCardChart
  | DashboardCardLastValue
  | DashboardCardImage
  | DashboardCardClock;

export interface DashboardEntity {
  id: string;
  name: string;
  timeInterval: TimeInterval;
  aggregationInterval?: AggregationInterval;
  dateFrom?: string;
  dateTo?: string;
  buildingId: string;
  createdAt: string;
}

export interface DashboardDetailEntity extends DashboardEntity {
  cards: DashboardCard[];
}

export interface CreateDashboardData
  extends Omit<DashboardEntity, 'createdAt' | 'id'> {}

export interface UpdateDashboardData
  extends Omit<DashboardDetailEntity, 'createdAt'> {}

/**
 * Create url that points to the Building Insights Service
 *
 * @param segment  The segment path of the url
 */
export const url = (segment: string): string =>
  urljoin(BUILDING_INSIGHTS_SERVICE_URI, segment);
