import React, { useState, useEffect } from 'react';
import { useNotifications } from '../../../contexts/notifications-context';
import { Qanteon } from './Qanteon';
import { useRequest } from '../../../hooks/useRequest';
import { getQanteonProvision } from '../../../api/qanteon';
import { ErrorCodes } from '../../../api/qanteon/common';
import { Loader } from '../../../components/Loader';
import { useGenericPages } from '../../../contexts/generic-page-context';

export const QanteonContainer: React.FC = () => {
  const { add } = useNotifications();
  const [url, setUrl] = useState('');

  const { response, error, loading } = useRequest(getQanteonProvision);

  useEffect(() => {
    if (loading) {
      return;
    }
    const noProvision = error?.responseCode === ErrorCodes.NO_PROVISION_FOUND;
    if (error && !noProvision) {
      add({
        type: 'danger',
        id: error.message,
        content: error.message,
      });
    } else if (response?.data?.url) {
      setUrl(response.data.url);
    }
  }, [error, response, loading, add, setUrl]);

  const { activateNotFound } = useGenericPages();

  useEffect(() => {
    if (error && error?.responseCode === ErrorCodes.NO_PROVISION_FOUND) {
      activateNotFound();
    }
  }, [loading, response, error, url, activateNotFound]);

  if (!url) {
    return <Loader />;
  }

  return <Qanteon url={url} />;
};
