import React from 'react';
import {
  Colors,
  classNames,
  createClasses,
  TimeScaleDataset,
} from '@kp/react-ui';
import Lottie, { LottieProps } from 'react-lottie-player';
import { Capability, ChartType } from '../../../../api/building-insights';
import chartLoadingAnimationData from '../../../../animations/chart-loading.json';
import { WidgetTableContainer } from '../../../../components/WidgetTable';
import { Chart } from '../../../../components/Chart';
import { WidgetFormChartEmpty } from './WidgetFormChartEmpty';

const animationOptions: LottieProps = {
  loop: true,
  play: true,
  animationData: chartLoadingAnimationData,
  style: { width: 64, height: 120 },
};

const classes = createClasses({
  root: {
    display: 'flex',
    width: '100%',
  },
  emptyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.Neutral.backgroundMedium,
  },
  chartContainer: {
    width: '100%',
    padding: '20px',
    overflow: 'auto',
  },
  backgroundChart: {
    background: Colors.Neutral.backgroundMedium,
  },
  backgroundTable: {
    background: Colors.Neutral.background,
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

export interface WidgetFormChartProps {
  loading?: boolean;
  capabilities: Capability[];
  chartData: Array<TimeScaleDataset & { deviceName: string }>;
  chartType: ChartType;
  collapsed: boolean;
  min?: Date;
  max?: Date;
  showPoints?: boolean;
  comparisonLine?: boolean;
}

export const WidgetFormChart: React.FC<WidgetFormChartProps> = ({
  capabilities,
  loading,
  chartData,
  chartType,
  collapsed,
  min,
  max,
  showPoints,
  comparisonLine,
}) => {
  const hasCapabilities = capabilities.length > 0;
  const showPlaceholder = !loading && !hasCapabilities;

  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.loader}>
          <Lottie {...animationOptions} />
        </div>
      )}
      {showPlaceholder && <WidgetFormChartEmpty />}
      {!loading && !showPlaceholder && (
        <div
          className={classNames(
            classes.chartContainer,
            chartType === ChartType.table
              ? classes.backgroundTable
              : classes.backgroundChart,
          )}
        >
          {chartType === ChartType.table ? (
            <WidgetTableContainer
              data={chartData}
              size={'large'}
              data-testid="table-view"
            />
          ) : (
            <Chart
              datasets={chartData}
              type={chartType === ChartType.bar ? 'bar' : 'line'}
              collapsed={collapsed}
              size="large"
              max={max}
              min={min}
              showIntervalPoint={showPoints}
              showCrosshair={comparisonLine}
              cursorStyle={comparisonLine ? 'crosshair' : 'default'}
            />
          )}
        </div>
      )}
    </div>
  );
};
