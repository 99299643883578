import React, { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  ChartType,
  CreateWidgetData,
  TimeInterval,
  createWidget,
  aggregationIntervalToREST,
} from '../../../api/building-insights';
import { useNotifications } from '../../../contexts/notifications-context';
import { usePrepareRequest } from '../../../hooks/useRequest';
import { WidgetFormContainer, WidgetFormData } from './WidgetFormContainer';
import { useBuilding } from '../../../contexts/building-context';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { DEFAULT_INTERVAL_SIZE } from '../../../constants/Defaults';

export const WidgetCreateContainer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: buildingId, name: buildingName } = useBuilding();
  const { add } = useNotifications();
  const [create, { loading }] = usePrepareRequest(createWidget);

  const handleCancel = useCallback(() => {
    navigate(`/buildings/${buildingId}/info`, {
      state: { scrollTo: 'widgets' },
    });
  }, [navigate, buildingId]);

  const handleSave = async (data: WidgetFormData) => {
    const createWidgetData: CreateWidgetData = {
      name: data?.name || t('widgets.name.default'),
      buildingId: buildingId || '',
      chartType:
        data?.chartType === ChartType.table ? ChartType.line : data?.chartType,
      timeInterval: data?.timeInterval,
      dateFrom: data.dateFrom
        ? format(data.dateFrom, "yyyy-MM-dd'T'HH:mm:ss.SSS")
        : undefined,
      dateTo: data.dateUntil
        ? format(data.dateUntil, "yyyy-MM-dd'T'HH:mm:ss.SSS")
        : undefined,
      collapsed: data?.behaviour === 'combined',
      aggregationInterval: aggregationIntervalToREST(data?.intervalSize),
      capabilities: (data?.capabilities || []).map((capability) => ({
        deviceId: capability.deviceId,
        capabilityId: capability.capabilityId,
        color: capability.color,
        selected: capability.selected,
      })),
      favourite: false,
      showPoints: data?.showPoints,
      comparisonLine: data?.comparisonLine,
    };

    const response = await create(createWidgetData);

    if (!response.error) {
      navigate(`/buildings/${buildingId}/info`, {
        state: { scrollTo: 'widgets' },
      });
    } else {
      add({
        type: 'danger',
        id: response.error.message,
        content: response.error.message,
      });
    }
  };

  useBreadcrumb([
    {
      title: buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    {
      title: t('info.headerNavigation.createWidget'),
      location: `/buildings/${buildingId}/widgets/new`,
      selected: true,
    },
  ]);

  const initialValues: WidgetFormData = useMemo(
    () => ({
      name: '',
      timeInterval: TimeInterval.lastDay,
      dateFrom: undefined,
      dateUntil: undefined,
      behaviour: 'stacked',
      chartType: ChartType.line,
      intervalSize: DEFAULT_INTERVAL_SIZE,
    }),
    [],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      <WidgetFormContainer
        loading={loading}
        type="create"
        onCancel={handleCancel}
      />
    </Formik>
  );
};
