import React, {
  useContext,
  createContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import noop from '@stdlib/utils-noop';
import { useAuth } from '@kp/react-sdk';
import { useParams } from 'react-router-dom';
import { useBuildingContextBuildingQuery } from '../__generated__/types';

import { useNotifications } from './notifications-context';
import { useGenericPages } from './generic-page-context';

interface Zone {
  id: string;
  name: string;
  storeyId: string;
}

interface Storey {
  id: string;
  name: string;
  zones: Zone[];
}

interface BuildingContextValue {
  loading: boolean;
  initialized: boolean;
  name?: string;
  id?: string;
  getStoreyById: (id: string) => Omit<Storey, 'zones'> | null | void;
  getZoneById: (id: string) => Zone | null | void;
  storeys: Storey[];
  zones: Zone[];
}

const BuildingContext = createContext<BuildingContextValue>({
  loading: false,
  initialized: false,
  getStoreyById: noop,
  getZoneById: noop,
  storeys: [],
  zones: [],
});

export const useBuilding = (): BuildingContextValue =>
  useContext(BuildingContext);

interface BuildingProviderProps {
  children?: React.ReactNode;
}

export const BuildingProvider: React.FC<BuildingProviderProps> = ({
  children,
}) => {
  const { buildingId } = useParams();
  const { add } = useNotifications();
  const { isAuthenticated } = useAuth();
  const { activateNotFound } = useGenericPages();

  const { data, loading } = useBuildingContextBuildingQuery({
    variables: { buildingId },
    skip: !buildingId || !isAuthenticated,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
    errorPolicy: 'all',
  });

  const [storeys, zones] = useMemo(
    () => [
      data?.building?.storeys || [],
      (data?.building?.storeys || []).reduce<Zone[]>(
        (result, storey) => result.concat(storey.zones),
        [],
      ),
    ],
    [data],
  );

  const getStoreyById = useCallback(
    (id: string) => storeys.find((storey) => storey.id === id) || null,
    [storeys],
  );

  const getZoneById = useCallback(
    (id: string) => zones.find((zone) => zone.id === id) || null,
    [zones],
  );

  const value = useMemo(
    () => ({
      loading,
      initialized: Boolean((!loading && data) || !buildingId),
      getStoreyById,
      getZoneById,
      storeys,
      zones,
      name: data?.building?.name,
      id: buildingId,
    }),
    [loading, data, buildingId, getStoreyById, getZoneById, storeys, zones],
  );

  useEffect(() => {
    if (!data?.building?.id && !loading) {
      activateNotFound();
    }
  }, [activateNotFound, loading, data]);

  return (
    <BuildingContext.Provider value={value}>
      {children}
    </BuildingContext.Provider>
  );
};
