import React from 'react';
import { createClasses } from '@kp/react-ui';

const classes = createClasses({
  root: {
    display: 'flex',
    height: 'calc(100vh - 185px);',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  wrapper: {
    display: 'block',
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 0,
  },
});

export interface QanteonProps {
  url?: string;
}

export const Qanteon: React.FC<QanteonProps> = ({ url }) => (
  <div className={classes.root}>
    <div className={classes.wrapper}>
      <iframe
        data-testid="iframe"
        src={url}
        title="Qanteon"
        className={classes.iframe}
      />
    </div>
  </div>
);
