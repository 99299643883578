export interface Device {
  id: string;
  serialNo?: string;
  name?: string;
  type?: string;
  storeyName?: string;
  zoneName?: string;
  description?: string;
  deviceIdentifier?: string;
  deviceModelName?: string;
  gatewayName?: string;
  attributes: Array<{ id: string; value?: string | null }>;
  capabilities: Array<DeviceModelCapability>;
}

export interface DeviceModelCapability {
  id: string;
  capability: { name: string };
  unit: { unitSymbol?: string | null };
}

export interface DeviceModelCapabilityValue extends DeviceModelCapability {
  capabilityValue?: string;
  capabilityLastUpdate?: Date;
}

export interface DevicesFilter {
  id: string;
  categoryId: DevicesQueryParam;
  label: string;
  selected?: boolean;
}

export enum DevicesQueryParam {
  DeviceType = 'deviceType',
  Field = 'sort',
  Order = 'order',
  Query = 'query',
  Storey = 'storey',
  Zone = 'zone',
}
