import { request } from '../utils/request';
import { Response } from '../../hooks/useRequest';
import {
  url,
  WidgetEntity,
  CreateWidgetData,
  UpdateWidgetData,
} from './common';
import { EntityResponse } from '../common';

export type Widget = WidgetEntity;

/**
 * List all widgets
 *
 * @param filter        The filter parameters
 */
export const getWidgets = async (filter?: {
  buildingId?: string;
}): Promise<Response<EntityResponse<Widget[]>>> => {
  return request<EntityResponse<Widget[]>>('GET', url(`/widgets`), filter);
};

/**
 * Get details of a widget
 *
 * @param widgetId       The id of a widget that will be fetched
 */
export const getWidget = async (
  widgetId: string,
): Promise<Response<EntityResponse<Widget>>> => {
  return request<EntityResponse<Widget>>('GET', url(`/widgets/${widgetId}`));
};

/**
 * Create a new widget
 *
 * @param data           The data of the new widget
 */
export const createWidget = async (
  data: CreateWidgetData,
): Promise<Response<EntityResponse<Widget>>> => {
  return request<EntityResponse<Widget>>('POST', url(`/widgets`), data);
};

/**
 * Update a selected widget
 *
 * @param widgetId       The id of a widget that will be updated
 * @param data           The data of the widget to be updated
 */
export const updateWidget = async (
  widgetId: string,
  data: UpdateWidgetData,
): Promise<Response<null>> => {
  return request<null>('PUT', url(`/widgets/${widgetId}`), data);
};

/**
 * Add widget to the favourites list
 *
 * @param widgetId       The id of a widget that will be updated
 */
export const postWidgetsFavourite = async (
  widgetId: string,
): Promise<Response<null>> => {
  return request<null>('POST', url(`/widgets/favourite`), {
    widgetId,
  });
};

/**
 * Remove widget from the favourites list
 *
 * @param widgetId       The id of a widget that will be updated
 */
export const deleteWidgetsFavourite = async (
  widgetId: string,
): Promise<Response<null>> => {
  return request<null>('DELETE', url(`/widgets/favourite/${widgetId}`));
};

/**
 * Delete a widget
 *
 * @param widgetId       The id of a widget that will be deleted
 */
export const deleteWidget = async (
  widgetId: string,
): Promise<Response<null>> => {
  return request<null>('DELETE', url(`/widgets/${widgetId}`));
};

/**
 * Reorder a selected widget
 *
 * @param widgetId       The id of a widget that will be updated
 * @param position       New widgets position
 */
export const reorderWidget = async (
  widgetId: string,
  position: number,
): Promise<Response<null>> => {
  return request<null>('PUT', url(`/widgets/${widgetId}/position/${position}`));
};
