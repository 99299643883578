export interface Device {
  name: string;
  description: string;
  deviceTypeName: string;
  deviceModelName: string;
  serialNo: string;
  gatewayName: string;
  identifier: string;
  capabilities: Array<{
    id: string;
    name: string;
    unitId: string;
    unitName: string;
    unitSymbol?: string;
    technicalMin?: number;
    technicalMax?: number;
  }>;
}

export interface DeviceLocation {
  siteName: string;
  buildingName: string;
  storeyName?: string;
  zoneName?: string;
  storeyId?: string;
  zoneId?: string;
}

export interface DeviceChartDataSet {
  label?: string;
  unit?: string;
  color: string;
  records: Array<{ x: string; y: number }>;
}

export enum DeviceChartType {
  LINE = 'line',
  BAR = 'bar',
}
