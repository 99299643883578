import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import noop from '@stdlib/utils-noop';

interface LayoutContextValue {
  isSidebarCollapsed: boolean;
  toggleSidebar: () => void;
}

const LayoutContext = createContext<LayoutContextValue>({
  isSidebarCollapsed: false,
  toggleSidebar: noop,
});

export const useLayout = (): LayoutContextValue => useContext(LayoutContext);

interface LayoutProviderProps {
  children?: React.ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const toggleSidebar = useCallback(() => {
    setIsSidebarCollapsed((value) => !value);
  }, []);

  const value = useMemo(
    () => ({
      isSidebarCollapsed,
      toggleSidebar,
    }),
    [isSidebarCollapsed, toggleSidebar],
  );
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
