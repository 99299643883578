import React from 'react';
import { classNames, createClasses, css, TimeScaleDataset } from '@kp/react-ui';
import { DeviceContentLayout } from '../common/DeviceContentLayout';
import { DeviceToolbarActions } from '../common/DeviceToolbarActions';
import { DeviceInfoSidebar } from '../common/DeviceInfoSidebar';
import { Device, DeviceChartType, DeviceLocation } from '../Device.types';
import { DeviceChartTable } from './DeviceChartTable';
import { DeviceChartChart } from './DeviceChartChart';
import { DeviceChartToolbarActions } from './DeviceChartToolbarActions';
import { useHorizontalResize } from '../../../hooks/useHorizontalResize';
import { DeviceChartSettingsSidebar } from './DeviceChartSettingsSidebar';
import { AggregationIntervals } from '../../../__generated__/types';
import { DeviceDatePicker } from '../common/DeviceDatePicker';

const classes = createClasses({
  canvas: {
    flexGrow: 1,
  },
  chart: {
    display: 'flex',
    flexShrink: 0,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  devices: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
  },
  resizing: {
    cursor: 'ns-resize',
  },
});

interface DeviceChartContainerProps {
  device?: Device;
  location?: DeviceLocation;
  loading?: boolean;
  loadingData?: boolean;
  defaultCapabilityId: string;
  chartData: TimeScaleDataset[];
  dateFrom: Date;
  dateUntil: Date;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  type: DeviceChartType;
  availableIntervals: AggregationIntervals[];
  intervalSize: AggregationIntervals;
  setIntervalSize: (intervalSize: AggregationIntervals) => void;
}

export const DeviceChart: React.FC<DeviceChartContainerProps> = ({
  device,
  location,
  loading,
  loadingData,
  defaultCapabilityId,
  chartData,
  dateFrom,
  dateUntil,
  collapsed,
  setCollapsed,
  availableIntervals,
  setIntervalSize,
  intervalSize,
  type,
}) => {
  const [
    [chartHeight, tableHeight],
    { isResizing, containerRef, startResizing },
  ] = useHorizontalResize({
    defaultOffset: (height) => height * (80 / 100),
    minTopOffset: 150,
    minBottomOffset: 200,
  });

  const [showPoints, setShowPoints] = React.useState<boolean>(false);

  return (
    <DeviceContentLayout
      title={device?.name || ''}
      actions={
        <>
          <DeviceDatePicker />
          <DeviceChartToolbarActions
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <DeviceToolbarActions
            capabilityId={defaultCapabilityId}
            device={device}
            deviceLocation={location}
            loading={loading || loadingData}
          />
        </>
      }
      sidebars={
        <>
          <DeviceInfoSidebar
            device={device}
            location={location}
            loading={loading}
          />
          <DeviceChartSettingsSidebar
            availableIntervals={availableIntervals}
            intervalSize={intervalSize}
            setIntervalSize={setIntervalSize}
            showPoints={showPoints}
            setShowPoints={setShowPoints}
          />
        </>
      }
    >
      <div
        data-testid="device-chart-container"
        ref={containerRef}
        className={classNames(classes.canvas, isResizing && classes.resizing)}
      >
        <DeviceChartChart
          className={classNames(classes.chart, css({ height: chartHeight }))}
          loading={loading || loadingData}
          chartData={chartData}
          collapsed={collapsed}
          dateFrom={dateFrom}
          dateUntil={dateUntil}
          type={type}
          showPoints={showPoints}
        />
        <DeviceChartTable
          onMouseDown={startResizing}
          className={classNames(classes.devices, css({ height: tableHeight }))}
          loading={loading}
        />
      </div>
    </DeviceContentLayout>
  );
};
