import React, { useMemo } from 'react';
import { useAuth, Permissions } from '@kp/react-sdk';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  ChartType,
  getDateFromOfTimeInterval,
  TimeInterval,
  timeIntervalToGraphQL,
  DashboardCardChart,
  aggregationIntervalToGraphQL,
} from '../../../../api/building-insights';
import { useNotifications } from '../../../../contexts/notifications-context';
import { useWidgetChartDataOfDeviceQuery } from '../../../../__generated__/types';
import { ChartCard } from './ChartCard';

import { DashboardForm } from '../../dashboard/Dashboard.types';

export interface ChartCardContainerProps {
  id: string;
  title: string;
  subtitle?: string;
  configuration?: DashboardCardChart['configuration'];
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selected?: boolean;
}

export const ChartCardContainer: React.FC<ChartCardContainerProps> = ({
  id,
  title,
  subtitle,
  configuration,
  onEdit,
  onDelete,
  selected,
}) => {
  const { hasPermission } = useAuth();
  const { t } = useTranslation();
  const { add } = useNotifications();

  const { watch } = useFormContext<DashboardForm>();

  const devices = useMemo(
    () =>
      (configuration?.items || []).map(({ deviceId, capabilityId }) => ({
        deviceId,
        deviceModelCapabilityIds: [{ deviceModelCapabilityId: capabilityId }],
      })),
    [configuration],
  );

  const timeInterval = watch('timeInterval', TimeInterval.lastDay);
  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');
  const aggregationInterval = watch('aggregationInterval');

  const { data, loading } = useWidgetChartDataOfDeviceQuery({
    variables: {
      devices,
      timeSpan: timeIntervalToGraphQL(timeInterval),
      readFrom: dateFrom,
      readUntil: dateTo,
      readUntilNow:
        timeInterval === TimeInterval.customInterval
          ? dateTo === undefined
          : true,
      intervalSize: aggregationIntervalToGraphQL(aggregationInterval),
    },
    skip: devices.length === 0,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const chartData = useMemo(() => {
    const deviceAndCapabilityInfos =
      data?.multipleDeviceSensorDataByViews?.[0]?.deviceAndCapabilityInfos;
    if (!deviceAndCapabilityInfos) {
      return [];
    }
    return (configuration?.items || [])
      .map((capability, i) => {
        const info = deviceAndCapabilityInfos.find(
          (entry) =>
            entry?.deviceModelCapabilityId === capability.capabilityId &&
            entry?.deviceId === capability.deviceId,
        );
        const dataCapability = info?.deviceModelCapability;
        return {
          id: `${capability.capabilityId}-${capability.deviceId}`,
          title: `${capability.name} ${dataCapability?.unit?.unitSymbol}`,
          label: dataCapability?.capability.name ?? `Data ${i}`,
          unit: dataCapability?.unit?.unitSymbol || undefined,
          color: capability.color || 'blue',
          data: (info?.telemetryRecords || []).map((record) => ({
            timestamp: record?.utcTimeMeasured || new Date(),
            value: record?.valueString ? parseFloat(record.valueString) : 0,
          })),
        };
      })
      .reverse();
  }, [configuration, data]);

  const [min, max] = useMemo(
    () =>
      timeInterval !== TimeInterval.customInterval
        ? [getDateFromOfTimeInterval(timeInterval), new Date()]
        : [
            dateFrom ? new Date(dateFrom) : undefined,
            dateTo ? new Date(dateTo) : undefined,
          ],
    [dateFrom, dateTo, timeInterval],
  );

  const type = useMemo(() => {
    switch (configuration?.type) {
      case ChartType.bar:
        return 'bar';
      case ChartType.line:
        return 'line';
      default:
        return 'line';
    }
  }, [configuration]);

  const chartSubtitle = useMemo(() => {
    if (subtitle) return subtitle;
    if (!hasPermission(Permissions.WidgetsWrite) && !configuration)
      return t('dashboards.card.empty');

    return '';
  }, [configuration, subtitle, hasPermission, t]);

  return (
    <ChartCard
      selected={selected}
      loading={loading}
      empty={devices.length === 0}
      min={min}
      max={max}
      chartType={type}
      chartData={chartData}
      id={id}
      title={title}
      subtitle={chartSubtitle}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};
