import React, { useCallback } from 'react';
import { createClasses, Button, Icons } from '@kp/react-ui';
import {
  DashboardRightBarTabTypes,
  useDashboard,
} from '../../dashboard-context';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
});

interface DashboardRightSidebarTabsProps {}

export const DashboardRightSidebarTabs: React.FC<
  DashboardRightSidebarTabsProps
> = () => {
  const {
    rightSidebarOpen,
    setRightSidebarOpen,
    selectedRightSidebarTab,
    setSelectedRightSidebarTab,
  } = useDashboard();

  const handleToggle = useCallback(
    (type: DashboardRightBarTabTypes) => {
      // I know, looks ugly but it handles all states of tabs :D
      if (type !== selectedRightSidebarTab && !rightSidebarOpen) {
        setRightSidebarOpen(true);
        setSelectedRightSidebarTab(type);
      }

      if (type === selectedRightSidebarTab && rightSidebarOpen) {
        setRightSidebarOpen(false);
        setSelectedRightSidebarTab(undefined);
      }

      if (type !== selectedRightSidebarTab && rightSidebarOpen) {
        setSelectedRightSidebarTab(type);
      }
    },
    [
      rightSidebarOpen,
      selectedRightSidebarTab,
      setRightSidebarOpen,
      setSelectedRightSidebarTab,
    ],
  );

  return (
    <div className={classes.root}>
      <Button
        icon={Icons.IconAddWidget}
        onClick={() => handleToggle(DashboardRightBarTabTypes.newCard)}
      />
      <Button
        selected={
          rightSidebarOpen &&
          selectedRightSidebarTab === DashboardRightBarTabTypes.settings
        }
        icon={Icons.IconSettings2}
        variant="tertiary"
        onClick={() => handleToggle(DashboardRightBarTabTypes.settings)}
      />
    </div>
  );
};
