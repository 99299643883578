import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { MetaObject } from '@xeokit/xeokit-sdk';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  classNames,
  createClasses,
  Typography,
  Colors,
  Button,
  Icons,
} from '@kp/react-ui';
import { InteractiveTreeCheckbox } from './InteractiveTreeCheckbox';
import { useXeoKitHighlight } from '../../../contexts/xeokit/useXeoKitHighlight';

const classes = createClasses({
  treeItem: {
    '& + &': {
      borderTop: `1px solid ${Colors.Neutral.borderMedium}`,
    },
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    padding: '8px 0',
    display: 'flex',
    alignItems: 'center',
  },
  titleExpanded: {
    borderBottom: `1px solid ${Colors.Neutral.borderMedium}`,
  },
  titleBody: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  type: {
    color: Colors.Neutral.textWeak,
  },
  titleName: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: Colors.Neutral.text,
  },
  icon: {
    marginRight: '12px',
    cursor: 'pointer',
  },
  iconSpacer: {
    flexShrink: 0,
    flexBasis: '32px',
  },
  iconSpacerOrphan: {
    flexShrink: 0,
    flexBasis: '8px',
  },
  body: {
    marginLeft: '8px',
  },
  checkbox: {
    flexShrink: 0,
    flexBasis: '32px',
    marginLeft: '13px',
  },
  copy: {
    flexShrink: 0,
    flexBasis: '32px',
    marginLeft: '4px',
  },
});

interface InteractiveTreeProps {
  className?: string;
  object: MetaObject;
  orphan?: boolean;
}

export const InteractiveTree: React.FC<InteractiveTreeProps> = React.memo(
  ({ className, object, orphan = false }) => {
    const [expanded, setExpanded] = useState(false);
    const { setMetaObjectHighlight } = useXeoKitHighlight();

    const handleIconClick = useCallback(() => {
      setExpanded(!expanded);
    }, [expanded, setExpanded]);

    const hasChildren = useMemo(
      () => object.children?.length > 0 && !orphan,
      [orphan, object],
    );

    useEffect(() => {
      if (object.children?.length > 1 || !object.children?.length) {
        return;
      }
      setExpanded(true);
    }, [object]);

    const handleMouseEnter = useCallback(() => {
      if (!hasChildren) {
        setMetaObjectHighlight(object, true);
      }
    }, [hasChildren, object, setMetaObjectHighlight]);

    const handleMouseLeave = useCallback(() => {
      if (!hasChildren) {
        setMetaObjectHighlight(object, false);
      }
    }, [hasChildren, object, setMetaObjectHighlight]);

    return (
      <div className={classNames(classes.treeItem, className)} key={object.id}>
        <div
          className={classNames(
            classes.title,
            expanded && classes.titleExpanded,
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {hasChildren ? (
            <Button
              variant="tertiary"
              icon={expanded ? Icons.IconChevronDown : Icons.IconChevronRight}
              className={classNames(classes.icon)}
              onClick={handleIconClick}
              size="small"
            />
          ) : (
            <div
              className={orphan ? classes.iconSpacerOrphan : classes.iconSpacer}
            />
          )}
          <div className={classes.titleBody}>
            <Typography
              component="div"
              variant="bodyMedium"
              className={classes.titleName}
            >
              {object.name}
            </Typography>
            <Typography variant="labelTiny" className={classes.type}>
              {object.type}
            </Typography>
          </div>
          <div className={classes.checkbox}>
            <InteractiveTreeCheckbox object={object} />
          </div>
          <div className={classes.copy}>
            <CopyToClipboard text={String(object.id)}>
              <Button
                icon={Icons.IconDuplicate}
                size="default"
                variant="tertiary"
              />
            </CopyToClipboard>
          </div>
        </div>
        {hasChildren && expanded && (
          <div className={classes.body}>
            {object.children.map((child) => (
              <InteractiveTree key={child.id} object={child} />
            ))}
          </div>
        )}
      </div>
    );
  },
);

InteractiveTree.displayName = 'InteractiveTree';
