import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

interface FiltersHook {
  query: string;
  setQuery: (query: string) => void;
}

export const useFilters = (): FiltersHook => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useMemo(() => searchParams.get('query') || '', [searchParams]);

  const setQuery = useCallback(
    (value: string) => {
      if (value) {
        searchParams.set('query', value);
      } else {
        searchParams.delete('query');
      }
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams],
  );

  return {
    query,
    setQuery,
  };
};
