import React, { useCallback, ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses, Input } from '@kp/react-ui';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },
  input: {
    maxWidth: '296px',
  },
  capability: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: '8px',
  },
  capabilityName: {
    maxWidth: '600px',
    flexGrow: 1,
  },
  capabilityActions: {
    alignSelf: 'end',
    flexGrow: 1,
  },
});

interface ImageEditPanelProp {
  title?: string;
  onChangeTitle: (title: string) => void;
  onSubmit: () => void;
}

export const ImageEditPanel: React.FC<ImageEditPanelProp> = ({
  title,
  onChangeTitle,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const handleTitleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => onChangeTitle(event.target.value),
    [onChangeTitle],
  );

  return (
    <div className={classes.root}>
      <div className={classes.input}>
        <Input
          placeholder={t('dashboards.card.untitled') || ''}
          label={t('dashboards.card.editPanel.fields.title') || ''}
          value={title}
          onChange={handleTitleChange}
          onBlur={onSubmit}
          fullWidth
        />
      </div>
      <div className={classes.input}>{/* -- UPLOAD AREA -- */}</div>
    </div>
  );
};
