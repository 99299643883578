import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, Tab, Tabs, createClasses, classNames } from '@kp/react-ui';
import { PROPERTYSIDEBAR_WIDTH } from '../../constants/UI';
import { PropertySidebarItems } from '../PropertySidebarItems';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: PROPERTYSIDEBAR_WIDTH + 1,
    background: Colors.Neutral.background,
    minHeight: '600px',
    borderRight: `1px solid ${Colors.Neutral.borderStrong}`,
    backgroundColor: Colors.Neutral.background,
  },
  search: {
    height: '76px',
    paddingLeft: '24px',
    paddingTop: '32px',
    paddingRight: '24px',
    width: '100%',
    backgroundColor: Colors.Neutral.background,
  },
  searchInput: {},
  tabs: {
    height: '39px',
    padding: '1px 0px 0px 16px',
    backgroundColor: Colors.Neutral.background,
    boxShadow: `inset 0px -1px 0px ${Colors.Neutral.borderStrong}`,
    borderBottom: 'none',
  },
  tab: {
    margin: '0px 8px 0px 8px',
  },
  properties: {
    overflow: 'overlay',
  },
});

export interface PropertySidebarProps {
  className?: string;
  loading?: boolean;
  properties?: Array<{
    id: string;
    name: string;
    buildings: Array<{
      isFavorite: boolean;
      id: string;
      name: string;
      location: {
        city: string;
        street: string;
        no: string;
        zip: string;
        coordinates?: { lat: number; lng: number };
      };
    }>;
  }>;
  handleFavClick?: (value: string) => void;
  handleTabClick?: (event: any) => void;
  selectedTabPage?: string;
  setSelectedTabPage?: (event: any) => void;
  setIsMouseOver?: (value: string) => void;
  handleClick?: (value: string) => void;
  onChangeQuery?: (query: string) => void;
}

export const PropertySidebar: React.FC<PropertySidebarProps> = ({
  className,
  loading,
  properties = [],
  handleFavClick,
  handleTabClick,
  selectedTabPage = 'all',
  setSelectedTabPage,
  setIsMouseOver,
  handleClick,
  onChangeQuery,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(classes.root, className)}
      data-testid={'propertysidebar'}
    >
      {/* <SearchField
        placeholder={t('searchInput.placeholder')}
        className={classes.search}
        fullWidth={true}
        onChange={onChangeQuery}
      /> */}
      <Tabs
        value={selectedTabPage}
        variant="small"
        className={classes.tabs}
        onChange={setSelectedTabPage}
      >
        <Tab
          value="all"
          label={t('startpage.tabs.all')}
          onClick={handleTabClick}
          className={classes.tab}
        />
        <Tab
          value="favourite"
          label={t('startpage.tabs.favourite')}
          onClick={handleTabClick}
          className={classes.tab}
          data-testid={'propertysidebar-favourite-tab'}
        />
        {/* <Tab
          value="recent"
          label="Recent"
          onClick={handleTabClick}
          className={classes.tab}
        /> */}
      </Tabs>
      <div className={classes.properties}>
        {properties.map((property) => (
          <PropertySidebarItems
            key={property.id}
            title={property.name}
            onClick={handleClick}
            onFavClick={handleFavClick}
            setIsMouseOver={setIsMouseOver}
            buildings={
              selectedTabPage === 'all'
                ? property.buildings
                : property.buildings.filter((b) => b.isFavorite)
            }
          />
        ))}
      </div>
    </div>
  );
};
