import React from 'react';
import { classNames, css, createClasses } from '@kp/react-ui';
import { Typography as TypographyStyles } from '../../constants/Typography';
import { ColorType, colorTypeToEnum } from '../../constants/Colors';

export enum TypographyVariant {
  LargeTitle = 'largeTitle',
  Title1 = 'title1',
  Title2 = 'title2',
  Title3 = 'title3',
  Headline = 'headline',
  Body = 'body',
  Subhead = 'subhead',
  SubheadMedium = 'subheadMedium',
  Footnote = 'footnote',
  Caption1 = 'caption1',
}

type TypographyType =
  | 'largeTitle'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'headline'
  | 'body'
  | 'subhead'
  | 'subheadMedium'
  | 'footnote'
  | 'caption1';

const classes = createClasses({
  largeTitle: {
    ...TypographyStyles.largeText,
  },
  title1: {
    ...TypographyStyles.title1,
  },
  title2: {
    ...TypographyStyles.title2,
  },
  title3: {
    ...TypographyStyles.title3,
  },
  headline: {
    ...TypographyStyles.headline,
  },
  body: {
    ...TypographyStyles.body,
  },
  subhead: {
    ...TypographyStyles.subhead,
  },
  subheadMedium: {
    ...TypographyStyles.subheadMedium,
  },
  footnote: {
    ...TypographyStyles.footnote,
  },
  caption1: {
    ...TypographyStyles.caption1,
  },
});

export interface TypographyProps {
  variant: TypographyType;
  component?: React.ElementType;
  color?: ColorType;
  className?: string;
  children?: React.ReactNode;
}

export const Typography: React.FC<TypographyProps> = ({
  variant,
  component: Component = 'span',
  className,
  color,
  children,
  ...props
}) => (
  <Component
    className={classNames(
      classes[variant],
      css({ color: colorTypeToEnum(color || 'black') }),
      className,
    )}
    {...props}
  >
    {children}
  </Component>
);
