import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { DashboardSelectCapabilities } from './DashboardSelectCapabilities';
import {
  DevicesByBuildingFilterInput,
  SortEnumType,
  useDashboardSelectCapabilitiesQuery,
  useDashboardSelectCapabilitiesSelectedDeviceQuery,
} from '../../../__generated__/types';
import { useNotifications } from '../../../contexts/notifications-context';
import { useBuilding } from '../../../contexts/building-context';
import {
  DashboardSelectCapabilitiesFilter,
  DashboardSelectCapabilitiesFilterCategory,
} from './DashboardSelectCapabilities.types';
import { usePlatform } from '../../../contexts/platform-context';

export interface DashboardSelectCapabilitiesContainerProps {
  onAdd: (capability: {
    deviceId: string;
    capabilityId: string;
    capabilityName: string;
  }) => void;
  onClose?: () => void;
  selectedCapability?: { deviceId: string; capabilityId: string };
  open: boolean;
}

export const DashboardSelectCapabilitiesContainer: React.FC<
  DashboardSelectCapabilitiesContainerProps
> = ({ onAdd, onClose, open, selectedCapability }) => {
  const { add } = useNotifications();
  const [query, setQuery] = useState('');
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | null>(
    null,
  );
  const [filters, setFilters] = useState<DashboardSelectCapabilitiesFilter[]>(
    [],
  );
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>([]);

  const {
    id: buildingId,
    getStoreyById,
    loading: loadingBuilding,
    storeys,
    zones,
  } = useBuilding();

  const { deviceTypes, loading: loadingPlatform } = usePlatform();

  const handleResetFilters = useCallback(() => {
    setSelectedFilterIds([]);
  }, []);

  const [selectedStoreys, selectedZones, selectedDeviceTypes] = useMemo(
    () => [
      storeys
        .map((storey) => storey.id)
        .filter((id) => selectedFilterIds.includes(id)),
      zones
        .map((zone) => zone.id)
        .filter((id) => selectedFilterIds.includes(id)),
      deviceTypes
        .map((deviceType) => deviceType.id)
        .filter((id) => selectedFilterIds.includes(id)),
    ],
    [selectedFilterIds, storeys, zones, deviceTypes],
  );

  // Tracks for unavailable selected zones and de-selects them
  useEffect(() => {
    if (selectedStoreys.length === 0) {
      return;
    }

    const unavailableZones = zones
      .filter((zone) => !selectedStoreys.includes(zone.storeyId))
      .map((zone) => zone.id);

    if (unavailableZones.length === 0) {
      return;
    }

    const selectedAvailableFilterIds = selectedFilterIds.filter(
      (id) => !unavailableZones.includes(id),
    );

    if (selectedAvailableFilterIds.length !== selectedFilterIds.length) {
      setSelectedFilterIds(selectedAvailableFilterIds);
    }
  }, [selectedStoreys, selectedFilterIds, zones]);

  // Updates the filters for the button
  useEffect(() => {
    setFilters([
      // Include all device types
      ...deviceTypes.map((deviceType) => ({
        id: deviceType.id,
        label: deviceType.name,
        categoryId: DashboardSelectCapabilitiesFilterCategory.DeviceType,
        selected: selectedDeviceTypes.includes(deviceType.id),
      })),
      // Include all storeys in the filters
      ...storeys.map((storey) => ({
        id: storey.id,
        label: storey.name,
        categoryId: DashboardSelectCapabilitiesFilterCategory.Floor,
        selected: selectedStoreys.includes(storey.id),
      })),
      // Include either all zones in case no storey is selected, or only the zones
      // of the selected storeys
      ...zones
        .filter(
          (zone) =>
            selectedStoreys.length === 0 ||
            selectedStoreys.includes(zone.storeyId),
        )
        .map((zone) => ({
          id: zone.id,
          label: zone.name,
          categoryId: DashboardSelectCapabilitiesFilterCategory.Zone,
          selected: selectedZones.includes(zone.id),
        })),
    ]);
  }, [
    storeys,
    zones,
    deviceTypes,
    selectedStoreys,
    selectedZones,
    selectedDeviceTypes,
  ]);

  const handleRemoveFilter = useCallback(
    (id: string) =>
      setSelectedFilterIds((filterIds) =>
        filterIds.filter((target) => target !== id),
      ),
    [setSelectedFilterIds],
  );

  const handleAddFilter = useCallback(
    (id: string) => setSelectedFilterIds((filterIds) => [...filterIds, id]),
    [setSelectedFilterIds],
  );

  const handleChangeSort = useCallback(
    (direction: 'ASC' | 'DESC', field: string) => {
      setSortDirection(direction);
      setSortField(field);
    },
    [setSortDirection, setSortField],
  );

  const order = useMemo(() => {
    if (!sortField || !sortDirection) {
      return undefined;
    }
    const direction =
      sortDirection === 'ASC' ? SortEnumType.Asc : SortEnumType.Desc;
    switch (sortField) {
      case 'deviceType':
        return { deviceTypeName: direction };
      case 'deviceName':
        return { deviceName: direction };
      case 'serialNumber':
        return { deviceSerialNumber: direction };
      case 'storey':
        return { storeyId: direction };
      case 'description':
        return { deviceDescription: direction };
      default:
        return undefined;
    }
  }, [sortField, sortDirection]);

  const where = useMemo(() => {
    const and: DevicesByBuildingFilterInput[] = [
      { buildingId: { eq: buildingId } },
    ];

    if (query) {
      and.push({
        or: [
          { deviceName: { contains: query } },
          { deviceDescription: { contains: query } },
          {
            deviceAttributeValues: {
              some: { value: { contains: query } },
            },
          },
          { deviceSerialNumber: { contains: query } },
          { deviceModelName: { contains: query } },
          { deviceGatewayName: { contains: query } },
          { deviceDeviceIdentifier: { contains: query } },
        ],
      });
    }

    if (selectedDeviceTypes.length > 0) {
      and.push({ deviceTypeId: { in: selectedDeviceTypes } });
    }

    if (selectedStoreys.length > 0) {
      and.push({ storeyId: { in: selectedStoreys } });
    }

    if (selectedZones.length > 0) {
      and.push({ zoneId: { in: selectedZones } });
    }

    return { and };
  }, [buildingId, query, selectedZones, selectedStoreys, selectedDeviceTypes]);

  const { data, loading, fetchMore, networkStatus } =
    useDashboardSelectCapabilitiesQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: { where, order, take: 30 },
      skip: !open,
      onError: (err) =>
        add({
          type: 'danger',
          id: err.message,
          content: err.message,
        }),
      errorPolicy: 'all',
    });

  const [skip, total] = useMemo(
    () => [
      data?.devicesByBuildings?.items?.length || 0,
      data?.devicesByBuildings?.totalCount || 0,
    ],
    [data],
  );

  const { data: dataSelectedCapability, loading: loadingSelectedCapability } =
    useDashboardSelectCapabilitiesSelectedDeviceQuery({
      variables: { deviceId: selectedCapability?.deviceId || '' },
      skip: !selectedCapability || !open,
      onError: (err) =>
        add({
          type: 'danger',
          id: err.message,
          content: err.message,
        }),
    });

  const handleScrollDown = useCallback(() => {
    fetchMore({ variables: { skip } });
  }, [fetchMore, skip]);

  const devices = useMemo(
    () =>
      (data?.devicesByBuildings?.items || [])
        // allow only device that have at least one capability
        .filter(
          (item) => item?.device?.deviceModel?.deviceModelCapabilities?.[0],
        )
        // don't include the selected device, since it's rendered on top of the table
        .filter((item) => item?.device?.id !== selectedCapability?.deviceId)
        .map((item) => ({
          id: item?.device?.id || '-1',
          serialNo: item?.device?.serialNo || '',
          name: item?.device?.name || '',
          type: item?.device?.deviceModel?.deviceType?.name || '',
          storeyName: getStoreyById(item?.storeyId || '')?.name || '',
          description: item?.device?.description || '',
          // We only get the first capability
          capabilityId:
            item?.device?.deviceModel?.deviceModelCapabilities[0]?.id || '',
          capabilityName:
            item?.device?.deviceModel?.deviceModelCapabilities[0]?.capability
              ?.name || '',
        })),
    [data, getStoreyById, selectedCapability],
  );

  const selectedDevice = useMemo(
    () =>
      selectedCapability
        ? {
            id: dataSelectedCapability?.device?.id || '-1',
            serialNo: dataSelectedCapability?.device?.serialNo || '',
            name: dataSelectedCapability?.device?.name || '',
            type:
              dataSelectedCapability?.device?.deviceModel?.deviceType?.name ||
              '',
            storeyName:
              getStoreyById(
                dataSelectedCapability?.placementOfDevice?.storeyId || '',
              )?.name || '',
            description: dataSelectedCapability?.device?.description || '',
            capabilityId: selectedCapability?.capabilityId,
            capabilityName:
              // Find the selected capability from the device
              dataSelectedCapability?.device?.deviceModel.deviceModelCapabilities?.find(
                ({ id }) => id === selectedCapability.capabilityId,
              )?.capability?.name || '',
          }
        : undefined,
    [dataSelectedCapability, getStoreyById, selectedCapability],
  );

  const canLoadMore =
    skip &&
    total &&
    skip < total &&
    !loading &&
    !loadingBuilding &&
    !loadingPlatform;

  return (
    <DashboardSelectCapabilities
      query={query}
      onChangeSort={handleChangeSort}
      sortDirection={sortDirection}
      onScrollDown={canLoadMore ? handleScrollDown : undefined}
      filters={filters}
      onRemoveFilter={handleRemoveFilter}
      onAddFilter={handleAddFilter}
      onResetFilters={handleResetFilters}
      sortField={sortField}
      onChangeQuery={setQuery}
      onAdd={onAdd}
      loadingMore={loading && networkStatus === NetworkStatus.fetchMore}
      loading={
        (loading && networkStatus !== NetworkStatus.fetchMore) ||
        loadingBuilding ||
        loadingPlatform ||
        loadingSelectedCapability
      }
      devices={devices}
      selectedCapability={selectedDevice}
      onClose={onClose}
      open={open}
    />
  );
};
