import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, Typography, createClasses, classNames } from '@kp/react-ui';

const classes = createClasses({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.Neutral.backgroundMedium,
  },
  text: {
    marginBottom: '80px',
    maxWidth: '480px',
    textAlign: 'center',
  },
  sampleContainer: {
    display: 'flex',
    gap: '24px',
  },
});

interface WidgetFormChartEmptyProps extends React.ComponentProps<'div'> {}

export const WidgetFormChartEmpty: React.FC<WidgetFormChartEmptyProps> = ({
  className,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.root, className)} {...props}>
      <Typography
        variant="titleMedium"
        component="span"
        className={classes.text}
      >
        {t('widgets.editor.emptyChartInfo')}
      </Typography>
      <div className={classes.sampleContainer}>
        <img src="/assets/sample-line-chart.svg" alt="sample-line-chart" />
        <img src="/assets/sample-bar-chart.svg" alt="sample-bar-chart" />
        <img src="/assets/sample-table-list.svg" alt="sample-table-list" />
      </div>
    </div>
  );
};
