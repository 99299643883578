import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useAuth } from '@kp/react-sdk';
import { Icons, Button, createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useInteractiveBuildingQuery } from '../../../__generated__/types';
import { useNotifications } from '../../../contexts/notifications-context';
import { useXeoKitControls, useXeoKitMarkers } from '../../../contexts/xeokit';
import { InteractiveHeaderStoreySelect } from './InteractiveHeaderStoreySelect';
import { InteractiveModelEnabled } from '../../../constants/FeatureFlags';
import { ViewMode } from './constants';
import { useBuilding } from '../../../contexts/building-context';
import { InteractiveLights } from './InteractiveLights';

const classes = createClasses({
  control: {
    '&:not(:first-child)': {
      marginLeft: '12px',
    },
    textDecoration: 'none',
  },
});

interface InteractiveHeaderContainerProps {
  className?: string;
  onFilterClick?: () => void;
  filterSelected?: boolean;
}

export const InteractiveHeaderContainer: React.FC<
  InteractiveHeaderContainerProps
> = ({ className, onFilterClick, filterSelected }) => {
  const { buildingId } = useParams();
  const { user } = useAuth();
  const { add } = useNotifications();
  const { t } = useTranslation();
  const {
    resetView,
    selectStoreys,
    showEverything,
    showStoreyFloorPlan,
    setNavMode,
  } = useXeoKitControls();
  const [selectedStoreys, setSelectedStoreys] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.ThreeDimensional);
  const { addDevice } = useXeoKitMarkers();
  const { storeys: buildingStoreys } = useBuilding();

  const model = useMemo(
    () =>
      InteractiveModelEnabled.find(
        ({ buildingId: bId, tenantId: tId }) =>
          bId === buildingId && tId === String(user?.info?.tenantId),
      ),
    [buildingId, user],
  );

  const zoneIds = (model?.mappings.storeys || []).reduce<string[]>(
    (result, storey) => {
      return result.concat((storey.zones || []).map(({ id }) => id));
    },
    [],
  );

  const { data, loading } = useInteractiveBuildingQuery({
    variables: { id: buildingId, zoneIds },
    onError: (error) =>
      add({
        type: 'danger',
        id: error.message,
        content: error.message,
      }),
  });

  const storeys = useMemo(
    () =>
      (model?.mappings.storeys || [])
        .filter((mapping) =>
          buildingStoreys.some((storey) => storey.id === mapping.id),
        )
        .map((mapping) => {
          const storey = buildingStoreys.find(({ id }) => id === mapping.id);
          return {
            id: storey?.id || '',
            label: storey?.name || '',
            ifcId: mapping.ifcId,
            selected: selectedStoreys.includes(storey?.id || ''),
          };
        }),
    [buildingStoreys, selectedStoreys, model],
  );

  const zones = useMemo(
    () =>
      (model?.mappings.storeys || [])
        .filter((mappingStorey) =>
          buildingStoreys.some((storey) => storey.id === mappingStorey.id),
        )
        .map((mappingStorey) => {
          const storey = buildingStoreys.find(
            ({ id }) => id === mappingStorey.id,
          );
          const buildingStoreyZones = storey?.zones || [];
          return (mappingStorey?.zones || [])
            .filter((mappingZone) =>
              buildingStoreyZones.some((zone) => zone.id === mappingZone.id),
            )
            .map((mappingZone) => {
              const zone = buildingStoreyZones.find(
                ({ id }) => id === mappingZone.id,
              );
              return {
                id: zone?.id || '',
                ifcId: mappingZone.ifcId,
              };
            });
        })
        .flat(),
    [buildingStoreys, model],
  );

  useEffect(() => {
    const devices = data?.devicesByBuildings?.items || [];
    const destroyMarkers = devices.map((device) => {
      const zone = zones.find(({ id }) => id === device?.zoneId);
      if (!zone || !zone.ifcId) {
        return () => {};
      }
      const deviceType =
        device?.device?.deviceModel?.deviceType?.name === 'Gateway Device'
          ? 'gateway'
          : 'sensor';
      return addDevice(zone.ifcId, deviceType);
    });
    return () => destroyMarkers.forEach((callback) => callback());
  }, [addDevice, zones, data]);

  const handleStoreySelect = useCallback(
    (selectedId: string) => {
      if (selectedStoreys.includes(selectedId)) {
        setSelectedStoreys(selectedStoreys.filter((id) => id !== selectedId));
      } else {
        setSelectedStoreys([...selectedStoreys, selectedId]);
      }
    },
    [selectedStoreys],
  );

  const handleStoreyCapture = useCallback(
    (selectedId: string) => {
      setSelectedStoreys([selectedId]);
      if (viewMode === ViewMode.TwoDimensional) {
        const ifcId = storeys.find(({ id }) => selectedId === id)?.ifcId || '';
        showStoreyFloorPlan(ifcId);
      }
    },
    [storeys, showStoreyFloorPlan, viewMode],
  );

  const handleResetView = useCallback(() => {
    setSelectedStoreys([]);
    if (viewMode === ViewMode.ThreeDimensional) {
      resetView();
    } else {
      showStoreyFloorPlan(storeys[0].ifcId);
    }
  }, [resetView, viewMode, showStoreyFloorPlan, storeys]);

  useEffect(() => {
    const selected = storeys.filter((storey) => storey.selected);
    if (selected.length === 0) {
      showEverything();
    } else {
      const ifcIds = selected.map(({ ifcId }) => ifcId);
      selectStoreys(ifcIds);
    }
  }, [storeys, selectStoreys, showEverything]);

  const handleThreeDimensionalClick = useCallback(() => {
    if (viewMode === ViewMode.TwoDimensional) {
      setViewMode(ViewMode.ThreeDimensional);
      resetView();
    } else {
      const selectedId = selectedStoreys[0];
      const ifcId =
        storeys.find(({ id }) => selectedId === id)?.ifcId || storeys[0].ifcId;
      setViewMode(ViewMode.TwoDimensional);
      showStoreyFloorPlan(ifcId);
    }
  }, [selectedStoreys, showStoreyFloorPlan, storeys, viewMode, resetView]);

  useEffect(() => {
    setNavMode(viewMode === ViewMode.TwoDimensional ? 'planView' : 'orbit');
  }, [viewMode, setNavMode]);

  return (
    <div className={className}>
      <Button
        variant="secondary"
        selected={viewMode === ViewMode.ThreeDimensional}
        onClick={handleThreeDimensionalClick}
        icon={Icons.IconCube}
        className={classes.control}
      />
      <InteractiveHeaderStoreySelect
        className={classes.control}
        storeys={storeys}
        onSelect={handleStoreySelect}
        onCapture={handleStoreyCapture}
        loading={loading}
      />
      {model?.mappings?.lights && (
        <InteractiveLights
          lights={model.mappings.lights}
          className={classes.control}
        />
      )}
      <Button
        icon={Icons.IconRefresh}
        variant="secondary"
        className={classes.control}
        onClick={handleResetView}
      >
        {t('interactive.reset')}
      </Button>
      <Button
        icon={Icons.IconFloorplan}
        component={NavLink}
        variant="secondary"
        className={classes.control}
        to={`/buildings/${buildingId}/map`}
      >
        {t('interactive.indoorMap')}
      </Button>
      <Button
        variant="secondary"
        selected={filterSelected}
        onClick={onFilterClick}
        icon={Icons.IconFilter}
        className={classes.control}
      />
    </div>
  );
};
