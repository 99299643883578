import React, { useEffect } from 'react';
import { useGenericPages } from '../contexts/generic-page-context';

export const MissingPermissionsHandler: React.FC = () => {
  const { activateMissingPermissions } = useGenericPages();

  useEffect(() => activateMissingPermissions(), [activateMissingPermissions]);

  return <></>;
};
