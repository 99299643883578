import { MutableRefObject, useContext, useEffect } from 'react';
import { XeoKitContext } from './xeokit-context';

/**
 * Used to render the xeokit canvas
 *
 * @param viewerRef  The ref for the viewer canvas element
 * @param cubeRef    The ref for the cube canvas element
 * @param xktFile    The xkt file for the model
 * @param metaFile   The meta file of the model
 */
export const useXeoKit = ({
  viewerRef,
  cubeRef,
  xktFile,
  metaFile,
}: {
  cubeRef: MutableRefObject<HTMLCanvasElement | null>;
  viewerRef: MutableRefObject<HTMLCanvasElement | null>;
  xktFile: string;
  metaFile?: string;
}): { loading: boolean } => {
  const { render, loading } = useContext(XeoKitContext);
  useEffect(() => {
    return viewerRef.current && cubeRef.current && xktFile
      ? render({
          viewerElement: viewerRef.current,
          navCubeElement: cubeRef.current,
          xktFile,
          metaFile,
        })
      : () => {};
  }, [viewerRef, cubeRef, xktFile, metaFile, render]);
  return { loading };
};
