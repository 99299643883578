export const ModelIFCObjectColors = {
  IfcSpace: {
    opacity: 0,
  },
  IfcCovering: {
    opacity: 0.4,
  },
  IfcWindow: {
    // Some IFC models have opaque windows
    opacity: 0.4,
  },
  IfcOpeningElement: {
    // These tend to obscure windows
    opacity: 0.3,
  },
  IfcPlate: {
    // These sometimes obscure windows
    opacity: 0.3,
  },
};
