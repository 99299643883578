import React, { useMemo } from 'react';
import { createClasses, Dropdown, ListItem, Typography } from '@kp/react-ui';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { AggregationInterval } from '../../../../../api/building-insights';

const classes = createClasses({
  root: {},
  text: {
    marginBottom: '4px',
  },
  dropdown: {
    width: '100%',
  },
});

const makeOptions = (t: TFunction) => [
  { label: t('widgets.intervalSize.None'), value: AggregationInterval.none },
  {
    label: t('widgets.intervalSize.Ms_500'),
    value: AggregationInterval.ms500,
  },
  { label: t('widgets.intervalSize.S_01'), value: AggregationInterval.s01 },
  { label: t('widgets.intervalSize.S_02'), value: AggregationInterval.s02 },
  { label: t('widgets.intervalSize.S_03'), value: AggregationInterval.s03 },
  { label: t('widgets.intervalSize.S_04'), value: AggregationInterval.s04 },
  { label: t('widgets.intervalSize.S_05'), value: AggregationInterval.s05 },
  { label: t('widgets.intervalSize.S_06'), value: AggregationInterval.s06 },
  { label: t('widgets.intervalSize.S_10'), value: AggregationInterval.s10 },
  { label: t('widgets.intervalSize.S_12'), value: AggregationInterval.s12 },
  { label: t('widgets.intervalSize.S_15'), value: AggregationInterval.s15 },
  { label: t('widgets.intervalSize.S_20'), value: AggregationInterval.s20 },
  { label: t('widgets.intervalSize.S_30'), value: AggregationInterval.s30 },
  { label: t('widgets.intervalSize.M_01'), value: AggregationInterval.m01 },
  { label: t('widgets.intervalSize.M_02'), value: AggregationInterval.m02 },
  { label: t('widgets.intervalSize.M_03'), value: AggregationInterval.m03 },
  { label: t('widgets.intervalSize.M_04'), value: AggregationInterval.m04 },
  { label: t('widgets.intervalSize.M_05'), value: AggregationInterval.m05 },
  { label: t('widgets.intervalSize.M_06'), value: AggregationInterval.m06 },
  { label: t('widgets.intervalSize.M_10'), value: AggregationInterval.m10 },
  { label: t('widgets.intervalSize.M_12'), value: AggregationInterval.m12 },
  { label: t('widgets.intervalSize.M_15'), value: AggregationInterval.m15 },
  { label: t('widgets.intervalSize.M_20'), value: AggregationInterval.m20 },
  { label: t('widgets.intervalSize.M_30'), value: AggregationInterval.m30 },
  { label: t('widgets.intervalSize.H_01'), value: AggregationInterval.h01 },
  { label: t('widgets.intervalSize.H_02'), value: AggregationInterval.h02 },
  { label: t('widgets.intervalSize.H_03'), value: AggregationInterval.h03 },
  { label: t('widgets.intervalSize.H_04'), value: AggregationInterval.h04 },
  { label: t('widgets.intervalSize.H_06'), value: AggregationInterval.h06 },
  { label: t('widgets.intervalSize.H_08'), value: AggregationInterval.h08 },
  { label: t('widgets.intervalSize.H_12'), value: AggregationInterval.h12 },
  { label: t('widgets.intervalSize.D_01'), value: AggregationInterval.d01 },
  { label: t('widgets.intervalSize.D_02'), value: AggregationInterval.d02 },
  { label: t('widgets.intervalSize.D_03'), value: AggregationInterval.d03 },
  { label: t('widgets.intervalSize.D_04'), value: AggregationInterval.d04 },
  { label: t('widgets.intervalSize.D_05'), value: AggregationInterval.d05 },
  { label: t('widgets.intervalSize.D_06'), value: AggregationInterval.d06 },
  { label: t('widgets.intervalSize.D_07'), value: AggregationInterval.d07 },
];

export interface AggregationDropdownProps {
  aggregationIntervals: AggregationInterval[];
  selectedInterval: AggregationInterval;
  label: string;
  onChangeInterval: (value: string) => void;
}

export const AggregationDropdown: React.FC<AggregationDropdownProps> = ({
  aggregationIntervals,
  selectedInterval,
  label,
  onChangeInterval,
}) => {
  const { t } = useTranslation();
  const options = useMemo(() => makeOptions(t), [t]);

  return (
    <div>
      <Typography variant="labelSmall" component="div" className={classes.text}>
        {label}
      </Typography>
      <Dropdown
        value={selectedInterval}
        onChange={onChangeInterval}
        className={classes.dropdown}
        data-testid="aggregation-selector"
      >
        {options
          .filter(({ value }) => aggregationIntervals.includes(value))
          .map((option) => (
            <ListItem
              value={option.value}
              title={option.label}
              key={option.value}
            />
          ))}
      </Dropdown>
    </div>
  );
};
