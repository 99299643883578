import React, { useCallback } from 'react';
import { useNotifications } from '../../contexts/notifications-context';
import { usePropertiesQuery } from '../../__generated__/types';
import { useFilters } from '../../hooks/filters';
import { Properties } from './Properties';
import { usePlatform } from '../../contexts/platform-context';

export const PropertiesContainer: React.FC = () => {
  const { add } = useNotifications();
  const { query, setQuery } = useFilters();

  const handleSetQuery = useCallback(
    (event: any) => {
      setQuery(event);
    },
    [setQuery],
  );

  const { data, loading } = usePropertiesQuery({
    variables: {
      where: {
        name: { contains: query },
      },
    },
    onError: (error) =>
      add({
        type: 'danger',
        id: error.message,
        content: error.message,
      }),
  });

  const { isFavouriteBuildingId } = usePlatform();

  const properties = (data?.properties?.items || []).map((property) => ({
    id: property.id,
    name: property.name,

    buildings: (property?.buildings || [])
      .map((building) => ({
        isFavorite: isFavouriteBuildingId(building.id) as boolean,
        id: building.id,
        name: building.name,
        location: {
          street: building.location?.street || '',
          no: building.location?.no || '',
          city: building.location?.city || '',
          zip: building.location?.zip ?? '',
          coordinates:
            building.location?.lat && building.location?.lon
              ? { lat: building.location.lat, lng: building.location.lon }
              : undefined,
        },
      }))
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
  }));

  return (
    <Properties
      loading={loading}
      properties={properties}
      onChangeQuery={handleSetQuery}
    />
  );
};
