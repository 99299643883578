import React, { useCallback } from 'react';
import { Button, Colors, createClasses, Icons } from '@kp/react-ui';
import { useDashboard } from './dashboard-context';

interface DashboardLayoutHeaderProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
}

const classes = createClasses({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px 0 18px',
    background: Colors.Neutral.background,
    height: '47px',
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
  },
  header: {
    display: 'flex',
    paddingTop: '1px',
    gap: '14px',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    gap: '12px',
    zIndex: '70',
    alignItems: 'center',
  },
});

export const DashboardLayoutHeader: React.FC<DashboardLayoutHeaderProps> = ({
  title,
  actions,
}) => {
  const { leftSidebarOpen, setLeftSidebarOpen } = useDashboard();
  const handleMenuClick = useCallback(() => {
    setLeftSidebarOpen(!leftSidebarOpen);
  }, [leftSidebarOpen, setLeftSidebarOpen]);

  return (
    <header className={classes.root}>
      <div className={classes.header}>
        <Button
          variant="tertiary"
          selected={leftSidebarOpen}
          onClick={handleMenuClick}
          icon={Icons.IconMenu}
        />
        {title}
      </div>
      <div className={classes.actions}>{actions}</div>
    </header>
  );
};
