import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import noop from '@stdlib/utils-noop';
import { Colors } from '@kp/react-ui';
import { startOfDay, subDays } from 'date-fns';
import { Device, DeviceChartType } from './Device.types';
import { DateIntervalResult, useDateInterval } from '../../hooks/date-interval';

interface DeviceContextValue extends DateIntervalResult {
  infoSidebarOpen: boolean;
  setInfoSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settingsSidebarOpen: boolean;
  setSettingsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hasSidebarOpen: boolean;
  capabilities: DeviceCapability[];
  setCapabilitySelected: (capabilityId: string, selected: boolean) => void;
  setCapabilityColor: (capabilityId: string, color: string) => void;
  setAllCapabilitiesSelected: (selected: boolean) => void;
  collapsedChart: boolean;
  setCollapsedChart: (value: boolean) => void;
  chartType: DeviceChartType;
  setChartType: (value: DeviceChartType) => void;
}

export const DeviceContext = createContext<DeviceContextValue>({
  hasSidebarOpen: true,
  infoSidebarOpen: true,
  setInfoSidebarOpen: noop,
  settingsSidebarOpen: true,
  setSettingsSidebarOpen: noop,
  setCapabilitySelected: noop,
  setCapabilityColor: noop,
  setAllCapabilitiesSelected: noop,
  collapsedChart: false,
  setCollapsedChart: noop,
  capabilities: [],
  dateFrom: startOfDay(subDays(new Date(), 7)),
  dateUntil: 'max',
  tmpDateFrom: startOfDay(subDays(new Date(), 7)),
  tmpDateUntil: 'max',
  minDate: new Date(),
  setMinDate: noop,
  setInterval: noop,
  setImmediateInterval: noop,
  chartType: DeviceChartType.LINE,
  setChartType: noop,
});

export const useDevice = (): DeviceContextValue => useContext(DeviceContext);

type DeviceCapability = Device['capabilities'][number] & {
  color: string;
  selected: boolean;
};

interface DeviceProviderProps {
  capabilities: Device['capabilities'];
  children?: React.ReactNode;
}

const colorPalette = [
  Colors.Palette.blue,
  Colors.Palette.green,
  Colors.Palette.meteor,
  Colors.Palette.orange,
  Colors.Palette.pink,
  Colors.Palette.purple,
  Colors.Palette.yonder,
  Colors.Palette.spring,
  Colors.Palette.brown,
  Colors.Palette.rust,
  Colors.Palette.hopbush,
  Colors.Palette.wisteria,
];

export const DeviceProvider: React.FC<DeviceProviderProps> = ({
  capabilities: capabilitiesData,
  children,
}) => {
  const [infoSidebarOpen, setInfoSidebarOpen] = useState(true);
  const [settingsSidebarOpen, setSettingsSidebarOpen] = useState(false);
  const [capabilities, setCapabilities] = useState<DeviceCapability[]>([]);
  const [collapsedChart, setCollapsedChart] = useState(false);
  const [chartType, setChartType] = useState<DeviceChartType>(
    DeviceChartType.LINE,
  );
  const dateInterval = useDateInterval();

  useEffect(() => {
    setCapabilities(
      capabilitiesData.map((capability, index) => ({
        ...capability,
        selected: true,
        color: colorPalette[index % colorPalette.length],
      })),
    );
  }, [capabilitiesData, setCapabilities]);

  const setCapabilitySelected = useCallback(
    (capabilityId: string, selected: boolean) => {
      setCapabilities((items) =>
        items.map((capability) => ({
          ...capability,
          selected:
            capability.id === capabilityId ? selected : capability.selected,
        })),
      );
    },
    [setCapabilities],
  );

  const setCapabilityColor = useCallback(
    (capabilityId: string, color: string) => {
      setCapabilities((items) =>
        items.map((capability) => ({
          ...capability,
          color: capability.id === capabilityId ? color : capability.color,
        })),
      );
    },
    [setCapabilities],
  );

  const setAllCapabilitiesSelected = useCallback(
    (selected: boolean) => {
      setCapabilities((items) =>
        items.map((capability) => ({ ...capability, selected })),
      );
    },
    [setCapabilities],
  );

  const value = useMemo(
    () => ({
      ...dateInterval,
      infoSidebarOpen,
      setInfoSidebarOpen,
      settingsSidebarOpen,
      setSettingsSidebarOpen,
      hasSidebarOpen: infoSidebarOpen || settingsSidebarOpen,
      setCapabilitySelected,
      setCapabilityColor,
      setAllCapabilitiesSelected,
      capabilities,
      collapsedChart,
      setCollapsedChart,
      setChartType,
      chartType,
    }),
    [
      dateInterval,
      infoSidebarOpen,
      setInfoSidebarOpen,
      settingsSidebarOpen,
      setSettingsSidebarOpen,
      setCapabilitySelected,
      setCapabilityColor,
      setAllCapabilitiesSelected,
      capabilities,
      collapsedChart,
      setCollapsedChart,
      setChartType,
      chartType,
    ],
  );

  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
};
