import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedValue } from 'rooks';
import { isAfter, startOfMinute, subDays } from 'date-fns';

export type IntervalDateUntil = Date | 'max';

export interface Interval {
  dateFrom?: Date;
  dateUntil?: IntervalDateUntil;
}

type SetInterval = (
  interval: Interval,
  options?: { immediate?: boolean },
) => void;

export interface DateIntervalResult {
  minDate: Date;
  tmpDateFrom: Date;
  tmpDateUntil: IntervalDateUntil;
  dateFrom: Date;
  dateUntil: IntervalDateUntil;
  setInterval: SetInterval;
  setImmediateInterval: (interval: Interval) => void;
  setMinDate: (date: Date) => void;
}

const makeMinDate = () => startOfMinute(subDays(new Date(), 7));

export const useDateInterval: () => DateIntervalResult = () => {
  const [minDate, setMinDate] = useState(makeMinDate());

  // Temporary Dates are used for displaying in the UI, when the user makes changes, like
  // when the slider is being used
  const [tmpDateFrom, setTmpDateFrom] = useState(makeMinDate());
  const [tmpDateUntil, setTmpDateUntil] = useState<IntervalDateUntil>('max');

  // dateFrom and dateUntil are the real values that should be used to make queries
  const [dateUntil, setDateUntil] = useDebouncedValue<IntervalDateUntil>(
    tmpDateUntil,
    500,
  );
  const [dateFrom, setDateFrom] = useDebouncedValue(tmpDateFrom, 500);

  const setInterval = useCallback(
    (interval: Interval, options: { immediate?: boolean } = {}) => {
      if (interval.dateFrom) {
        setTmpDateFrom(interval.dateFrom);
        if (options.immediate) {
          setDateFrom(interval.dateFrom);
        }
      }
      if (interval.dateUntil) {
        setTmpDateUntil(interval.dateUntil);
        if (options.immediate) {
          setDateUntil(interval.dateUntil);
        }
      }
    },
    [setDateFrom, setDateUntil],
  );

  const setImmediateInterval = useCallback(
    (interval: Interval) => {
      setInterval(interval, { immediate: true });
    },
    [setInterval],
  );

  useEffect(() => {
    if (dateFrom && isAfter(minDate, dateFrom)) {
      setInterval({ dateFrom: minDate }, { immediate: true });
    }
  }, [minDate, dateFrom, setInterval]);

  return useMemo(
    () => ({
      setInterval,
      setImmediateInterval,
      minDate,
      setMinDate,
      tmpDateFrom,
      tmpDateUntil,
      dateFrom: dateFrom || tmpDateFrom,
      dateUntil: dateUntil || tmpDateUntil,
    }),
    [
      setInterval,
      setImmediateInterval,
      minDate,
      tmpDateFrom,
      tmpDateUntil,
      dateFrom,
      dateUntil,
    ],
  );
};
