import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useBuilding } from '../../contexts/building-context';
import { useRequest } from '../../hooks/useRequest';
import { getDashboards } from '../../api/building-insights';
import { NotFoundHandler } from '../NotFoundHandler';
import { DashboardsEmpty } from './empty/DashboardsEmpty';
import { DashboardContainer } from './dashboard/DashboardContainer';
import { useNotifications } from '../../contexts/notifications-context';
import { DashboardProvider } from './dashboard-context';

export const DashboardsContainer: React.FC = () => {
  const { id: buildingId } = useBuilding();
  const { add } = useNotifications();
  const { response, error, loading, refresh } = useRequest(
    getDashboards,
    buildingId,
  );

  useEffect(() => {
    if (error && error.message) {
      add({
        type: 'danger',
        id: error.message,
        content: error.message,
      });
    }
  }, [error, add]);

  return (
    <DashboardProvider refresh={refresh} loading={loading}>
      <Routes>
        <Route
          path="/"
          element={<DashboardsEmpty dashboards={response?.data || []} />}
        />
        <Route
          path="/:dashboardId"
          element={<DashboardContainer dashboards={response?.data || []} />}
        />
        <Route path="*" element={<NotFoundHandler />} />
      </Routes>
    </DashboardProvider>
  );
};
