import React from 'react';
import {
  classNames,
  Colors,
  createClasses,
  DropdownButton,
  ContextMenuItem,
  Icons,
  Typography,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: Colors.Neutral.background,
    borderRadius: '6px',
    height: '100%',
    position: 'relative',
  },
  unselected: {
    border: `1px solid ${Colors.Neutral.borderMedium}`,
  },
  selected: {
    padding: '1px',
    boxShadow: `0px 0px 0px 2px ${Colors.Selected.border}`,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  },
  content: {
    padding: '0px 24px',
    height: '100%',
  },
  title: {
    // Overrides the label of the dropdown button.
    '& > div': {
      display: 'inline-block',
      maxWidth: '100%',
    },
    '& > div > label > button': {
      maxWidth: '100%',
      color: Colors.Neutral.text,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  titleText: {
    padding: '6px 12px',
    display: 'block',
  },
  subtitle: {
    color: `${Colors.Neutral.textWeak}`,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '0 12px',
  },
  popup: {
    zIndex: 10000,
    left: 'max(0px, calc(100% - 88px))',
    minWidth: '176px',
  },
  spacer: {
    display: 'none',
  },
});

export interface DashboardCardProps {
  title: string;
  subtitle?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  children?: React.ReactNode;
  selected?: boolean;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  subtitle,
  onEdit,
  onDelete,
  children,
  selected,
}) => {
  const { t } = useTranslation();
  const cardRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (selected && cardRef.current?.scrollIntoView) {
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [selected]);

  return (
    <div
      className={classNames(
        classes.root,
        selected ? classes.selected : classes.unselected,
      )}
      ref={cardRef}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          {onEdit || onDelete ? (
            <DropdownButton
              variant="tertiary"
              label={title}
              position="right"
              popupClassName={classes.popup}
            >
              <div className={classNames(classes.spacer, 'spacer')} />
              {onEdit && (
                <ContextMenuItem
                  icon={Icons.IconEdit}
                  title={t('dashboards.card.menu.edit') || ''}
                  onClick={onEdit}
                  fullWidth
                />
              )}
              {onDelete && (
                <ContextMenuItem
                  icon={Icons.IconTrash}
                  title={t('dashboards.card.menu.delete') || ''}
                  onClick={onDelete}
                  fullWidth
                />
              )}
            </DropdownButton>
          ) : (
            <Typography variant="labelMedium" className={classes.titleText}>
              {title}
            </Typography>
          )}
        </div>
        <Typography variant="bodySmall" className={classes.subtitle}>
          {subtitle}
        </Typography>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
