import React, { useCallback, useMemo } from 'react';
import { TimeUnit } from 'chart.js';
import {
  TimeScaleBarChart,
  TimeScaleDataset,
  TimeScaleLineChart,
} from '@kp/react-ui';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';
import { differenceInHours } from 'date-fns';

interface ChartProps {
  datasets: TimeScaleDataset[];
  collapsed: boolean;
  min?: Date;
  max?: Date;
  size?: 'small' | 'large';
  className?: string;
  type?: 'line' | 'bar';
  autoFit?: boolean;
  showIntervalPoint?: boolean;
  showCrosshair?: boolean;
  cursorStyle?: 'crosshair' | 'default';
  redraw?: boolean;
}

export const Chart: React.FC<ChartProps> = ({
  datasets,
  min,
  max,
  type = 'line',
  size = 'small',
  autoFit,
  showIntervalPoint = false,
  showCrosshair = false,
  cursorStyle = 'default',
  ...rest
}) => {
  const { i18n, t } = useTranslation();
  const Component = type === 'line' ? TimeScaleLineChart : TimeScaleBarChart;

  const unit: TimeUnit = useMemo(() => {
    if (!min || !max) {
      return 'hour';
    }

    const diffHours = differenceInHours(max, min);

    if (diffHours < 2) {
      return 'minute';
    }
    if (diffHours < 25) {
      return 'hour';
    }
    return 'day';
  }, [min, max]);

  const handleTickFormat = useCallback(
    (value: number) =>
      value < 1000
        ? value.toString()
        : `${(value / 1000).toFixed(0)} ${t('chart.thousand')}`,
    [t],
  );

  return (
    <Component
      locale={i18n.language === 'de' ? de : en}
      time={{
        displayFormats: {
          minute: 'p',
          hour: 'p',
          day: i18n.language === 'de' ? 'd. MMM' : 'MMM, do',
        },
        unit,
      }}
      data={{ datasets }}
      min={min?.toISOString()}
      max={max?.toISOString()}
      size={size}
      yTickFormat={size === 'small' ? handleTickFormat : undefined}
      autoFit={autoFit}
      showIntervalPoint={showIntervalPoint}
      showCrosshair={showCrosshair}
      cursorStyle={cursorStyle}
      {...rest}
    />
  );
};
