declare global {
  interface Window {
    ENV_GRAPHQL_URI: string;
    ENV_AUTH_URI: string;
    ENV_ADMIN_APP_URI: string;
    ENV_USER_SERVICE_URI: string;
    ENV_CLIENT_ID: string;
    ENV_ACCOUNT_APP_URI: string;
    ENV_QANTEON_SERVICE_URI: string;
    ENV_BUILDING_INSIGHTS_SERVICE_URI: string;
    ENV_APPLICATIONINSIGHTS_CONNECTION_STRING: string;
    ENV_GOOGLE_MAPS_API_KEY: string;
  }
}

export const GRAPHQL_URI = window.ENV_GRAPHQL_URI;
export const AUTH_URI = window.ENV_AUTH_URI;
export const ADMIN_APP_URI = window.ENV_ADMIN_APP_URI;
export const USER_SERVICE_URI = window.ENV_USER_SERVICE_URI;
export const CLIENT_ID = window.ENV_CLIENT_ID;
export const ACCOUNT_APP_URI = window.ENV_ACCOUNT_APP_URI;
export const QANTEON_SERVICE_URI = window.ENV_QANTEON_SERVICE_URI;
export const BUILDING_INSIGHTS_SERVICE_URI =
  window.ENV_BUILDING_INSIGHTS_SERVICE_URI;
export const APPLICATIONINSIGHTS_CONNECTION_STRING =
  window.ENV_APPLICATIONINSIGHTS_CONNECTION_STRING;
export const GOOGLE_MAPS_API_KEY = window.ENV_GOOGLE_MAPS_API_KEY;
