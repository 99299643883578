import React, { ChangeEventHandler, useCallback } from 'react';
import { MetaObject } from '@xeokit/xeokit-sdk';
import { Checkbox } from '@kp/react-ui';
import { useXeoKitVisibility } from '../../../contexts/xeokit';

interface InteractiveTreeCheckboxProps {
  object: MetaObject;
}

export const InteractiveTreeCheckbox: React.FC<InteractiveTreeCheckboxProps> =
  React.memo(({ object }) => {
    const { visibleMetaObjectIds, setMetaObjectVisibility } =
      useXeoKitVisibility();

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        setMetaObjectVisibility(object, event.target.checked);
      },
      [object, setMetaObjectVisibility],
    );

    return (
      <Checkbox
        checked={visibleMetaObjectIds.includes(String(object.id))}
        onChange={handleChange}
      />
    );
  });

InteractiveTreeCheckbox.displayName = 'InteractiveTreeCheckbox';
