import React from 'react';
import { createClasses, DropdownBase, Icons } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useDevice } from '../device-context';
import { useLocaleDateFns } from '../../../hooks/date-fns';
import { IntervalCustom } from '../../../components/IntervalSelector';

const classes = createClasses({
  root: {},
  popup: {
    width: '320px',
    'div &': {
      maxHeight: 'none',
      overflow: 'visible',
      zIndex: 99999,
      padding: 0,
    },
  },
  dropdown: {
    fontFeatureSettings: "'tnum'",
  },
});

interface DeviceDatePickerProps {}

export const DeviceDatePicker: React.FC<DeviceDatePickerProps> = () => {
  const { t } = useTranslation();
  const { tmpDateFrom, tmpDateUntil, setImmediateInterval } = useDevice();
  const { format } = useLocaleDateFns();

  return (
    <div className={classes.root}>
      <DropdownBase
        popupClassName={classes.popup}
        className={classes.dropdown}
        label={`${format(tmpDateFrom, 'P HH:mm')} - ${
          tmpDateUntil !== 'max'
            ? format(tmpDateUntil, 'P HH:mm')
            : t('common.customInterval.latest')
        }`}
        arrow={false}
        popupOverflow
        position="right"
        icon={Icons.IconCalendarTime}
        data-testid="calendar"
      >
        {(close: () => void) => (
          <IntervalCustom
            dateFrom={tmpDateFrom}
            dateUntil={tmpDateUntil}
            onCancel={close}
            onChange={setImmediateInterval}
          />
        )}
      </DropdownBase>
    </div>
  );
};
