import { format } from 'date-fns';
import { Colors } from '@kp/react-ui';
import { DataTypes } from '../__generated__/types';

export const createRangeString = (
  capability?: {
    unitSymbol?: string;
    technicalMin?: number;
    technicalMax?: number;
  } | null,
): string => {
  if (!capability) return '-';

  const symbol = capability.unitSymbol || '';

  if (capability.technicalMin && !capability.technicalMax)
    return `> ${capability.technicalMin} ${symbol}`;
  if (!capability.technicalMin && capability.technicalMax)
    return `< ${capability.technicalMax} ${symbol}`;

  if (capability.technicalMin && capability.technicalMax)
    return `${capability.technicalMin} ${symbol} - ${capability.technicalMax} ${symbol}`;

  return '-';
};

export const formatValue = (
  value: string,
  dataType: DataTypes,
): React.ReactNode => {
  switch (dataType) {
    case DataTypes.Date:
      return value ? format(new Date(value), 'dd.MM.yyyy') : value;
    case DataTypes.Datetime:
      return value ? format(new Date(value), 'dd.MM.yyyy, HH:mm') : value;
    case DataTypes.Bool:
    case DataTypes.String:
    case DataTypes.NotSpecified:
    case DataTypes.Integer:
    case DataTypes.Decimal:
    default:
      return value;
  }
};

export const getPaletteColor = (options: { exclude?: string[] } = {}) =>
  Object.values(Colors.Palette).find(
    (paletteColor) => !(options.exclude || []).includes(paletteColor),
  ) || Colors.Palette.blue;
