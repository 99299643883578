import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { Colors, UI, createClasses } from '@kp/react-ui';
import { PropertySidebar } from '../../components/PropertySidebar';
import { Map } from '../../components/Map';
import { usePlatform } from '../../contexts/platform-context';
import { useNotifications } from '../../contexts/notifications-context';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: `calc(100vh - ${UI.HEADER_HEIGHT}px)`,
    width: '100%',
  },
  propertySidebar: {
    display: 'flex',
    height: '100%',
  },
  map: {
    height: '100%',
    width: '100%',
  },
  mapPlaceholder: {
    height: '100%',
    width: '100%',
  },
  mapMarker: {
    position: 'absolute',
    padding: '2px 6px',
    height: '20px',
    background: Colors.Neutral.backgroundInverted,
    left: '30px',
    top: '37px',
    borderRadius: '6px',
    zIndex: 1000,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface PropertiesProps {
  loading?: boolean;
  properties?: Array<{
    id: string;
    name: string;
    buildings: Array<{
      isFavorite: boolean;
      id: string;
      name: string;
      location: {
        city: string;
        street: string;
        no: string;
        zip: string;
        coordinates?: { lat: number; lng: number };
      };
    }>;
  }>;
  onChangeQuery: (query: string) => void;
  demo?: boolean;
}

export const Properties: React.FC<PropertiesProps> = ({
  loading,
  properties = [],
  onChangeQuery,
}) => {
  const navigate = useNavigate();

  const { add } = useNotifications();
  const {
    isFavouriteBuildingId,
    addFavouriteBuildingId,
    removeFavouriteBuildingId,
  } = usePlatform();

  const toggleFavourite = useCallback(
    (buildingId: string) => {
      if (!buildingId) {
        return;
      }

      const request = (
        buildingId ? isFavouriteBuildingId(buildingId) || false : false
      )
        ? removeFavouriteBuildingId
        : addFavouriteBuildingId;
      request(buildingId).catch((error) => {
        add({
          type: 'danger',
          id: error.message,
          content: error.message,
        });
      });
    },
    [
      add,
      addFavouriteBuildingId,
      removeFavouriteBuildingId,
      isFavouriteBuildingId,
    ],
  );

  const [selectedTabPage, setSelectedTabPage] = useState('all');
  const handleTabClick = useCallback((event: any) => {}, []);

  const favOnly = selectedTabPage === 'favourite';

  const getProperties = () => {
    return favOnly
      ? properties.filter((p) => p.buildings.some((y) => y.isFavorite))
      : properties;
  };

  const getPositions = () => {
    return favOnly
      ? getProperties()
          .map((prop) =>
            prop.buildings
              .filter((b) => b.isFavorite)
              .map((building) => ({
                key: building.id,
                text: building.name,
                lat: building.location.coordinates
                  ? building.location.coordinates.lat
                  : 52,
                lng: building.location.coordinates
                  ? building.location.coordinates.lng
                  : 13,
              })),
          )
          .flat()
      : getProperties()
          .map((prop) =>
            prop.buildings.map((building) => ({
              key: building.id,
              text: building.name,
              lat: building.location.coordinates
                ? building.location.coordinates.lat
                : 52,
              lng: building.location.coordinates
                ? building.location.coordinates.lng
                : 13,
            })),
          )
          .flat();
  };

  const getCenterPosition = () => {
    if (getPositions().length === 1) {
      return [getPositions()[0].lat + 0.1, getPositions()[0].lng + 0.01] as [
        number,
        number,
      ];
    }
    if (getPositions().length > 1) {
      const latDiff =
        Math.max(...getPositions().map((o) => o.lat)) -
        Math.min(...getPositions().map((o) => o.lat));
      const latMiddle =
        Math.max(...getPositions().map((o) => o.lat)) - latDiff / 2;
      const lngDiff =
        Math.max(...getPositions().map((o) => o.lng)) -
        Math.min(...getPositions().map((o) => o.lng));
      const lngMiddle =
        Math.max(...getPositions().map((o) => o.lng)) - lngDiff / 2;
      let result = [latMiddle, lngMiddle] as [number, number];
      if (latDiff < 0.1 && lngDiff < 0.01)
        result = [
          getPositions()[0].lat + 0.1,
          getPositions()[0].lng + 0.01,
        ] as [number, number];
      return result;
    }
    return [52.51017842433487, 13.378036208354121] as [number, number];
  };

  const handleFavClick = useCallback(
    (event: any) => {
      toggleFavourite(event);
    },
    [toggleFavourite],
  );

  const [isMouseOver, setIsMouseOver] = useState('');

  const handleClick = useCallback(
    (event: any) => {
      navigate(`/buildings/${event}/info`);
    },
    [navigate],
  );

  return (
    <>
      <div className={classes.root}>
        <PropertySidebar
          className={classes.propertySidebar}
          properties={getProperties()}
          handleFavClick={handleFavClick}
          handleTabClick={handleTabClick}
          selectedTabPage={selectedTabPage}
          setSelectedTabPage={setSelectedTabPage}
          setIsMouseOver={setIsMouseOver}
          handleClick={handleClick}
          onChangeQuery={onChangeQuery}
        />
        <div className={classes.map}>
          <Map
            className={classes.map}
            markerClassName={classes.mapMarker}
            positions={getPositions()}
            center={getCenterPosition()}
            marker={true}
            interactive={true}
            isMouseOver={isMouseOver}
            onMarkerClick={handleClick}
          />
        </div>
      </div>
    </>
  );
};
