import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, parseISO, startOfMinute } from 'date-fns';
import { NetworkStatus } from '@apollo/client';
import { useNavigate } from 'react-router';
import { DeviceTable } from './DeviceTable';
import { Device, DeviceLocation } from '../Device.types';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import {
  useDeviceTableDataMinQuery,
  useDeviceTableDataQuery,
} from '../../../__generated__/types';
import { useNotifications } from '../../../contexts/notifications-context';
import { useDevice } from '../device-context';

interface DeviceTableContainerProps {
  loading?: boolean;
  device?: Device;
  location?: DeviceLocation;
}

export const DeviceTableContainer: React.FC<DeviceTableContainerProps> = ({
  device,
  location,
  loading,
}) => {
  const { add } = useNotifications();
  const { capabilityId, buildingId, deviceId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { capabilities, setMinDate, dateUntil, dateFrom } = useDevice();

  const { data: dataMin, loading: loadingMin } = useDeviceTableDataMinQuery({
    variables: {
      deviceId,
      capabilityId,
    },
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (dataMin?.sensorMeasurementsInfo?.minUtcTimeMeasured) {
      setMinDate(parseISO(dataMin.sensorMeasurementsInfo.minUtcTimeMeasured));
    }
  }, [dataMin, setMinDate]);

  const {
    data,
    loading: loadingData,
    fetchMore,
    networkStatus,
  } = useDeviceTableDataQuery({
    variables: {
      take: 30,
      deviceId,
      capabilityId,
      readFrom: format(dateFrom, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
      readUntil: format(
        dateUntil !== 'max' ? dateUntil : startOfMinute(new Date()),
        "yyyy-MM-dd'T'HH:mm:ss.SSS",
      ),
    },
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const [skip, hasNextPage] = useMemo(
    () => [
      data?.sensorMeasurements?.items?.length || 0,
      data?.sensorMeasurements?.pageInfo?.hasNextPage || false,
    ],
    [data],
  );

  const handleScrollDown = useCallback(() => {
    fetchMore({ variables: { skip } });
  }, [fetchMore, skip]);

  useBreadcrumb([
    {
      title: location?.buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    {
      title: t('page.devices'),
      location: `/buildings/${buildingId}/devices`,
    },
    {
      title: device?.name || '',
      selected: true,
      location: `/buildings/${buildingId}/devices/${deviceId}`,
    },
  ]);

  const canLoadMore = skip && hasNextPage && !loading;

  const entries = useMemo(
    () =>
      data?.sensorMeasurements?.items?.map((entry) => ({
        timestamp: entry?.utcTimeMeasured
          ? parseISO(entry.utcTimeMeasured || '')
          : new Date(),
        value: entry?.valueString || '0',
      })) || [],
    [data],
  );

  const capability = useMemo(
    () => capabilities.find(({ id }) => id === capabilityId),
    [capabilities, capabilityId],
  );

  const handleChangeCapability = useCallback(
    (id: string) => {
      navigate(
        `/buildings/${buildingId}/devices/${deviceId}/capabilities/${id}`,
      );
    },
    [navigate, buildingId, deviceId],
  );

  return (
    <DeviceTable
      data={entries}
      onChangeCapability={handleChangeCapability}
      device={device}
      location={location}
      capabilityId={capabilityId}
      capabilityName={capability?.name}
      unitSymbol={capability?.unitSymbol}
      technicalMin={capability?.technicalMin}
      technicalMax={capability?.technicalMax}
      onScrollDown={canLoadMore ? handleScrollDown : undefined}
      loading={
        (loadingData && networkStatus !== NetworkStatus.fetchMore) ||
        loading ||
        loadingMin
      }
      loadingMore={loadingData && networkStatus === NetworkStatus.fetchMore}
    />
  );
};
