import React, { useCallback } from 'react';
import { DashboardCardClock } from '../../../../../api/building-insights';
import { DashboardFormSubmit } from '../../Dashboard.types';
import { ClockEditPanel } from './ClockEditPanel';

interface ClockEditPanelContainerProps {
  card: DashboardCardClock;
  onUpdate: (card: Partial<DashboardCardClock>) => void;
  onSubmit: DashboardFormSubmit;
}

export const ClockEditPanelContainer: React.FC<
  ClockEditPanelContainerProps
> = ({ card, onUpdate, onSubmit }) => {
  const handleChangeTitle = useCallback(
    (title: string) => onUpdate({ title }),
    [onUpdate],
  );

  const handleChangeSubtitle = useCallback(
    (subtitle: string) => onUpdate({ subtitle }),
    [onUpdate],
  );

  return (
    <ClockEditPanel
      title={card.title}
      subtitle={card.subtitle}
      onChangeTitle={handleChangeTitle}
      onChangeSubtitle={handleChangeSubtitle}
      onSubmit={onSubmit}
    />
  );
};
