import React, { useCallback, useMemo } from 'react';
import {
  ChartType,
  DashboardCardChart,
  DashboardCardChartItem,
} from '../../../../../api/building-insights';
import { ChartEditPanel } from './ChartEditPanel';
import { useChartEditPanelDevicesQuery } from '../../../../../__generated__/types';
import { useNotifications } from '../../../../../contexts/notifications-context';
import { getPaletteColor } from '../../../../../utils/helpers';
import { DashboardFormSubmit } from '../../Dashboard.types';

export interface ChartEditPanelContainerProps {
  card: DashboardCardChart;
  onUpdate: (card: Partial<DashboardCardChart>) => void;
  onSubmit: DashboardFormSubmit;
}

export const ChartEditPanelContainer: React.FC<
  ChartEditPanelContainerProps
> = ({ card, onUpdate, onSubmit }) => {
  const { add } = useNotifications();

  const deviceIds = useMemo(
    () => card.configuration?.items.map((items) => items.deviceId) || [],
    [card],
  );

  const { data, loading } = useChartEditPanelDevicesQuery({
    variables: { deviceIds },
    skip: deviceIds.length === 0,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const handleChangeTitle = useCallback(
    (title: string) => onUpdate({ title }),
    [onUpdate],
  );

  const handleChangeSubtitle = useCallback(
    (subtitle: string) => onUpdate({ subtitle }),
    [onUpdate],
  );

  const handleChangeType = useCallback(
    (type: ChartType) => {
      onUpdate({
        configuration: { type, items: card.configuration?.items || [] },
      });
      onSubmit().catch(console.warn);
    },
    [card, onUpdate, onSubmit],
  );

  const handleAddItem = useCallback(
    (
      item: Pick<DashboardCardChartItem, 'capabilityId' | 'deviceId' | 'name'>,
    ) => {
      const currentItems = card.configuration?.items || [];
      onUpdate({
        configuration: {
          type: card.configuration?.type || ChartType.line,
          items: [
            ...currentItems,
            {
              color: getPaletteColor({
                exclude: currentItems.map(({ color }) => color),
              }),
              ...item,
            },
          ],
        },
      });
      onSubmit().catch(console.warn);
    },
    [card, onUpdate, onSubmit],
  );

  const handleRemoveItem = useCallback(
    (id: string) => {
      if (!card.configuration) {
        return;
      }
      onUpdate({
        configuration: {
          type: card.configuration.type,
          items: card.configuration.items.filter((item) => item.id !== id),
        },
      });
      onSubmit().catch(console.warn);
    },
    [card, onUpdate, onSubmit],
  );

  const handleChangeItem = useCallback(
    (id: string, updates: Partial<DashboardCardChartItem>) => {
      if (!card.configuration) {
        return;
      }
      onUpdate({
        configuration: {
          type: card.configuration.type,
          items: card.configuration.items.map((item) =>
            item.id === id ? { ...item, ...updates } : item,
          ),
        },
      });
      onSubmit({ debounce: true }).catch(console.warn);
    },
    [card, onUpdate, onSubmit],
  );

  const items = useMemo(
    () =>
      (card.configuration?.items || []).map((item) => {
        const device = data?.devices?.items?.find(
          ({ id }) => id === item.deviceId,
        );
        const capability = device?.deviceModel.deviceModelCapabilities.find(
          ({ id }) => id === item.capabilityId,
        );

        return {
          ...item,
          id: item.id || 'new',
          deviceName: device?.name || '',
          capabilityName: capability?.capability.name || '',
          unitName: capability?.unit?.name || '',
          unitSymbol: capability?.unit?.unitSymbol || '',
        };
      }),
    [data, card],
  );

  return (
    <ChartEditPanel
      title={card.title}
      subtitle={card.subtitle}
      type={card?.configuration?.type || ChartType.line}
      items={items}
      onChangeTitle={handleChangeTitle}
      onChangeSubtitle={handleChangeSubtitle}
      onChangeType={handleChangeType}
      onChangeItem={handleChangeItem}
      onAddItem={handleAddItem}
      onRemoveItem={handleRemoveItem}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
};
