import React, { useCallback, useState, useMemo } from 'react';
import { FieldArrayRenderProps } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  createClasses,
  classNames,
  Tabs,
  Tab,
  Colors,
  Button,
  Icons,
} from '@kp/react-ui';
import { WidgetFormDevicesSearchContainer } from './WidgetFormDevicesSearch/WidgetFormDevicesSearchContainer';
import { WidgetFormDevicesAddedContainer } from './WidgetFormDevicesAdded/WidgetFormDevicesAddedContainer';
import {
  FormCapability,
  FormCapabilityIdentifier,
} from './widgetFormDevicesTypes';
import { WidgetFormDevicesQuery } from '../../../__generated__/types';
import { WIDGETS_TABS_HEIGHT } from '../../../constants/UI';
import { getPaletteColor } from '../../../utils/helpers';

const classes = createClasses({
  root: {
    borderTop: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.background,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${WIDGETS_TABS_HEIGHT}px)`,
    background: Colors.Neutral.background,
    overflow: 'hidden',
  },
  drag: {
    top: 0,
    bottom: 0,
    height: '2px',
    width: '100%',
    '&:hover': {
      cursor: 'ns-resize',
      background: Colors.Selected.border,
    },
  },
});

export interface WidgetFormDevicesProps
  extends Pick<
    FieldArrayRenderProps,
    'push' | 'form' | 'remove' | 'replace' | 'move'
  > {
  type?: 'edit' | 'create' | 'view';
  className?: string;
  onMouseDown: () => void;
  onToggle?: () => void;
  onClickTab?: () => void;
  devicesData?: WidgetFormDevicesQuery;
  loadingDevices?: boolean;
}

export const WidgetFormDevices: React.FC<WidgetFormDevicesProps> = ({
  push,
  form,
  move,
  remove,
  replace,
  type,
  className,
  onToggle,
  onMouseDown,
  onClickTab,
  devicesData,
  loadingDevices,
}) => {
  const [selectedTab, setSelectedTab] = useState<'search' | 'added'>(
    type === 'create' ? 'search' : 'added',
  );
  const { t } = useTranslation();

  const capabilities: FormCapability[] = useMemo(
    () => form.values.capabilities || [],
    [form],
  );

  const handleAdd = useCallback(
    (capability: FormCapabilityIdentifier) => {
      push({
        ...capability,
        color: getPaletteColor({
          exclude: capabilities.map(({ color }) => color),
        }),
        selected: true,
      });
      setSelectedTab('added');
    },
    [setSelectedTab, push, capabilities],
  );

  const handleRemove = useCallback(
    ({ deviceId, capabilityId }: FormCapabilityIdentifier) => {
      remove(
        capabilities.findIndex(
          (capability) =>
            capability.deviceId === deviceId &&
            capability.capabilityId === capabilityId,
        ),
      );
    },
    [remove, capabilities],
  );

  const handleChange = useCallback(
    (
      { deviceId, capabilityId }: FormCapabilityIdentifier,
      changes: Partial<FormCapability>,
    ) => {
      const capabilityIndex = capabilities.findIndex(
        (capability) =>
          capability.deviceId === deviceId &&
          capability.capabilityId === capabilityId,
      );
      replace(capabilityIndex, {
        ...capabilities[capabilityIndex],
        ...changes,
      });
    },
    [replace, capabilities],
  );

  const handleChangeTab = useCallback(
    (value: string) => {
      switch (value) {
        case 'search':
          return setSelectedTab('search');
        case 'added':
          return setSelectedTab('added');
        default:
          return setSelectedTab('search');
      }
    },
    [setSelectedTab],
  );

  return (
    <div
      className={classNames(className, classes.root)}
      data-testid="devices-tabs"
    >
      <div
        className={classes.drag}
        data-testid="drag-border"
        onMouseDown={onMouseDown}
      />
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        actions={
          <Button
            variant="secondary"
            icon={Icons.IconChevronUpDown}
            onClick={onToggle}
          />
        }
      >
        {type !== 'view' && (
          <Tab
            value="search"
            label={t('widgets.tabs.deviceSearch')}
            onClick={onClickTab}
          />
        )}
        <Tab
          value="added"
          label={`${t('widgets.tabs.addedDevices')}${
            capabilities.length > 0 ? ` (${capabilities.length})` : ''
          }`}
          onClick={onClickTab}
        />
      </Tabs>
      <div className={classes.content}>
        {selectedTab === 'search' && (
          <WidgetFormDevicesSearchContainer
            onAdd={handleAdd}
            capabilities={capabilities}
          />
        )}
        {selectedTab === 'added' && (
          <WidgetFormDevicesAddedContainer
            onMove={move}
            loading={loadingDevices}
            data={devicesData}
            onRemove={type === 'view' ? undefined : handleRemove}
            onChange={handleChange}
            onDuplicate={type === 'view' ? undefined : handleAdd}
            capabilities={capabilities}
          />
        )}
      </div>
    </div>
  );
};
