import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

export type MapProps = {
  center: [number, number];
  marker?: boolean;
  className?: string;
  interactive?: boolean;
  markerClassName?: string;
  positions?: Array<{
    key: string;
    text: string;
    lat: number;
    lng: number;
  }>;
  isMouseOver?: string;
  onMarkerClick?: (value: string) => void;
};

export const Map: React.FC<MapProps> = ({
  center,
  marker = false,
  interactive = true,
  positions,
  className,
  markerClassName,
  isMouseOver,
  onMarkerClick,
}) => {
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
  const options = useMemo(
    () =>
      interactive
        ? {
            clickableIcons: false,
            keyboardShortcuts: false,
            // gestureHandling: 'none',
          }
        : {
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            gestureHandling: 'none',
            clickableIcons: false,
            keyboardShortcuts: false,
          },
    [interactive],
  );

  const refreshBounds = useCallback(
    (map: google.maps.Map) => {
      const bounds = new window.google.maps.LatLngBounds({
        lat: center[0],
        lng: center[1],
      });
      if (positions && positions?.length > 0) {
        positions.forEach((pos) => {
          bounds.extend({ lat: pos.lat, lng: pos.lng });
        });
      }

      if (positions && positions?.length === 0) {
        // Zoom out Germany if no makers available
        bounds.extend({ lat: 45, lng: 5 });
        bounds.extend({ lat: 55, lng: 22 });
      }

      if (!positions) {
        map.setZoom(13);
      } else {
        map.fitBounds(bounds, 50);
      }
    },
    [positions, center],
  );

  const onLoad = React.useCallback((map: google.maps.Map) => {
    setMapRef(map);
  }, []);

  useEffect(() => {
    if (mapRef) {
      refreshBounds(mapRef);
    }
  }, [refreshBounds, mapRef]);

  // const [internalMouseOver, setIsInternalMouseOver] = useState('');

  return (
    <div className={className}>
      <GoogleMap
        mapContainerStyle={{ height: '100%' }}
        center={{ lat: center[0], lng: center[1] }}
        onLoad={onLoad}
        options={options}
      >
        {marker && !positions && (
          <MarkerF
            options={{ clickable: false }}
            position={{ lat: center[0], lng: center[1] }}
            icon="/assets/building-marker-default.png"
          />
        )}
        {marker &&
          positions &&
          positions.length > 0 &&
          positions.map((pos) => (
            <MarkerF
              // 'Marker' are not showen at startup only 'MarkerF' works
              // isMouseOver only working with 'Marker' not with 'MarkerF'
              // Map not showing elements anymore with React 18 #2978 https://github.com/JustFly1984/react-google-maps-api/issues/2978
              key={pos.key}
              position={{ lat: pos.lat, lng: pos.lng }}
              onClick={() => onMarkerClick && onMarkerClick(pos.key)}
              // onMouseOver={() => setIsInternalMouseOver(pos.key)}
              // onMouseOut={() => setIsInternalMouseOver('')}
              icon={
                isMouseOver && isMouseOver === pos.key // ||
                  ? // (internalMouseOver && internalMouseOver === pos.key)
                    '/assets/building-marker-hover.png'
                  : '/assets/building-marker-default.png'
              }
              label={{
                className: markerClassName,
                text: pos.text,
                fontSize: '12px',
                // fontWeight: '500',
                fontFamily: 'Inter',
                color: 'white',
              }}
            />
          ))}
      </GoogleMap>
    </div>
  );
};
