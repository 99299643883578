import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { WidgetFormChart } from './WidgetFormChart';
import type { WidgetFormData } from '../WidgetFormContainer';
import {
  TimeInterval,
  getDateFromOfTimeInterval,
} from '../../../../api/building-insights';
import { WidgetFormDataQuery } from '../../../../__generated__/types';

export interface WidgetFormChartContainerProps {
  loading?: boolean;
  data?: WidgetFormDataQuery;
}

export const WidgetFormChartContainer: React.FC<
  WidgetFormChartContainerProps
> = ({ loading, data }) => {
  const {
    values: {
      behaviour,
      capabilities,
      timeInterval,
      dateFrom,
      dateUntil,
      chartType,
      comparisonLine,
      showPoints,
    },
  } = useFormikContext<WidgetFormData>();

  const selectedCapabilities = useMemo(
    () => (capabilities || []).filter((capability) => capability.selected),
    [capabilities],
  );

  const chartData = useMemo(() => {
    const deviceAndCapabilityInfos =
      data?.multipleDeviceSensorDataByViews?.[0]?.deviceAndCapabilityInfos;
    if (!deviceAndCapabilityInfos) {
      return [];
    }
    return selectedCapabilities
      .map((capability, index) => {
        const info = deviceAndCapabilityInfos.find(
          (entry) =>
            entry?.deviceModelCapabilityId === capability.capabilityId &&
            entry?.deviceId === capability.deviceId,
        );
        const dataCapability = info?.deviceModelCapability;
        return {
          id: `${capability.deviceId}-${capability.capabilityId}`,
          label: dataCapability?.capability.name ?? `Data ${index}`,
          unit: dataCapability?.unit?.unitSymbol || undefined,
          deviceName: info?.device?.name || '',
          color: capability.color,
          data: (info?.telemetryRecords || []).map((record) => ({
            timestamp: record?.utcTimeMeasured || new Date(),
            value: record?.valueString ? parseFloat(record.valueString) : 0,
          })),
        };
      })
      .filter((item) => !!item)
      .reverse();
  }, [selectedCapabilities, data]);

  const [min, max] = useMemo(
    () =>
      timeInterval !== TimeInterval.customInterval
        ? [getDateFromOfTimeInterval(timeInterval), new Date()]
        : [dateFrom, dateUntil],
    [dateFrom, dateUntil, timeInterval],
  );

  return (
    <WidgetFormChart
      loading={loading}
      capabilities={selectedCapabilities}
      chartType={chartType}
      chartData={chartData}
      collapsed={behaviour === 'combined'}
      min={min}
      max={max}
      comparisonLine={comparisonLine}
      showPoints={showPoints}
    />
  );
};
