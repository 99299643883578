import { useContext, useCallback } from 'react';
import { MetaObject } from '@xeokit/xeokit-sdk';
import { XeoKitContext } from './xeokit-context';

/**
 * Used to manipulate visibility of xeokit objects
 */
export const useXeoKitHighlight = (): {
  setMetaObjectHighlight: (
    metaObject: MetaObject,
    highlighted: boolean,
  ) => void;
} => {
  const { scene } = useContext(XeoKitContext);

  /**
   * Highlight object
   */
  const setMetaObjectHighlight = useCallback(
    (metaObject: MetaObject, highlighted: boolean) => {
      metaObject.withMetaObjectsInSubtree(({ id }) => {
        const object = scene?.objects[id];
        if (object) {
          object.highlighted = highlighted;
        }
      });
    },
    [scene],
  );

  return { setMetaObjectHighlight };
};
