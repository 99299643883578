import { request } from '../utils/request';
import { Response } from '../../hooks/useRequest';
import { url, QanteonEntity } from './common';
import { EntityResponse } from '../common';

export type QanteonProvision = QanteonEntity;

/**
 * Get details of qanteon provision
 */
export const getQanteonProvision = async (): Promise<
  Response<EntityResponse<QanteonProvision>>
> => {
  return request<EntityResponse<QanteonProvision>>('GET', url(`/provision`));
};
