import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  createClasses,
  Dropdown,
  Icons,
  ListItem,
  ToggleButton,
  ToggleButtonGroup,
} from '@kp/react-ui';
import { useDevice } from '../device-context';
import { DeviceChartType } from '../Device.types';

const classes = createClasses({
  chartSelector: {
    width: '160px',
  },
});

export interface DeviceChartToolbarActionsProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

export const DeviceChartToolbarActions: React.FC<
  DeviceChartToolbarActionsProps
> = ({ collapsed, setCollapsed }) => {
  const handleToggleButtonChange = useCallback(
    (value: string) => {
      setCollapsed(value === 'stacked');
    },
    [setCollapsed],
  );
  const { t } = useTranslation();

  const {
    chartType,
    setChartType,
    setSettingsSidebarOpen,
    settingsSidebarOpen,
    setInfoSidebarOpen,
  } = useDevice();

  const handleSettingsSidebarClick = useCallback(() => {
    setSettingsSidebarOpen((value) => !value);
    setInfoSidebarOpen(false);
  }, [setSettingsSidebarOpen, setInfoSidebarOpen]);

  const handleChangeChartType = useCallback(
    (value: string) => {
      switch (value) {
        case DeviceChartType.LINE:
          setChartType(DeviceChartType.LINE);
          break;
        case DeviceChartType.BAR:
          setChartType(DeviceChartType.BAR);
          break;
      }
    },
    [setChartType],
  );

  return (
    <>
      <Dropdown
        value={chartType}
        onChange={handleChangeChartType}
        className={classes.chartSelector}
        data-testid="chart-type-selector"
      >
        <ListItem
          icon={Icons.IconLineChart2}
          value={DeviceChartType.LINE}
          title={t('device.chart.chartType.line') || ''}
        />
        <ListItem
          icon={Icons.IconBarChart2}
          value={DeviceChartType.BAR}
          title={t('device.chart.chartType.bar') || ''}
        />
      </Dropdown>
      <ToggleButtonGroup
        value={collapsed ? 'stacked' : 'combined'}
        onChange={handleToggleButtonChange}
      >
        <ToggleButton
          value="stacked"
          icon={Icons.IconStackedLineChart}
          data-testid="stacked-button"
        />
        <ToggleButton
          value="combined"
          icon={Icons.IconLineChart}
          data-testid="combined-button"
        />
      </ToggleButtonGroup>
      <Button
        variant="secondary"
        data-testid="settings-button"
        selected={settingsSidebarOpen}
        onClick={handleSettingsSidebarClick}
        icon={Icons.IconSettings2}
      />
    </>
  );
};
