import React, { useCallback } from 'react';
import { Button, TableRow, TableCell, createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { Device } from './DashboardSelectCapabilities.types';

const classes = createClasses({
  dropdown: {
    width: '100%',
  },
  button: {
    width: '69px',
  },
  cell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export interface DashboardSelectCapabilitiesDeviceRowProps {
  device: Device;
  selected?: boolean;
  onAdd: (capability: {
    deviceId: string;
    capabilityId: string;
    capabilityName: string;
  }) => void;
}

export const DashboardSelectCapabilitiesDeviceRow: React.FC<
  DashboardSelectCapabilitiesDeviceRowProps
> = ({ device, onAdd, selected }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onAdd({
      deviceId: device.id,
      capabilityId: device.capabilityId,
      capabilityName: device.capabilityName || '',
    });
  }, [device, onAdd]);

  return (
    <TableRow>
      <TableCell className={classes.cell}>{device.name}</TableCell>
      <TableCell className={classes.cell}>{device.serialNo}</TableCell>
      <TableCell className={classes.cell}>{device.type}</TableCell>
      <TableCell className={classes.cell}>{device.storeyName}</TableCell>
      <TableCell className={classes.cell}>{device.description}</TableCell>
      <TableCell className={classes.cell}>{device.capabilityName}</TableCell>
      <TableCell className={classes.cell}>
        <Button
          data-testid={`add-device-${device.id}`}
          variant="tertiary"
          className={classes.button}
          size="default"
          selected={selected}
          onClick={handleClick}
          value={device.id}
        >
          {selected
            ? t('dashboards.modal.capabilities.row.button.added')
            : t('dashboards.modal.capabilities.row.button.add')}
        </Button>
      </TableCell>
    </TableRow>
  );
};
