import { TimeInterval } from '../../../../api/building-insights';

type HeatingTemplatebuilderProps = {
  buildingId: string;
};

export const buildHeatingTemplate = (
  t: Function,
  { buildingId }: HeatingTemplatebuilderProps,
) => {
  return {
    name: '',
    buildingId,
    timeInterval: TimeInterval.lastDay,
    cards: [
      {
        title: t('dashboards.template.heating.titleCardOne'),
        type: 'lastValue',
        x: 0,
        y: 0,
        w: 4,
        h: 2,
      },
      {
        title: t('dashboards.template.heating.titleCardTwo'),
        type: 'lastValue',
        x: 4,
        y: 0,
        w: 4,
        h: 2,
      },
      {
        title: t('dashboards.template.heating.titleCardThree'),
        type: 'chart',
        x: 0,
        y: 2,
        w: 6,
        h: 5,
      },
      {
        title: t('dashboards.template.heating.titleCardFour'),
        type: 'chart',
        x: 6,
        y: 2,
        w: 6,
        h: 5,
      },
    ],
  };
};
