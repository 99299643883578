import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Permissions, useAuth } from '@kp/react-sdk';
import { Formik } from 'formik';
import { format } from 'date-fns';
import {
  getWidget,
  updateWidget,
  deleteWidget,
  aggregationIntervalToGraphQL,
  aggregationIntervalToREST,
  ChartType,
  TimeInterval,
} from '../../../api/building-insights';
import { useNotifications } from '../../../contexts/notifications-context';
import { usePrepareRequest, useRequest } from '../../../hooks/useRequest';
import { WidgetFormData, WidgetFormContainer } from './WidgetFormContainer';
import { useBuilding } from '../../../contexts/building-context';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { DEFAULT_INTERVAL_SIZE } from '../../../constants/Defaults';

export const WidgetEditContainer: React.FC = () => {
  const { t } = useTranslation();
  const { widgetId } = useParams();
  const { id: buildingId, name: buildingName } = useBuilding();
  const { add } = useNotifications();
  const navigate = useNavigate();
  const { response, error, loading } = useRequest(getWidget, widgetId);
  const [update] = usePrepareRequest(updateWidget);
  const [del] = usePrepareRequest(deleteWidget);
  const { hasPermission } = useAuth();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      add({
        type: 'danger',
        id: error.message,
        content: error.message,
      });
    }
  }, [error, loading, add]);

  const widget = useMemo(() => {
    if (!response?.data) {
      return undefined;
    }

    return {
      ...response.data,
      capabilities: response.data.capabilities || [],
    };
  }, [response]);

  const handleCancel = useCallback(() => {
    navigate(`/buildings/${buildingId}/info`, {
      state: { scrollTo: 'widgets' },
    });
  }, [navigate, buildingId]);

  const handleSave = async (data: WidgetFormData) => {
    if (!widget) {
      return;
    }

    const updateResponse = await update(widgetId, {
      ...widget,
      name: data?.name || t('widgets.name.default'),
      collapsed: data?.behaviour === 'combined',
      chartType:
        data?.chartType === ChartType.table ? ChartType.line : data?.chartType,
      timeInterval: data?.timeInterval,
      dateFrom:
        data.dateFrom && format(data.dateFrom, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"),
      dateTo:
        data.dateUntil &&
        format(data.dateUntil, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"),
      aggregationInterval: aggregationIntervalToREST(data?.intervalSize),
      capabilities: (data?.capabilities || []).map((capability) => ({
        deviceId: capability.deviceId,
        capabilityId: capability.capabilityId,
        color: capability.color,
        selected: capability.selected,
      })),
      showPoints: data?.showPoints,
      comparisonLine: data?.comparisonLine,
    });

    if (!updateResponse.error) {
      navigate(`/buildings/${buildingId}/info`, {
        state: { scrollTo: 'widgets' },
      });
    } else {
      add({
        type: 'danger',
        id: updateResponse.error.message,
        content: updateResponse.error.message,
      });
    }
  };

  const handleDelete = useCallback(async () => {
    const deleteResponse = await del(widgetId);

    if (deleteResponse?.error) {
      add({
        type: 'danger',
        id: deleteResponse.error.message,
        content: deleteResponse.error.message,
      });
    } else {
      navigate(`/buildings/${buildingId}/info`, {
        state: { scrollTo: 'widgets' },
      });
    }
  }, [buildingId, widgetId, add, del, navigate]);

  const type = hasPermission(Permissions.WidgetsWrite) ? 'edit' : 'view';

  useBreadcrumb([
    {
      title: buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    {
      title:
        type === 'edit'
          ? t('info.headerNavigation.editWidget')
          : widget?.name || '',
      location: `/buildings/${buildingId}/widgets/${widgetId}`,
      selected: true,
    },
  ]);

  const initialValues: WidgetFormData = useMemo(
    () => ({
      name: widget ? widget.name : '',
      timeInterval: widget ? widget.timeInterval : TimeInterval.lastDay,
      dateFrom: widget?.dateFrom ? new Date(widget.dateFrom) : undefined,
      dateUntil: widget?.dateTo ? new Date(widget.dateTo) : undefined,
      intervalSizeDefault: false,
      chartType:
        (widget?.chartType !== ChartType.table ? widget?.chartType : null) ||
        ChartType.line,
      capabilities: widget?.capabilities,
      behaviour: widget?.collapsed ? 'combined' : 'stacked',
      intervalSize: widget?.aggregationInterval
        ? aggregationIntervalToGraphQL(widget.aggregationInterval)
        : DEFAULT_INTERVAL_SIZE,
      showPoints: widget?.showPoints,
      comparisonLine: widget?.comparisonLine,
    }),
    [widget],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
    >
      <WidgetFormContainer
        loading={loading}
        type={type}
        onCancel={type === 'edit' ? handleCancel : undefined}
        onDelete={type === 'edit' ? handleDelete : undefined}
      />
    </Formik>
  );
};
