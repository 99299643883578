import React, {
  createContext,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import noop from '@stdlib/utils-noop';
import {
  createDashboard,
  CreateDashboardData,
} from '../../api/building-insights';
import { usePrepareRequest } from '../../hooks/useRequest';
import { useNotifications } from '../../contexts/notifications-context';
import { useBuilding } from '../../contexts/building-context';

export enum DashboardRightBarTabTypes {
  newCard = 'newCard',
  settings = 'settings',
}

type DashboardContextValue = {
  refresh: () => void;
  loading: boolean;
  createLoading: boolean;
  leftSidebarOpen: boolean;
  setLeftSidebarOpen: React.Dispatch<SetStateAction<boolean>>;
  rightSidebarOpen: boolean;
  setRightSidebarOpen: React.Dispatch<SetStateAction<boolean>>;
  templatesOpen: boolean;
  setTemplatesOpen: React.Dispatch<SetStateAction<boolean>>;
  create: (data: CreateDashboardData) => void;
  selectedRightSidebarTab?: DashboardRightBarTabTypes;
  setSelectedRightSidebarTab: React.Dispatch<
    SetStateAction<DashboardRightBarTabTypes | undefined>
  >;
};

const DashboardContext = createContext<DashboardContextValue>({
  refresh: noop,
  loading: false,
  createLoading: false,
  leftSidebarOpen: false,
  setLeftSidebarOpen: noop,
  rightSidebarOpen: false,
  setRightSidebarOpen: noop,
  templatesOpen: false,
  setTemplatesOpen: noop,
  create: noop,
  setSelectedRightSidebarTab: noop,
});

export const useDashboard = (): DashboardContextValue =>
  useContext(DashboardContext);

type DashboardProviderProps = {
  loading: boolean;
  refresh: () => void;
  children?: React.ReactNode;
};

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
  refresh,
  loading,
}) => {
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [templatesOpen, setTemplatesOpen] = useState(false);
  const [selectedRightSidebarTab, setSelectedRightSidebarTab] =
    useState<DashboardRightBarTabTypes>();

  const { add } = useNotifications();
  const navigate = useNavigate();
  const { id: buildingId } = useBuilding();

  // Create Dashboard
  const [createRequest, { loading: createLoading }] = usePrepareRequest(
    createDashboard,
    {
      onError: (error) =>
        add({
          type: 'danger',
          id: error.message,
          content: error.message,
        }),
    },
  );
  const create = useCallback(
    (data: CreateDashboardData) => {
      createRequest(data)
        .then(({ response }) => {
          refresh();
          setLeftSidebarOpen(false);
          setRightSidebarOpen(false);
          setTemplatesOpen(false);
          setSelectedRightSidebarTab(undefined);
          return navigate(
            `/buildings/${buildingId}/dashboards/${response?.data.id}`,
          );
        })
        .catch(noop);
    },
    [buildingId, createRequest, navigate, refresh],
  );

  const value = useMemo(
    () => ({
      refresh,
      loading,
      createLoading,
      leftSidebarOpen,
      setLeftSidebarOpen,
      rightSidebarOpen,
      setRightSidebarOpen,
      templatesOpen,
      setTemplatesOpen,
      create,
      setSelectedRightSidebarTab,
      selectedRightSidebarTab,
    }),
    [
      refresh,
      loading,
      createLoading,
      leftSidebarOpen,
      setLeftSidebarOpen,
      rightSidebarOpen,
      setRightSidebarOpen,
      templatesOpen,
      setTemplatesOpen,
      create,
      setSelectedRightSidebarTab,
      selectedRightSidebarTab,
    ],
  );

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
