import React from 'react';
import {
  classNames,
  Colors,
  createClasses,
  TimeScaleDataset,
} from '@kp/react-ui';
import Lottie, { LottieProps } from 'react-lottie-player';
import chartLoadingAnimationData from '../../../animations/chart-loading.json';
import { DeviceChartType } from '../Device.types';
import { Chart } from '../../../components/Chart';

const animationOptions: LottieProps = {
  loop: true,
  play: true,
  animationData: chartLoadingAnimationData,
  style: { width: 64, height: 64 },
};

const classes = createClasses({
  root: {
    display: 'flex',
    width: '100%',
  },
  chartContainer: {
    width: '100%',
    padding: '20px',
    overflow: 'auto',
    background: Colors.Neutral.backgroundMedium,
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

export interface DeviceChartChartProps {
  loading?: boolean;
  chartData: TimeScaleDataset[];
  collapsed: boolean;
  dateFrom: Date;
  dateUntil: Date;
  type: DeviceChartType;
  className?: string;
  showPoints?: boolean;
  comparisonLine?: boolean;
}

export const DeviceChartChart: React.FC<DeviceChartChartProps> = ({
  loading,
  chartData,
  collapsed,
  dateFrom,
  dateUntil,
  className,
  type,
  showPoints,
  comparisonLine,
}) => (
  <div className={classNames(classes.root, className)}>
    {loading ? (
      <div className={classes.loader}>
        <Lottie {...animationOptions} />
      </div>
    ) : (
      <div className={classes.chartContainer}>
        <Chart
          datasets={chartData}
          collapsed={collapsed}
          size="large"
          min={dateFrom}
          max={dateUntil}
          type={type === DeviceChartType.LINE ? 'line' : 'bar'}
          showIntervalPoint={showPoints}
          showCrosshair={comparisonLine}
          cursorStyle={comparisonLine ? 'crosshair' : 'default'}
        />
      </div>
    )}
  </div>
);
