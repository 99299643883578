import React from 'react';
import { createClasses, classNames, Colors, Typography } from '@kp/react-ui';

const classes = createClasses({
  root: {
    textAlign: 'left',
    padding: '6px 14px',
    color: Colors.Neutral.text,
    background: Colors.Neutral.backgroundStrong,
    borderTop: `1px solid ${Colors.Neutral.borderMedium}`,
    borderLeft: `1px solid ${Colors.Neutral.borderMedium}`,
    borderBottom: `1px solid ${Colors.Neutral.borderMedium}`,
    '&:first-child': {
      borderTopLeftRadius: '6px',
    },
    '&:last-child': {
      borderRight: `1px solid ${Colors.Neutral.borderMedium}`,
      borderTopRightRadius: '6px',
    },
  },
});

interface WidgetTableHeadCellProps extends React.ComponentProps<'th'> {
  className?: string;
}

export const WidgetTableHeadCell: React.FC<WidgetTableHeadCellProps> = ({
  className,
  ...props
}) => (
  <Typography
    variant="labelMedium"
    component="th"
    className={classNames(classes.root, className)}
    {...props}
  />
);
