import React, { DragEvent } from 'react';
import { Colors, createClasses, Icon, Typography } from '@kp/react-ui';
import { icon as IconType } from '@kp/react-ui/dist/cjs/__generated__/icons';
import { DashboardCardType } from '../../../../../api/building-insights';

const classes = createClasses({
  root: {},
  detail: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px 0px',
    gap: '4px',
  },
  title: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  icon: {
    color: Colors.Neutral.icon,
  },
  image: {
    width: '100%',
    cursor: 'grab',
    webkitUserDrag: 'element',
  },
  description: {
    color: Colors.Neutral.textWeak,
  },
  deleteButton: { paddingLeft: 0 },
});

interface DashboardRightSidebarNewCardItemProps {
  image: string;
  title: string;
  icon: { name: IconType; data: string };
  description: string;
  type: DashboardCardType;
}

export const DashboardRightSidebarNewCardItem: React.FC<
  DashboardRightSidebarNewCardItemProps
> = ({ image, title, icon, description, type }) => {
  // This prevention blocks title and description selection in second dragging.
  // Safari Bug noticed here:
  // https://bugs.webkit.org/show_bug.cgi?id=237446
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragStart = (e: DragEvent<HTMLImageElement>) => {
    // The `dataTransfer` data value can't be read while being dragged ( only onDrop event ),
    // however all data type keys can be read, so in our case we abuse them to specify the
    // correct card type in order for the grid to know the size of the placeholder item
    // to render.
    // https://stackoverflow.com/questions/40940288/drag-datatransfer-data-unavailable-in-ondragover-event
    e.dataTransfer.setData(type, '');
  };

  return (
    <div className={classes.root} onMouseMove={handleMouseMove}>
      <img
        draggable
        onDragStart={handleDragStart}
        src={image}
        className={classes.image}
        alt={title}
      />
      <div className={classes.detail}>
        <div className={classes.title}>
          <Icon className={classes.icon} icon={icon} size={20} />
          <Typography variant="labelMedium" component="div">
            {title}
          </Typography>
        </div>
        <Typography
          className={classes.description}
          variant="bodySmall"
          component="div"
        >
          {description}
        </Typography>
      </div>
    </div>
  );
};
