import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, Typography, classNames, createClasses } from '@kp/react-ui';

const classes = createClasses({
  root: {
    borderRadius: '6px',
    height: '288px',
    minWidth: '100px',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${Colors.Neutral.border}`,
    backgroundColor: Colors.Neutral.backgroundStrong,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    marginTop: '36px',
    color: Colors.Neutral.textWeak,
    textAlign: 'center',
    width: '200px',
  },
  img: {
    width: '64px',
    height: '64px',
  },
});

interface InfoWidgetEmptyProps extends React.ComponentProps<'div'> {}

export const InfoWidgetEmptyCard: React.FC<InfoWidgetEmptyProps> = ({
  className,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.root, className)} {...props}>
      <img
        src="/assets/chart-big.svg"
        alt="chart-big"
        className={classes.img}
      />
      <Typography variant="labelMedium" className={classes.text}>
        {t('info.monitoring.createWidgetInfo')}
      </Typography>
    </div>
  );
};
