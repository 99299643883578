import React from 'react';
import { Colors, createClasses, Typography } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DashboardEntity } from '../../../api/building-insights';
import { DashboardsLayout } from '../DashboardsLayout';
import { useBuilding } from '../../../contexts/building-context';
import emptyStateUrl from '../../../images/empty-state.svg';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useDashboard } from '../dashboard-context';

const classes = createClasses({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    color: Colors.Neutral.textWeak,
    height: '100%',
  },
  image: {
    marginBottom: '12px',
    width: '48px',
    height: '48px',
  },
});

interface DashboardsEmptyProps {
  dashboards: DashboardEntity[];
}

export const DashboardsEmpty: React.FC<DashboardsEmptyProps> = ({
  dashboards,
}) => {
  const { t } = useTranslation();
  const { id: buildingId, name: buildingName } = useBuilding();
  const navigate = useNavigate();
  const { loading } = useDashboard();

  useBreadcrumb([
    {
      title: buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    {
      title: t('page.dashboards'),
      location: '',
      selected: true,
    },
  ]);

  if (!loading && dashboards[0]) {
    navigate(`/buildings/${buildingId}/dashboards/${dashboards[0].id}`);
  }

  return (
    <DashboardsLayout
      dashboards={dashboards}
      loading={loading}
      title={
        <Typography variant="titleLarge">
          {t('dashboards.title.dashboard')}
        </Typography>
      }
    >
      <div className={classes.root}>
        <img
          alt="no-capabilities"
          src={emptyStateUrl}
          className={classes.image}
        />
        <Typography variant="titleTiny">
          {t('dashboards.empty.title')}
        </Typography>
      </div>
    </DashboardsLayout>
  );
};
