import React, { useMemo } from 'react';
import {
  AggregationInterval,
  Capability,
  ChartType,
  TimeInterval,
  aggregationIntervalToGraphQL,
  timeIntervalToGraphQL,
  getDateFromOfTimeInterval,
  reorderWidget,
} from '../../../../api/building-insights';
import { useNotifications } from '../../../../contexts/notifications-context';
import { useWidgetCardDataQuery } from '../../../../__generated__/types';
import { InfoWidgetCard } from './InfoWidgetCard';
import { InfoWidgetCardChart } from './InfoWidgetCardChart';

interface InfoWidgetCardContainerProps {
  id: string;
  name: string;
  timeInterval: TimeInterval;
  dateFrom?: string;
  dateTo?: string;
  intervalSize: AggregationInterval;
  collapsed: boolean;
  chartType: ChartType;
  createdAt: string;
  capabilities?: Capability[];
  index: number;
  isFavourite: boolean;
  favouriteSave: (id: string | undefined, isFav: boolean | undefined) => void;
  moveWidget?: (dragIndex: number, hoverIndex: number) => void;
}

export const InfoWidgetCardContainer: React.FC<
  InfoWidgetCardContainerProps
> = ({
  id,
  name,
  timeInterval = TimeInterval.customInterval,
  dateFrom = null,
  dateTo = null,
  intervalSize,
  collapsed,
  chartType,
  createdAt,
  capabilities,
  index,
  moveWidget,
  isFavourite,
  favouriteSave,
}) => {
  const { add } = useNotifications();

  const [selectedCapabilities, deviceIds] = useMemo(
    () => [
      (capabilities || []).filter((capability) => capability.selected),
      (capabilities || [])
        .map(({ deviceId }) => deviceId)
        .filter((deviceId, i, array) => array.indexOf(deviceId) === i),
    ],
    [capabilities],
  );

  const { data, loading } = useWidgetCardDataQuery({
    variables: {
      devices: deviceIds.map((deviceId) => ({
        deviceId,
        deviceModelCapabilityIds: selectedCapabilities
          .filter((capability) => capability.deviceId === deviceId)
          .map(({ capabilityId }) => ({
            deviceModelCapabilityId: capabilityId,
          })),
      })),
      timeSpan: timeIntervalToGraphQL(timeInterval),
      readFrom: dateFrom,
      readUntil: dateTo,
      readUntilNow: dateTo === null,
      intervalSize: aggregationIntervalToGraphQL(intervalSize),
    },
    skip: selectedCapabilities.length === 0,
    onError: (err) =>
      add({ type: 'danger', id: err.message, content: err.message }),
  });

  const chartData = useMemo(() => {
    const deviceAndCapabilityInfos =
      data?.multipleDeviceSensorDataByViews?.[0]?.deviceAndCapabilityInfos;
    if (!deviceAndCapabilityInfos) {
      return [];
    }
    return selectedCapabilities
      .map((capability, i) => {
        const info = deviceAndCapabilityInfos.find(
          (entry) =>
            entry?.deviceModelCapabilityId === capability.capabilityId &&
            entry?.deviceId === capability.deviceId,
        );
        const dataCapability = info?.deviceModelCapability;
        return {
          id: `${capability.deviceId}-${capability.capabilityId}`,
          label: dataCapability?.capability.name ?? `Data ${i}`,
          unit: dataCapability?.unit?.unitSymbol || undefined,
          deviceName: info?.device?.name || '',
          color: capability.color,
          data: (info?.telemetryRecords || []).map((record) => ({
            timestamp: record?.utcTimeMeasured || new Date(),
            value: record?.valueString ? parseFloat(record.valueString) : 0,
          })),
        };
      })
      .reverse();
  }, [selectedCapabilities, data]);

  const [min, max] = useMemo(() => {
    if (timeInterval !== TimeInterval.customInterval) {
      return [getDateFromOfTimeInterval(timeInterval), new Date()];
    }
    return [
      dateFrom ? new Date(dateFrom) : undefined,
      dateTo ? new Date(dateTo) : undefined,
    ];
  }, [dateFrom, dateTo, timeInterval]);

  return (
    <InfoWidgetCard
      id={id}
      loading={loading}
      name={name}
      timeInterval={timeInterval}
      createdAt={createdAt}
      index={index}
      moveWidget={moveWidget}
      reorderWidget={reorderWidget}
      isFavourite={isFavourite}
      favouriteSave={favouriteSave}
    >
      <InfoWidgetCardChart
        loading={loading}
        min={min}
        max={max}
        chartType={chartType}
        collapsed={collapsed}
        chartData={chartData}
      />
    </InfoWidgetCard>
  );
};
