import { useContext, useCallback } from 'react';
import { MetaObject } from '@xeokit/xeokit-sdk';
import { XeoKitContext } from './xeokit-context';

/**
 * Used to manipulate visibility of xeokit objects
 */
export const useXeoKitVisibility = (): {
  visibleMetaObjectIds: string[];
  setMetaObjectVisibility: (metaObject: MetaObject, visible: boolean) => void;
} => {
  const { scene, visibleMetaObjectIds } = useContext(XeoKitContext);

  /**
   * Show object with children
   */
  const setMetaObjectVisibility = useCallback(
    (metaObject: MetaObject, visible: boolean) => {
      metaObject.withMetaObjectsInSubtree(({ id }) => {
        const object = scene?.objects[id];
        if (object) {
          object.visible = visible;
        }
      });
    },
    [scene],
  );

  return {
    visibleMetaObjectIds,
    setMetaObjectVisibility,
  };
};
