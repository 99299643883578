import React, { useContext, createContext, useMemo, useCallback } from 'react';
import { useAuth } from '@kp/react-sdk';
import noop from '@stdlib/utils-noop';
import { usePlatformContextQuery } from '../__generated__/types';
import { useNotifications } from './notifications-context';
import { usePrepareRequest, useRequest } from '../hooks/useRequest';
import {
  getBuildingsFavourite,
  postBuildingsFavourite,
  deleteBuildingsFavourite,
} from '../api/building-insights';

interface DeviceType {
  id: string;
  name: string;
}

interface PlatformContextValue {
  loading: boolean;
  initialized: boolean;
  deviceTypes: DeviceType[];
  addFavouriteBuildingId: (buildingId: string) => Promise<void>;
  removeFavouriteBuildingId: (buildingId: string) => Promise<void>;
  favouriteBuildingIds: string[];
  isFavouriteBuildingId: (buildingId: string) => boolean | void;
}

const PlatformContext = createContext<PlatformContextValue>({
  loading: false,
  initialized: false,
  deviceTypes: [],
  addFavouriteBuildingId: () => Promise.resolve(),
  removeFavouriteBuildingId: () => Promise.resolve(),
  favouriteBuildingIds: [],
  isFavouriteBuildingId: noop,
});

export const usePlatform = (): PlatformContextValue =>
  useContext(PlatformContext);

interface PlatformProviderProps {
  children?: React.ReactNode;
}

export const PlatformProvider: React.FC<PlatformProviderProps> = ({
  children,
}) => {
  const { add } = useNotifications();
  const { isAuthenticated } = useAuth();

  const { data, loading } = usePlatformContextQuery({
    skip: !isAuthenticated,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
    errorPolicy: 'all',
  });

  const deviceTypes = useMemo(() => data?.deviceTypes?.items || [], [data]);

  const { response, refresh } = useRequest(getBuildingsFavourite);

  const [addFavouriteBuildingIdRequest] = usePrepareRequest(
    postBuildingsFavourite,
    {
      onError: (err) =>
        add({
          type: 'danger',
          id: err.message,
          content: err.message,
        }),
    },
  );

  const [removeFavouriteBuildingIdRequest] = usePrepareRequest(
    deleteBuildingsFavourite,
    {
      onError: (err) =>
        add({
          type: 'danger',
          id: err.message,
          content: err.message,
        }),
    },
  );

  const favouriteBuildingIds = useMemo(
    () => response?.data?.map((building) => building.buildingId) || [],
    [response],
  );

  const isFavouriteBuildingId = useCallback(
    (buildingId: string) => favouriteBuildingIds.includes(buildingId),
    [favouriteBuildingIds],
  );

  const addFavouriteBuildingId = useCallback(
    (buildingId: string) =>
      addFavouriteBuildingIdRequest(buildingId).then(() => refresh()),
    [addFavouriteBuildingIdRequest, refresh],
  );

  const removeFavouriteBuildingId = useCallback(
    (buildingId: string) =>
      removeFavouriteBuildingIdRequest(buildingId).then(() => refresh()),
    [removeFavouriteBuildingIdRequest, refresh],
  );

  const value = useMemo(
    () => ({
      loading,
      initialized: Boolean(!loading && data),
      deviceTypes,
      addFavouriteBuildingId,
      removeFavouriteBuildingId,
      favouriteBuildingIds,
      isFavouriteBuildingId,
    }),
    [
      loading,
      data,
      deviceTypes,
      addFavouriteBuildingId,
      removeFavouriteBuildingId,
      favouriteBuildingIds,
      isFavouriteBuildingId,
    ],
  );

  return (
    <PlatformContext.Provider value={value}>
      {children}
    </PlatformContext.Provider>
  );
};
