import { Button, createClasses, Icons } from '@kp/react-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ImageCardEmptyProps {
  onAddDataPointClick?: () => void;
}

const classes = createClasses({
  root: {
    width: '100%',
  },
  editButton: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export const ImageCardEmpty: React.FC<ImageCardEmptyProps> = ({
  onAddDataPointClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Button
        className={classes.editButton}
        variant="tertiary"
        icon={Icons.IconPlantImage}
        onClick={onAddDataPointClick}
        disabled={!onAddDataPointClick}
      >
        {t('dashboards.card.editWidget')}
      </Button>
    </div>
  );
};
