export enum DashboardSelectCapabilitiesFilterCategory {
  DeviceType = 'deviceType',
  Floor = 'floor',
  Zone = 'zone',
}

export interface DashboardSelectCapabilitiesFilter {
  id: string;
  categoryId: DashboardSelectCapabilitiesFilterCategory;
  label: string;
  selected?: boolean;
}

export interface Device {
  id: string;
  serialNo?: string;
  name?: string;
  type?: string;
  storeyName?: string;
  description?: string;
  capabilityId: string;
  capabilityName?: string;
}
