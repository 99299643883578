import React from 'react';
import {
  Colors,
  DescriptionList,
  Typography,
  FormControl,
  createClasses,
  classNames,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { DEVICE_RIGHT_SIDEBAR_WIDTH } from '../../../constants/UI';
import { Device, DeviceLocation } from '../Device.types';
import { useDevice } from '../device-context';

const classes = createClasses({
  root: {
    position: 'absolute',
    borderLeft: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.background,
    right: 0,
    transform: `translateX(${DEVICE_RIGHT_SIDEBAR_WIDTH}px)`,
    transition: 'transform 0.2s',
    width: DEVICE_RIGHT_SIDEBAR_WIDTH,
    bottom: 0,
    height: '100%',
    zIndex: 40,
    padding: '12px 24px',
  },
  open: {
    transform: 'translateX(0)',
  },
  title: {
    margin: '16px 0 4px 0',
    '&:first-child': {
      margin: '4px 0',
    },
  },
});

export interface DeviceInfoSidebarProps {
  device?: Device;
  location?: DeviceLocation;
  loading?: boolean;
}

export const DeviceInfoSidebar: React.FC<DeviceInfoSidebarProps> = ({
  device,
  location,
}) => {
  const { t } = useTranslation();
  const { infoSidebarOpen } = useDevice();

  return (
    <div
      className={classNames(classes.root, infoSidebarOpen && classes.open)}
      data-testid="info-sidebar"
      data-open={infoSidebarOpen}
    >
      <Typography
        variant="labelLarge"
        className={classes.title}
        component="div"
      >
        {t('device.info.sidebar.details')}
      </Typography>
      {device && (
        <>
          <FormControl>
            <DescriptionList
              term={t('device.info.sidebar.deviceType')}
              description={device.deviceTypeName}
            />
          </FormControl>
          <FormControl>
            <DescriptionList
              term={t('device.info.sidebar.deviceModel')}
              description={device.deviceModelName}
            />
          </FormControl>
          <FormControl>
            <DescriptionList
              term={t('device.info.sidebar.gateway')}
              description={device.gatewayName}
            />
          </FormControl>
          <FormControl>
            <DescriptionList
              term={t('device.info.sidebar.deviceIdentifier')}
              description={device.identifier}
            />
          </FormControl>
        </>
      )}
      <Typography
        variant="labelLarge"
        className={classes.title}
        component="div"
      >
        {t('device.info.sidebar.location')}
      </Typography>
      {location && (
        <>
          <FormControl>
            <DescriptionList
              term={t('device.info.sidebar.site')}
              description={location.siteName}
            />
          </FormControl>
          <FormControl>
            <DescriptionList
              term={t('device.info.sidebar.building')}
              description={location.buildingName}
            />
          </FormControl>
          {location.storeyName && (
            <FormControl>
              <DescriptionList
                term={t('device.info.sidebar.storey')}
                description={location.storeyName}
              />
            </FormControl>
          )}
          {location.zoneName && (
            <FormControl>
              <DescriptionList
                term={t('device.info.sidebar.zone')}
                description={location.zoneName}
              />
            </FormControl>
          )}
        </>
      )}
    </div>
  );
};
