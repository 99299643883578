import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  ListItem,
  Icons,
  createClasses,
  classNames,
} from '@kp/react-ui';
import { LightMapping } from '../../../constants/FeatureFlags';
import { useXeoKitLights } from '../../../contexts/xeokit/useXeoKitLights';

import ukraine from './light-presets/ukraine.json';
import runningLights from './light-presets/running-lights.json';
import pacman from './light-presets/pac-man.json';

const classes = createClasses({
  root: {
    display: 'flex',
  },
  control: {
    '&:not(:first-child)': {
      marginLeft: '12px',
    },
  },
  dropdown: {
    minWidth: '150px',
  },
  content: {
    padding: '4px 0',
    left: 'auto',
  },
  buttonClassName: {
    width: '100%',
  },
});

interface InteractiveLightsProps {
  className?: string;
  lights: LightMapping[];
}

const themes = [
  { id: 'ukraine', label: 'Ukraine', theme: ukraine },
  { id: 'running-lights', label: 'Running Lights', theme: runningLights },
  { id: 'pac-man', label: 'Pac Man', theme: pacman },
];

export const InteractiveLights: React.FC<InteractiveLightsProps> = React.memo(
  ({ className, lights }) => {
    const [themeId, setThemeId] = useState<string>(themes[0].id);
    const { start, setTheme, running, stop } = useXeoKitLights(lights);

    useEffect(() => {
      const theme = themes.find(({ id }) => id === themeId);
      if (theme?.theme) {
        setTheme(theme.theme);
      }
    }, [setTheme, themeId]);

    return (
      <div className={classNames(classes.root, className)}>
        <div className={classes.control}>
          <Dropdown
            value={themeId}
            onChange={setThemeId}
            className={classes.dropdown}
          >
            {themes.map((theme) => (
              <ListItem key={theme.id} title={theme.label} value={theme.id} />
            ))}
          </Dropdown>
        </div>
        <Button
          onClick={running ? stop : start}
          className={classes.control}
          variant="secondary"
          icon={Icons.IconWeatherSun}
          selected={running}
        />
      </div>
    );
  },
);

InteractiveLights.displayName = 'InteractiveLights';
