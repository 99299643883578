import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownButton, createClasses } from '@kp/react-ui';
import { animations } from '../../../utils/css';
import {
  InteractiveHeaderStoreySelectStorey,
  Storey,
} from './InteractiveHeaderStoreySelectStorey';
import { Color } from '../../../constants/Colors';

const classes = createClasses({
  content: {
    padding: '4px 0',
    left: 'auto',
  },
  buttonClassName: {
    width: '100%',
  },
  skeleton: {
    height: '32px',
    width: '100px',
    borderRadius: 4,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `
      linear-gradient(
        90deg, 
        rgba(0, 0, 0, 0) 0, 
        rgba(0, 0, 0, .04) 50%, 
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(${Color.grey4} 100%, transparent 0);
    `,
    backgroundSize: `
      80% 32px,
      100% 100%;
    `,
    backgroundPosition: `
      -550% 0,
      0 0;
    `,
    animation: `${animations.slideRight} 1.5s infinite`,
  },
});

interface InteractiveHeaderStoreySelectProps {
  className?: string;
  storeys: Storey[];
  onSelect?: (id: string) => void;
  onCapture?: (id: string) => void;
  loading?: boolean;
}

export const InteractiveHeaderStoreySelect: React.FC<
  InteractiveHeaderStoreySelectProps
> = ({ className, loading, storeys, onSelect, onCapture }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {loading ? (
        <div className={classes.skeleton} />
      ) : (
        <DropdownButton
          label={t('interactive.floors')}
          className={className}
          popupOverflow
          popupClassName={classes.content}
        >
          {storeys.map(({ id, label, selected }) => (
            <InteractiveHeaderStoreySelectStorey
              key={id}
              id={id}
              label={label}
              selected={selected}
              onSelect={onSelect}
              onCapture={onCapture}
            />
          ))}
        </DropdownButton>
      )}
    </div>
  );
};
