import { useEffect, useState } from 'react';
import { useAuth, Permissions } from '@kp/react-sdk';
import { usePrepareRequest } from './useRequest';
import { getQanteonProvision } from '../api/qanteon';
import { ErrorCodes, Status } from '../api/qanteon/common';
import { useNotifications } from '../contexts/notifications-context';

export const useHasQanteon = (): boolean => {
  const { hasPermission } = useAuth();
  const { add } = useNotifications();
  const [hasQanteon, setHasQanteon] = useState(false);

  const [
    getProvision,
    { response: responseQanteon, error: errorQanteon, loading: loadingQanteon },
  ] = usePrepareRequest(getQanteonProvision);

  useEffect(() => {
    if (
      hasPermission(Permissions.QanteonProvisioningRead) &&
      !responseQanteon &&
      !loadingQanteon &&
      !errorQanteon
    ) {
      getProvision();
    }
  }, [
    hasPermission,
    getProvision,
    responseQanteon,
    loadingQanteon,
    errorQanteon,
  ]);

  useEffect(() => {
    if (loadingQanteon) {
      return;
    }
    const noProvision =
      errorQanteon?.responseCode === ErrorCodes.NO_PROVISION_FOUND;
    if (errorQanteon && !noProvision) {
      add({
        type: 'danger',
        id: errorQanteon.message,
        content: errorQanteon.message,
      });
    } else if (responseQanteon?.data?.status === Status.available) {
      setHasQanteon(true);
    }
  }, [errorQanteon, responseQanteon, loadingQanteon, add]);

  return hasQanteon;
};
