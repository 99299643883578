import React from 'react';
import { Colors, createClasses, Typography } from '@kp/react-ui';

const classes = createClasses({
  chartLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    padding: '12px 0px 16px 0px',
  },
  chartLabel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
  },
  chartLabelText: {
    color: Colors.Neutral.textWeak,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  colorLine: {
    width: '11px',
    borderRadius: '4px',
    margin: '6px 0',
  },
});

export type ChartLabel = {
  id: string;
  title: string;
  color: string;
};

export interface ChartCardLabelsProps {
  labels: ChartLabel[];
}

export const ChartCardLabels: React.FC<ChartCardLabelsProps> = ({ labels }) => {
  return (
    <div className={classes.chartLabelContainer}>
      {labels.map((label) => (
        <div className={classes.chartLabel} key={`${label.id}`}>
          <div
            className={classes.colorLine}
            style={{ backgroundColor: label.color }}
          />
          <Typography
            className={classes.chartLabelText}
            variant="bodySmall"
            component="div"
          >
            {label.title}
          </Typography>
        </div>
      ))}
    </div>
  );
};
