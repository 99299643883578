import React, { useMemo } from 'react';
import { useInViewRef } from 'rooks';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableRow,
  TableCell,
  SearchField,
  TableHeadCellSortable,
  classNames,
  createClasses,
} from '@kp/react-ui';
import { Device, FormCapabilityIdentifier } from '../widgetFormDevicesTypes';
import { WidgetFormDevicesSearchDeviceRow } from './WidgetFormDevicesSearchDeviceRow';
import {
  WidgetFormDevicesSearchFilter,
  WidgetFormDevicesSearchFilterCategory,
} from './WidgetFormDevicesSearch.types';
import { TableRowNoResults } from '../../../../components/TableRowNoResults';

const classes = createClasses({
  root: {
    height: '100%',
  },
  input: {
    zIndex: 200,
    padding: '8px 24px 1px 24px',
  },
  table: {
    flexGrow: 1,
    overflow: 'scroll',
    position: 'relative',
    height: 'calc(100% - 41px)',
    '& table thead th': {
      zIndex: 101,
    },
  },
  emptyTable: {
    // Increase CSS specificity
    '$table &': {
      position: 'static',
    },
  },
  actions: {
    width: '68px',
  },
  capabilities: {
    width: '20%',
  },
  scrollDetector: {
    height: '40px',
  },
});

export interface WidgetFormDevicesSearchProps {
  query: string;
  onChangeQuery: (query: string) => void;
  onChangeSort: (direction: 'ASC' | 'DESC', field: string) => void;
  sortField: string | null;
  sortDirection: 'ASC' | 'DESC' | null;
  onAdd: (capability: FormCapabilityIdentifier) => void;
  loading?: boolean;
  devices: Device[];
  filters: WidgetFormDevicesSearchFilter[];
  onScrollDown?: () => void;
  loadingMore?: boolean;
  onAddFilter: (filterId: string) => void;
  onRemoveFilter: (filterId: string) => void;
  onResetFilters: () => void;
}

export const WidgetFormDevicesSearch: React.FC<
  WidgetFormDevicesSearchProps
> = ({
  query,
  onChangeQuery,
  onChangeSort,
  sortField,
  sortDirection,
  filters,
  onAdd,
  onScrollDown,
  onResetFilters,
  onAddFilter,
  onRemoveFilter,
  loading,
  loadingMore,
  devices,
}) => {
  const { t } = useTranslation();
  const [scrollDetectorRef] = useInViewRef((entries) => {
    if (onScrollDown && entries[0].isIntersecting) {
      onScrollDown();
    }
  });
  const hasEmptyResults = !loading && devices.length === 0;

  const categories = useMemo(
    () => [
      {
        id: WidgetFormDevicesSearchFilterCategory.DeviceType,
        label: t('widgets.filters.tab.deviceType'),
      },
      {
        id: WidgetFormDevicesSearchFilterCategory.Floor,
        label: t('widgets.filters.tab.floor'),
      },
      {
        id: WidgetFormDevicesSearchFilterCategory.Zone,
        label: t('widgets.filters.tab.zone'),
      },
    ],
    [t],
  );

  return (
    <div className={classes.root}>
      <div className={classes.input}>
        <SearchField
          fullWidth
          data-testid="device-search-input"
          onResetFilters={onResetFilters}
          categories={categories}
          onChange={onChangeQuery}
          onAddFilter={onAddFilter}
          onRemoveFilter={onRemoveFilter}
          filterButtonLabel={t('widgets.filters.button.filter') || ''}
          filters={filters}
          value={query}
        />
      </div>
      <div className={classes.table} data-testid="device-search">
        <Table
          scrollable
          className={classNames(hasEmptyResults && classes.emptyTable)}
        >
          <TableHead>
            <TableRow>
              <TableHeadCellSortable
                onClick={onChangeSort}
                sort={sortField === 'deviceType' ? sortDirection : null}
                value="deviceType"
              >
                {t('widgets.table.columns.deviceType')}
              </TableHeadCellSortable>
              <TableHeadCellSortable
                onClick={onChangeSort}
                sort={sortField === 'deviceName' ? sortDirection : null}
                value="deviceName"
              >
                {t('widgets.table.columns.deviceName')}
              </TableHeadCellSortable>
              <TableHeadCellSortable
                onClick={onChangeSort}
                sort={sortField === 'serialNumber' ? sortDirection : null}
                value="serialNumber"
              >
                {t('widgets.table.columns.serialNumber')}
              </TableHeadCellSortable>
              <TableHeadCellSortable
                onClick={onChangeSort}
                sort={sortField === 'storey' ? sortDirection : null}
                value="storey"
              >
                {t('widgets.table.columns.storey')}
              </TableHeadCellSortable>
              <TableHeadCellSortable
                onClick={onChangeSort}
                sort={sortField === 'description' ? sortDirection : null}
                value="description"
              >
                {t('widgets.table.columns.description')}
              </TableHeadCellSortable>
              <TableHeadCell
                className={classes.capabilities}
                onClick={onScrollDown}
              >
                {t('widgets.table.columns.capabilities')}
              </TableHeadCell>
              <TableHeadCell className={classes.actions} />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <>
                <TableRow loading>
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                </TableRow>
                <TableRow loading>
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                </TableRow>
                <TableRow loading>
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                </TableRow>
              </>
            )}
            {hasEmptyResults && (
              <TableRowNoResults label={t('widgets.table.empty.text')} />
            )}
            {!loading &&
              devices.length > 0 &&
              devices.map((device) => (
                <WidgetFormDevicesSearchDeviceRow
                  key={device.id}
                  device={device}
                  onAdd={onAdd}
                />
              ))}
            {loadingMore && (
              <TableRow loading>
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
              </TableRow>
            )}
            {onScrollDown && (
              <TableRow>
                <TableCell>
                  <div
                    className={classes.scrollDetector}
                    ref={scrollDetectorRef}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
