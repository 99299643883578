import * as React from 'react';
import { useMemo } from 'react';
import { useNotifications } from '../../../../contexts/notifications-context';
import { usePointPreviewDeviceQuery } from '../../../../__generated__/types';
import {
  PreviewDevice,
  PreviewDeviceCapability,
  PreviewDeviceLocation,
} from './PreviewDevice.types';
import { PointPreview } from './PointPreview';

interface PointPreviewContainerProps {
  deviceId: string;
  capabilityId: string;
}

export const PointPreviewContainer: React.FC<PointPreviewContainerProps> = ({
  deviceId,
  capabilityId,
}) => {
  const { add } = useNotifications();

  const { data } = usePointPreviewDeviceQuery({
    variables: { deviceId, capabilityId },
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const [device, location, capability] = useMemo<
    [
      PreviewDevice | undefined,
      PreviewDeviceLocation | undefined,
      PreviewDeviceCapability | undefined,
    ]
  >(() => {
    if (!data) return [undefined, undefined, undefined];

    return [
      {
        id: data.device?.id || '',
        identifier: data.device?.deviceIdentifier || '',
        serialNo: data.device?.serialNo || '',
      },
      {
        siteName: data.placementOfDevice?.siteName || '',
        buildingName: data.placementOfDevice?.buildingName || '',
        storeyName: data.placementOfDevice?.storeyName || '',
        zoneName: data.placementOfDevice?.zoneName || '',
      },
      {
        id: data.deviceModelCapability?.id || '',
        capability: {
          name: data.deviceModelCapability?.capability.name || '',
        },
        unit: {
          unitSymbol: data.deviceModelCapability?.unit.unitSymbol || '',
        },
      },
    ];
  }, [data]);

  return (
    <>
      {device && location && (
        <PointPreview
          device={device}
          location={location}
          capability={capability}
        />
      )}
    </>
  );
};
