import React, { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NetworkStatus } from '@apollo/client';
import { Devices } from './Devices';
import {
  DevicesByBuildingFilterInput,
  SortEnumType,
  useDevicesBuildingAndAttributesQuery,
  useDevicesQuery,
} from '../../__generated__/types';
import { useNotifications } from '../../contexts/notifications-context';
import { useBreadcrumb } from '../../contexts/breadcrumb-context';
import { useBuilding } from '../../contexts/building-context';
import { useGenericPages } from '../../contexts/generic-page-context';
import { DevicesFilter, DevicesQueryParam } from './Devices.types';
import { usePlatform } from '../../contexts/platform-context';

export const DevicesContainer: React.FC = () => {
  const { add } = useNotifications();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const {
    loading: loadingBuilding,
    id: buildingId,
    name: buildingName,
    getStoreyById,
    getZoneById,
    zones,
  } = useBuilding();
  const { deviceTypes, loading: loadingPlatform } = usePlatform();
  const { activateNotFound } = useGenericPages();

  const [
    sortField,
    sortDirection,
    query,
    selectedStorey,
    selectedZones,
    selectedDeviceTypes,
  ] = useMemo<
    [
      string | null,
      'ASC' | 'DESC' | null,
      string,
      string | null,
      string[],
      string[],
    ]
  >(() => {
    const field = searchParams.get(DevicesQueryParam.Field);
    const order = searchParams.get(DevicesQueryParam.Order);
    const direction = order === 'ASC' ? 'ASC' : 'DESC';
    return [
      field || null,
      field ? direction : null,
      searchParams.get(DevicesQueryParam.Query) || '',
      searchParams.get(DevicesQueryParam.Storey) || null,
      searchParams.getAll(DevicesQueryParam.Zone),
      searchParams.getAll(DevicesQueryParam.DeviceType),
    ];
  }, [searchParams]);

  const handleChangeSort = useCallback(
    (direction: 'ASC' | 'DESC', field: string) => {
      setSearchParams((currentSearchParams) => {
        currentSearchParams.set(DevicesQueryParam.Field, field);
        currentSearchParams.set(DevicesQueryParam.Order, direction);
        return currentSearchParams;
      });
    },
    [setSearchParams],
  );

  const handleSelectStorey = useCallback(
    (storeyId?: string) => {
      setSearchParams((currentSearchParams) => {
        if (!storeyId) {
          currentSearchParams.delete(DevicesQueryParam.Storey);
        } else {
          currentSearchParams.set(DevicesQueryParam.Storey, storeyId);
        }
        currentSearchParams.delete(DevicesQueryParam.Zone);
        currentSearchParams.delete(DevicesQueryParam.DeviceType);
        currentSearchParams.delete(DevicesQueryParam.Query);

        return currentSearchParams;
      });
    },
    [setSearchParams],
  );

  const handleRemoveFilter = useCallback(
    (id: string, categoryId: string) => {
      setSearchParams((currentSearchParams) => {
        const currentValues = currentSearchParams.getAll(categoryId);
        currentSearchParams.delete(categoryId);
        currentValues
          .filter((value) => value !== id)
          .forEach((value) => currentSearchParams.append(categoryId, value));

        return currentSearchParams;
      });
    },
    [setSearchParams],
  );

  const handleAddFilter = useCallback(
    (id: string, categoryId: string) => {
      setSearchParams((currentSearchParams) => {
        const currentValues = currentSearchParams.getAll(categoryId);
        currentSearchParams.delete(categoryId);
        [...currentValues, id].forEach((value) =>
          currentSearchParams.append(categoryId, value),
        );
        return currentSearchParams;
      });
    },
    [setSearchParams],
  );

  // Updates the filters for the button
  const filters: DevicesFilter[] = useMemo(
    () => [
      // Include all device types
      ...deviceTypes.map((deviceType) => ({
        id: deviceType.id,
        label: deviceType.name,
        categoryId: DevicesQueryParam.DeviceType,
        selected: selectedDeviceTypes.includes(deviceType.id),
      })),
      // Include zones part of the storeys, or only the zones of
      // the selected storeys
      ...(!selectedStorey
        ? []
        : zones
            .filter((zone) => selectedStorey === zone.storeyId)
            .map((zone) => ({
              id: zone.id,
              label: zone.name,
              categoryId: DevicesQueryParam.Zone,
              selected: selectedZones.includes(zone.id),
            }))),
    ],
    [zones, deviceTypes, selectedStorey, selectedZones, selectedDeviceTypes],
  );

  const handleChangeQuery = useCallback(
    (newQuery: string) => {
      // Since we don't want to trigger refresh, we don't change anything
      // in case the new query is the same as the old one.
      // This seems like a nice feature to add to the SearchField in the ui kit.
      if (newQuery === query) {
        return;
      }
      setSearchParams((currentSearchParams) => {
        if (newQuery) {
          currentSearchParams.set(DevicesQueryParam.Query, newQuery);
        } else {
          currentSearchParams.delete(DevicesQueryParam.Query);
        }
        return currentSearchParams;
      });
    },
    [query, setSearchParams],
  );

  const handleResetFilters = useCallback(() => {
    setSearchParams((currentSearchParams) => {
      currentSearchParams.delete(DevicesQueryParam.Zone);
      currentSearchParams.delete(DevicesQueryParam.DeviceType);
      return currentSearchParams;
    });
  }, [setSearchParams]);

  const categories = useMemo(
    () => [
      {
        id: DevicesQueryParam.DeviceType,
        label: t('widgets.filters.tab.deviceType'),
      },
      ...(selectedStorey
        ? [
            {
              id: DevicesQueryParam.Zone,
              label: t('widgets.filters.tab.zone'),
            },
          ]
        : []),
    ],
    [t, selectedStorey],
  );

  const {
    data: dataBuildingAndAttributes,
    loading: loadingBuildingAndAttributes,
    error: errorBuildingAndAttributes,
  } = useDevicesBuildingAndAttributesQuery({
    variables: { buildingId: buildingId || '' },
    skip: !buildingId,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
    errorPolicy: 'all',
  });

  const order = useMemo(() => {
    if (!sortField || !sortDirection) {
      return undefined;
    }
    const direction =
      sortDirection === 'ASC' ? SortEnumType.Asc : SortEnumType.Desc;
    return { [sortField]: direction };
  }, [sortField, sortDirection]);

  const where = useMemo(() => {
    const and: DevicesByBuildingFilterInput[] = [
      { buildingId: { eq: buildingId } },
    ];

    if (query) {
      and.push({
        or: [
          { deviceName: { contains: query } },
          { deviceDescription: { contains: query } },
          {
            deviceAttributeValues: {
              some: { value: { contains: query } },
            },
          },
          { deviceSerialNumber: { contains: query } },
          { deviceModelName: { contains: query } },
          { deviceGatewayName: { contains: query } },
          { deviceDeviceIdentifier: { contains: query } },
        ],
      });
    }

    if (selectedStorey) {
      and.push({ storeyId: { eq: selectedStorey } });
    }

    if (selectedDeviceTypes.length > 0) {
      and.push({ deviceTypeId: { in: selectedDeviceTypes } });
    }

    if (selectedZones.length > 0) {
      and.push({ zoneId: { in: selectedZones } });
    }

    return { and };
  }, [buildingId, query, selectedStorey, selectedZones, selectedDeviceTypes]);

  const { data, loading, error, fetchMore, networkStatus } = useDevicesQuery({
    variables: {
      take: 30,
      order,
      where,
    },
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const [skip, total] = useMemo(
    () => [
      data?.devicesByBuildings?.items?.length || 0,
      data?.devicesByBuildings?.totalCount || 0,
    ],
    [data],
  );

  const handleScrollDown = useCallback(() => {
    fetchMore({ variables: { skip } });
  }, [fetchMore, skip]);

  const attributes =
    dataBuildingAndAttributes?.attributesByBuildings?.items?.[0]
      ?.deviceAttributes || [];

  const devices = useMemo(
    () =>
      (data?.devicesByBuildings?.items || [])
        // remove any results that don't have a device (this can be removed when the query is updated to filter those)
        .filter((item) => item?.device)
        .map((item) => ({
          id: item?.device?.id || '-1',
          serialNo: item?.device?.serialNo || '',
          name: item?.device?.name || '',
          type: item?.device?.deviceModel?.deviceType?.name || '',
          storeyName: getStoreyById(item?.storeyId || '')?.name || '',
          zoneName: getZoneById(item?.zoneId || '')?.name || '',
          description: item?.device?.description || '',
          deviceIdentifier: item?.device?.deviceIdentifier || '',
          gatewayName: item?.device?.parentDevice?.name || '',
          deviceModelName: item?.device?.deviceModel?.name || '',
          attributes: item?.device?.deviceAttributeValues || [],
          capabilities: item?.device?.deviceModel.deviceModelCapabilities || [],
        })),
    [data, getStoreyById, getZoneById],
  );

  useEffect(() => {
    if (
      !loadingBuildingAndAttributes &&
      !loading &&
      !loadingBuilding &&
      !loadingPlatform &&
      !buildingName &&
      !errorBuildingAndAttributes &&
      !error
    )
      activateNotFound();
  }, [
    loadingBuildingAndAttributes,
    loading,
    loadingBuilding,
    loadingPlatform,
    buildingName,
    errorBuildingAndAttributes,
    error,
    activateNotFound,
  ]);

  useBreadcrumb([
    {
      title: buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    { title: t('page.devices'), selected: true, location: '/' },
  ]);

  const canLoadMore =
    skip &&
    total &&
    skip < total &&
    !loading &&
    !loadingBuilding &&
    !loadingPlatform;

  return (
    <Devices
      query={query}
      onChangeQuery={handleChangeQuery}
      categories={categories}
      onAddFilter={handleAddFilter}
      onRemoveFilter={handleRemoveFilter}
      onStoryFilter={handleSelectStorey}
      storeyId={selectedStorey}
      onResetFilters={handleResetFilters}
      filters={filters}
      onScrollDown={canLoadMore ? handleScrollDown : undefined}
      buildingId={buildingId || ''}
      onChangeSort={handleChangeSort}
      sortDirection={sortDirection}
      sortField={sortField}
      loadingMore={loading && networkStatus === NetworkStatus.fetchMore}
      loading={
        (loading && networkStatus !== NetworkStatus.fetchMore) ||
        loadingBuildingAndAttributes
      }
      devices={devices}
      attributes={attributes}
    />
  );
};
