import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, Button, createClasses } from '@kp/react-ui';
import { WIDGETS_HEADER_HEIGHT } from '../../../constants/UI';
import { Breadcrumbs } from '../../../components/Breadcrumbs';

const classes = createClasses({
  root: {
    display: 'flex',
    // Offset for the border
    height: WIDGETS_HEADER_HEIGHT - 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
    background: Colors.Neutral.background,
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
  },
  actions: {
    display: 'flex',
  },
  button: {
    '&:not(:first-child)': {
      marginLeft: '12px',
    },
  },
});

export interface WidgetFormHeaderProps {
  loading?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
}

export const WidgetFormHeader: React.FC<WidgetFormHeaderProps> = ({
  loading,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Breadcrumbs />
      <div className={classes.actions}>
        {onCancel && (
          <Button
            className={classes.button}
            variant="secondary"
            onClick={onCancel}
          >
            {t('widgets.actions.cancel')}
          </Button>
        )}
        {onSave && (
          <Button
            className={classes.button}
            variant="primary"
            onClick={onSave}
            disabled={loading}
          >
            {t('widgets.actions.save')}
          </Button>
        )}
      </div>
    </div>
  );
};
