import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Card, createClasses, Icon, Icons } from '@kp/react-ui';

const classes = createClasses({
  cardWrapper: {
    display: 'flex',
    marginBottom: '24px',
  },
  exploreCardWrapper: {
    minWidth: '100%',
    flexDirection: 'row',
  },
  exploreCard: {
    flexDirection: 'row',
    background: 'rgba(246, 247, 249, 0.94)',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-evenly',
    width: 'calc(25% - 8px)',
    padding: '8px',
    margin: '8px',
  },
  exploreCardText: {
    flexDirection: 'column',
    paddingLeft: '8px',
  },
  exploreCardTitle: {
    fontSize: '100%',
    padding: '2px',
  },
  exploreCardSubtitle: {
    fontSize: '10px',
    padding: '2px',
  },
  exploreCardKeywords: {
    fontSize: '10px',
    color: 'grey',
    padding: '2px',
  },
  link: {
    textDecoration: 'none',
  },
});

export interface InfoInteractiveProps {
  buildingId?: string;
}

export const InfoInteractive: React.FC<InfoInteractiveProps> = ({
  buildingId,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="bodyMedium" component="h2">
        {t('info.explore.title')}
      </Typography>
      <div className={classes.cardWrapper}>
        <Card className={classes.exploreCardWrapper}>
          <Card className={classes.exploreCard}>
            <Icon icon={Icons.IconModelEditor} size={24} />
            <div className={classes.exploreCardText}>
              <div className={classes.exploreCardSubtitle}>
                {t('info.explore.buildingModel.subtitle')}
              </div>
              <RouterLink
                className={classes.link}
                to={`/buildings/${buildingId}/interactive`}
              >
                <div className={classes.exploreCardTitle}>
                  {t('info.explore.buildingModel.title')}
                </div>
              </RouterLink>
              <div className={classes.exploreCardKeywords}>
                {t('info.explore.buildingModel.keywords')}
              </div>
            </div>
          </Card>
          <Card className={classes.exploreCard}>
            <Icon icon={Icons.IconDevices} size={24} />
            <div className={classes.exploreCardText}>
              <div className={classes.exploreCardSubtitle}>
                {t('info.explore.equipmentModel.subtitle')}
              </div>
              <RouterLink className={classes.link} to="#">
                <div className={classes.exploreCardTitle}>
                  {t('info.explore.equipmentModel.title')}
                </div>
              </RouterLink>
              <div className={classes.exploreCardKeywords}>
                {t('info.explore.equipmentModel.keywords')}
              </div>
            </div>
          </Card>
          <Card className={classes.exploreCard}>
            <Icon icon={Icons.IconFloorplan} size={24} />
            <div className={classes.exploreCardText}>
              <div className={classes.exploreCardSubtitle}>
                {t('info.explore.indoorMap.subtitle')}
              </div>
              <RouterLink
                className={classes.link}
                to={`/buildings/${buildingId}/map`}
              >
                <div className={classes.exploreCardTitle}>
                  {t('info.explore.indoorMap.title')}
                </div>
              </RouterLink>
              <div className={classes.exploreCardKeywords}>
                {t('info.explore.indoorMap.keywords')}
              </div>
            </div>
          </Card>
          <Card className={classes.exploreCard}>
            <Icon icon={Icons.IconSettings2} size={24} />
            <div className={classes.exploreCardText}>
              <div className={classes.exploreCardSubtitle}>
                {t('info.explore.maintenance.subtitle')}
              </div>
              <RouterLink className={classes.link} to="#">
                <div className={classes.exploreCardTitle}>
                  {t('info.explore.maintenance.title')}
                </div>
              </RouterLink>
              <div className={classes.exploreCardKeywords}>
                {t('info.explore.maintenance.keywords')}
              </div>
            </div>
          </Card>
        </Card>
      </div>
    </>
  );
};
