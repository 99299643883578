import React from 'react';
import { Label, Typography, createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { ToggleCheckboxField } from '../../../components/formik-fields';

const classes = createClasses({
  fieldContainer: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
});

export interface WidgetFormViewProps {}

export const WidgetFormView: React.FC<WidgetFormViewProps> = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Label title={t('widgets.view.label') || ''} />
      <div className={classes.fieldContainer}>
        <Typography variant={'bodyMedium'} component={'div'}>
          {t('widgets.view.showPoints')}
        </Typography>
        <div>
          <Field
            name="showPoints"
            component={ToggleCheckboxField}
            data-testid="showPoints-toggleCheckbox"
          />
        </div>
      </div>
      <div className={classes.fieldContainer}>
        <Typography variant={'bodyMedium'}>
          {t('widgets.view.comparisonLine')}
        </Typography>
        <Field
          name="comparisonLine"
          component={ToggleCheckboxField}
          data-testid="comparisonLine-toggleCheckbox"
        />
      </div>
    </div>
  );
};
