import { createClasses, Typography } from '@kp/react-ui';
import React, { useCallback } from 'react';
import { DashboardCard } from '../common/DashboardCard';

const classes = createClasses({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'end',
    paddingBottom: '16px',
  },
  value: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  mainPart: {
    marginRight: '8px',
  },
});

interface ClockCardProps {
  id: string;
  title: string;
  subtitle?: string;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selected?: boolean;
  time: string;
  timePostfix?: string;
}

export const ClockCard: React.FC<ClockCardProps> = ({
  id,
  title,
  subtitle,
  onEdit,
  onDelete,
  selected,
  time,
  timePostfix,
}) => {
  const handleEdit = useCallback(() => {
    onEdit && onEdit(id);
  }, [onEdit, id]);

  const handleDelete = useCallback(() => {
    onDelete && onDelete(id);
  }, [onDelete, id]);

  return (
    <DashboardCard
      title={title}
      subtitle={subtitle}
      onDelete={onDelete ? handleDelete : undefined}
      onEdit={onEdit ? handleEdit : undefined}
      selected={selected}
    >
      <div className={classes.root}>
        <div className={classes.value}>
          <Typography variant="displaySmall" className={classes.mainPart}>
            {time}
          </Typography>
          {timePostfix && (
            <Typography variant="headlineMedium">{timePostfix}</Typography>
          )}
        </div>
      </div>
    </DashboardCard>
  );
};
