import React from 'react';
import { Colors, classNames, createClasses } from '@kp/react-ui';
import {
  DASHBOARD_RIGHT_SIDEBAR_WIDTH,
  DASHBOARD_RIGHT_SIDEBAR_COLLAPSED_WIDTH,
} from '../../constants';
import {
  DashboardRightBarTabTypes,
  useDashboard,
} from '../../dashboard-context';
import { DashboardRightSidebarTabs } from './DashboardRightSidebarTabs';
import { DashboardRightSidebarNewCard } from './newCard/DashboardRightSidebarNewCard';
import { DashboardRightSidebarSettings } from './settings/DashboardRightSidebarSettings';

const classes = createClasses({
  root: {
    position: 'absolute',
    borderLeft: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.background,
    right: 0,
    transform: `translateX(${
      DASHBOARD_RIGHT_SIDEBAR_WIDTH - DASHBOARD_RIGHT_SIDEBAR_COLLAPSED_WIDTH
    }px)`,
    transition: 'transform 0.2s',
    width: DASHBOARD_RIGHT_SIDEBAR_WIDTH,
    zIndex: 40,
    height: 'calc(100vh - 151px)',
    display: 'flex',
    userSelect: 'none',
  },
  open: {
    transform: 'translateX(0)',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    margin: '6px 12px',
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '12px 12px 12px 11px',
  },
  contentContainer: {
    paddingTop: '2px',
    width: '100%',
    overflowX: 'scroll',
    padding: '12px 24px 12px 12px',
  },
});

interface DashboardRightSidebarProps {
  submit: () => void;
  onDelete: () => void;
}

export const DashboardRightSidebar: React.FC<DashboardRightSidebarProps> = ({
  submit,
  onDelete,
}) => {
  const { rightSidebarOpen, selectedRightSidebarTab } = useDashboard();

  return (
    <div
      className={classNames(classes.root, rightSidebarOpen && classes.open)}
      data-testid="right-sidebar"
      data-open={rightSidebarOpen}
    >
      <div className={classes.tabsContainer}>
        <DashboardRightSidebarTabs />
      </div>
      <div className={classes.contentContainer}>
        {selectedRightSidebarTab === DashboardRightBarTabTypes.newCard && (
          <DashboardRightSidebarNewCard />
        )}

        {selectedRightSidebarTab === DashboardRightBarTabTypes.settings && (
          <DashboardRightSidebarSettings onDelete={onDelete} submit={submit} />
        )}
      </div>
    </div>
  );
};
