import React from 'react';
import {
  Colors,
  Typography,
  classNames,
  createClasses,
  css,
  TimeScaleDataset,
} from '@kp/react-ui';
import { useLocaleDateFns } from '../../hooks/date-fns';
import { WidgetTable, WidgetTableProps } from './WidgetTable';
import { WidgetTableBody } from './WidgetTableBody';
import { WidgetTableCell } from './WidgetTableCell';
import { WidgetTableHead } from './WidgetTableHead';
import { WidgetTableHeadCell } from './WidgetTableHeadCell';
import { WidgetTableRow } from './WidgetTableRow';

interface WidgetTablePropsContainerProps extends WidgetTableProps {
  data: Array<TimeScaleDataset & { deviceName: string }>;
  size?: 'small' | 'large';
}

interface Item {
  value: number;
  name: string;
  symbol: string;
}

const classes = createClasses({
  colorPicker: {
    width: '12px',
    height: '12px',
    marginRight: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '6px',
  },
  textHeaderMain: {
    color: Colors.Neutral.text,
    paddingRight: '3px',
  },
  textHeaderSub: {
    color: Colors.Neutral.textWeak,
  },
  firstColumn: {
    backgroundColor: Colors.Neutral.backgroundMedium,
  },
  otherColumns: {
    backgroundColor: Colors.Neutral.background,
  },
  cellRoundCorner: {
    'tr:last-child &:first-child': {
      borderBottomLeftRadius: '6px',
    },
    'tr:last-child &:last-child': {
      borderBottomRightRadius: '6px',
    },
  },
});

export const WidgetTableContainer: React.FC<WidgetTablePropsContainerProps> = ({
  data,
  size = 'small',
  className,
  ...props
}) => {
  const items = data
    .map((item) =>
      item.data.map((record) => ({
        date: new Date(record.timestamp),
        value: record.value,
        type: item.label || '',
        symbol: item.unit || '',
      })),
    )
    .flat();

  const { format } = useLocaleDateFns();
  const revertData: Map<string, Array<Item>> = new Map<string, Array<Item>>();
  items.forEach((item) => {
    const key = item.date.toUTCString();
    const y = revertData.get(key) || new Array<Item>();
    y.push({
      value: item.value,
      name: item.type,
      symbol: item.symbol,
    });
    revertData.set(key, y);
  });

  const entries = Array.from(revertData.entries()).reverse();

  return (
    <WidgetTable className={className} {...props}>
      <WidgetTableHead>
        <WidgetTableRow>
          <WidgetTableHeadCell />
          {data.map((item) => (
            <WidgetTableHeadCell key={`${item.deviceName}-${item.label}`}>
              {size === 'large' && (
                <div className={classes.header}>
                  <div
                    className={classNames(
                      classes.colorPicker,
                      css({
                        backgroundColor: item.color,
                      }),
                    )}
                  ></div>
                  <Typography
                    variant="labelSmall"
                    className={classes.textHeaderMain}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    variant="labelSmall"
                    className={classes.textHeaderMain}
                  >
                    {item.unit}
                  </Typography>
                </div>
              )}
              <Typography
                variant="labelSmall"
                className={classes.textHeaderSub}
              >
                {item.deviceName}
              </Typography>
            </WidgetTableHeadCell>
          ))}
        </WidgetTableRow>
      </WidgetTableHead>
      <WidgetTableBody>
        {entries.map(([key, value]) => (
          <WidgetTableRow key={key}>
            <WidgetTableCell
              className={classNames(
                classes.cellRoundCorner,
                classes.firstColumn,
              )}
            >
              {format(new Date(key), 'P p')}
            </WidgetTableCell>
            {value.map((row) => (
              <WidgetTableCell
                key={row.name}
                className={classNames(
                  classes.cellRoundCorner,
                  classes.otherColumns,
                )}
              >
                {row.value}
                {row.symbol}
              </WidgetTableCell>
            ))}
          </WidgetTableRow>
        ))}
      </WidgetTableBody>
    </WidgetTable>
  );
};
