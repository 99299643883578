import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbRootLink,
  Icons,
} from '@kp/react-ui';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useBreadcrumb } from '../../contexts/breadcrumb-context';

export const Breadcrumbs: React.FC = () => {
  const navigate = useNavigate();
  const links = useBreadcrumb();

  return (
    <Breadcrumb>
      <BreadcrumbRootLink icon={Icons.IconSite} component={NavLink} to="/" />
      {links.map((link, index) => {
        return !link.selected ? (
          <BreadcrumbLink
            key={`${index}-${link.location}`}
            title={link.title}
            onClick={
              link.onClick ||
              (() => {
                navigate(link.location);
              })
            }
          />
        ) : (
          <BreadcrumbLink
            selected={link.selected}
            key={`${index}-${link.location}`}
            title={link.title}
          />
        );
      })}
    </Breadcrumb>
  );
};
