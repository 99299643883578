import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { TableCell, createClasses } from '@kp/react-ui';

const classes = createClasses({
  dropCell: { borderBottom: 0 },
});

export interface WidgetFormDevicesAddedLastRowProps {
  onMove: (from: number, to: number) => void;
  index: number;
}

export const WidgetFormDevicesAddedLastRow: React.FC<
  WidgetFormDevicesAddedLastRowProps
> = ({ onMove, index }) => {
  const ref = useRef<HTMLTableSectionElement>(null);

  const [, drop] = useDrop({
    accept: 'WIDGET_FORM_DEVICES_ADDED_DEVICE_ROW',
    canDrop: (item: { index: number }) => index !== item.index,
    drop: (item) => onMove(item.index, index),
  });

  drop(ref);

  return (
    <tbody ref={ref} data-testid="devices-added-last-row">
      <tr>
        <TableCell colSpan={7} className={classes.dropCell} />
      </tr>
    </tbody>
  );
};
