import React, { useCallback } from 'react';
import { createClasses, Modal } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import {
  DashboardTemplateEmptyButton,
  DashboardTemplateHeatingButton,
} from './button';
import { useDashboard } from '../dashboard-context';

export interface DashboardTemplatesProps {}

const classes = createClasses({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '800px',
    padding: '24px',
    gap: '12px',
  },
});

export const DashboardTemplates: React.FC<DashboardTemplatesProps> = () => {
  const { t } = useTranslation();

  const { create, createLoading, templatesOpen, setTemplatesOpen } =
    useDashboard();

  const onToggle = useCallback(() => {
    setTemplatesOpen(!templatesOpen);
  }, [templatesOpen, setTemplatesOpen]);

  return (
    <Modal
      title={t('dashboards.template.title')}
      open={templatesOpen}
      onClose={onToggle}
    >
      <div className={classes.container}>
        <DashboardTemplateEmptyButton
          onClick={create}
          disabled={createLoading}
        />
        <DashboardTemplateHeatingButton
          onClick={create}
          disabled={createLoading}
        />
      </div>
    </Modal>
  );
};
