import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  createClasses,
} from '@kp/react-ui';
import {
  Entry,
  FormCapability,
  FormCapabilityIdentifier,
} from '../widgetFormDevicesTypes';
import { WidgetFormDevicesAddedDeviceRow } from './WidgetFormDevicesAddedDeviceRow';
import { WidgetFormDevicesAddedLastRow } from './WidgetFormDevicesAddedLastRow';

const classes = createClasses({
  root: {
    overflow: 'scroll',
    height: '100%',
    '& table thead th': {
      zIndex: 101,
    },
  },
  actions: {
    width: '68px',
  },
  capabilities: {
    width: '20%',
  },
});

export interface WidgetFormDevicesAddedProps {
  onChange: (
    capability: FormCapabilityIdentifier,
    updates: Partial<FormCapability>,
  ) => void;
  onRemove?: (capability: FormCapabilityIdentifier) => void;
  onDuplicate?: (capability: FormCapabilityIdentifier) => void;
  onMove: (from: number, to: number) => void;
  loading?: boolean;
  entries: Entry[];
}

export const WidgetFormDevicesAdded: React.FC<WidgetFormDevicesAddedProps> = ({
  onRemove,
  onChange,
  onMove,
  onDuplicate,
  loading,
  entries,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Table scrollable data-testid="device-added">
        <TableHead>
          <TableRow>
            <TableHeadCell className={classes.actions} />
            <TableHeadCell>
              {t('widgets.table.columns.deviceType')}
            </TableHeadCell>
            <TableHeadCell>
              {t('widgets.table.columns.deviceName')}
            </TableHeadCell>
            <TableHeadCell>
              {t('widgets.table.columns.serialNumber')}
            </TableHeadCell>
            <TableHeadCell>{t('widgets.table.columns.storey')}</TableHeadCell>
            <TableHeadCell>
              {t('widgets.table.columns.description')}
            </TableHeadCell>
            <TableHeadCell className={classes.capabilities}>
              {t('widgets.table.columns.capabilities')}
            </TableHeadCell>
          </TableRow>
        </TableHead>
        {loading ? (
          <TableBody>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
            </TableRow>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
            </TableRow>
            <TableRow loading>
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
              <TableCell loading />
            </TableRow>
          </TableBody>
        ) : (
          <>
            {entries.map((entry, index) => (
              <WidgetFormDevicesAddedDeviceRow
                index={index}
                key={`${entry.id}-${entry.capabilityId}`}
                entry={entry}
                onChange={onChange}
                onRemove={onRemove}
                onDuplicate={onDuplicate}
                onMove={onMove}
              />
            ))}
            <WidgetFormDevicesAddedLastRow
              index={entries.length - 1}
              onMove={onMove}
            />
          </>
        )}
      </Table>
    </div>
  );
};
