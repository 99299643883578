import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Icons,
  Sidebar as Component,
  SidebarItem,
  createClasses,
} from '@kp/react-ui';
import { useHasQanteon } from '../../hooks/useHasQanteon';

const classes = createClasses({
  root: {
    // raises the sidebar tooltip above layout content
    zIndex: 99,
    // context menu breaks the flow, so we need higher zIndex on hover
    '&:hover': {
      zIndex: 110,
    },
  },
});

interface SidebarProps {
  isCollapsed: boolean;
  level: 'building' | 'device' | 'qanteon' | 'dashboard';
}

export const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, level }) => {
  const { t } = useTranslation();
  const hasQanteon = useHasQanteon();
  const { buildingId } = useParams();

  return (
    <Component open={!isCollapsed} className={classes.root}>
      {buildingId && (
        <>
          <SidebarItem
            icon={Icons.IconBusiness}
            title="Insights"
            selected={level === 'building'}
            component={Link}
            to={`/buildings/${buildingId}/info`}
          />
          <SidebarItem
            component={Link}
            to={`/buildings/${buildingId}/dashboards`}
            icon={Icons.IconDashboard}
            title={t('page.dashboards')}
            selected={level === 'dashboard'}
          />
          <SidebarItem
            icon={Icons.IconDevicesAlt}
            title={t('page.devices')}
            selected={level === 'device'}
            component={Link}
            to={`/buildings/${buildingId}/devices`}
          />
          {hasQanteon && (
            <SidebarItem
              icon={Icons.IconQanteon}
              title="Qanteon"
              selected={level === 'qanteon'}
              component={Link}
              to={`/buildings/${buildingId}/qanteon`}
            />
          )}
        </>
      )}
    </Component>
  );
};
