import { createClasses, TimeScaleDataset } from '@kp/react-ui';
import React, { useCallback } from 'react';
import Lottie, { LottieProps } from 'react-lottie-player';
import { ChartCardEmpty } from './ChartCardEmpty';
import { Chart } from '../../../../components/Chart';
import { ChartCardLabels } from './ChartCardLabels';
import { DashboardCard } from '../common/DashboardCard';
import chartLoadingAnimationData from '../../../../animations/chart-loading.json';

const animationOptions: LottieProps = {
  loop: true,
  play: true,
  animationData: chartLoadingAnimationData,
  style: { width: 48, height: 48 },
};

const classes = createClasses({
  empty: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  loading: {
    paddingBottom: '56px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  chart: {
    height: 'calc(100% - 46px)',
  },
});

export interface ChartCardProps {
  loading?: boolean;
  empty?: boolean;
  id: string;
  title: string;
  subtitle: string;
  min?: Date;
  max?: Date;
  chartData: Array<
    TimeScaleDataset & { id: string; title: string; color: string }
  >;
  chartType?: 'line' | 'bar';
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selected?: boolean;
}

export const ChartCard: React.FC<ChartCardProps> = ({
  loading,
  empty,
  id,
  title,
  subtitle,
  min,
  max,
  chartData,
  chartType,
  onEdit,
  onDelete,
  selected,
}) => {
  const handleEdit = useCallback(() => {
    onEdit && onEdit(id);
  }, [onEdit, id]);

  const handleDelete = useCallback(() => {
    onDelete && onDelete(id);
  }, [onDelete, id]);

  return (
    <DashboardCard
      title={title}
      subtitle={subtitle}
      onDelete={onDelete ? handleDelete : undefined}
      onEdit={onEdit ? handleEdit : undefined}
      selected={selected}
    >
      {empty && (
        <div className={classes.empty}>
          <ChartCardEmpty
            onAddDataPointClick={onEdit ? handleEdit : undefined}
          />
        </div>
      )}
      {loading && (
        <div className={classes.loading}>
          <Lottie {...animationOptions} />
        </div>
      )}
      {!loading && !empty && (
        <div className={classes.container}>
          <div className={classes.chart}>
            <Chart
              datasets={chartData}
              collapsed={true}
              size="large"
              type={chartType}
              min={min}
              max={max}
              autoFit
            />
          </div>
          <ChartCardLabels labels={chartData} />
        </div>
      )}
    </DashboardCard>
  );
};
