export const Typography = {
  body: {
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  caption1: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  footnote: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  headline: {
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  largeText: {
    fontSize: '34px',
    fontWeight: 700,
    lineHeight: '41px',
  },
  subhead: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  subheadMedium: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  title1: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '34px',
  },
  title2: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '28px',
  },
  title3: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '25px',
  },
};
