import React, { useCallback } from 'react';
import {
  Colors,
  Typography,
  TickListItem,
  classNames,
  createClasses,
  Button,
  Icons,
} from '@kp/react-ui';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth, Permissions } from '@kp/react-sdk';
import { DASHBOARD_LEFT_SIDEBAR_WIDTH } from './constants';
import { DashboardEntity } from '../../api/building-insights';
import { useBuilding } from '../../contexts/building-context';
import { DashboardTemplates } from './templates/DashboardTemplates';
import { useDashboard } from './dashboard-context';

const classes = createClasses({
  root: {
    position: 'absolute',
    borderRight: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.backgroundMedium,
    left: 0,
    transform: `translateX(-${DASHBOARD_LEFT_SIDEBAR_WIDTH}px)`,
    transition: 'transform 0.2s',
    width: DASHBOARD_LEFT_SIDEBAR_WIDTH,
    minHeight: 'calc(100vh - 151px)',
  },
  open: {
    transform: 'translateX(0)',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    margin: '6px 12px',
  },
  header: {
    padding: '4px 12px',
    borderBottom: `1px solid ${Colors.Neutral.borderMedium}`,
    background: Colors.Neutral.background,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

interface DashboardsLayoutSidebarProps {
  dashboards: DashboardEntity[];
  loading?: boolean;
}

export const DashboardsLayoutSidebar: React.FC<
  DashboardsLayoutSidebarProps
> = ({ dashboards }) => {
  const { leftSidebarOpen, setTemplatesOpen } = useDashboard();
  const { id: buildingId } = useBuilding();
  const { t } = useTranslation();
  const { hasPermission } = useAuth();

  const handleAddButtonClick = useCallback(() => {
    setTemplatesOpen(true);
  }, [setTemplatesOpen]);

  return (
    <div
      className={classNames(classes.root, leftSidebarOpen && classes.open)}
      data-testid="dashboards-sidebar"
      data-open={leftSidebarOpen}
    >
      <div className={classes.header}>
        <Typography
          variant="titleSmall"
          component="div"
          className={classes.title}
        >
          {t('dashboards.sidebar.title')}
        </Typography>
        {hasPermission(Permissions.WidgetsWrite) && (
          <Button
            variant="tertiary"
            icon={Icons.IconAdd}
            onClick={handleAddButtonClick}
          />
        )}
        <DashboardTemplates />
      </div>
      {dashboards.map(({ id, name }) => (
        <NavLink
          key={id}
          to={`/buildings/${buildingId}/dashboards/${id}`}
          className={classes.link}
        >
          {({ isActive }) => (
            <TickListItem
              label={name || t('dashboards.template.untitled')}
              selected={isActive}
              aria-label={name || t('dashboards.template.untitled')}
            />
          )}
        </NavLink>
      ))}
    </div>
  );
};
