import React, { useCallback, useEffect, useMemo, useState } from 'react';
import update from 'immutability-helper';
import {
  getWidgets,
  getWidgetSettings,
  updateWidgetSettings,
} from '../../../../api/building-insights';
import {
  WidgetEntity,
  WidgetListType,
} from '../../../../api/building-insights/common';
import { useNotifications } from '../../../../contexts/notifications-context';
import { useRequest, usePrepareRequest } from '../../../../hooks/useRequest';
import { InfoWidgets } from './InfoWidgets';
import { useBuilding } from '../../../../contexts/building-context';

interface InfoWidgetsContainerProps {
  loading?: boolean;
}

export const InfoWidgetsContainer: React.FC<InfoWidgetsContainerProps> = ({
  loading: pageLoading,
}) => {
  const { id: buildingId } = useBuilding();
  const { add } = useNotifications();
  const filter = useMemo(() => ({ buildingId }), [buildingId]);
  const [widgets, setWidgets] = useState<WidgetEntity[]>([]);
  const [columns, setColumns] = useState<WidgetListType>(
    WidgetListType.THREE_COLUMNS,
  );

  const { response, error, loading } = useRequest(getWidgets, filter);
  const {
    response: settingsResponse,
    error: settingsError,
    loading: settingsLoading,
  } = useRequest(getWidgetSettings, buildingId);
  const [updateSettings, { response: updateSettingsResponse }] =
    usePrepareRequest(updateWidgetSettings, {
      onError: (err) => {
        add({
          type: 'danger',
          id: err.message,
          content: err.message,
        });
      },
    });

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      add({
        type: 'danger',
        id: error.message,
        content: error.message,
      });
    }
    setWidgets(response?.data || []);
  }, [error, loading, add, response?.data]);

  useEffect(() => {
    if (settingsLoading) {
      return;
    }
    if (settingsError && settingsError.message !== 'Not Found') {
      add({
        type: 'danger',
        id: settingsError.message,
        content: settingsError.message,
      });
    }
    if (settingsResponse?.data?.widgetListType) {
      setColumns(settingsResponse.data.widgetListType);
    }
  }, [settingsError, settingsLoading, add, settingsResponse]);

  useEffect(() => {
    if (updateSettingsResponse?.data?.widgetListType) {
      setColumns(updateSettingsResponse.data?.widgetListType);
    }
  }, [updateSettingsResponse]);

  const moveWidget = useCallback((dragIndex: number, hoverIndex: number) => {
    setWidgets((prevCards: WidgetEntity[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as WidgetEntity],
        ],
      }),
    );
  }, []);

  const handleChangeColumns = useCallback(
    async (value: string) => {
      if (!buildingId) {
        return;
      }
      switch (value) {
        case WidgetListType.THREE_COLUMNS:
        case WidgetListType.TWO_COLUMNS:
          // We set the new column setting immediately and later save it to the
          // backend, so we don't delay the result to the user
          setColumns(value);
          await updateSettings(buildingId, { widgetListType: value });
      }
    },
    [setColumns, buildingId, updateSettings],
  );

  return (
    <InfoWidgets
      loading={loading || pageLoading || settingsLoading}
      widgets={widgets}
      columns={columns}
      onChangeColumns={handleChangeColumns}
      buildingId={buildingId || ''}
      moveWidget={moveWidget}
    />
  );
};
