import React from 'react';
import { ToggleButtonGroup, ToggleButtonGroupProps } from '@kp/react-ui';
import { FieldProps } from 'formik';

export interface ToggleButtonGroupFieldProps
  extends FieldProps,
    Omit<ToggleButtonGroupProps, 'value' | 'onChange'> {}

const fieldToToggleButtonGroup = ({
  field: { onChange: _onChange, onBlur: _onBlur, ...field },
  form,
  ...props
}: ToggleButtonGroupFieldProps): ToggleButtonGroupProps => ({
  onChange: (newValue) => {
    form.setFieldValue(field.name, newValue);
  },
  ...field,
  ...props,
});

export const ToggleButtonGroupField = (props: ToggleButtonGroupFieldProps) => (
  <ToggleButtonGroup {...fieldToToggleButtonGroup(props)} />
);

ToggleButtonGroupField.displayName = 'FormikToggleButtonGroup';
