import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DeviceEmpty } from './DeviceEmpty';
import { Device, DeviceLocation } from '../Device.types';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';

interface DeviceEmptyContainerProps {
  device?: Device;
  location?: DeviceLocation;
}

export const DeviceEmptyContainer: React.FC<DeviceEmptyContainerProps> = ({
  device,
  location,
}) => {
  const { buildingId, deviceId } = useParams();
  const { t } = useTranslation();

  useBreadcrumb([
    {
      title: location?.buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    {
      title: t('page.devices'),
      location: `/buildings/${buildingId}/devices`,
    },
    {
      title: device?.name || '',
      selected: true,
      location: `/buildings/${buildingId}/devices/${deviceId}`,
    },
  ]);
  return <DeviceEmpty device={device} location={location} />;
};
