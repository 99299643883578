import React, { useCallback } from 'react';
import {
  Colors,
  Icon,
  Icons,
  Typography,
  TypographyVariant,
  classNames,
  createClasses,
} from '@kp/react-ui';
import { PropertySidebarItem } from './PropertySidebarItem';

const classes = createClasses({
  root: {
    display: 'grid',
    padding: '0px',
    width: '320px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    paddingLeft: '24px',
    height: '40px',
    color: Colors.Neutral.text,
    backgroundColor: Colors.Neutral.backgroundStrong,
    borderBottom: `1px solid ${Colors.Neutral.borderMedium}`,
  },
  titleIcon: {
    color: Colors.Neutral.icon,
    paddingRight: '20px',
  },
  building: {
    cursor: 'pointer',
    backgroundColor: Colors.Neutral.background,
    height: '80px',
    width: '100%',
    borderBottom: `1px solid ${Colors.Neutral.borderMedium}`,
    '&:hover': {
      backgroundColor: Colors.Neutral.backgroundHoverWeak,
    },
  },
});

export interface PropertySidebarItemsProps
  extends Omit<React.ComponentProps<'button'>, 'onClick'> {
  title: string;
  buildings?: Array<{
    isFavorite: boolean;
    id: string;
    name: string;
    location: {
      street: string;
      city: string;
      no: string;
      zip: string;
      coordinates?: { lat: number; lng: number };
    };
  }>;
  onClick?: (value: string) => void;
  onFavClick?: (value: string) => void;
  setIsMouseOver?: (value: string) => void;
}

export const PropertySidebarItems: React.FC<PropertySidebarItemsProps> = ({
  title,
  buildings,
  onClick,
  onFavClick,
  setIsMouseOver,
}) => {
  const handleFavClick = useCallback(
    (event: any) => {
      if (onFavClick) onFavClick(event);
    },
    [onFavClick],
  );

  const handleClick = useCallback(
    (event: any) => {
      if (onClick) onClick(event);
    },
    [onClick],
  );

  return (
    <div className={classNames(classes.root)}>
      <div className={classes.title}>
        <Icon
          className={classes.titleIcon}
          icon={Icons.IconApartment}
          size={20}
        />
        <Typography variant={TypographyVariant.LabelMedium}>{title}</Typography>
      </div>
      {buildings?.map((building) => (
        <div className={classes.building} key={building.id}>
          <PropertySidebarItem
            building={building}
            value={building.id}
            onClick={handleClick}
            onFavClick={handleFavClick}
            setIsMouseOver={setIsMouseOver}
          />
        </div>
      ))}
    </div>
  );
};
