import React, { ChangeEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxListItem, DropdownButton, createClasses } from '@kp/react-ui';

const classes = createClasses({
  content: {
    maxHeight: '576px',
    overflow: 'scroll',
    'div &': { left: 'auto' },
    zIndex: 105,
  },
  checkbox: {
    whiteSpace: 'nowrap',
  },
});

export interface DevicesColumn {
  label: string;
  selected: boolean;
  id: string;
  disabled?: boolean;
  sortKey?: string;
}

interface DevicesColumnsDropdownProps {
  columns: DevicesColumn[];
  onChange: (id: string, value: boolean) => void;
}

export const DevicesColumnsDropdown: React.FC<DevicesColumnsDropdownProps> = ({
  columns,
  onChange,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(event.target.name, event.target.checked);
    },
    [onChange],
  );
  return (
    <DropdownButton
      type="button"
      label={t('devices.columns.dropdown.label')}
      popupClassName={classes.content}
      position="right"
    >
      {columns.map((column) => (
        <CheckboxListItem
          name={column.id}
          className={classes.checkbox}
          disabled={column.disabled}
          checked={column.selected}
          label={column.label}
          key={column.id}
          onChange={handleChange}
        />
      ))}
    </DropdownButton>
  );
};
