import { useEffect, useState, useCallback, useMemo } from 'react';
import { useBoundingclientrectRef, useEffectOnceWhen } from 'rooks';

type HorizontalResizeResult = [
  [number, number],
  {
    startY: number;
    endY: number;
    isResizing: boolean;
    setOffset: (
      value: number | ((value: number, height: number) => number),
    ) => void;
    startResizing: () => void;
    containerRef: ((node: HTMLElement | null) => any) | null;
  },
];

export const useHorizontalResize = ({
  defaultOffset,
  minBottomOffset = 0,
  minTopOffset = 0,
}: {
  defaultOffset: (height: number) => number;
  minBottomOffset?: number;
  minTopOffset?: number;
}): HorizontalResizeResult => {
  const [isResizing, setIsResizing] = useState(false);
  const [containerRef, boundingClientRect] = useBoundingclientrectRef();

  const [offset, setOffset] = useState<number | null>(null);

  const startResizing = useCallback(() => setIsResizing(true), []);
  const stopResizing = useCallback(() => setIsResizing(false), []);

  const { startY, endY, minY, maxY } = useMemo(() => {
    const start = boundingClientRect?.y || 0;
    const end = start + (boundingClientRect?.height || 0);
    const min = start + minTopOffset;
    const max = end - minBottomOffset;
    return {
      startY: start,
      endY: end,
      minY: min,
      maxY: max,
    };
  }, [boundingClientRect, minTopOffset, minBottomOffset]);

  const setRelativeOffset = useCallback(
    (value: number | ((value: number, height: number) => number)) => {
      const height = endY - startY;
      setOffset(
        typeof value === 'number'
          ? startY + value
          : (oldValue) => value((oldValue || 0) - startY, height) + startY,
      );
    },
    [setOffset, endY, startY],
  );

  // In Safari the icons is always replaced with `text`, instead of resize icon
  // according to SO we have to capture `onSelectStart` and disable it manually
  // https://stackoverflow.com/questions/47295211/safari-wrong-cursor-when-dragging
  useEffect(() => {
    document.onselectstart = isResizing ? () => false : () => true;
  }, [isResizing]);

  // Set the default offset
  useEffectOnceWhen(() => {
    const height = endY - startY;
    setRelativeOffset(defaultOffset(height));
  }, offset === null && endY > 0);

  const heights = useMemo<[number, number]>(
    () =>
      (startY === 0 && endY === 0) || offset === null
        ? [0, 0]
        : [Math.ceil(offset - startY), Math.floor(endY - offset)],
    [offset, startY, endY],
  );

  const resize = useCallback(
    (event: MouseEvent) => {
      if (isResizing) {
        setOffset(Math.min(Math.max(event.clientY, minY), maxY));
      }
    },
    [maxY, minY, isResizing],
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return [
    heights,
    {
      startY,
      endY,
      isResizing,
      setOffset: setRelativeOffset,
      startResizing,
      containerRef,
    },
  ];
};
