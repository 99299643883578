import React, { useMemo } from 'react';
import { createClasses, DropdownBase, Icons } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { TimeInterval } from '../../api/building-insights';
import { timeIntervals } from '../../constants/TimeIntervals';
import { useLocaleDateFns } from '../../hooks/date-fns';
import { IntervalSelector, Interval } from '../IntervalSelector';

const classes = createClasses({
  dropdownPopup: {
    width: '320px',
    'div &': {
      maxHeight: 'none',
      overflow: 'visible',
      zIndex: 99999,
      padding: 0,
    },
  },
});

export interface IntervalDropdownProps {
  timeInterval: TimeInterval;
  dateFrom?: string;
  dateTo?: string;
  onChangeInterval: (interval: Interval) => void;
}

export const IntervalDropdown: React.FC<IntervalDropdownProps> = ({
  timeInterval,
  dateFrom,
  dateTo,
  onChangeInterval,
}) => {
  const { t } = useTranslation();
  const { format } = useLocaleDateFns();

  const label = useMemo(() => {
    if (timeInterval !== TimeInterval.customInterval) {
      return timeIntervals(t)[timeInterval];
    }
    return `${format(new Date(dateFrom || ''), 'P HH:mm')} - ${
      dateTo !== undefined
        ? format(new Date(dateTo), 'P HH:mm')
        : t('common.customInterval.latest')
    }`;
  }, [timeInterval, dateFrom, dateTo, t, format]);

  return (
    <DropdownBase
      label={label}
      arrow={false}
      popupClassName={classes.dropdownPopup}
      popupOverflow
      position="right"
      icon={Icons.IconCalendarTime}
      data-testid="calendar"
    >
      {(close: () => void) => (
        <IntervalSelector
          interval={{
            dateFrom: dateFrom ? new Date(dateFrom) : undefined,
            dateUntil: dateTo ? new Date(dateTo) : undefined,
            timeInterval,
          }}
          onChange={onChangeInterval}
          onCancel={close}
        />
      )}
    </DropdownBase>
  );
};
