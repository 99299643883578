import React, { useMemo } from 'react';
import { ListItem, Typography, createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Field, useFormikContext } from 'formik';
import { AggregationIntervals } from '../../../__generated__/types';
import { DropdownField } from '../../../components/formik-fields/DropdownField';
import { ToggleCheckboxField } from '../../../components/formik-fields';
import { TimeInterval } from '../../../api/building-insights';

const classes = createClasses({
  intervalSize: {
    width: '100%',
    marginBottom: '8px',
  },
  fieldContainer: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
});

const makeOptions = (t: TFunction) => [
  { label: t('widgets.intervalSize.None'), value: AggregationIntervals.None },
  {
    label: t('widgets.intervalSize.Ms_500'),
    value: AggregationIntervals.Ms_500,
  },
  { label: t('widgets.intervalSize.S_01'), value: AggregationIntervals.S_01 },
  { label: t('widgets.intervalSize.S_02'), value: AggregationIntervals.S_02 },
  { label: t('widgets.intervalSize.S_03'), value: AggregationIntervals.S_03 },
  { label: t('widgets.intervalSize.S_04'), value: AggregationIntervals.S_04 },
  { label: t('widgets.intervalSize.S_05'), value: AggregationIntervals.S_05 },
  { label: t('widgets.intervalSize.S_06'), value: AggregationIntervals.S_06 },
  { label: t('widgets.intervalSize.S_10'), value: AggregationIntervals.S_10 },
  { label: t('widgets.intervalSize.S_12'), value: AggregationIntervals.S_12 },
  { label: t('widgets.intervalSize.S_15'), value: AggregationIntervals.S_15 },
  { label: t('widgets.intervalSize.S_20'), value: AggregationIntervals.S_20 },
  { label: t('widgets.intervalSize.S_30'), value: AggregationIntervals.S_30 },
  { label: t('widgets.intervalSize.M_01'), value: AggregationIntervals.M_01 },
  { label: t('widgets.intervalSize.M_02'), value: AggregationIntervals.M_02 },
  { label: t('widgets.intervalSize.M_03'), value: AggregationIntervals.M_03 },
  { label: t('widgets.intervalSize.M_04'), value: AggregationIntervals.M_04 },
  { label: t('widgets.intervalSize.M_05'), value: AggregationIntervals.M_05 },
  { label: t('widgets.intervalSize.M_06'), value: AggregationIntervals.M_06 },
  { label: t('widgets.intervalSize.M_10'), value: AggregationIntervals.M_10 },
  { label: t('widgets.intervalSize.M_12'), value: AggregationIntervals.M_12 },
  { label: t('widgets.intervalSize.M_15'), value: AggregationIntervals.M_15 },
  { label: t('widgets.intervalSize.M_20'), value: AggregationIntervals.M_20 },
  { label: t('widgets.intervalSize.M_30'), value: AggregationIntervals.M_30 },
  { label: t('widgets.intervalSize.H_01'), value: AggregationIntervals.H_01 },
  { label: t('widgets.intervalSize.H_02'), value: AggregationIntervals.H_02 },
  { label: t('widgets.intervalSize.H_03'), value: AggregationIntervals.H_03 },
  { label: t('widgets.intervalSize.H_04'), value: AggregationIntervals.H_04 },
  { label: t('widgets.intervalSize.H_06'), value: AggregationIntervals.H_06 },
  { label: t('widgets.intervalSize.H_08'), value: AggregationIntervals.H_08 },
  { label: t('widgets.intervalSize.H_12'), value: AggregationIntervals.H_12 },
  { label: t('widgets.intervalSize.D_01'), value: AggregationIntervals.D_01 },
  { label: t('widgets.intervalSize.D_02'), value: AggregationIntervals.D_02 },
  { label: t('widgets.intervalSize.D_03'), value: AggregationIntervals.D_03 },
  { label: t('widgets.intervalSize.D_04'), value: AggregationIntervals.D_04 },
  { label: t('widgets.intervalSize.D_05'), value: AggregationIntervals.D_05 },
  { label: t('widgets.intervalSize.D_06'), value: AggregationIntervals.D_06 },
  { label: t('widgets.intervalSize.D_07'), value: AggregationIntervals.D_07 },
];

export interface WidgetFormIntervalSizeProps {
  availableIntervals: AggregationIntervals[];
}

export type WidgetFormIntervalSize = {
  timeInterval: TimeInterval;
  intervalSize: AggregationIntervals;
};

export const WidgetFormIntervalSize: React.FC<WidgetFormIntervalSizeProps> = ({
  availableIntervals,
}) => {
  const { t } = useTranslation();
  const options = useMemo(() => makeOptions(t), [t]);
  const { setFieldValue } = useFormikContext<WidgetFormIntervalSize>();

  return (
    <div data-testid="interval-size-container">
      <Field
        name="intervalSize"
        component={DropdownField}
        onChange={(value: any) => {
          setFieldValue('intervalSize', value);
          setFieldValue('intervalSizeDefault', false);
        }}
        label={t('widgets.intervalSize.label')}
        className={classes.intervalSize}
        data-testid="intervalSize-dropdown"
      >
        {options
          .filter(({ value }) => availableIntervals.includes(value))
          .map((option) => (
            <ListItem
              value={option.value}
              title={option.label}
              key={option.value}
            />
          ))}
      </Field>
      <div className={classes.fieldContainer}>
        <Typography variant={'bodyMedium'}>
          {t('widgets.intervalSize.default')}
        </Typography>
        <Field
          name="intervalSizeDefault"
          component={ToggleCheckboxField}
          data-testid="intervalSizeDefault-toggleCheckbox"
        />
      </div>
    </div>
  );
};
