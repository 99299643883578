import { request } from '../utils/request';
import { Response } from '../../hooks/useRequest';
import { url, BuildingEntity } from './common';
import { EntityResponse } from '../common';

export type Building = BuildingEntity;

/**
 * List all favourite buildings
 *
 */
export const getBuildingsFavourite = async (): Promise<
  Response<EntityResponse<Building[]>>
> => {
  return request<EntityResponse<Building[]>>(
    'GET',
    url(`/buildings/favourite`),
  );
};

/**
 * Add building to the favourite list
 *
 * @param buildingId   The building that will be added to favourites
 */
export const postBuildingsFavourite = async (
  buildingId: string,
): Promise<Response<EntityResponse<null>>> => {
  return request<EntityResponse<null>>('POST', url(`/buildings/favourite`), {
    buildingId,
  });
};

/**
 * Delete building from the favourite list
 *
 * @param buildingId   The id of a building that will be deleted from the favourites
 */
export const deleteBuildingsFavourite = async (
  buildingId: string,
): Promise<Response<null>> => {
  return request<null>('DELETE', url(`/buildings/favourite/${buildingId}`));
};
