import React from 'react';
import 'chartjs-adapter-date-fns';
import Lottie, { LottieProps } from 'react-lottie-player';
import { createClasses, TimeScaleDataset } from '@kp/react-ui';
import chartLoadingAnimationData from '../../../../animations/chart-loading.json';
import { ChartType } from '../../../../api/building-insights';
import { Chart } from '../../../../components/Chart';

const classes = createClasses({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

const animationOptions: LottieProps = {
  loop: true,
  play: true,
  animationData: chartLoadingAnimationData,
  style: { width: 64, height: 64 },
};

interface InfoWidgetCardChartProps {
  loading: boolean;
  chartData: TimeScaleDataset[];
  chartType: ChartType;
  collapsed: boolean;
  min?: Date;
  max?: Date;
}

export const InfoWidgetCardChart: React.FC<InfoWidgetCardChartProps> =
  React.memo(({ loading, chartData, chartType, collapsed, min, max }) => (
    <>
      {loading && (
        <div className={classes.loader}>
          <Lottie {...animationOptions} />
        </div>
      )}
      {!loading && (
        <Chart
          autoFit={true}
          datasets={chartData}
          collapsed={collapsed}
          min={min}
          max={max}
          type={chartType === ChartType.bar ? 'bar' : 'line'}
        />
      )}
    </>
  ));

InfoWidgetCardChart.displayName = 'InfoWidgetCardChart';
