import React, { useCallback } from 'react';
import {
  Colors,
  Button,
  Icons,
  Input,
  ToggleButton,
  Typography,
  Dropdown,
  ContextMenu,
  ListItem,
  ContextMenuItem,
  createClasses,
} from '@kp/react-ui';
import slugify from 'slugify';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import { CSVLink } from 'react-csv';
import { ChartType, TimeInterval } from '../../../api/building-insights';
import { ToggleButtonGroupField } from '../../../components/formik-fields';
import { AggregationIntervals } from '../../../__generated__/types';
import { WIDGETS_TOOLBAR_HEIGHT } from '../../../constants/UI';
import { useWidget } from './widget-context';
import { Interval } from '../../../components/IntervalSelector';
import { IntervalDropdown } from '../../../components/IntervalDropdown';

const classes = createClasses({
  root: {
    display: 'flex',
    height: `${WIDGETS_TOOLBAR_HEIGHT}px`,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
    background: Colors.Neutral.background,
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
    flexShrink: 0,
  },
  name: {
    width: '200px',
  },
  actions: {
    display: 'flex',
  },
  popupWrapper: {
    marginRight: '12px',
  },
  popup: {
    width: '320px',
    'div &': {
      maxHeight: 'none',
      overflow: 'visible',
      zIndex: 99999,
      padding: 0,
    },
  },
  action: {
    marginRight: '12px',
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  chartSelector: {
    width: '160px',
  },
  link: {
    textDecoration: 'none',
  },
  dropdown: {
    fontFeatureSettings: "'tnum'",
  },
});

export type WidgetFormToolbarData = {
  name: string;
  timeInterval: TimeInterval;
  dateFrom: Date | undefined;
  dateUntil: Date | undefined;
  behaviour: 'stacked' | 'combined';
  intervalSize: AggregationIntervals;
  showPoints?: boolean;
  comparisonLine?: boolean;
  chartType: ChartType;
};

export interface WidgetFormToolbarProps {
  onSettingsClick?: () => void;
  settingsSelected?: boolean;
  type?: 'edit' | 'create' | 'view';
  csv: Array<Array<string>>;
}

export const WidgetFormToolbar: React.FC<WidgetFormToolbarProps> = ({
  onSettingsClick,
  settingsSelected,
  type,
  csv,
}) => {
  const { t, i18n } = useTranslation();

  const { values, setFieldValue } = useFormikContext<WidgetFormToolbarData>();
  const { setWidgetValues, timeInterval, dateFrom, dateUntil } = useWidget();

  const handleChangeInterval = useCallback(
    (interval: Interval) => {
      if (interval.timeInterval === TimeInterval.customInterval) {
        setWidgetValues({
          timeInterval: interval.timeInterval,
          dateFrom: interval.dateFrom || 'min',
          dateUntil: interval.dateUntil || 'max',
        });
      } else {
        setWidgetValues({ timeInterval: interval.timeInterval });
      }
    },
    [setWidgetValues],
  );

  const handleChangeChartType = useCallback(
    (chartType: string) => {
      setFieldValue('chartType', chartType);
    },
    [setFieldValue],
  );

  return (
    <div className={classes.root}>
      <div className={classes.name}>
        {type === 'view' ? (
          <Typography variant="titleSmall" className={classes.title}>
            {values.name}
          </Typography>
        ) : (
          <Field
            id="name"
            name="name"
            as={Input}
            placeholder={t('widgets.name.default')}
            fullWidth
          />
        )}
      </div>
      <div className={classes.actions}>
        <div className={classes.popupWrapper}>
          <Dropdown
            value={values.chartType}
            onChange={handleChangeChartType}
            className={classes.chartSelector}
            data-testid="chart-type-selector"
          >
            <ListItem
              icon={Icons.IconLineChart2}
              value={ChartType.line}
              title={t('widgets.chartType.line') || ''}
            />
            <ListItem
              icon={Icons.IconBarChart2}
              value={ChartType.bar}
              title={t('widgets.chartType.bar') || ''}
            />
            <ListItem
              icon={Icons.IconTableChart}
              value={ChartType.table}
              title={t('widgets.chartType.table') || ''}
            />
          </Dropdown>
        </div>
        <div className={classes.popupWrapper}>
          <IntervalDropdown
            timeInterval={timeInterval}
            dateFrom={
              dateFrom === 'min'
                ? new Date().toISOString()
                : dateFrom.toISOString()
            }
            dateTo={dateUntil === 'max' ? undefined : dateUntil.toISOString()}
            onChangeInterval={handleChangeInterval}
          />
        </div>
        <Field
          name="behaviour"
          component={ToggleButtonGroupField}
          className={classes.action}
        >
          <ToggleButton
            value="stacked"
            icon={Icons.IconStackedLineChart}
            data-testid="stacked-button"
          />
          <ToggleButton
            value="combined"
            icon={Icons.IconLineChart}
            data-testid="combined-button"
          />
        </Field>
        <div className={classes.action} data-testid="export-actions">
          <ContextMenu
            position="right"
            icon={Icons.IconExport}
            data-testid="export"
          >
            <ContextMenuItem
              className={classes.link}
              icon={Icons.IconCsv}
              fullWidth
              title={t('widgets.export.csv') || ''}
              component={CSVLink}
              data-testid="csv-link"
              data={csv}
              filename={`${slugify(values.name || 'widget', {
                lower: true,
                locale: i18n.language,
              })}.csv`}
            />
          </ContextMenu>
        </div>
        <Button
          variant="secondary"
          data-testid="settings-button"
          selected={settingsSelected}
          onClick={onSettingsClick}
          icon={Icons.IconSettings2}
        />
      </div>
    </div>
  );
};
