import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createClasses, InlineInput } from '@kp/react-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import noop from '@stdlib/utils-noop';
import { DashboardForm, DashboardFormSubmit } from './Dashboard.types';
import { useDashboard } from '../dashboard-context';

const classes = createClasses({
  root: {},
});

interface DashboardNameProps {
  loading?: boolean;
  submit: DashboardFormSubmit;
}

export const DashboardName: React.FC<DashboardNameProps> = ({ submit }) => {
  const [value, setValue] = useState('');
  const { watch, ...form } = useFormContext<DashboardForm>();
  const ref = useRef<HTMLInputElement>(null);
  const name = watch('name', '');
  const { refresh } = useDashboard();
  const { t } = useTranslation();

  useEffect(() => setValue(name || ''), [name]);

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
    const currentName = form.getValues('name');
    if (currentName === value) return;
    form.setValue('name', value);
    submit()
      .then(() => refresh())
      .catch(noop);
  }, [form, value, submit, refresh]);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    ({ key }) => {
      if (key === 'Escape') {
        setValue(name || '');
        setTimeout(() => ref.current?.blur(), 1);
      }
      if (key === 'Enter') {
        ref.current?.blur();
      }
    },
    [name, ref],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setValue(event.target.value),
    [setValue],
  );

  return (
    <InlineInput
      className={classes.root}
      variant="titleLarge"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      placeholder={t('dashboards.template.untitled') || ''}
      ref={ref}
      value={value}
    />
  );
};
