import React, { useCallback } from 'react';
import Lottie, { LottieProps } from 'react-lottie-player';
import {
  Card,
  Colors,
  createClasses,
  TextToggleButton,
  TimeScaleDataset,
  ToggleButtonGroup,
  Typography,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { Chart } from '../Chart';
import chartLoadingAnimationData from '../../animations/chart-loading.json';
import { TimeSpans } from '../../__generated__/types';
import { DeviceModelCapability } from '../../features/devices/Devices.types';

const classes = createClasses({
  root: {
    width: '42%',
    height: '208px',
  },

  body: {
    padding: 0,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    padding: '17px 22px',
    position: 'relative',
  },

  actions: {
    display: 'flex',
    position: 'absolute',
    top: '12px',
    right: '12px',
  },

  chartBody: {
    padding: '0 24px',
  },

  chart: {
    height: '120px',
  },

  chartLabel: {
    color: Colors.Neutral.textWeak,
    paddingLeft: '24px',
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
  },

  colorLine: {
    backgroundColor: 'blue',
    width: '11px',
    borderRadius: '4px',
    margin: '6px 0',
  },

  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

const animationOptions: LottieProps = {
  loop: true,
  play: true,
  animationData: chartLoadingAnimationData,
  style: { width: 64, height: 120 },
};

interface DevicePreviewChartProps {
  loading?: boolean;
  deviceModelCapability?: DeviceModelCapability;
  chartData: TimeScaleDataset[];
  timeSpan: TimeSpans;
  min?: Date;
  max?: Date;
  onChange?: (value: TimeSpans) => void;
}

export const DevicePreviewChart: React.FC<DevicePreviewChartProps> = ({
  loading = false,
  deviceModelCapability,
  chartData,
  timeSpan,
  min,
  max,
  onChange,
}) => {
  const { t } = useTranslation();

  /* eslint consistent-return: off */
  const handleChange = useCallback(
    (value: string) => {
      if (!onChange) {
        return;
      }
      switch (value) {
        case TimeSpans.Last_24Hour:
          return onChange(TimeSpans.Last_24Hour);
        case TimeSpans.Last_7Day:
          return onChange(TimeSpans.Last_7Day);
        case TimeSpans.Last_30Day:
          return onChange(TimeSpans.Last_30Day);
        default:
          return onChange(TimeSpans.Last_24Hour);
      }
    },
    [onChange],
  );

  return (
    <Card
      className={classes.root}
      bodyClassName={classes.body}
      data-testid={'preview-chart'}
    >
      <div className={classes.header}>
        <Typography variant="labelMedium" component="div">
          {t('device.preview.history')}
        </Typography>

        <div className={classes.actions}>
          <ToggleButtonGroup
            value={timeSpan}
            onChange={handleChange}
            type="text"
          >
            <TextToggleButton
              text="1D"
              value={TimeSpans.Last_24Hour}
              data-testid={'chart-1d'}
            />
            <TextToggleButton
              text="1W"
              value={TimeSpans.Last_7Day}
              data-testid={'chart-1w'}
            />
            <TextToggleButton
              text="1M"
              value={TimeSpans.Last_30Day}
              data-testid={'chart-1m'}
            />
          </ToggleButtonGroup>
        </div>
      </div>
      <div className={classes.chartBody}>
        {loading ? (
          <div className={classes.loader}>
            <Lottie {...animationOptions} />
          </div>
        ) : (
          <Chart
            autoFit
            min={min}
            max={max}
            className={classes.chart}
            datasets={chartData}
            collapsed={true}
            size="small"
            type="line"
          />
        )}
      </div>
      <div className={classes.chartLabel}>
        {deviceModelCapability && (
          <>
            <div className={classes.colorLine} />
            <Typography variant="bodySmall" component="div">
              {deviceModelCapability.capability.name}{' '}
              {deviceModelCapability.unit.unitSymbol}
            </Typography>
          </>
        )}
      </div>
    </Card>
  );
};
