import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  UUID: string;
};

export enum AggregationIntervals {
  D_01 = 'D_01',
  D_02 = 'D_02',
  D_03 = 'D_03',
  D_04 = 'D_04',
  D_05 = 'D_05',
  D_06 = 'D_06',
  D_07 = 'D_07',
  H_01 = 'H_01',
  H_02 = 'H_02',
  H_03 = 'H_03',
  H_04 = 'H_04',
  H_06 = 'H_06',
  H_08 = 'H_08',
  H_12 = 'H_12',
  Ms_500 = 'MS_500',
  M_01 = 'M_01',
  M_02 = 'M_02',
  M_03 = 'M_03',
  M_04 = 'M_04',
  M_05 = 'M_05',
  M_06 = 'M_06',
  M_10 = 'M_10',
  M_12 = 'M_12',
  M_15 = 'M_15',
  M_20 = 'M_20',
  M_30 = 'M_30',
  None = 'NONE',
  S_01 = 'S_01',
  S_02 = 'S_02',
  S_03 = 'S_03',
  S_04 = 'S_04',
  S_05 = 'S_05',
  S_06 = 'S_06',
  S_10 = 'S_10',
  S_12 = 'S_12',
  S_15 = 'S_15',
  S_20 = 'S_20',
  S_30 = 'S_30'
}

export enum AggregationTypes {
  Avg = 'AVG',
  Sum = 'SUM'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Attribute = {
  __typename?: 'Attribute';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};


export type AttributeDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


export type AttributeMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type AttributeCreateType = {
  dataType: DataTypes;
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  required?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeDefinition = {
  __typename?: 'AttributeDefinition';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
};

export type AttributeDefinitionFilterInput = {
  and?: InputMaybe<Array<AttributeDefinitionFilterInput>>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AttributeDefinitionFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
};

export type AttributeFilterInput = {
  and?: InputMaybe<Array<AttributeFilterInput>>;
  attributeId?: InputMaybe<UuidOperationFilterInput>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AttributeFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type AttributeIdAttributeValueInputType = {
  attributeId?: InputMaybe<Scalars['UUID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type AttributeSetNullInputType = {
  dataType?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  deviceAttributeValues?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeSortInput = {
  attributeId?: InputMaybe<SortEnumType>;
  dataType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  required?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type AttributeUpdateType = {
  dataType?: InputMaybe<DataTypes>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeWithValue = {
  __typename?: 'AttributeWithValue';
  attribute?: Maybe<Attribute>;
  value?: Maybe<Scalars['String']>;
};

export type AttributesByBuildings = {
  __typename?: 'AttributesByBuildings';
  buildingId?: Maybe<Scalars['UUID']>;
  deviceAttributes?: Maybe<Array<AttributeDefinition>>;
};

/** A segment of a collection. */
export type AttributesByBuildingsCollectionSegment = {
  __typename?: 'AttributesByBuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<AttributesByBuildings>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AttributesByBuildingsFilterInput = {
  and?: InputMaybe<Array<AttributesByBuildingsFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceAttributes?: InputMaybe<ListFilterInputTypeOfAttributeDefinitionFilterInput>;
  or?: InputMaybe<Array<AttributesByBuildingsFilterInput>>;
};

export type AttributesByBuildingsSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AttributesCollectionSegment = {
  __typename?: 'AttributesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Attribute>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AuditLog = {
  __typename?: 'AuditLog';
  action?: Maybe<Scalars['String']>;
  changeDate: Scalars['DateTime'];
  entity?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  service?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
};

export type AuditLogCreateType = {
  action?: InputMaybe<Scalars['String']>;
  changeDate: Scalars['DateTime'];
  entity?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

export type AuditLogFilterInput = {
  action?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AuditLogFilterInput>>;
  auditLogId?: InputMaybe<UuidOperationFilterInput>;
  changeDate?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<StringOperationFilterInput>;
  entityType?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AuditLogFilterInput>>;
  service?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  userId?: InputMaybe<UuidOperationFilterInput>;
};

export type AuditLogSortInput = {
  action?: InputMaybe<SortEnumType>;
  auditLogId?: InputMaybe<SortEnumType>;
  changeDate?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<SortEnumType>;
  entityType?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  service?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AuditLogsCollectionSegment = {
  __typename?: 'AuditLogsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<AuditLog>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

/** I am a generated type! */
export type Building = {
  __typename?: 'Building';
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  mappingDeviceBuildings?: Maybe<Array<Maybe<MappingDeviceBuilding>>>;
  name: Scalars['String'];
  site: Site;
  siteId: Scalars['UUID'];
  storeys: Array<Storey>;
  tenantId?: Maybe<Scalars['UUID']>;
  weather?: Maybe<Weather>;
};


/** I am a generated type! */
export type BuildingStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  where?: InputMaybe<StoreyFilterInput>;
};

export type BuildingCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  siteId: Scalars['UUID'];
};

export type BuildingFilterInput = {
  and?: InputMaybe<Array<BuildingFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimension?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  mappingDeviceBuildings?: InputMaybe<ListFilterInputTypeOfMappingDeviceBuildingFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BuildingFilterInput>>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  storeys?: InputMaybe<ListFilterInputTypeOfStoreyFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  weather?: InputMaybe<WeatherFilterInput>;
};

/** A segment of a collection. */
export type BuildingFilterTableCollectionSegment = {
  __typename?: 'BuildingFilterTableCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PlacementFilterRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type BuildingSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  dimension?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  site?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type BuildingSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  weather?: InputMaybe<WeatherSortInput>;
};

export type BuildingUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type BuildingsCollectionSegment = {
  __typename?: 'BuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Building>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type CapabilitiesCollectionSegment = {
  __typename?: 'CapabilitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Capability>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Capability = {
  __typename?: 'Capability';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type CapabilityDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type CapabilityCreateType = {
  dataType: DataTypes;
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type CapabilityFilterInput = {
  and?: InputMaybe<Array<CapabilityFilterInput>>;
  capabilityId?: InputMaybe<UuidOperationFilterInput>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CapabilityFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type CapabilitySetNullInputType = {
  dataType?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type CapabilitySortInput = {
  capabilityId?: InputMaybe<SortEnumType>;
  dataType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type CapabilityUpdateType = {
  dataType?: InputMaybe<DataTypes>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export enum ConnectionStates {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  NeverConnected = 'NEVER_CONNECTED',
  Unknown = 'UNKNOWN'
}

export type ConnectionStatesOperationFilterInput = {
  eq?: InputMaybe<ConnectionStates>;
  in?: InputMaybe<Array<ConnectionStates>>;
  neq?: InputMaybe<ConnectionStates>;
  nin?: InputMaybe<Array<ConnectionStates>>;
};

export type DataPoint = {
  __typename?: 'DataPoint';
  device: Device;
  deviceId?: Maybe<Scalars['UUID']>;
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId?: Maybe<Scalars['UUID']>;
  tenantId?: Maybe<Scalars['UUID']>;
  utcTimeMeasured?: Maybe<Scalars['DateTime']>;
  valueString?: Maybe<Scalars['String']>;
};

export type DataPointAggregated = {
  __typename?: 'DataPointAggregated';
  avg_value?: Maybe<Scalars['Float']>;
  count_values?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  device_id?: Maybe<Scalars['Int']>;
  device_model_capability_id?: Maybe<Scalars['Int']>;
  first_value?: Maybe<Scalars['Float']>;
  hour?: Maybe<Scalars['Int']>;
  last_value?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export enum DataTypes {
  Bool = 'BOOL',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Double = 'DOUBLE',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  NotSpecified = 'NOT_SPECIFIED',
  String = 'STRING'
}

export type DataTypesOperationFilterInput = {
  eq?: InputMaybe<DataTypes>;
  in?: InputMaybe<Array<DataTypes>>;
  neq?: InputMaybe<DataTypes>;
  nin?: InputMaybe<Array<DataTypes>>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export enum DeleteE2ETestDataResult {
  Failed = 'FAILED',
  Successful = 'SUCCESSFUL'
}

export type DeleteSensorMeasurementsResponse = {
  __typename?: 'DeleteSensorMeasurementsResponse';
  numAffected: Scalars['Int'];
};

export enum DemoSiteType {
  Cube = 'CUBE',
  Dstrct = 'DSTRCT',
  Edge = 'EDGE',
  KpHeadquarters = 'KP_HEADQUARTERS',
  Ship = 'SHIP'
}

/** I am a generated type! */
export type Device = {
  __typename?: 'Device';
  connectionState: ConnectionStates;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModel: DeviceModel;
  deviceModelId: Scalars['UUID'];
  firstConnected?: Maybe<Scalars['DateTime']>;
  gatewayDeviceConnections: Array<GatewayDeviceConnection>;
  heartbeatState?: Maybe<HeartbeatStates>;
  id: Scalars['UUID'];
  inverseParentDevice: Array<Device>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  lastConnected?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  parentDevice?: Maybe<Device>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type DeviceDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


/** I am a generated type! */
export type DeviceGatewayDeviceConnectionsArgs = {
  order?: InputMaybe<Array<GatewayDeviceConnectionSortInput>>;
  where?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};


/** I am a generated type! */
export type DeviceInverseParentDeviceArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type DeviceAndCapabilityInfo = {
  __typename?: 'DeviceAndCapabilityInfo';
  device: Device;
  deviceId: Scalars['UUID'];
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId: Scalars['UUID'];
  telemetryRecords?: Maybe<Array<Maybe<TelemetryRecord>>>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type DeviceAttributeValue = {
  __typename?: 'DeviceAttributeValue';
  attribute: Attribute;
  attributeId: Scalars['UUID'];
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
  value?: Maybe<Scalars['String']>;
};

export type DeviceAttributeValueCreateType = {
  attributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceAttributeValueFilterInput = {
  and?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  attribute?: InputMaybe<AttributeFilterInput>;
  attributeId?: InputMaybe<UuidOperationFilterInput>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceAttributeValueId?: InputMaybe<UuidOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type DeviceAttributeValueSetNullInputType = {
  attribute?: InputMaybe<Scalars['Boolean']>;
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceAttributeValueSortInput = {
  attribute?: InputMaybe<AttributeSortInput>;
  attributeId?: InputMaybe<SortEnumType>;
  device?: InputMaybe<DeviceSortInput>;
  deviceAttributeValueId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type DeviceAttributeValueUpdateType = {
  attributeId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  value?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceAttributeValuesCollectionSegment = {
  __typename?: 'DeviceAttributeValuesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceAttributeValue>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceCreate = {
  __typename?: 'DeviceCreate';
  description?: Maybe<Scalars['String']>;
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModel: DeviceModel;
  deviceModelId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
};

export type DeviceCreateAttributeValueType = {
  attributeId: Scalars['UUID'];
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceCreateInputType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  deviceIdentifier?: InputMaybe<Scalars['String']>;
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentDeviceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  deviceIdentifier?: InputMaybe<Scalars['String']>;
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentDeviceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceFilterInput = {
  and?: InputMaybe<Array<DeviceFilterInput>>;
  connectionState?: InputMaybe<ConnectionStatesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  deviceIdentifier?: InputMaybe<StringOperationFilterInput>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  firstConnected?: InputMaybe<DateTimeOperationFilterInput>;
  gatewayDeviceConnections?: InputMaybe<ListFilterInputTypeOfGatewayDeviceConnectionFilterInput>;
  heartbeatState?: InputMaybe<NullableOfHeartbeatStatesOperationFilterInput>;
  inverseParentDevice?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastConnected?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceFilterInput>>;
  parentDevice?: InputMaybe<DeviceFilterInput>;
  parentDeviceId?: InputMaybe<UuidOperationFilterInput>;
  serialNo?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceMappingReturnType = {
  __typename?: 'DeviceMappingReturnType';
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  placementId: Scalars['UUID'];
};

export type DeviceMessage = {
  __typename?: 'DeviceMessage';
  connectionState: ConnectionStates;
  description?: Maybe<Scalars['String']>;
  deviceId: Scalars['UUID'];
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModelId?: Maybe<Scalars['UUID']>;
  firstConnected?: Maybe<Scalars['DateTime']>;
  heartbeatState?: Maybe<HeartbeatStates>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastConnected?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type DeviceModel = {
  __typename?: 'DeviceModel';
  dataMeasurementTimestampFieldSelector?: Maybe<Scalars['String']>;
  dataMeasurementTimestampFormat?: Maybe<Scalars['String']>;
  deviceIdentifierFieldSelector?: Maybe<Scalars['String']>;
  deviceModelAttributeSets: Array<DeviceModelAttributeSet>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  deviceType: DeviceType;
  deviceTypeId: Scalars['UUID'];
  devices: Array<Device>;
  gatewayReceivedDataTimestampFieldSelector?: Maybe<Scalars['String']>;
  gatewayReceivedDataTimestampFormat?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type DeviceModelDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


export type DeviceModelDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};


export type DeviceModelDevicesArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type DeviceModelAttributeSet = {
  __typename?: 'DeviceModelAttributeSet';
  deviceModel: DeviceModel;
  deviceModelId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type DeviceModelAttributeSetMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type DeviceModelAttributeSetCreateType = {
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceModelAttributeSetSetNullInputType = {
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelAttributeSetSortInput = {
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceModelAttributeSetUpdateType = {
  deviceModelId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceModelAttributeSetsCollectionSegment = {
  __typename?: 'DeviceModelAttributeSetsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceModelAttributeSet>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type DeviceModelCapabilitiesCollectionSegment = {
  __typename?: 'DeviceModelCapabilitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceModelCapability>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceModelCapability = {
  __typename?: 'DeviceModelCapability';
  capability: Capability;
  capabilityId: Scalars['UUID'];
  deviceModel: DeviceModel;
  deviceModelId: Scalars['UUID'];
  fieldSelector?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  technicalMax?: Maybe<Scalars['Float']>;
  technicalMin?: Maybe<Scalars['Float']>;
  tenantId?: Maybe<Scalars['UUID']>;
  unit: Unit;
  unitId: Scalars['UUID'];
};

export type DeviceModelCapabilityCreateType = {
  capabilityId: Scalars['UUID'];
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  fieldSelector?: InputMaybe<Scalars['String']>;
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId: Scalars['UUID'];
};

export type DeviceModelCapabilityFilterInput = {
  and?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  capability?: InputMaybe<CapabilityFilterInput>;
  capabilityId?: InputMaybe<UuidOperationFilterInput>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelCapabilityId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  fieldSelector?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  technicalMax?: InputMaybe<FloatOperationFilterInput>;
  technicalMin?: InputMaybe<FloatOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  unit?: InputMaybe<UnitFilterInput>;
  unitId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceModelCapabilityIdsInput = {
  deviceModelCapabilityId?: InputMaybe<Scalars['UUID']>;
};

export type DeviceModelCapabilitySetNullInputType = {
  capability?: InputMaybe<Scalars['Boolean']>;
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  fieldSelector?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Boolean']>;
  technicalMin?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelCapabilitySortInput = {
  capability?: InputMaybe<CapabilitySortInput>;
  capabilityId?: InputMaybe<SortEnumType>;
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelCapabilityId?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  fieldSelector?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  technicalMax?: InputMaybe<SortEnumType>;
  technicalMin?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<UnitSortInput>;
  unitId?: InputMaybe<SortEnumType>;
};

export type DeviceModelCapabilityUpdateType = {
  capabilityId?: InputMaybe<Scalars['UUID']>;
  deviceModelId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  fieldSelector?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['UUID']>;
};

export type DeviceModelCreateType = {
  /** MinLength=0, MaxLength=50 */
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  deviceIdentifierFieldSelector?: InputMaybe<Scalars['String']>;
  deviceTypeId: Scalars['UUID'];
  /** MinLength=0, MaxLength=50 */
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceModelFilterInput = {
  and?: InputMaybe<Array<DeviceModelFilterInput>>;
  dataMeasurementTimestampFieldSelector?: InputMaybe<StringOperationFilterInput>;
  dataMeasurementTimestampFormat?: InputMaybe<StringOperationFilterInput>;
  deviceIdentifierFieldSelector?: InputMaybe<StringOperationFilterInput>;
  deviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfDeviceModelAttributeSetFilterInput>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  deviceType?: InputMaybe<DeviceTypeFilterInput>;
  deviceTypeId?: InputMaybe<UuidOperationFilterInput>;
  devices?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<StringOperationFilterInput>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceModelSetNullInputType = {
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['Boolean']>;
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['Boolean']>;
  deviceIdentifierFieldSelector?: InputMaybe<Scalars['Boolean']>;
  deviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  deviceType?: InputMaybe<Scalars['Boolean']>;
  devices?: InputMaybe<Scalars['Boolean']>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['Boolean']>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelSortInput = {
  dataMeasurementTimestampFieldSelector?: InputMaybe<SortEnumType>;
  dataMeasurementTimestampFormat?: InputMaybe<SortEnumType>;
  deviceIdentifierFieldSelector?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  deviceType?: InputMaybe<DeviceTypeSortInput>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<SortEnumType>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceModelUpdateType = {
  /** MinLength=0, MaxLength=50 */
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  deviceIdentifierFieldSelector?: InputMaybe<Scalars['String']>;
  deviceTypeId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceModelsCollectionSegment = {
  __typename?: 'DeviceModelsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceModel>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  deviceAttributeValues?: InputMaybe<Scalars['Boolean']>;
  deviceIdentifier?: InputMaybe<Scalars['Boolean']>;
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  gatewayDeviceConnections?: InputMaybe<Scalars['Boolean']>;
  inverseParentDevice?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  parentDevice?: InputMaybe<Scalars['Boolean']>;
  parentDeviceId?: InputMaybe<Scalars['Boolean']>;
  serialNo?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceSortInput = {
  connectionState?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceIdentifier?: InputMaybe<SortEnumType>;
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelId?: InputMaybe<SortEnumType>;
  firstConnected?: InputMaybe<SortEnumType>;
  heartbeatState?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastConnected?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentDevice?: InputMaybe<DeviceSortInput>;
  parentDeviceId?: InputMaybe<SortEnumType>;
  serialNo?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  deviceModels: Array<DeviceModel>;
  id: Scalars['UUID'];
  isGateway?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type DeviceTypeDeviceModelsArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  where?: InputMaybe<DeviceModelFilterInput>;
};

export type DeviceTypeCreateType = {
  isGateway?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceTypeFilterInput = {
  and?: InputMaybe<Array<DeviceTypeFilterInput>>;
  deviceModels?: InputMaybe<ListFilterInputTypeOfDeviceModelFilterInput>;
  deviceTypeId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGateway?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceTypeFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceTypeSetNullInputType = {
  deviceModels?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isGateway?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTypeSortInput = {
  deviceTypeId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGateway?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceTypeUpdateType = {
  id: Scalars['UUID'];
  isGateway?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceTypesCollectionSegment = {
  __typename?: 'DeviceTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceWithAttributeValues = {
  __typename?: 'DeviceWithAttributeValues';
  attributeValues?: Maybe<Array<Maybe<AttributeWithValue>>>;
  connectionState: ConnectionStates;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues?: Maybe<Array<Maybe<DeviceAttributeValue>>>;
  deviceId: Scalars['UUID'];
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<DeviceModel>;
  deviceModelId: Scalars['UUID'];
  firstConnected?: Maybe<Scalars['DateTime']>;
  gatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  heartbeatState?: Maybe<HeartbeatStates>;
  inverseParentDevice?: Maybe<Array<Maybe<Device>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastConnected?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  parentDevice?: Maybe<Device>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type DevicesByBuilding = {
  __typename?: 'DevicesByBuilding';
  building: Building;
  buildingId?: Maybe<Scalars['UUID']>;
  device: Device;
  deviceAttributeValues?: Maybe<Array<ValueEntity>>;
  deviceDescription?: Maybe<Scalars['String']>;
  deviceDeviceIdentifier?: Maybe<Scalars['String']>;
  deviceGatewayName?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['UUID']>;
  deviceModelName?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceSerialNumber?: Maybe<Scalars['String']>;
  deviceTypeId?: Maybe<Scalars['UUID']>;
  deviceTypeName?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['UUID']>;
  storeyId?: Maybe<Scalars['UUID']>;
  zoneId?: Maybe<Scalars['UUID']>;
};

export type DevicesByBuildingFilterInput = {
  and?: InputMaybe<Array<DevicesByBuildingFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfValueEntityFilterInput>;
  deviceDescription?: InputMaybe<StringOperationFilterInput>;
  deviceDeviceIdentifier?: InputMaybe<StringOperationFilterInput>;
  deviceGatewayName?: InputMaybe<StringOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelName?: InputMaybe<StringOperationFilterInput>;
  deviceName?: InputMaybe<StringOperationFilterInput>;
  deviceSerialNumber?: InputMaybe<StringOperationFilterInput>;
  deviceTypeId?: InputMaybe<UuidOperationFilterInput>;
  deviceTypeName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DevicesByBuildingFilterInput>>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type DevicesByBuildingSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  deviceDescription?: InputMaybe<SortEnumType>;
  deviceDeviceIdentifier?: InputMaybe<SortEnumType>;
  deviceGatewayName?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceModelName?: InputMaybe<SortEnumType>;
  deviceName?: InputMaybe<SortEnumType>;
  deviceSerialNumber?: InputMaybe<SortEnumType>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  deviceTypeName?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type DevicesByBuildingsCollectionSegment = {
  __typename?: 'DevicesByBuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DevicesByBuilding>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type DevicesCollectionSegment = {
  __typename?: 'DevicesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Device>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type GatewayDeviceConnection = {
  __typename?: 'GatewayDeviceConnection';
  device: Device;
  deviceId: Scalars['UUID'];
  host: Scalars['String'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  port: Scalars['Int'];
  primaryAuthenticationKey: Scalars['String'];
  primaryConnectionString?: Maybe<Scalars['String']>;
  secondaryAuthenticationKey: Scalars['String'];
  secondaryConnectionString?: Maybe<Scalars['String']>;
  sharedAccessSignatureExpiry?: Maybe<Scalars['DateTime']>;
  tenantId?: Maybe<Scalars['UUID']>;
  user: Scalars['String'];
};

export type GatewayDeviceConnectionCreateType = {
  deviceId: Scalars['UUID'];
  /** MinLength=0, MaxLength=100 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  primaryAuthenticationKey: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryAuthenticationKey: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
};

export type GatewayDeviceConnectionFilterInput = {
  and?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  gatewayDeviceConnectionId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  primaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  primaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  secondaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  secondaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  sharedAccessSignatureExpiry?: InputMaybe<DateTimeOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type GatewayDeviceConnectionSetNullInputType = {
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  primaryConnectionString?: InputMaybe<Scalars['Boolean']>;
  secondaryConnectionString?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type GatewayDeviceConnectionSortInput = {
  device?: InputMaybe<DeviceSortInput>;
  deviceId?: InputMaybe<SortEnumType>;
  gatewayDeviceConnectionId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  primaryAuthenticationKey?: InputMaybe<SortEnumType>;
  primaryConnectionString?: InputMaybe<SortEnumType>;
  secondaryAuthenticationKey?: InputMaybe<SortEnumType>;
  secondaryConnectionString?: InputMaybe<SortEnumType>;
  sharedAccessSignatureExpiry?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type GatewayDeviceConnectionUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=100 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  primaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type GatewayDeviceConnectionsCollectionSegment = {
  __typename?: 'GatewayDeviceConnectionsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<GatewayDeviceConnection>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type GatewayProperties = IGateway_Gateway_Properties & {
  __typename?: 'GatewayProperties';
  firmwareVersion?: Maybe<Scalars['String']>;
};

export type Gateway_Gateway_Gateway = {
  __typename?: 'Gateway_Gateway_Gateway';
  id: Scalars['String'];
  properties?: Maybe<IGateway_Gateway_Properties>;
  status?: Maybe<Scalars['String']>;
};

export type Gateway_Gateway_Properties_GatewayProperties = IGateway_Gateway_Properties & {
  __typename?: 'Gateway_Gateway_Properties_GatewayProperties';
  firmwareVersion?: Maybe<Scalars['String']>;
};

export enum HeartbeatStates {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Unknown = 'UNKNOWN'
}

export type IGateway_Gateway_Properties = {
  firmwareVersion?: Maybe<Scalars['String']>;
};

/** I am a generated type! */
export type Image = {
  __typename?: 'Image';
  buildings: Array<Building>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingDeviceImages: Array<MappingDeviceImage>;
  name: Scalars['String'];
  sites: Array<Site>;
  storeys: Array<Storey>;
  tenantId?: Maybe<Scalars['UUID']>;
  url?: Maybe<Scalars['String']>;
  zones: Array<Zone>;
};


/** I am a generated type! */
export type ImageBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type ImageMappingDeviceImagesArgs = {
  order?: InputMaybe<Array<MappingDeviceImageSortInput>>;
  where?: InputMaybe<MappingDeviceImageFilterInput>;
};


/** I am a generated type! */
export type ImageSitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  where?: InputMaybe<SiteFilterInput>;
};


/** I am a generated type! */
export type ImageStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  where?: InputMaybe<StoreyFilterInput>;
};


/** I am a generated type! */
export type ImageZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type ImageCreateType = {
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=500 */
  url?: InputMaybe<Scalars['String']>;
};

export type ImageFilterInput = {
  and?: InputMaybe<Array<ImageFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceImages?: InputMaybe<ListFilterInputTypeOfMappingDeviceImageFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ImageFilterInput>>;
  sites?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  storeys?: InputMaybe<ListFilterInputTypeOfStoreyFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
  zones?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
};

export type ImageSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceImages?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['Boolean']>;
  zones?: InputMaybe<Scalars['Boolean']>;
};

export type ImageSortInput = {
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  url?: InputMaybe<SortEnumType>;
};

export type ImageUpdateType = {
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  url?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type ImagesCollectionSegment = {
  __typename?: 'ImagesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Image>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ListFilterInputTypeOfAttributeDefinitionFilterInput = {
  all?: InputMaybe<AttributeDefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AttributeDefinitionFilterInput>;
  some?: InputMaybe<AttributeDefinitionFilterInput>;
};

export type ListFilterInputTypeOfBuildingFilterInput = {
  all?: InputMaybe<BuildingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BuildingFilterInput>;
  some?: InputMaybe<BuildingFilterInput>;
};

export type ListFilterInputTypeOfDeviceAttributeValueFilterInput = {
  all?: InputMaybe<DeviceAttributeValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceAttributeValueFilterInput>;
  some?: InputMaybe<DeviceAttributeValueFilterInput>;
};

export type ListFilterInputTypeOfDeviceFilterInput = {
  all?: InputMaybe<DeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceFilterInput>;
  some?: InputMaybe<DeviceFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelCapabilityFilterInput = {
  all?: InputMaybe<DeviceModelCapabilityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelCapabilityFilterInput>;
  some?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelFilterInput = {
  all?: InputMaybe<DeviceModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelFilterInput>;
  some?: InputMaybe<DeviceModelFilterInput>;
};

export type ListFilterInputTypeOfGatewayDeviceConnectionFilterInput = {
  all?: InputMaybe<GatewayDeviceConnectionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GatewayDeviceConnectionFilterInput>;
  some?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};

export type ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceBuildingFilterInput = {
  all?: InputMaybe<MappingDeviceBuildingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceBuildingFilterInput>;
  some?: InputMaybe<MappingDeviceBuildingFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceImageFilterInput = {
  all?: InputMaybe<MappingDeviceImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceImageFilterInput>;
  some?: InputMaybe<MappingDeviceImageFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceZoneFilterInput = {
  all?: InputMaybe<MappingDeviceZoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceZoneFilterInput>;
  some?: InputMaybe<MappingDeviceZoneFilterInput>;
};

export type ListFilterInputTypeOfSiteFilterInput = {
  all?: InputMaybe<SiteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SiteFilterInput>;
  some?: InputMaybe<SiteFilterInput>;
};

export type ListFilterInputTypeOfStoreyFilterInput = {
  all?: InputMaybe<StoreyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StoreyFilterInput>;
  some?: InputMaybe<StoreyFilterInput>;
};

export type ListFilterInputTypeOfValueEntityFilterInput = {
  all?: InputMaybe<ValueEntityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ValueEntityFilterInput>;
  some?: InputMaybe<ValueEntityFilterInput>;
};

export type ListFilterInputTypeOfZoneFilterInput = {
  all?: InputMaybe<ZoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ZoneFilterInput>;
  some?: InputMaybe<ZoneFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

/** I am a generated type! */
export type Location = {
  __typename?: 'Location';
  buildings: Array<Building>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  no?: Maybe<Scalars['String']>;
  sites: Array<Site>;
  street?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  zip?: Maybe<Scalars['String']>;
};


/** I am a generated type! */
export type LocationBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type LocationSitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  where?: InputMaybe<SiteFilterInput>;
};

export type LocationCreateType = {
  /** MinLength=0, MaxLength=250 */
  city?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  country?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  county?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  no?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  street?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  zip?: InputMaybe<Scalars['String']>;
};

export type LocationFilterInput = {
  and?: InputMaybe<Array<LocationFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  county?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lat?: InputMaybe<FloatOperationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  lon?: InputMaybe<FloatOperationFilterInput>;
  no?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationFilterInput>>;
  sites?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  street?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type LocationSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['Boolean']>;
  county?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  no?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<Scalars['Boolean']>;
  street?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zip?: InputMaybe<Scalars['Boolean']>;
};

export type LocationSortInput = {
  city?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  county?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lat?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  lon?: InputMaybe<SortEnumType>;
  no?: InputMaybe<SortEnumType>;
  street?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

export type LocationUpdateType = {
  /** MinLength=0, MaxLength=250 */
  city?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  country?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  county?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=50 */
  no?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  street?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  zip?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type LocationsCollectionSegment = {
  __typename?: 'LocationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Location>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type MappingAttributeDeviceModelAttributeSet = {
  __typename?: 'MappingAttributeDeviceModelAttributeSet';
  attribute: Attribute;
  attributeId: Scalars['UUID'];
  deviceModelAttributeSet: DeviceModelAttributeSet;
  deviceModelAttributeSetId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type MappingAttributeDeviceModelAttributeSetCreateType = {
  attributeId: Scalars['UUID'];
  deviceModelAttributeSetId: Scalars['UUID'];
};

export type MappingAttributeDeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  attribute?: InputMaybe<AttributeFilterInput>;
  attributeId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingAttributeDeviceModelAttributeSetSetNullInputType = {
  attribute?: InputMaybe<Scalars['Boolean']>;
  deviceModelAttributeSet?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAttributeDeviceModelAttributeSetSortInput = {
  attribute?: InputMaybe<AttributeSortInput>;
  attributeId?: InputMaybe<SortEnumType>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingAttributeDeviceModelAttributeSetUpdateType = {
  attributeId?: InputMaybe<Scalars['UUID']>;
  deviceModelAttributeSetId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};

/** A segment of a collection. */
export type MappingAttributeDeviceModelAttributeSetsCollectionSegment = {
  __typename?: 'MappingAttributeDeviceModelAttributeSetsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingAttributeDeviceModelAttributeSet>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** I am a generated type! */
export type MappingDeviceBuilding = {
  __typename?: 'MappingDeviceBuilding';
  building: Building;
  buildingId: Scalars['UUID'];
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type MappingDeviceBuildingCreateType = {
  buildingId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
};

export type MappingDeviceBuildingFilterInput = {
  and?: InputMaybe<Array<MappingDeviceBuildingFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceBuildingId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceBuildingFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingDeviceBuildingSetNullInputType = {
  building?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceBuildingSortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceBuildingId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceBuildingUpdateType = {
  buildingId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};

/** A segment of a collection. */
export type MappingDeviceBuildingsCollectionSegment = {
  __typename?: 'MappingDeviceBuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingDeviceBuilding>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** I am a generated type! */
export type MappingDeviceImage = {
  __typename?: 'MappingDeviceImage';
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  image: Image;
  imageId: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type MappingDeviceImageCreateType = {
  deviceId: Scalars['UUID'];
  imageId: Scalars['UUID'];
};

export type MappingDeviceImageFilterInput = {
  and?: InputMaybe<Array<MappingDeviceImageFilterInput>>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceImageId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceImageFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingDeviceImageSetNullInputType = {
  image?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceImageSortInput = {
  deviceId?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceImageId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceImageUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type MappingDeviceImagesCollectionSegment = {
  __typename?: 'MappingDeviceImagesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingDeviceImage>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** I am a generated type! */
export type MappingDeviceZone = {
  __typename?: 'MappingDeviceZone';
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
  zone: Zone;
  zoneId: Scalars['UUID'];
};

export type MappingDeviceZoneCreateType = {
  deviceId: Scalars['UUID'];
  zoneId: Scalars['UUID'];
};

export type MappingDeviceZoneFilterInput = {
  and?: InputMaybe<Array<MappingDeviceZoneFilterInput>>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceZoneId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceZoneFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zone?: InputMaybe<ZoneFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingDeviceZoneSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zone?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceZoneSortInput = {
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceZoneId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zone?: InputMaybe<ZoneSortInput>;
  zoneId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceZoneUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  zoneId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type MappingDeviceZonesCollectionSegment = {
  __typename?: 'MappingDeviceZonesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingDeviceZone>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkProvisionIoTHubDevices: Scalars['String'];
  cleanupGatewayDevicesFromIoTHub: Scalars['String'];
  createAttribute?: Maybe<Attribute>;
  createAttributes: Array<Maybe<Attribute>>;
  createAuditLog?: Maybe<AuditLog>;
  createAuditLogs: Array<Maybe<AuditLog>>;
  createBuilding?: Maybe<Building>;
  createBuildings: Array<Maybe<Building>>;
  createCapabilities: Array<Maybe<Capability>>;
  createCapability?: Maybe<Capability>;
  createDeviceModel?: Maybe<DeviceModel>;
  createDeviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  createDeviceModelAttributeSets: Array<Maybe<DeviceModelAttributeSet>>;
  createDeviceModelCapabilities: Array<Maybe<DeviceModelCapability>>;
  createDeviceModelCapability?: Maybe<DeviceModelCapability>;
  createDeviceModels: Array<Maybe<DeviceModel>>;
  createDeviceType?: Maybe<DeviceType>;
  createDeviceTypes: Array<Maybe<DeviceType>>;
  createDeviceWithPlacement: DeviceCreate;
  createGatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  createGatewayDeviceConnections: Array<Maybe<GatewayDeviceConnection>>;
  createImage?: Maybe<Image>;
  createImages: Array<Maybe<Image>>;
  createLocation?: Maybe<Location>;
  createLocations: Array<Maybe<Location>>;
  createMappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  createMappingAttributeDeviceModelAttributeSets: Array<Maybe<MappingAttributeDeviceModelAttributeSet>>;
  createMappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  createMappingDeviceBuildings: Array<Maybe<MappingDeviceBuilding>>;
  createMappingDeviceImage?: Maybe<MappingDeviceImage>;
  createMappingDeviceImages: Array<Maybe<MappingDeviceImage>>;
  createMappingDeviceZone?: Maybe<MappingDeviceZone>;
  createMappingDeviceZones: Array<Maybe<MappingDeviceZone>>;
  createRule?: Maybe<Rule>;
  createRuleWithChecks: RuleWithChecks;
  createRules: Array<Maybe<Rule>>;
  createRulesWithChecks: Array<RuleWithChecks>;
  createSite?: Maybe<Site>;
  createSites: Array<Maybe<Site>>;
  createStorey?: Maybe<Storey>;
  createStoreys: Array<Maybe<Storey>>;
  createUnit?: Maybe<Unit>;
  createUnits: Array<Maybe<Unit>>;
  createZone?: Maybe<Zone>;
  createZones: Array<Maybe<Zone>>;
  deleteAttribute?: Maybe<Attribute>;
  deleteAttributes: Array<Maybe<Attribute>>;
  deleteBuilding?: Maybe<Building>;
  deleteBuildings: Array<Maybe<Building>>;
  deleteCapabilities: Array<Maybe<Capability>>;
  deleteCapability?: Maybe<Capability>;
  deleteDevice?: Maybe<Device>;
  /** Delete list of sensor measurements by device and device capability */
  deleteDeviceCapabilitySensorMeasurements?: Maybe<DeleteSensorMeasurementsResponse>;
  deleteDeviceModel?: Maybe<DeviceModel>;
  deleteDeviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  deleteDeviceModelAttributeSets: Array<Maybe<DeviceModelAttributeSet>>;
  deleteDeviceModelCapabilities: Array<Maybe<DeviceModelCapability>>;
  deleteDeviceModelCapability?: Maybe<DeviceModelCapability>;
  deleteDeviceModels: Array<Maybe<DeviceModel>>;
  deleteDeviceType?: Maybe<DeviceType>;
  deleteDeviceTypes: Array<Maybe<DeviceType>>;
  deleteDevices: Array<Maybe<Device>>;
  deleteDevicesByGatewayId: DeleteE2ETestDataResult;
  deleteGatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  deleteGatewayDeviceConnections: Array<Maybe<GatewayDeviceConnection>>;
  deleteImage?: Maybe<Image>;
  deleteImages: Array<Maybe<Image>>;
  deleteLocation?: Maybe<Location>;
  deleteLocations: Array<Maybe<Location>>;
  deleteMappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  deleteMappingAttributeDeviceModelAttributeSets: Array<Maybe<MappingAttributeDeviceModelAttributeSet>>;
  deleteMappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  deleteMappingDeviceBuildings: Array<Maybe<MappingDeviceBuilding>>;
  deleteMappingDeviceImage?: Maybe<MappingDeviceImage>;
  deleteMappingDeviceImages: Array<Maybe<MappingDeviceImage>>;
  deleteMappingDeviceZone?: Maybe<MappingDeviceZone>;
  deleteMappingDeviceZones: Array<Maybe<MappingDeviceZone>>;
  deleteRule?: Maybe<Rule>;
  deleteRules: Array<Maybe<Rule>>;
  deleteSite?: Maybe<Site>;
  deleteSites: Array<Maybe<Site>>;
  deleteStorey?: Maybe<Storey>;
  deleteStoreys: Array<Maybe<Storey>>;
  deleteUnit?: Maybe<Unit>;
  deleteUnits: Array<Maybe<Unit>>;
  deleteZone?: Maybe<Zone>;
  deleteZones: Array<Maybe<Zone>>;
  exportIoTDevicesToBlobStorage: Scalars['String'];
  rebuildDeviceMappingsInStorageTable: Scalars['String'];
  redoCreateGatewayDeviceNotification?: Maybe<Scalars['String']>;
  regenerateGatewayDeviceConnectionKey?: Maybe<GatewayDeviceConnection>;
  regenerateGatewayDeviceConnectionsKeys: Array<Maybe<GatewayDeviceConnection>>;
  regenerateGatewayDeviceSharedAccessSignature: SharedAccessSignatureData;
  regenerateTenantGatewayDeviceConnectionsKeys: Array<GatewayDeviceConnection>;
  removeDeletedDeviceMappingsInStorageTable: Scalars['String'];
  setDeviceAttributeValue?: Maybe<DeviceAttributeValue>;
  setDeviceAttributeValues: Array<Maybe<DeviceAttributeValue>>;
  testSubscriptionDeviceMessage?: Maybe<DeviceMessage>;
  unsetDeviceAttributeValue?: Maybe<DeviceAttributeValue>;
  unsetDeviceAttributeValues: Array<Maybe<DeviceAttributeValue>>;
  updateAttribute: Attribute;
  updateAttributes: Array<Attribute>;
  updateBuilding: Building;
  updateBuildings: Array<Building>;
  updateCapabilities: Array<Capability>;
  updateCapability: Capability;
  updateDevice: Device;
  updateDeviceModel: DeviceModel;
  updateDeviceModelAttributeSet: DeviceModelAttributeSet;
  updateDeviceModelAttributeSets: Array<DeviceModelAttributeSet>;
  updateDeviceModelCapabilities: Array<DeviceModelCapability>;
  updateDeviceModelCapability: DeviceModelCapability;
  updateDeviceModels: Array<DeviceModel>;
  updateDeviceType: DeviceType;
  updateDeviceTypes: Array<DeviceType>;
  updateDevices: Array<Device>;
  updateGatewayDeviceConnection: GatewayDeviceConnection;
  updateGatewayDeviceConnections: Array<GatewayDeviceConnection>;
  updateImage: Image;
  updateImages: Array<Image>;
  updateLocation: Location;
  updateLocations: Array<Location>;
  updateMappingAttributeDeviceModelAttributeSet: MappingAttributeDeviceModelAttributeSet;
  updateMappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  updateMappingDeviceBuilding: MappingDeviceBuilding;
  updateMappingDeviceBuildingToBuilding: MappingDeviceBuilding;
  updateMappingDeviceBuildingToZone: MappingDeviceZone;
  updateMappingDeviceBuildings: Array<MappingDeviceBuilding>;
  updateMappingDeviceImage: MappingDeviceImage;
  updateMappingDeviceImages: Array<MappingDeviceImage>;
  updateMappingDeviceZone: MappingDeviceZone;
  updateMappingDeviceZoneToBuilding: MappingDeviceBuilding;
  updateMappingDeviceZoneToZone: MappingDeviceZone;
  updateMappingDeviceZones: Array<MappingDeviceZone>;
  updatePlacementOfDevice: DeviceMappingReturnType;
  updateRule: Rule;
  updateRuleWithChecks: RuleWithChecks;
  updateRules: Array<Rule>;
  updateRulesWithChecks: Array<RuleWithChecks>;
  updateSite: Site;
  updateSites: Array<Site>;
  updateStorey: Storey;
  updateStoreys: Array<Storey>;
  updateStressTestMappingTablesAsync: Scalars['String'];
  updateUnit: Unit;
  updateUnits: Array<Unit>;
  updateZone: Zone;
  updateZones: Array<Zone>;
};


export type MutationBulkProvisionIoTHubDevicesArgs = {
  numDevices: Scalars['Int'];
  numTenants: Scalars['Int'];
};


export type MutationCleanupGatewayDevicesFromIoTHubArgs = {
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationCreateAttributeArgs = {
  attribute: AttributeCreateType;
};


export type MutationCreateAttributesArgs = {
  attributes: Array<AttributeCreateType>;
};


export type MutationCreateAuditLogArgs = {
  auditLog: AuditLogCreateType;
};


export type MutationCreateAuditLogsArgs = {
  auditLogs: Array<AuditLogCreateType>;
};


export type MutationCreateBuildingArgs = {
  building: BuildingCreateType;
};


export type MutationCreateBuildingsArgs = {
  buildings: Array<BuildingCreateType>;
};


export type MutationCreateCapabilitiesArgs = {
  capabilities: Array<CapabilityCreateType>;
};


export type MutationCreateCapabilityArgs = {
  capability: CapabilityCreateType;
};


export type MutationCreateDeviceModelArgs = {
  deviceModel: DeviceModelCreateType;
};


export type MutationCreateDeviceModelAttributeSetArgs = {
  deviceModelAttributeSet: DeviceModelAttributeSetCreateType;
};


export type MutationCreateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets: Array<DeviceModelAttributeSetCreateType>;
};


export type MutationCreateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities: Array<DeviceModelCapabilityCreateType>;
};


export type MutationCreateDeviceModelCapabilityArgs = {
  deviceModelCapability: DeviceModelCapabilityCreateType;
};


export type MutationCreateDeviceModelsArgs = {
  deviceModels: Array<DeviceModelCreateType>;
};


export type MutationCreateDeviceTypeArgs = {
  deviceType: DeviceTypeCreateType;
};


export type MutationCreateDeviceTypesArgs = {
  deviceTypes: Array<DeviceTypeCreateType>;
};


export type MutationCreateDeviceWithPlacementArgs = {
  attributeValues: Array<DeviceCreateAttributeValueType>;
  device: DeviceCreateInputType;
  placementId: Scalars['UUID'];
  placementType: PlacementKind;
};


export type MutationCreateGatewayDeviceConnectionArgs = {
  gatewayDeviceConnection: GatewayDeviceConnectionCreateType;
};


export type MutationCreateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections: Array<GatewayDeviceConnectionCreateType>;
};


export type MutationCreateImageArgs = {
  image: ImageCreateType;
};


export type MutationCreateImagesArgs = {
  images: Array<ImageCreateType>;
};


export type MutationCreateLocationArgs = {
  location: LocationCreateType;
};


export type MutationCreateLocationsArgs = {
  locations: Array<LocationCreateType>;
};


export type MutationCreateMappingAttributeDeviceModelAttributeSetArgs = {
  mappingAttributeDeviceModelAttributeSet: MappingAttributeDeviceModelAttributeSetCreateType;
};


export type MutationCreateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSetCreateType>;
};


export type MutationCreateMappingDeviceBuildingArgs = {
  mappingDeviceBuilding: MappingDeviceBuildingCreateType;
};


export type MutationCreateMappingDeviceBuildingsArgs = {
  mappingDeviceBuildings: Array<MappingDeviceBuildingCreateType>;
};


export type MutationCreateMappingDeviceImageArgs = {
  mappingDeviceImage: MappingDeviceImageCreateType;
};


export type MutationCreateMappingDeviceImagesArgs = {
  mappingDeviceImages: Array<MappingDeviceImageCreateType>;
};


export type MutationCreateMappingDeviceZoneArgs = {
  mappingDeviceZone: MappingDeviceZoneCreateType;
};


export type MutationCreateMappingDeviceZonesArgs = {
  mappingDeviceZones: Array<MappingDeviceZoneCreateType>;
};


export type MutationCreateRuleArgs = {
  rule: RuleCreateType;
};


export type MutationCreateRuleWithChecksArgs = {
  rule: RuleCreateType;
};


export type MutationCreateRulesArgs = {
  rules: Array<RuleCreateType>;
};


export type MutationCreateRulesWithChecksArgs = {
  rules: Array<RuleCreateType>;
};


export type MutationCreateSiteArgs = {
  site: SiteCreateType;
};


export type MutationCreateSitesArgs = {
  sites: Array<SiteCreateType>;
};


export type MutationCreateStoreyArgs = {
  storey: StoreyCreateType;
};


export type MutationCreateStoreysArgs = {
  storeys: Array<StoreyCreateType>;
};


export type MutationCreateUnitArgs = {
  unit: UnitCreateType;
};


export type MutationCreateUnitsArgs = {
  units: Array<UnitCreateType>;
};


export type MutationCreateZoneArgs = {
  zone: ZoneCreateType;
};


export type MutationCreateZonesArgs = {
  zones: Array<ZoneCreateType>;
};


export type MutationDeleteAttributeArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteAttributesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteBuildingArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteBuildingsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteCapabilitiesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteCapabilityArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceArgs = {
  deleteChildDevices?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceCapabilitySensorMeasurementsArgs = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceModelArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceModelAttributeSetsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeviceModelCapabilitiesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeviceModelCapabilityArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceModelsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeviceTypeArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceTypesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDevicesArgs = {
  deleteChildDevices?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDevicesByGatewayIdArgs = {
  gatewayId: Scalars['UUID'];
};


export type MutationDeleteGatewayDeviceConnectionArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteGatewayDeviceConnectionsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteImageArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteImagesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteLocationsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingAttributeDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingAttributeDeviceModelAttributeSetsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingDeviceBuildingArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingDeviceBuildingsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingDeviceImageArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingDeviceImagesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingDeviceZoneArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingDeviceZonesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteRuleArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteRulesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteSiteArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteSitesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteStoreyArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteStoreysArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteUnitArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteUnitsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteZoneArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteZonesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationExportIoTDevicesToBlobStorageArgs = {
  blobName?: InputMaybe<Scalars['String']>;
};


export type MutationRebuildDeviceMappingsInStorageTableArgs = {
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationRedoCreateGatewayDeviceNotificationArgs = {
  deviceId: Scalars['UUID'];
};


export type MutationRegenerateGatewayDeviceConnectionKeyArgs = {
  deviceId: Scalars['UUID'];
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRegenerateGatewayDeviceConnectionsKeysArgs = {
  deviceIds: Array<Scalars['UUID']>;
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRegenerateGatewayDeviceSharedAccessSignatureArgs = {
  deviceId: Scalars['UUID'];
  expirySeconds: Scalars['Int'];
};


export type MutationRegenerateTenantGatewayDeviceConnectionsKeysArgs = {
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRemoveDeletedDeviceMappingsInStorageTableArgs = {
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationSetDeviceAttributeValueArgs = {
  attributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  value: Scalars['String'];
};


export type MutationSetDeviceAttributeValuesArgs = {
  deviceId: Scalars['UUID'];
  input: Array<AttributeIdAttributeValueInputType>;
};


export type MutationTestSubscriptionDeviceMessageArgs = {
  deviceId: Scalars['UUID'];
};


export type MutationUnsetDeviceAttributeValueArgs = {
  attributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
};


export type MutationUnsetDeviceAttributeValuesArgs = {
  attributeIds: Array<Scalars['UUID']>;
  deviceId: Scalars['UUID'];
};


export type MutationUpdateAttributeArgs = {
  attribute: AttributeUpdateType;
};


export type MutationUpdateAttributesArgs = {
  attributes: Array<AttributeUpdateType>;
};


export type MutationUpdateBuildingArgs = {
  building: BuildingUpdateType;
};


export type MutationUpdateBuildingsArgs = {
  buildings: Array<BuildingUpdateType>;
};


export type MutationUpdateCapabilitiesArgs = {
  capabilities: Array<CapabilityUpdateType>;
};


export type MutationUpdateCapabilityArgs = {
  capability: CapabilityUpdateType;
};


export type MutationUpdateDeviceArgs = {
  device: DeviceUpdateType;
};


export type MutationUpdateDeviceModelArgs = {
  deviceModel: DeviceModelUpdateType;
};


export type MutationUpdateDeviceModelAttributeSetArgs = {
  deviceModelAttributeSet: DeviceModelAttributeSetUpdateType;
};


export type MutationUpdateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets: Array<DeviceModelAttributeSetUpdateType>;
};


export type MutationUpdateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities: Array<DeviceModelCapabilityUpdateType>;
};


export type MutationUpdateDeviceModelCapabilityArgs = {
  deviceModelCapability: DeviceModelCapabilityUpdateType;
};


export type MutationUpdateDeviceModelsArgs = {
  deviceModels: Array<DeviceModelUpdateType>;
};


export type MutationUpdateDeviceTypeArgs = {
  deviceType: DeviceTypeUpdateType;
};


export type MutationUpdateDeviceTypesArgs = {
  deviceTypes: Array<DeviceTypeUpdateType>;
};


export type MutationUpdateDevicesArgs = {
  devices: Array<DeviceUpdateType>;
};


export type MutationUpdateGatewayDeviceConnectionArgs = {
  gatewayDeviceConnection: GatewayDeviceConnectionUpdateType;
};


export type MutationUpdateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections: Array<GatewayDeviceConnectionUpdateType>;
};


export type MutationUpdateImageArgs = {
  image: ImageUpdateType;
};


export type MutationUpdateImagesArgs = {
  images: Array<ImageUpdateType>;
};


export type MutationUpdateLocationArgs = {
  location: LocationUpdateType;
};


export type MutationUpdateLocationsArgs = {
  locations: Array<LocationUpdateType>;
};


export type MutationUpdateMappingAttributeDeviceModelAttributeSetArgs = {
  mappingAttributeDeviceModelAttributeSet: MappingAttributeDeviceModelAttributeSetUpdateType;
};


export type MutationUpdateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSetUpdateType>;
};


export type MutationUpdateMappingDeviceBuildingArgs = {
  mappingDeviceBuilding: MappingDeviceBuildingUpdateType;
};


export type MutationUpdateMappingDeviceBuildingToBuildingArgs = {
  mappingDeviceBuildingToBuildingUpdate: MappingDeviceBuildingUpdateType;
};


export type MutationUpdateMappingDeviceBuildingToZoneArgs = {
  mappingDeviceBuildingToZoneUpdate: MappingDeviceZoneCreateType;
};


export type MutationUpdateMappingDeviceBuildingsArgs = {
  mappingDeviceBuildings: Array<MappingDeviceBuildingUpdateType>;
};


export type MutationUpdateMappingDeviceImageArgs = {
  mappingDeviceImage: MappingDeviceImageUpdateType;
};


export type MutationUpdateMappingDeviceImagesArgs = {
  mappingDeviceImages: Array<MappingDeviceImageUpdateType>;
};


export type MutationUpdateMappingDeviceZoneArgs = {
  mappingDeviceZone: MappingDeviceZoneUpdateType;
};


export type MutationUpdateMappingDeviceZoneToBuildingArgs = {
  mappingDeviceZoneToBuildingUpdate: MappingDeviceBuildingCreateType;
};


export type MutationUpdateMappingDeviceZoneToZoneArgs = {
  mappingDeviceZoneToZoneUpdate: MappingDeviceZoneUpdateType;
};


export type MutationUpdateMappingDeviceZonesArgs = {
  mappingDeviceZones: Array<MappingDeviceZoneUpdateType>;
};


export type MutationUpdatePlacementOfDeviceArgs = {
  deviceId: Scalars['UUID'];
  placementId: Scalars['UUID'];
  placementType: PlacementKind;
};


export type MutationUpdateRuleArgs = {
  rule: RuleUpdateType;
};


export type MutationUpdateRuleWithChecksArgs = {
  rule: RuleUpdateType;
};


export type MutationUpdateRulesArgs = {
  rules: Array<RuleUpdateType>;
};


export type MutationUpdateRulesWithChecksArgs = {
  rules: Array<RuleUpdateType>;
};


export type MutationUpdateSiteArgs = {
  site: SiteUpdateType;
};


export type MutationUpdateSitesArgs = {
  sites: Array<SiteUpdateType>;
};


export type MutationUpdateStoreyArgs = {
  storey: StoreyUpdateType;
};


export type MutationUpdateStoreysArgs = {
  storeys: Array<StoreyUpdateType>;
};


export type MutationUpdateStressTestMappingTablesAsyncArgs = {
  delete?: Scalars['Boolean'];
  numCapabilities: Scalars['Int'];
  numDevices: Scalars['Int'];
  numTenants: Scalars['Int'];
};


export type MutationUpdateUnitArgs = {
  unit: UnitUpdateType;
};


export type MutationUpdateUnitsArgs = {
  units: Array<UnitUpdateType>;
};


export type MutationUpdateZoneArgs = {
  zone: ZoneUpdateType;
};


export type MutationUpdateZonesArgs = {
  zones: Array<ZoneUpdateType>;
};

export type Node = {
  __typename?: 'Node';
  contentTypes: Array<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
};

export type NullableOfHeartbeatStatesOperationFilterInput = {
  eq?: InputMaybe<HeartbeatStates>;
  in?: InputMaybe<Array<InputMaybe<HeartbeatStates>>>;
  neq?: InputMaybe<HeartbeatStates>;
  nin?: InputMaybe<Array<InputMaybe<HeartbeatStates>>>;
};

export type PlacementFilterRow = {
  __typename?: 'PlacementFilterRow';
  buildingId?: Maybe<Scalars['UUID']>;
  deviceId?: Maybe<Scalars['UUID']>;
  locationId?: Maybe<Scalars['UUID']>;
  mappingPlacementId?: Maybe<Scalars['UUID']>;
  siteId?: Maybe<Scalars['UUID']>;
  storeyId?: Maybe<Scalars['UUID']>;
  zoneId?: Maybe<Scalars['UUID']>;
};

export type PlacementFilterRowFilterInput = {
  and?: InputMaybe<Array<PlacementFilterRowFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  mappingPlacementId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<PlacementFilterRowFilterInput>>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type PlacementFilterRowSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  mappingPlacementId?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

export enum PlacementKind {
  Building = 'BUILDING',
  Zone = 'ZONE'
}

export type PlacementOfDevice = {
  __typename?: 'PlacementOfDevice';
  buildingCity?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['UUID']>;
  buildingName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  buildingStreet?: Maybe<Scalars['String']>;
  buildingZip?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['UUID']>;
  locationCity?: Maybe<Scalars['String']>;
  locationCountry?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['UUID']>;
  mappingPlacementId?: Maybe<Scalars['UUID']>;
  mappingPlacementType?: Maybe<Scalars['String']>;
  siteCity?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['UUID']>;
  siteName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  siteStreet?: Maybe<Scalars['String']>;
  siteZip?: Maybe<Scalars['String']>;
  storeyId?: Maybe<Scalars['UUID']>;
  storeyName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['UUID']>;
  zoneName?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  attribute?: Maybe<Attribute>;
  attributes?: Maybe<AttributesCollectionSegment>;
  /** Queries all attributes assigned to devices in a building */
  attributesByBuildings?: Maybe<AttributesByBuildingsCollectionSegment>;
  auditLog?: Maybe<AuditLog>;
  /** Audit Logs */
  auditLogs?: Maybe<AuditLogsCollectionSegment>;
  building?: Maybe<Building>;
  buildingFilterTable?: Maybe<BuildingFilterTableCollectionSegment>;
  buildingTree: Array<Node>;
  buildings?: Maybe<BuildingsCollectionSegment>;
  capabilities?: Maybe<CapabilitiesCollectionSegment>;
  capability?: Maybe<Capability>;
  createDemoSite: Scalars['Boolean'];
  /** Get DataService Info */
  dataServiceInfo?: Maybe<ServiceInfo>;
  device?: Maybe<Device>;
  deviceAttributeValue?: Maybe<DeviceAttributeValue>;
  deviceAttributeValues?: Maybe<DeviceAttributeValuesCollectionSegment>;
  deviceGroupedSensorDataByViews?: Maybe<Array<Maybe<TimeAndAggregationParameter>>>;
  deviceModel?: Maybe<DeviceModel>;
  deviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  deviceModelAttributeSets?: Maybe<DeviceModelAttributeSetsCollectionSegment>;
  deviceModelCapabilities?: Maybe<DeviceModelCapabilitiesCollectionSegment>;
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModels?: Maybe<DeviceModelsCollectionSegment>;
  deviceType?: Maybe<DeviceType>;
  deviceTypes?: Maybe<DeviceTypesCollectionSegment>;
  deviceWithAttributes?: Maybe<DeviceWithAttributeValues>;
  devices?: Maybe<DevicesCollectionSegment>;
  /** Queries all devices in a building */
  devicesByBuildings?: Maybe<DevicesByBuildingsCollectionSegment>;
  fakeErrors: Array<TestError>;
  gatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  gatewayDeviceConnections?: Maybe<GatewayDeviceConnectionsCollectionSegment>;
  gatewayDevices?: Maybe<Array<Maybe<Device>>>;
  image?: Maybe<Image>;
  images?: Maybe<ImagesCollectionSegment>;
  latestSensorData?: Maybe<Array<Maybe<DataPoint>>>;
  location?: Maybe<Location>;
  locations?: Maybe<LocationsCollectionSegment>;
  mappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  mappingAttributeDeviceModelAttributeSets?: Maybe<MappingAttributeDeviceModelAttributeSetsCollectionSegment>;
  mappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  mappingDeviceBuildings?: Maybe<MappingDeviceBuildingsCollectionSegment>;
  mappingDeviceImage?: Maybe<MappingDeviceImage>;
  mappingDeviceImages?: Maybe<MappingDeviceImagesCollectionSegment>;
  mappingDeviceZone?: Maybe<MappingDeviceZone>;
  mappingDeviceZones?: Maybe<MappingDeviceZonesCollectionSegment>;
  multipleDeviceSensorDataByViews?: Maybe<Array<Maybe<TimeAndAggregationParameter>>>;
  /** Get Info of multi sensor measurements */
  multipleDeviceSensorDataInfo?: Maybe<SensorMeasurementsInfo>;
  placementOfDevice?: Maybe<PlacementOfDevice>;
  rule?: Maybe<Rule>;
  rules?: Maybe<RulesCollectionSegment>;
  /** Get list of sensor measurements */
  sensorMeasurements?: Maybe<SensorMeasurementsCollectionSegment>;
  /** Get Info of sensor measurement */
  sensorMeasurementsInfo?: Maybe<SensorMeasurementsInfo>;
  site?: Maybe<Site>;
  sites?: Maybe<SitesCollectionSegment>;
  storey?: Maybe<Storey>;
  storeys?: Maybe<StoreysCollectionSegment>;
  unit?: Maybe<Unit>;
  units?: Maybe<UnitsCollectionSegment>;
  zone?: Maybe<Zone>;
  zoneAndBuildingFilterTable?: Maybe<ZoneAndBuildingFilterTableCollectionSegment>;
  zoneFilterTable?: Maybe<ZoneFilterTableCollectionSegment>;
  zones?: Maybe<ZonesCollectionSegment>;
};


export type QueryAttributeArgs = {
  id: Scalars['UUID'];
};


export type QueryAttributesArgs = {
  order?: InputMaybe<Array<AttributeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttributeFilterInput>;
};


export type QueryAttributesByBuildingsArgs = {
  order?: InputMaybe<Array<AttributesByBuildingsSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttributesByBuildingsFilterInput>;
};


export type QueryAuditLogArgs = {
  id: Scalars['UUID'];
};


export type QueryAuditLogsArgs = {
  order?: InputMaybe<Array<AuditLogSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuditLogFilterInput>;
};


export type QueryBuildingArgs = {
  id: Scalars['UUID'];
};


export type QueryBuildingFilterTableArgs = {
  order?: InputMaybe<Array<PlacementFilterRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryBuildingTreeArgs = {
  maxDepth?: InputMaybe<Scalars['Int']>;
  rootSpaceNodeId?: InputMaybe<Scalars['String']>;
};


export type QueryBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingFilterInput>;
};


export type QueryCapabilitiesArgs = {
  order?: InputMaybe<Array<CapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CapabilityFilterInput>;
};


export type QueryCapabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryCreateDemoSiteArgs = {
  demoSiteType: DemoSiteType;
};


export type QueryDeviceArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceAttributeValueArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


export type QueryDeviceGroupedSensorDataByViewsArgs = {
  where?: InputMaybe<QueryGroupFiltersMultipleInput>;
};


export type QueryDeviceModelArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


export type QueryDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};


export type QueryDeviceModelCapabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelsArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelFilterInput>;
};


export type QueryDeviceTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceTypesArgs = {
  order?: InputMaybe<Array<DeviceTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTypeFilterInput>;
};


export type QueryDeviceWithAttributesArgs = {
  id: Scalars['UUID'];
};


export type QueryDevicesArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};


export type QueryDevicesByBuildingsArgs = {
  order?: InputMaybe<Array<DevicesByBuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DevicesByBuildingFilterInput>;
};


export type QueryGatewayDeviceConnectionArgs = {
  id: Scalars['UUID'];
};


export type QueryGatewayDeviceConnectionsArgs = {
  order?: InputMaybe<Array<GatewayDeviceConnectionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};


export type QueryImageArgs = {
  id: Scalars['UUID'];
};


export type QueryImagesArgs = {
  order?: InputMaybe<Array<ImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageFilterInput>;
};


export type QueryLatestSensorDataArgs = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<Scalars['UUID']>>;
  limitPerCapability?: InputMaybe<Scalars['Int']>;
};


export type QueryLocationArgs = {
  id: Scalars['UUID'];
};


export type QueryLocationsArgs = {
  order?: InputMaybe<Array<LocationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationFilterInput>;
};


export type QueryMappingAttributeDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};


export type QueryMappingDeviceBuildingArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceBuildingsArgs = {
  order?: InputMaybe<Array<MappingDeviceBuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceBuildingFilterInput>;
};


export type QueryMappingDeviceImageArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceImagesArgs = {
  order?: InputMaybe<Array<MappingDeviceImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceImageFilterInput>;
};


export type QueryMappingDeviceZoneArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceZonesArgs = {
  order?: InputMaybe<Array<MappingDeviceZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceZoneFilterInput>;
};


export type QueryMultipleDeviceSensorDataByViewsArgs = {
  where?: InputMaybe<QueryFiltersMultipleInput>;
};


export type QueryMultipleDeviceSensorDataInfoArgs = {
  queryFilters?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>>;
};


export type QueryPlacementOfDeviceArgs = {
  deviceId: Scalars['UUID'];
};


export type QueryRuleArgs = {
  id: Scalars['UUID'];
};


export type QueryRulesArgs = {
  order?: InputMaybe<Array<RuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleFilterInput>;
};


export type QuerySensorMeasurementsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryFiltersInput>;
};


export type QuerySensorMeasurementsInfoArgs = {
  where?: InputMaybe<SensorInfoQueryFilterInput>;
};


export type QuerySiteArgs = {
  id: Scalars['UUID'];
};


export type QuerySitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteFilterInput>;
};


export type QueryStoreyArgs = {
  id: Scalars['UUID'];
};


export type QueryStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreyFilterInput>;
};


export type QueryUnitArgs = {
  id: Scalars['UUID'];
};


export type QueryUnitsArgs = {
  order?: InputMaybe<Array<UnitSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitFilterInput>;
};


export type QueryZoneArgs = {
  id: Scalars['UUID'];
};


export type QueryZoneAndBuildingFilterTableArgs = {
  order?: InputMaybe<Array<PlacementFilterRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryZoneFilterTableArgs = {
  order?: InputMaybe<Array<PlacementFilterRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type QueryFiltersInput = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds: Array<Scalars['UUID']>;
  limit?: InputMaybe<Scalars['Int']>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
};

export type QueryFiltersMultipleDeviceAndCapabilityInput = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityIdsInput>>>;
};

export type QueryFiltersMultipleInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  queryFiltersMultipleDeviceAndCapability?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
};

export type QueryGroupFiltersMultipleInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  deviceModelCapabilityId: Scalars['UUID'];
  limit: Scalars['Int'];
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
};

export type Rule = {
  __typename?: 'Rule';
  createdAt?: Maybe<Scalars['DateTime']>;
  deployedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  json?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type RuleCreateType = {
  /** Rule Definition as JSON */
  json?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=512 */
  name?: InputMaybe<Scalars['String']>;
};

export type RuleFilterInput = {
  and?: InputMaybe<Array<RuleFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deployedAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  json?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RuleFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type RuleSetNullInputType = {
  json?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
};

export type RuleSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  deployedAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  json?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type RuleUpdateType = {
  id: Scalars['UUID'];
  /** Rule Definition as JSON */
  json?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type RuleWithChecks = {
  __typename?: 'RuleWithChecks';
  createdAt?: Maybe<Scalars['DateTime']>;
  deployedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  json?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type RulesCollectionSegment = {
  __typename?: 'RulesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<Rule>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type SensorInfoQueryFilterInput = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityId: Scalars['UUID'];
};

export type SensorMeasurement = {
  __typename?: 'SensorMeasurement';
  device: Device;
  deviceId: Scalars['UUID'];
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId: Scalars['UUID'];
  ingestedAt?: Maybe<Scalars['DateTime']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  measuredAt?: Maybe<Scalars['DateTime']>;
  measuredTimeZone?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  utcTimeMeasured: Scalars['DateTime'];
  valueBool?: Maybe<Scalars['Boolean']>;
  valueDate?: Maybe<Scalars['DateTime']>;
  valueDateTime?: Maybe<Scalars['DateTime']>;
  valueDecimal?: Maybe<Scalars['Decimal']>;
  valueDouble?: Maybe<Scalars['Float']>;
  valueFloat?: Maybe<Scalars['Float']>;
  valueInteger?: Maybe<Scalars['Int']>;
  valueString?: Maybe<Scalars['String']>;
};

/** A segment of a collection. */
export type SensorMeasurementsCollectionSegment = {
  __typename?: 'SensorMeasurementsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<SensorMeasurement>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type SensorMeasurementsInfo = {
  __typename?: 'SensorMeasurementsInfo';
  count: Scalars['Int'];
  maxUtcTimeMeasured: Scalars['DateTime'];
  minUtcTimeMeasured: Scalars['DateTime'];
};

export type ServiceInfo = {
  __typename?: 'ServiceInfo';
  dateTimeNow: Scalars['DateTime'];
  daylightSavingTimeName?: Maybe<Scalars['String']>;
  env?: Maybe<Scalars['String']>;
  utcNow: Scalars['DateTime'];
};

export type SharedAccessSignatureData = {
  __typename?: 'SharedAccessSignatureData';
  sharedAccessSignature: Scalars['String'];
  sharedAccessSignatureExpiry: Scalars['DateTime'];
};

/** I am a generated type! */
export type Site = {
  __typename?: 'Site';
  buildings: Array<Building>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  inverseParentSite: Array<Site>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  parentSite?: Maybe<Site>;
  parentSiteId?: Maybe<Scalars['UUID']>;
  tenantId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type SiteBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type SiteInverseParentSiteArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  where?: InputMaybe<SiteFilterInput>;
};

export type SiteCreateType = {
  description?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentSiteId?: InputMaybe<Scalars['UUID']>;
};

export type SiteFilterInput = {
  and?: InputMaybe<Array<SiteFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  inverseParentSite?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SiteFilterInput>>;
  parentSite?: InputMaybe<SiteFilterInput>;
  parentSiteId?: InputMaybe<UuidOperationFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type SiteSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  inverseParentSite?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  parentSite?: InputMaybe<Scalars['Boolean']>;
  parentSiteId?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type SiteSortInput = {
  description?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentSite?: InputMaybe<SiteSortInput>;
  parentSiteId?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type SiteUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  parentSiteId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type SitesCollectionSegment = {
  __typename?: 'SitesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Site>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** I am a generated type! */
export type Storey = {
  __typename?: 'Storey';
  building: Building;
  buildingId: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  zones: Array<Zone>;
};


/** I am a generated type! */
export type StoreyZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type StoreyCreateType = {
  buildingId: Scalars['UUID'];
  description?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type StoreyFilterInput = {
  and?: InputMaybe<Array<StoreyFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StoreyFilterInput>>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zones?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
};

export type StoreySetNullInputType = {
  building?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zones?: InputMaybe<Scalars['Boolean']>;
};

export type StoreySortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type StoreyUpdateType = {
  buildingId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type StoreysCollectionSegment = {
  __typename?: 'StoreysCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Storey>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  commissioningStatusChanged?: Maybe<Gateway_Gateway_Gateway>;
  /** Subscription providing updates when a device's connection state is changed. */
  deviceStateChanged: DeviceMessage;
};


export type SubscriptionCommissioningStatusChangedArgs = {
  gatewayIds?: InputMaybe<Array<Scalars['String']>>;
};


export type SubscriptionDeviceStateChangedArgs = {
  deviceId: Scalars['UUID'];
};

export type TelemetryRecord = {
  __typename?: 'TelemetryRecord';
  utcTimeMeasured: Scalars['DateTime'];
  valueCount: Scalars['Float'];
  valueDouble: Scalars['Float'];
  valueMax: Scalars['Float'];
  valueMin: Scalars['Float'];
  valueString?: Maybe<Scalars['String']>;
};

export type TestError = {
  __typename?: 'TestError';
  errorCode: Scalars['String'];
};

export type TimeAndAggregationParameter = {
  __typename?: 'TimeAndAggregationParameter';
  aggregationIntervalActive: AggregationIntervals;
  aggregationIntervalDefault: AggregationIntervals;
  aggregationIntervalsList?: Maybe<Array<AggregationIntervals>>;
  aggregationType?: Maybe<AggregationTypes>;
  deviceAndCapabilityInfos?: Maybe<Array<Maybe<DeviceAndCapabilityInfo>>>;
};

export enum TimeSpans {
  Last_1Hour = 'LAST_1_HOUR',
  Last_2Hour = 'LAST_2_HOUR',
  Last_4Hour = 'LAST_4_HOUR',
  Last_7Day = 'LAST_7_DAY',
  Last_12Hour = 'LAST_12_HOUR',
  Last_15Min = 'LAST_15_MIN',
  Last_24Hour = 'LAST_24_HOUR',
  Last_30Day = 'LAST_30_DAY',
  Last_30Min = 'LAST_30_MIN',
  Last_90Day = 'LAST_90_DAY'
}

export type Unit = {
  __typename?: 'Unit';
  deviceModelCapabilities: Array<DeviceModelCapability>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  unitSymbol?: Maybe<Scalars['String']>;
  uris?: Maybe<Array<Scalars['String']>>;
};


export type UnitDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type UnitCreateType = {
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  unitSymbol?: InputMaybe<Scalars['String']>;
  uris?: InputMaybe<Array<Scalars['String']>>;
};

export type UnitFilterInput = {
  and?: InputMaybe<Array<UnitFilterInput>>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UnitFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  unitId?: InputMaybe<UuidOperationFilterInput>;
  unitSymbol?: InputMaybe<StringOperationFilterInput>;
  uris?: InputMaybe<ListStringOperationFilterInput>;
};

export type UnitSetNullInputType = {
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  unitSymbol?: InputMaybe<Scalars['Boolean']>;
  uris?: InputMaybe<Scalars['Boolean']>;
};

export type UnitSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unitId?: InputMaybe<SortEnumType>;
  unitSymbol?: InputMaybe<SortEnumType>;
};

export type UnitUpdateType = {
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  unitSymbol?: InputMaybe<Scalars['String']>;
  uris?: InputMaybe<Array<Scalars['String']>>;
};

/** A segment of a collection. */
export type UnitsCollectionSegment = {
  __typename?: 'UnitsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Unit>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ValueEntity = {
  __typename?: 'ValueEntity';
  value?: Maybe<Scalars['String']>;
};

export type ValueEntityFilterInput = {
  and?: InputMaybe<Array<ValueEntityFilterInput>>;
  or?: InputMaybe<Array<ValueEntityFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

/** Weather information for building location */
export type Weather = {
  __typename?: 'Weather';
  building?: Maybe<Building>;
  buildingId: Scalars['UUID'];
  iceProbability: Scalars['Int'];
  iconCode: Scalars['Int'];
  lastUpdated: Scalars['DateTime'];
  phrase: Scalars['String'];
  rainProbability: Scalars['Int'];
  snowProbability: Scalars['Int'];
  temperatureUnit: Scalars['String'];
  temperatureValue: Scalars['Float'];
  weatherId: Scalars['UUID'];
};

export type WeatherFilterInput = {
  and?: InputMaybe<Array<WeatherFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  iceProbability?: InputMaybe<IntOperationFilterInput>;
  iconCode?: InputMaybe<IntOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<WeatherFilterInput>>;
  phrase?: InputMaybe<StringOperationFilterInput>;
  rainProbability?: InputMaybe<IntOperationFilterInput>;
  snowProbability?: InputMaybe<IntOperationFilterInput>;
  temperatureUnit?: InputMaybe<StringOperationFilterInput>;
  temperatureValue?: InputMaybe<FloatOperationFilterInput>;
  weatherId?: InputMaybe<UuidOperationFilterInput>;
};

export type WeatherSortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  iceProbability?: InputMaybe<SortEnumType>;
  iconCode?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  phrase?: InputMaybe<SortEnumType>;
  rainProbability?: InputMaybe<SortEnumType>;
  snowProbability?: InputMaybe<SortEnumType>;
  temperatureUnit?: InputMaybe<SortEnumType>;
  temperatureValue?: InputMaybe<SortEnumType>;
  weatherId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type Zone = {
  __typename?: 'Zone';
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  inverseParentZone: Array<Zone>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingDeviceZones: Array<MappingDeviceZone>;
  name: Scalars['String'];
  parentZone?: Maybe<Zone>;
  parentZoneId?: Maybe<Scalars['UUID']>;
  storey: Storey;
  storeyId: Scalars['UUID'];
  tenantId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type ZoneInverseParentZoneArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  where?: InputMaybe<ZoneFilterInput>;
};


/** I am a generated type! */
export type ZoneMappingDeviceZonesArgs = {
  order?: InputMaybe<Array<MappingDeviceZoneSortInput>>;
  where?: InputMaybe<MappingDeviceZoneFilterInput>;
};

/** A segment of a collection. */
export type ZoneAndBuildingFilterTableCollectionSegment = {
  __typename?: 'ZoneAndBuildingFilterTableCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PlacementFilterRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ZoneCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentZoneId?: InputMaybe<Scalars['UUID']>;
  storeyId: Scalars['UUID'];
};

export type ZoneFilterInput = {
  and?: InputMaybe<Array<ZoneFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimension?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  inverseParentZone?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceZones?: InputMaybe<ListFilterInputTypeOfMappingDeviceZoneFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ZoneFilterInput>>;
  parentZone?: InputMaybe<ZoneFilterInput>;
  parentZoneId?: InputMaybe<UuidOperationFilterInput>;
  storey?: InputMaybe<StoreyFilterInput>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

/** A segment of a collection. */
export type ZoneFilterTableCollectionSegment = {
  __typename?: 'ZoneFilterTableCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PlacementFilterRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ZoneSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  dimension?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  inverseParentZone?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceZones?: InputMaybe<Scalars['Boolean']>;
  parentZone?: InputMaybe<Scalars['Boolean']>;
  parentZoneId?: InputMaybe<Scalars['Boolean']>;
  storey?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type ZoneSortInput = {
  description?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentZone?: InputMaybe<ZoneSortInput>;
  parentZoneId?: InputMaybe<SortEnumType>;
  storey?: InputMaybe<StoreySortInput>;
  storeyId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

export type ZoneUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  parentZoneId?: InputMaybe<Scalars['UUID']>;
  storeyId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type ZonesCollectionSegment = {
  __typename?: 'ZonesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Zone>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type BuildingContextBuildingQueryVariables = Exact<{
  buildingId: Scalars['UUID'];
}>;


export type BuildingContextBuildingQuery = { __typename?: 'Query', building?: { __typename?: 'Building', id: string, name: string, storeys: Array<{ __typename?: 'Storey', id: string, name: string, zones: Array<{ __typename?: 'Zone', name: string, id: string, storeyId: string }> }> } | null };

export type PlatformContextQueryVariables = Exact<{ [key: string]: never; }>;


export type PlatformContextQuery = { __typename?: 'Query', deviceTypes?: { __typename?: 'DeviceTypesCollectionSegment', items?: Array<{ __typename?: 'DeviceType', id: string, name: string }> | null } | null };

export type InfoQueryVariables = Exact<{
  buildingId: Scalars['UUID'];
}>;


export type InfoQuery = { __typename?: 'Query', building?: { __typename?: 'Building', description?: string | null, location?: { __typename?: 'Location', city?: string | null, country?: string | null, no?: string | null, street?: string | null, zip?: string | null, lat?: number | null, lon?: number | null } | null } | null };

export type WidgetCardDataQueryVariables = Exact<{
  devices?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>> | InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>;
  timeSpan?: InputMaybe<TimeSpans>;
  readFrom?: InputMaybe<Scalars['DateTime']>;
  readUntil?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  intervalSize?: InputMaybe<AggregationIntervals>;
}>;


export type WidgetCardDataQuery = { __typename?: 'Query', multipleDeviceSensorDataByViews?: Array<{ __typename?: 'TimeAndAggregationParameter', deviceAndCapabilityInfos?: Array<{ __typename?: 'DeviceAndCapabilityInfo', deviceId: string, deviceModelCapabilityId: string, device: { __typename?: 'Device', name: string }, deviceModelCapability?: { __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', name: string }, unit: { __typename?: 'Unit', unitSymbol?: string | null } } | null, telemetryRecords?: Array<{ __typename?: 'TelemetryRecord', utcTimeMeasured: any, valueString?: string | null } | null> | null } | null> | null } | null> | null };

export type InteractiveBuildingQueryVariables = Exact<{
  id: Scalars['UUID'];
  zoneIds?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;


export type InteractiveBuildingQuery = { __typename?: 'Query', devicesByBuildings?: { __typename?: 'DevicesByBuildingsCollectionSegment', items?: Array<{ __typename?: 'DevicesByBuilding', zoneId?: string | null, device: { __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', deviceType: { __typename?: 'DeviceType', name: string } } } }> | null } | null };

export type WidgetFormDevicesSearchQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where: DevicesByBuildingFilterInput;
  order?: InputMaybe<Array<DevicesByBuildingSortInput> | DevicesByBuildingSortInput>;
}>;


export type WidgetFormDevicesSearchQuery = { __typename?: 'Query', devicesByBuildings?: { __typename?: 'DevicesByBuildingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'DevicesByBuilding', deviceId?: string | null, storeyId?: string | null, zoneId?: string | null, device: { __typename?: 'Device', name: string, id: string, serialNo?: string | null, description?: string | null, deviceModel: { __typename?: 'DeviceModel', deviceType: { __typename?: 'DeviceType', name: string }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', name: string } }> } } }> | null } | null };

export type WidgetFormDataQueryVariables = Exact<{
  devices?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>> | InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>;
  timeSpan?: InputMaybe<TimeSpans>;
  readFrom?: InputMaybe<Scalars['DateTime']>;
  readUntil?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  intervalSize?: InputMaybe<AggregationIntervals>;
}>;


export type WidgetFormDataQuery = { __typename?: 'Query', multipleDeviceSensorDataByViews?: Array<{ __typename?: 'TimeAndAggregationParameter', aggregationIntervalActive: AggregationIntervals, aggregationIntervalDefault: AggregationIntervals, aggregationIntervalsList?: Array<AggregationIntervals> | null, deviceAndCapabilityInfos?: Array<{ __typename?: 'DeviceAndCapabilityInfo', deviceId: string, deviceModelCapabilityId: string, device: { __typename?: 'Device', name: string, serialNo?: string | null, description?: string | null }, deviceModelCapability?: { __typename?: 'DeviceModelCapability', id: string, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null }, capability: { __typename?: 'Capability', name: string } } | null, telemetryRecords?: Array<{ __typename?: 'TelemetryRecord', utcTimeMeasured: any, valueString?: string | null } | null> | null } | null> | null } | null> | null, multipleDeviceSensorDataInfo?: { __typename?: 'SensorMeasurementsInfo', minUtcTimeMeasured: any } | null };

export type WidgetFormDevicesQueryVariables = Exact<{
  deviceIds?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;


export type WidgetFormDevicesQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', items?: Array<{ __typename?: 'Device', name: string, id: string, serialNo?: string | null, description?: string | null, deviceModel: { __typename?: 'DeviceModel', deviceType: { __typename?: 'DeviceType', name: string }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', name: string } }> } }> | null } | null, mappingDeviceZones?: { __typename?: 'MappingDeviceZonesCollectionSegment', items?: Array<{ __typename?: 'MappingDeviceZone', device: { __typename?: 'Device', id: string }, zone: { __typename?: 'Zone', id: string, name: string, storeyId: string, storey: { __typename?: 'Storey', id: string, name: string } } }> | null } | null };

export type DashboardSelectCapabilitiesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where: DevicesByBuildingFilterInput;
  order?: InputMaybe<Array<DevicesByBuildingSortInput> | DevicesByBuildingSortInput>;
}>;


export type DashboardSelectCapabilitiesQuery = { __typename?: 'Query', devicesByBuildings?: { __typename?: 'DevicesByBuildingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'DevicesByBuilding', deviceId?: string | null, storeyId?: string | null, zoneId?: string | null, device: { __typename?: 'Device', name: string, id: string, serialNo?: string | null, description?: string | null, deviceModel: { __typename?: 'DeviceModel', deviceType: { __typename?: 'DeviceType', name: string }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', name: string } }> } } }> | null } | null };

export type DashboardSelectCapabilitiesSelectedDeviceQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type DashboardSelectCapabilitiesSelectedDeviceQuery = { __typename?: 'Query', placementOfDevice?: { __typename?: 'PlacementOfDevice', deviceId?: string | null, storeyId?: string | null, zoneId?: string | null } | null, device?: { __typename?: 'Device', id: string, name: string, serialNo?: string | null, description?: string | null, deviceModel: { __typename?: 'DeviceModel', deviceType: { __typename?: 'DeviceType', name: string }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', name: string } }> } } | null };

export type WidgetChartDataOfDeviceQueryVariables = Exact<{
  devices?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>> | InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>;
  timeSpan?: InputMaybe<TimeSpans>;
  readFrom?: InputMaybe<Scalars['DateTime']>;
  readUntil?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  intervalSize?: InputMaybe<AggregationIntervals>;
}>;


export type WidgetChartDataOfDeviceQuery = { __typename?: 'Query', multipleDeviceSensorDataByViews?: Array<{ __typename?: 'TimeAndAggregationParameter', aggregationIntervalActive: AggregationIntervals, deviceAndCapabilityInfos?: Array<{ __typename?: 'DeviceAndCapabilityInfo', deviceId: string, deviceModelCapabilityId: string, device: { __typename?: 'Device', name: string }, telemetryRecords?: Array<{ __typename?: 'TelemetryRecord', utcTimeMeasured: any, valueString?: string | null } | null> | null, deviceModelCapability?: { __typename?: 'DeviceModelCapability', unit: { __typename?: 'Unit', unitSymbol?: string | null }, capability: { __typename?: 'Capability', name: string } } | null } | null> | null } | null> | null };

export type WidgetLastValueOfDeviceQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  deviceModelCapabilityId: Scalars['UUID'];
}>;


export type WidgetLastValueOfDeviceQuery = { __typename?: 'Query', sensorMeasurements?: { __typename?: 'SensorMeasurementsCollectionSegment', items?: Array<{ __typename?: 'SensorMeasurement', valueString?: string | null, utcTimeMeasured: any, deviceModelCapability?: { __typename?: 'DeviceModelCapability', unit: { __typename?: 'Unit', unitSymbol?: string | null } } | null }> | null } | null };

export type ChartEditPanelDevicesQueryVariables = Exact<{
  deviceIds?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;


export type ChartEditPanelDevicesQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', items?: Array<{ __typename?: 'Device', id: string, name: string, deviceModel: { __typename?: 'DeviceModel', deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', id: string, name: string }, unit: { __typename?: 'Unit', name: string, unitSymbol?: string | null } }> } }> | null } | null };

export type LastValueEditPanelDeviceQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type LastValueEditPanelDeviceQuery = { __typename?: 'Query', device?: { __typename?: 'Device', name: string, deviceModel: { __typename?: 'DeviceModel', deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', id: string, name: string }, unit: { __typename?: 'Unit', name: string, unitSymbol?: string | null } }> } } | null };

export type PointPreviewDeviceQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  capabilityId: Scalars['UUID'];
}>;


export type PointPreviewDeviceQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, deviceIdentifier?: string | null, serialNo?: string | null } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', mappingPlacementType?: string | null, siteName?: string | null, buildingName?: string | null, storeyName?: string | null, zoneName?: string | null, buildingId?: string | null, storeyId?: string | null, zoneId?: string | null } | null, deviceModelCapability?: { __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', name: string }, unit: { __typename?: 'Unit', unitSymbol?: string | null } } | null };

export type PointPreviewDeviceCapabilityChartQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  capabilityId: Scalars['UUID'];
  timeSpan?: InputMaybe<TimeSpans>;
  intervalSize?: InputMaybe<AggregationIntervals>;
}>;


export type PointPreviewDeviceCapabilityChartQuery = { __typename?: 'Query', multipleDeviceSensorDataByViews?: Array<{ __typename?: 'TimeAndAggregationParameter', aggregationIntervalActive: AggregationIntervals, deviceAndCapabilityInfos?: Array<{ __typename?: 'DeviceAndCapabilityInfo', deviceModelCapabilityId: string, telemetryRecords?: Array<{ __typename?: 'TelemetryRecord', utcTimeMeasured: any, valueString?: string | null } | null> | null } | null> | null } | null> | null };

export type DashboardAggregationIntervalsQueryVariables = Exact<{
  timeSpan?: InputMaybe<TimeSpans>;
  readFrom?: InputMaybe<Scalars['DateTime']>;
  readUntil?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  intervalSize?: InputMaybe<AggregationIntervals>;
}>;


export type DashboardAggregationIntervalsQuery = { __typename?: 'Query', multipleDeviceSensorDataByViews?: Array<{ __typename?: 'TimeAndAggregationParameter', aggregationIntervalActive: AggregationIntervals, aggregationIntervalsList?: Array<AggregationIntervals> | null } | null> | null };

export type DeviceChartDataQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityIdsInput>> | InputMaybe<DeviceModelCapabilityIdsInput>>;
  readFrom?: InputMaybe<Scalars['DateTime']>;
  readUntil?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  intervalSize?: InputMaybe<AggregationIntervals>;
}>;


export type DeviceChartDataQuery = { __typename?: 'Query', multipleDeviceSensorDataByViews?: Array<{ __typename?: 'TimeAndAggregationParameter', aggregationIntervalActive: AggregationIntervals, aggregationIntervalsList?: Array<AggregationIntervals> | null, deviceAndCapabilityInfos?: Array<{ __typename?: 'DeviceAndCapabilityInfo', deviceModelCapabilityId: string, telemetryRecords?: Array<{ __typename?: 'TelemetryRecord', utcTimeMeasured: any, valueString?: string | null } | null> | null } | null> | null } | null> | null };

export type DeviceChartDataMinQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityIdsInput>> | InputMaybe<DeviceModelCapabilityIdsInput>>;
}>;


export type DeviceChartDataMinQuery = { __typename?: 'Query', multipleDeviceSensorDataInfo?: { __typename?: 'SensorMeasurementsInfo', minUtcTimeMeasured: any } | null };

export type DeviceQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type DeviceQuery = { __typename?: 'Query', device?: { __typename?: 'Device', name: string, description?: string | null, deviceIdentifier?: string | null, serialNo?: string | null, deviceModel: { __typename?: 'DeviceModel', name: string, deviceType: { __typename?: 'DeviceType', name: string }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, technicalMin?: number | null, technicalMax?: number | null, capability: { __typename?: 'Capability', name: string }, unit: { __typename?: 'Unit', id: string, name: string, unitSymbol?: string | null } }> }, parentDevice?: { __typename?: 'Device', name: string } | null } | null, placementOfDevice?: { __typename?: 'PlacementOfDevice', mappingPlacementType?: string | null, siteName?: string | null, buildingName?: string | null, storeyName?: string | null, zoneName?: string | null, buildingId?: string | null, storeyId?: string | null, zoneId?: string | null } | null };

export type DeviceExportDataQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  capabilityIds?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
  limitPerCapability?: InputMaybe<Scalars['Int']>;
}>;


export type DeviceExportDataQuery = { __typename?: 'Query', latestSensorData?: Array<{ __typename?: 'DataPoint', deviceId?: string | null, deviceModelCapabilityId?: string | null, utcTimeMeasured?: any | null, valueString?: string | null } | null> | null };

export type DeviceTableDataQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  capabilityId: Scalars['UUID'];
  readFrom?: InputMaybe<Scalars['DateTime']>;
  readUntil?: InputMaybe<Scalars['DateTime']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type DeviceTableDataQuery = { __typename?: 'Query', sensorMeasurements?: { __typename?: 'SensorMeasurementsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'SensorMeasurement', deviceId: string, deviceModelCapabilityId: string, utcTimeMeasured: any, valueString?: string | null }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean } } | null };

export type DeviceTableDataMinQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  capabilityId: Scalars['UUID'];
}>;


export type DeviceTableDataMinQuery = { __typename?: 'Query', sensorMeasurementsInfo?: { __typename?: 'SensorMeasurementsInfo', minUtcTimeMeasured: any } | null };

export type DevicesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where: DevicesByBuildingFilterInput;
  order?: InputMaybe<Array<DevicesByBuildingSortInput> | DevicesByBuildingSortInput>;
}>;


export type DevicesQuery = { __typename?: 'Query', devicesByBuildings?: { __typename?: 'DevicesByBuildingsCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'DevicesByBuilding', deviceId?: string | null, storeyId?: string | null, zoneId?: string | null, device: { __typename?: 'Device', name: string, id: string, serialNo?: string | null, description?: string | null, deviceIdentifier?: string | null, parentDevice?: { __typename?: 'Device', name: string } | null, deviceModel: { __typename?: 'DeviceModel', name: string, deviceType: { __typename?: 'DeviceType', name: string }, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, capability: { __typename?: 'Capability', name: string }, unit: { __typename?: 'Unit', unitSymbol?: string | null } }> }, deviceAttributeValues: Array<{ __typename?: 'DeviceAttributeValue', value?: string | null, id: string }> } }> | null } | null };

export type DevicesBuildingAndAttributesQueryVariables = Exact<{
  buildingId: Scalars['UUID'];
}>;


export type DevicesBuildingAndAttributesQuery = { __typename?: 'Query', attributesByBuildings?: { __typename?: 'AttributesByBuildingsCollectionSegment', items?: Array<{ __typename?: 'AttributesByBuildings', deviceAttributes?: Array<{ __typename?: 'AttributeDefinition', id: string, name: string, dataType: DataTypes }> | null }> | null } | null };

export type DevicesBuildingQueryVariables = Exact<{
  buildingId: Scalars['UUID'];
}>;


export type DevicesBuildingQuery = { __typename?: 'Query', building?: { __typename?: 'Building', id: string, name: string, storeys: Array<{ __typename?: 'Storey', id: string, name: string, zones: Array<{ __typename?: 'Zone', name: string, id: string }> }> } | null };

export type ChartDataForDeviceQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityIdsInput>> | InputMaybe<DeviceModelCapabilityIdsInput>>;
  timeSpan?: InputMaybe<TimeSpans>;
  intervalSize?: InputMaybe<AggregationIntervals>;
}>;


export type ChartDataForDeviceQuery = { __typename?: 'Query', multipleDeviceSensorDataByViews?: Array<{ __typename?: 'TimeAndAggregationParameter', aggregationIntervalActive: AggregationIntervals, deviceAndCapabilityInfos?: Array<{ __typename?: 'DeviceAndCapabilityInfo', deviceModelCapabilityId: string, telemetryRecords?: Array<{ __typename?: 'TelemetryRecord', utcTimeMeasured: any, valueString?: string | null } | null> | null } | null> | null } | null> | null };

export type LastCapabilityValueForDeviceQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
  deviceModelCapabilityId: Scalars['UUID'];
}>;


export type LastCapabilityValueForDeviceQuery = { __typename?: 'Query', sensorMeasurements?: { __typename?: 'SensorMeasurementsCollectionSegment', items?: Array<{ __typename?: 'SensorMeasurement', valueString?: string | null, utcTimeMeasured: any }> | null } | null };

export type PropertiesQueryVariables = Exact<{
  where?: InputMaybe<SiteFilterInput>;
}>;


export type PropertiesQuery = { __typename?: 'Query', properties?: { __typename?: 'SitesCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Site', id: string, name: string, location?: { __typename?: 'Location', id: string, street?: string | null, no?: string | null, zip?: string | null, city?: string | null, lat?: number | null, lon?: number | null } | null, buildings: Array<{ __typename?: 'Building', name: string, id: string, location?: { __typename?: 'Location', id: string, street?: string | null, no?: string | null, zip?: string | null, city?: string | null, lat?: number | null, lon?: number | null } | null }> }> | null } | null };


export const BuildingContextBuildingDocument = gql`
    query BuildingContextBuilding($buildingId: UUID!) {
  building(id: $buildingId) {
    id
    name
    storeys {
      id
      name
      zones {
        name
        id
        storeyId
      }
    }
  }
}
    `;

/**
 * __useBuildingContextBuildingQuery__
 *
 * To run a query within a React component, call `useBuildingContextBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingContextBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingContextBuildingQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingContextBuildingQuery(baseOptions: Apollo.QueryHookOptions<BuildingContextBuildingQuery, BuildingContextBuildingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingContextBuildingQuery, BuildingContextBuildingQueryVariables>(BuildingContextBuildingDocument, options);
      }
export function useBuildingContextBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingContextBuildingQuery, BuildingContextBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingContextBuildingQuery, BuildingContextBuildingQueryVariables>(BuildingContextBuildingDocument, options);
        }
export type BuildingContextBuildingQueryHookResult = ReturnType<typeof useBuildingContextBuildingQuery>;
export type BuildingContextBuildingLazyQueryHookResult = ReturnType<typeof useBuildingContextBuildingLazyQuery>;
export type BuildingContextBuildingQueryResult = Apollo.QueryResult<BuildingContextBuildingQuery, BuildingContextBuildingQueryVariables>;
export const PlatformContextDocument = gql`
    query PlatformContext {
  deviceTypes {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __usePlatformContextQuery__
 *
 * To run a query within a React component, call `usePlatformContextQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformContextQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlatformContextQuery(baseOptions?: Apollo.QueryHookOptions<PlatformContextQuery, PlatformContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformContextQuery, PlatformContextQueryVariables>(PlatformContextDocument, options);
      }
export function usePlatformContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformContextQuery, PlatformContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformContextQuery, PlatformContextQueryVariables>(PlatformContextDocument, options);
        }
export type PlatformContextQueryHookResult = ReturnType<typeof usePlatformContextQuery>;
export type PlatformContextLazyQueryHookResult = ReturnType<typeof usePlatformContextLazyQuery>;
export type PlatformContextQueryResult = Apollo.QueryResult<PlatformContextQuery, PlatformContextQueryVariables>;
export const InfoDocument = gql`
    query Info($buildingId: UUID!) {
  building(id: $buildingId) {
    description
    location {
      city
      country
      no
      street
      zip
      lat
      lon
    }
  }
}
    `;

/**
 * __useInfoQuery__
 *
 * To run a query within a React component, call `useInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useInfoQuery(baseOptions: Apollo.QueryHookOptions<InfoQuery, InfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfoQuery, InfoQueryVariables>(InfoDocument, options);
      }
export function useInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoQuery, InfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfoQuery, InfoQueryVariables>(InfoDocument, options);
        }
export type InfoQueryHookResult = ReturnType<typeof useInfoQuery>;
export type InfoLazyQueryHookResult = ReturnType<typeof useInfoLazyQuery>;
export type InfoQueryResult = Apollo.QueryResult<InfoQuery, InfoQueryVariables>;
export const WidgetCardDataDocument = gql`
    query WidgetCardData($devices: [QueryFiltersMultipleDeviceAndCapabilityInput], $timeSpan: TimeSpans, $readFrom: DateTime, $readUntil: DateTime, $readUntilNow: Boolean, $intervalSize: AggregationIntervals) {
  multipleDeviceSensorDataByViews(
    where: {queryFiltersMultipleDeviceAndCapability: $devices, readFromUtc: $readFrom, readUntilUtc: $readUntil, timeSpan: $timeSpan, readUntilNow: $readUntilNow, aggregationIntervalTypeActive: $intervalSize}
  ) {
    deviceAndCapabilityInfos {
      deviceId
      device {
        name
      }
      deviceModelCapabilityId
      deviceModelCapability {
        id
        capability {
          name
        }
        unit {
          unitSymbol
        }
      }
      telemetryRecords {
        utcTimeMeasured
        valueString
      }
    }
  }
}
    `;

/**
 * __useWidgetCardDataQuery__
 *
 * To run a query within a React component, call `useWidgetCardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetCardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetCardDataQuery({
 *   variables: {
 *      devices: // value for 'devices'
 *      timeSpan: // value for 'timeSpan'
 *      readFrom: // value for 'readFrom'
 *      readUntil: // value for 'readUntil'
 *      readUntilNow: // value for 'readUntilNow'
 *      intervalSize: // value for 'intervalSize'
 *   },
 * });
 */
export function useWidgetCardDataQuery(baseOptions?: Apollo.QueryHookOptions<WidgetCardDataQuery, WidgetCardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetCardDataQuery, WidgetCardDataQueryVariables>(WidgetCardDataDocument, options);
      }
export function useWidgetCardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetCardDataQuery, WidgetCardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetCardDataQuery, WidgetCardDataQueryVariables>(WidgetCardDataDocument, options);
        }
export type WidgetCardDataQueryHookResult = ReturnType<typeof useWidgetCardDataQuery>;
export type WidgetCardDataLazyQueryHookResult = ReturnType<typeof useWidgetCardDataLazyQuery>;
export type WidgetCardDataQueryResult = Apollo.QueryResult<WidgetCardDataQuery, WidgetCardDataQueryVariables>;
export const InteractiveBuildingDocument = gql`
    query InteractiveBuilding($id: UUID!, $zoneIds: [UUID!]) {
  devicesByBuildings(
    where: {and: [{buildingId: {eq: $id}}, {zoneId: {in: $zoneIds}}]}
  ) {
    items {
      zoneId
      device {
        id
        name
        deviceModel {
          deviceType {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useInteractiveBuildingQuery__
 *
 * To run a query within a React component, call `useInteractiveBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractiveBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractiveBuildingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      zoneIds: // value for 'zoneIds'
 *   },
 * });
 */
export function useInteractiveBuildingQuery(baseOptions: Apollo.QueryHookOptions<InteractiveBuildingQuery, InteractiveBuildingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractiveBuildingQuery, InteractiveBuildingQueryVariables>(InteractiveBuildingDocument, options);
      }
export function useInteractiveBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractiveBuildingQuery, InteractiveBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractiveBuildingQuery, InteractiveBuildingQueryVariables>(InteractiveBuildingDocument, options);
        }
export type InteractiveBuildingQueryHookResult = ReturnType<typeof useInteractiveBuildingQuery>;
export type InteractiveBuildingLazyQueryHookResult = ReturnType<typeof useInteractiveBuildingLazyQuery>;
export type InteractiveBuildingQueryResult = Apollo.QueryResult<InteractiveBuildingQuery, InteractiveBuildingQueryVariables>;
export const WidgetFormDevicesSearchDocument = gql`
    query WidgetFormDevicesSearch($skip: Int, $take: Int, $where: DevicesByBuildingFilterInput!, $order: [DevicesByBuildingSortInput!]) {
  devicesByBuildings(skip: $skip, take: $take, where: $where, order: $order) {
    items {
      deviceId
      storeyId
      zoneId
      device {
        name
        id
        serialNo
        description
        deviceModel {
          deviceType {
            name
          }
          deviceModelCapabilities {
            id
            capability {
              name
            }
          }
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useWidgetFormDevicesSearchQuery__
 *
 * To run a query within a React component, call `useWidgetFormDevicesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetFormDevicesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetFormDevicesSearchQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useWidgetFormDevicesSearchQuery(baseOptions: Apollo.QueryHookOptions<WidgetFormDevicesSearchQuery, WidgetFormDevicesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetFormDevicesSearchQuery, WidgetFormDevicesSearchQueryVariables>(WidgetFormDevicesSearchDocument, options);
      }
export function useWidgetFormDevicesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetFormDevicesSearchQuery, WidgetFormDevicesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetFormDevicesSearchQuery, WidgetFormDevicesSearchQueryVariables>(WidgetFormDevicesSearchDocument, options);
        }
export type WidgetFormDevicesSearchQueryHookResult = ReturnType<typeof useWidgetFormDevicesSearchQuery>;
export type WidgetFormDevicesSearchLazyQueryHookResult = ReturnType<typeof useWidgetFormDevicesSearchLazyQuery>;
export type WidgetFormDevicesSearchQueryResult = Apollo.QueryResult<WidgetFormDevicesSearchQuery, WidgetFormDevicesSearchQueryVariables>;
export const WidgetFormDataDocument = gql`
    query WidgetFormData($devices: [QueryFiltersMultipleDeviceAndCapabilityInput], $timeSpan: TimeSpans, $readFrom: DateTime, $readUntil: DateTime, $readUntilNow: Boolean, $intervalSize: AggregationIntervals) {
  multipleDeviceSensorDataByViews(
    where: {queryFiltersMultipleDeviceAndCapability: $devices, aggregationIntervalTypeActive: $intervalSize, readFromUtc: $readFrom, readUntilUtc: $readUntil, readUntilNow: $readUntilNow, timeSpan: $timeSpan}
  ) {
    aggregationIntervalActive
    aggregationIntervalDefault
    aggregationIntervalsList
    deviceAndCapabilityInfos {
      device {
        name
        serialNo
        description
      }
      deviceId
      deviceModelCapabilityId
      deviceModelCapability {
        id
        unit {
          id
          name
          unitSymbol
        }
        capability {
          name
        }
      }
      telemetryRecords {
        utcTimeMeasured
        valueString
      }
    }
  }
  multipleDeviceSensorDataInfo(queryFilters: $devices) {
    minUtcTimeMeasured
  }
}
    `;

/**
 * __useWidgetFormDataQuery__
 *
 * To run a query within a React component, call `useWidgetFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetFormDataQuery({
 *   variables: {
 *      devices: // value for 'devices'
 *      timeSpan: // value for 'timeSpan'
 *      readFrom: // value for 'readFrom'
 *      readUntil: // value for 'readUntil'
 *      readUntilNow: // value for 'readUntilNow'
 *      intervalSize: // value for 'intervalSize'
 *   },
 * });
 */
export function useWidgetFormDataQuery(baseOptions?: Apollo.QueryHookOptions<WidgetFormDataQuery, WidgetFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetFormDataQuery, WidgetFormDataQueryVariables>(WidgetFormDataDocument, options);
      }
export function useWidgetFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetFormDataQuery, WidgetFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetFormDataQuery, WidgetFormDataQueryVariables>(WidgetFormDataDocument, options);
        }
export type WidgetFormDataQueryHookResult = ReturnType<typeof useWidgetFormDataQuery>;
export type WidgetFormDataLazyQueryHookResult = ReturnType<typeof useWidgetFormDataLazyQuery>;
export type WidgetFormDataQueryResult = Apollo.QueryResult<WidgetFormDataQuery, WidgetFormDataQueryVariables>;
export const WidgetFormDevicesDocument = gql`
    query WidgetFormDevices($deviceIds: [UUID!]) {
  devices(where: {deviceId: {in: $deviceIds}}) {
    items {
      name
      id
      serialNo
      description
      deviceModel {
        deviceType {
          name
        }
        deviceModelCapabilities {
          id
          capability {
            name
          }
        }
      }
    }
  }
  mappingDeviceZones(where: {deviceId: {in: $deviceIds}}) {
    items {
      device {
        id
      }
      zone {
        id
        name
        storeyId
        storey {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useWidgetFormDevicesQuery__
 *
 * To run a query within a React component, call `useWidgetFormDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetFormDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetFormDevicesQuery({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useWidgetFormDevicesQuery(baseOptions?: Apollo.QueryHookOptions<WidgetFormDevicesQuery, WidgetFormDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetFormDevicesQuery, WidgetFormDevicesQueryVariables>(WidgetFormDevicesDocument, options);
      }
export function useWidgetFormDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetFormDevicesQuery, WidgetFormDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetFormDevicesQuery, WidgetFormDevicesQueryVariables>(WidgetFormDevicesDocument, options);
        }
export type WidgetFormDevicesQueryHookResult = ReturnType<typeof useWidgetFormDevicesQuery>;
export type WidgetFormDevicesLazyQueryHookResult = ReturnType<typeof useWidgetFormDevicesLazyQuery>;
export type WidgetFormDevicesQueryResult = Apollo.QueryResult<WidgetFormDevicesQuery, WidgetFormDevicesQueryVariables>;
export const DashboardSelectCapabilitiesDocument = gql`
    query DashboardSelectCapabilities($skip: Int, $take: Int, $where: DevicesByBuildingFilterInput!, $order: [DevicesByBuildingSortInput!]) {
  devicesByBuildings(skip: $skip, take: $take, where: $where, order: $order) {
    items {
      deviceId
      storeyId
      zoneId
      device {
        name
        id
        serialNo
        description
        deviceModel {
          deviceType {
            name
          }
          deviceModelCapabilities {
            id
            capability {
              name
            }
          }
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useDashboardSelectCapabilitiesQuery__
 *
 * To run a query within a React component, call `useDashboardSelectCapabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardSelectCapabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardSelectCapabilitiesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDashboardSelectCapabilitiesQuery(baseOptions: Apollo.QueryHookOptions<DashboardSelectCapabilitiesQuery, DashboardSelectCapabilitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardSelectCapabilitiesQuery, DashboardSelectCapabilitiesQueryVariables>(DashboardSelectCapabilitiesDocument, options);
      }
export function useDashboardSelectCapabilitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardSelectCapabilitiesQuery, DashboardSelectCapabilitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardSelectCapabilitiesQuery, DashboardSelectCapabilitiesQueryVariables>(DashboardSelectCapabilitiesDocument, options);
        }
export type DashboardSelectCapabilitiesQueryHookResult = ReturnType<typeof useDashboardSelectCapabilitiesQuery>;
export type DashboardSelectCapabilitiesLazyQueryHookResult = ReturnType<typeof useDashboardSelectCapabilitiesLazyQuery>;
export type DashboardSelectCapabilitiesQueryResult = Apollo.QueryResult<DashboardSelectCapabilitiesQuery, DashboardSelectCapabilitiesQueryVariables>;
export const DashboardSelectCapabilitiesSelectedDeviceDocument = gql`
    query DashboardSelectCapabilitiesSelectedDevice($deviceId: UUID!) {
  placementOfDevice(deviceId: $deviceId) {
    deviceId
    storeyId
    zoneId
  }
  device(id: $deviceId) {
    id
    name
    serialNo
    description
    deviceModel {
      deviceType {
        name
      }
      deviceModelCapabilities {
        id
        capability {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useDashboardSelectCapabilitiesSelectedDeviceQuery__
 *
 * To run a query within a React component, call `useDashboardSelectCapabilitiesSelectedDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardSelectCapabilitiesSelectedDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardSelectCapabilitiesSelectedDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDashboardSelectCapabilitiesSelectedDeviceQuery(baseOptions: Apollo.QueryHookOptions<DashboardSelectCapabilitiesSelectedDeviceQuery, DashboardSelectCapabilitiesSelectedDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardSelectCapabilitiesSelectedDeviceQuery, DashboardSelectCapabilitiesSelectedDeviceQueryVariables>(DashboardSelectCapabilitiesSelectedDeviceDocument, options);
      }
export function useDashboardSelectCapabilitiesSelectedDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardSelectCapabilitiesSelectedDeviceQuery, DashboardSelectCapabilitiesSelectedDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardSelectCapabilitiesSelectedDeviceQuery, DashboardSelectCapabilitiesSelectedDeviceQueryVariables>(DashboardSelectCapabilitiesSelectedDeviceDocument, options);
        }
export type DashboardSelectCapabilitiesSelectedDeviceQueryHookResult = ReturnType<typeof useDashboardSelectCapabilitiesSelectedDeviceQuery>;
export type DashboardSelectCapabilitiesSelectedDeviceLazyQueryHookResult = ReturnType<typeof useDashboardSelectCapabilitiesSelectedDeviceLazyQuery>;
export type DashboardSelectCapabilitiesSelectedDeviceQueryResult = Apollo.QueryResult<DashboardSelectCapabilitiesSelectedDeviceQuery, DashboardSelectCapabilitiesSelectedDeviceQueryVariables>;
export const WidgetChartDataOfDeviceDocument = gql`
    query WidgetChartDataOfDevice($devices: [QueryFiltersMultipleDeviceAndCapabilityInput], $timeSpan: TimeSpans, $readFrom: DateTime, $readUntil: DateTime, $readUntilNow: Boolean, $intervalSize: AggregationIntervals) {
  multipleDeviceSensorDataByViews(
    where: {queryFiltersMultipleDeviceAndCapability: $devices, aggregationIntervalTypeActive: $intervalSize, readFromUtc: $readFrom, readUntilUtc: $readUntil, timeSpan: $timeSpan, readUntilNow: $readUntilNow}
  ) {
    aggregationIntervalActive
    deviceAndCapabilityInfos {
      deviceId
      device {
        name
      }
      deviceModelCapabilityId
      telemetryRecords {
        utcTimeMeasured
        valueString
      }
      deviceModelCapability {
        unit {
          unitSymbol
        }
        capability {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useWidgetChartDataOfDeviceQuery__
 *
 * To run a query within a React component, call `useWidgetChartDataOfDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetChartDataOfDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetChartDataOfDeviceQuery({
 *   variables: {
 *      devices: // value for 'devices'
 *      timeSpan: // value for 'timeSpan'
 *      readFrom: // value for 'readFrom'
 *      readUntil: // value for 'readUntil'
 *      readUntilNow: // value for 'readUntilNow'
 *      intervalSize: // value for 'intervalSize'
 *   },
 * });
 */
export function useWidgetChartDataOfDeviceQuery(baseOptions?: Apollo.QueryHookOptions<WidgetChartDataOfDeviceQuery, WidgetChartDataOfDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetChartDataOfDeviceQuery, WidgetChartDataOfDeviceQueryVariables>(WidgetChartDataOfDeviceDocument, options);
      }
export function useWidgetChartDataOfDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetChartDataOfDeviceQuery, WidgetChartDataOfDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetChartDataOfDeviceQuery, WidgetChartDataOfDeviceQueryVariables>(WidgetChartDataOfDeviceDocument, options);
        }
export type WidgetChartDataOfDeviceQueryHookResult = ReturnType<typeof useWidgetChartDataOfDeviceQuery>;
export type WidgetChartDataOfDeviceLazyQueryHookResult = ReturnType<typeof useWidgetChartDataOfDeviceLazyQuery>;
export type WidgetChartDataOfDeviceQueryResult = Apollo.QueryResult<WidgetChartDataOfDeviceQuery, WidgetChartDataOfDeviceQueryVariables>;
export const WidgetLastValueOfDeviceDocument = gql`
    query WidgetLastValueOfDevice($deviceId: UUID!, $deviceModelCapabilityId: UUID!) {
  sensorMeasurements(
    where: {limit: 1, deviceId: $deviceId, deviceModelCapabilityIds: [$deviceModelCapabilityId]}
  ) {
    items {
      valueString
      utcTimeMeasured
      deviceModelCapability {
        unit {
          unitSymbol
        }
      }
    }
  }
}
    `;

/**
 * __useWidgetLastValueOfDeviceQuery__
 *
 * To run a query within a React component, call `useWidgetLastValueOfDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetLastValueOfDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetLastValueOfDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceModelCapabilityId: // value for 'deviceModelCapabilityId'
 *   },
 * });
 */
export function useWidgetLastValueOfDeviceQuery(baseOptions: Apollo.QueryHookOptions<WidgetLastValueOfDeviceQuery, WidgetLastValueOfDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetLastValueOfDeviceQuery, WidgetLastValueOfDeviceQueryVariables>(WidgetLastValueOfDeviceDocument, options);
      }
export function useWidgetLastValueOfDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetLastValueOfDeviceQuery, WidgetLastValueOfDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetLastValueOfDeviceQuery, WidgetLastValueOfDeviceQueryVariables>(WidgetLastValueOfDeviceDocument, options);
        }
export type WidgetLastValueOfDeviceQueryHookResult = ReturnType<typeof useWidgetLastValueOfDeviceQuery>;
export type WidgetLastValueOfDeviceLazyQueryHookResult = ReturnType<typeof useWidgetLastValueOfDeviceLazyQuery>;
export type WidgetLastValueOfDeviceQueryResult = Apollo.QueryResult<WidgetLastValueOfDeviceQuery, WidgetLastValueOfDeviceQueryVariables>;
export const ChartEditPanelDevicesDocument = gql`
    query ChartEditPanelDevices($deviceIds: [UUID!]) {
  devices(where: {deviceId: {in: $deviceIds}}) {
    items {
      id
      name
      deviceModel {
        deviceModelCapabilities {
          id
          capability {
            id
            name
          }
          unit {
            name
            unitSymbol
          }
        }
      }
    }
  }
}
    `;

/**
 * __useChartEditPanelDevicesQuery__
 *
 * To run a query within a React component, call `useChartEditPanelDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartEditPanelDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartEditPanelDevicesQuery({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useChartEditPanelDevicesQuery(baseOptions?: Apollo.QueryHookOptions<ChartEditPanelDevicesQuery, ChartEditPanelDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartEditPanelDevicesQuery, ChartEditPanelDevicesQueryVariables>(ChartEditPanelDevicesDocument, options);
      }
export function useChartEditPanelDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartEditPanelDevicesQuery, ChartEditPanelDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartEditPanelDevicesQuery, ChartEditPanelDevicesQueryVariables>(ChartEditPanelDevicesDocument, options);
        }
export type ChartEditPanelDevicesQueryHookResult = ReturnType<typeof useChartEditPanelDevicesQuery>;
export type ChartEditPanelDevicesLazyQueryHookResult = ReturnType<typeof useChartEditPanelDevicesLazyQuery>;
export type ChartEditPanelDevicesQueryResult = Apollo.QueryResult<ChartEditPanelDevicesQuery, ChartEditPanelDevicesQueryVariables>;
export const LastValueEditPanelDeviceDocument = gql`
    query LastValueEditPanelDevice($deviceId: UUID!) {
  device(id: $deviceId) {
    name
    deviceModel {
      deviceModelCapabilities {
        id
        capability {
          id
          name
        }
        unit {
          name
          unitSymbol
        }
      }
    }
  }
}
    `;

/**
 * __useLastValueEditPanelDeviceQuery__
 *
 * To run a query within a React component, call `useLastValueEditPanelDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastValueEditPanelDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastValueEditPanelDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useLastValueEditPanelDeviceQuery(baseOptions: Apollo.QueryHookOptions<LastValueEditPanelDeviceQuery, LastValueEditPanelDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastValueEditPanelDeviceQuery, LastValueEditPanelDeviceQueryVariables>(LastValueEditPanelDeviceDocument, options);
      }
export function useLastValueEditPanelDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastValueEditPanelDeviceQuery, LastValueEditPanelDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastValueEditPanelDeviceQuery, LastValueEditPanelDeviceQueryVariables>(LastValueEditPanelDeviceDocument, options);
        }
export type LastValueEditPanelDeviceQueryHookResult = ReturnType<typeof useLastValueEditPanelDeviceQuery>;
export type LastValueEditPanelDeviceLazyQueryHookResult = ReturnType<typeof useLastValueEditPanelDeviceLazyQuery>;
export type LastValueEditPanelDeviceQueryResult = Apollo.QueryResult<LastValueEditPanelDeviceQuery, LastValueEditPanelDeviceQueryVariables>;
export const PointPreviewDeviceDocument = gql`
    query PointPreviewDevice($deviceId: UUID!, $capabilityId: UUID!) {
  device(id: $deviceId) {
    id
    deviceIdentifier
    serialNo
  }
  placementOfDevice(deviceId: $deviceId) {
    mappingPlacementType
    siteName
    buildingName
    storeyName
    zoneName
    buildingId
    storeyId
    zoneId
  }
  deviceModelCapability(id: $capabilityId) {
    id
    capability {
      name
    }
    unit {
      unitSymbol
    }
  }
}
    `;

/**
 * __usePointPreviewDeviceQuery__
 *
 * To run a query within a React component, call `usePointPreviewDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointPreviewDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointPreviewDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      capabilityId: // value for 'capabilityId'
 *   },
 * });
 */
export function usePointPreviewDeviceQuery(baseOptions: Apollo.QueryHookOptions<PointPreviewDeviceQuery, PointPreviewDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointPreviewDeviceQuery, PointPreviewDeviceQueryVariables>(PointPreviewDeviceDocument, options);
      }
export function usePointPreviewDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointPreviewDeviceQuery, PointPreviewDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointPreviewDeviceQuery, PointPreviewDeviceQueryVariables>(PointPreviewDeviceDocument, options);
        }
export type PointPreviewDeviceQueryHookResult = ReturnType<typeof usePointPreviewDeviceQuery>;
export type PointPreviewDeviceLazyQueryHookResult = ReturnType<typeof usePointPreviewDeviceLazyQuery>;
export type PointPreviewDeviceQueryResult = Apollo.QueryResult<PointPreviewDeviceQuery, PointPreviewDeviceQueryVariables>;
export const PointPreviewDeviceCapabilityChartDocument = gql`
    query PointPreviewDeviceCapabilityChart($deviceId: UUID!, $capabilityId: UUID!, $timeSpan: TimeSpans, $intervalSize: AggregationIntervals) {
  multipleDeviceSensorDataByViews(
    where: {queryFiltersMultipleDeviceAndCapability: [{deviceId: $deviceId, deviceModelCapabilityIds: [{deviceModelCapabilityId: $capabilityId}]}], aggregationIntervalTypeActive: $intervalSize, timeSpan: $timeSpan, readUntilNow: true}
  ) {
    aggregationIntervalActive
    deviceAndCapabilityInfos {
      deviceModelCapabilityId
      telemetryRecords {
        utcTimeMeasured
        valueString
      }
    }
  }
}
    `;

/**
 * __usePointPreviewDeviceCapabilityChartQuery__
 *
 * To run a query within a React component, call `usePointPreviewDeviceCapabilityChartQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointPreviewDeviceCapabilityChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointPreviewDeviceCapabilityChartQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      capabilityId: // value for 'capabilityId'
 *      timeSpan: // value for 'timeSpan'
 *      intervalSize: // value for 'intervalSize'
 *   },
 * });
 */
export function usePointPreviewDeviceCapabilityChartQuery(baseOptions: Apollo.QueryHookOptions<PointPreviewDeviceCapabilityChartQuery, PointPreviewDeviceCapabilityChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointPreviewDeviceCapabilityChartQuery, PointPreviewDeviceCapabilityChartQueryVariables>(PointPreviewDeviceCapabilityChartDocument, options);
      }
export function usePointPreviewDeviceCapabilityChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointPreviewDeviceCapabilityChartQuery, PointPreviewDeviceCapabilityChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointPreviewDeviceCapabilityChartQuery, PointPreviewDeviceCapabilityChartQueryVariables>(PointPreviewDeviceCapabilityChartDocument, options);
        }
export type PointPreviewDeviceCapabilityChartQueryHookResult = ReturnType<typeof usePointPreviewDeviceCapabilityChartQuery>;
export type PointPreviewDeviceCapabilityChartLazyQueryHookResult = ReturnType<typeof usePointPreviewDeviceCapabilityChartLazyQuery>;
export type PointPreviewDeviceCapabilityChartQueryResult = Apollo.QueryResult<PointPreviewDeviceCapabilityChartQuery, PointPreviewDeviceCapabilityChartQueryVariables>;
export const DashboardAggregationIntervalsDocument = gql`
    query DashboardAggregationIntervals($timeSpan: TimeSpans, $readFrom: DateTime, $readUntil: DateTime, $readUntilNow: Boolean, $intervalSize: AggregationIntervals) {
  multipleDeviceSensorDataByViews(
    where: {queryFiltersMultipleDeviceAndCapability: [{deviceId: "00000000-0000-0000-0000-000000000000", deviceModelCapabilityIds: [{deviceModelCapabilityId: "00000000-0000-0000-0000-000000000000"}]}], aggregationIntervalTypeActive: $intervalSize, readFromUtc: $readFrom, readUntilUtc: $readUntil, timeSpan: $timeSpan, readUntilNow: $readUntilNow}
  ) {
    aggregationIntervalActive
    aggregationIntervalsList
  }
}
    `;

/**
 * __useDashboardAggregationIntervalsQuery__
 *
 * To run a query within a React component, call `useDashboardAggregationIntervalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAggregationIntervalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAggregationIntervalsQuery({
 *   variables: {
 *      timeSpan: // value for 'timeSpan'
 *      readFrom: // value for 'readFrom'
 *      readUntil: // value for 'readUntil'
 *      readUntilNow: // value for 'readUntilNow'
 *      intervalSize: // value for 'intervalSize'
 *   },
 * });
 */
export function useDashboardAggregationIntervalsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardAggregationIntervalsQuery, DashboardAggregationIntervalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardAggregationIntervalsQuery, DashboardAggregationIntervalsQueryVariables>(DashboardAggregationIntervalsDocument, options);
      }
export function useDashboardAggregationIntervalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAggregationIntervalsQuery, DashboardAggregationIntervalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardAggregationIntervalsQuery, DashboardAggregationIntervalsQueryVariables>(DashboardAggregationIntervalsDocument, options);
        }
export type DashboardAggregationIntervalsQueryHookResult = ReturnType<typeof useDashboardAggregationIntervalsQuery>;
export type DashboardAggregationIntervalsLazyQueryHookResult = ReturnType<typeof useDashboardAggregationIntervalsLazyQuery>;
export type DashboardAggregationIntervalsQueryResult = Apollo.QueryResult<DashboardAggregationIntervalsQuery, DashboardAggregationIntervalsQueryVariables>;
export const DeviceChartDataDocument = gql`
    query DeviceChartData($deviceId: UUID!, $deviceModelCapabilityIds: [DeviceModelCapabilityIdsInput], $readFrom: DateTime, $readUntil: DateTime, $readUntilNow: Boolean, $intervalSize: AggregationIntervals) {
  multipleDeviceSensorDataByViews(
    where: {queryFiltersMultipleDeviceAndCapability: [{deviceId: $deviceId, deviceModelCapabilityIds: $deviceModelCapabilityIds}], aggregationIntervalTypeActive: $intervalSize, readFromUtc: $readFrom, readUntilUtc: $readUntil, readUntilNow: $readUntilNow}
  ) {
    aggregationIntervalActive
    aggregationIntervalsList
    deviceAndCapabilityInfos {
      deviceModelCapabilityId
      telemetryRecords {
        utcTimeMeasured
        valueString
      }
    }
  }
}
    `;

/**
 * __useDeviceChartDataQuery__
 *
 * To run a query within a React component, call `useDeviceChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceChartDataQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceModelCapabilityIds: // value for 'deviceModelCapabilityIds'
 *      readFrom: // value for 'readFrom'
 *      readUntil: // value for 'readUntil'
 *      readUntilNow: // value for 'readUntilNow'
 *      intervalSize: // value for 'intervalSize'
 *   },
 * });
 */
export function useDeviceChartDataQuery(baseOptions: Apollo.QueryHookOptions<DeviceChartDataQuery, DeviceChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceChartDataQuery, DeviceChartDataQueryVariables>(DeviceChartDataDocument, options);
      }
export function useDeviceChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceChartDataQuery, DeviceChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceChartDataQuery, DeviceChartDataQueryVariables>(DeviceChartDataDocument, options);
        }
export type DeviceChartDataQueryHookResult = ReturnType<typeof useDeviceChartDataQuery>;
export type DeviceChartDataLazyQueryHookResult = ReturnType<typeof useDeviceChartDataLazyQuery>;
export type DeviceChartDataQueryResult = Apollo.QueryResult<DeviceChartDataQuery, DeviceChartDataQueryVariables>;
export const DeviceChartDataMinDocument = gql`
    query DeviceChartDataMin($deviceId: UUID!, $deviceModelCapabilityIds: [DeviceModelCapabilityIdsInput]) {
  multipleDeviceSensorDataInfo(
    queryFilters: [{deviceId: $deviceId, deviceModelCapabilityIds: $deviceModelCapabilityIds}]
  ) {
    minUtcTimeMeasured
  }
}
    `;

/**
 * __useDeviceChartDataMinQuery__
 *
 * To run a query within a React component, call `useDeviceChartDataMinQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceChartDataMinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceChartDataMinQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceModelCapabilityIds: // value for 'deviceModelCapabilityIds'
 *   },
 * });
 */
export function useDeviceChartDataMinQuery(baseOptions: Apollo.QueryHookOptions<DeviceChartDataMinQuery, DeviceChartDataMinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceChartDataMinQuery, DeviceChartDataMinQueryVariables>(DeviceChartDataMinDocument, options);
      }
export function useDeviceChartDataMinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceChartDataMinQuery, DeviceChartDataMinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceChartDataMinQuery, DeviceChartDataMinQueryVariables>(DeviceChartDataMinDocument, options);
        }
export type DeviceChartDataMinQueryHookResult = ReturnType<typeof useDeviceChartDataMinQuery>;
export type DeviceChartDataMinLazyQueryHookResult = ReturnType<typeof useDeviceChartDataMinLazyQuery>;
export type DeviceChartDataMinQueryResult = Apollo.QueryResult<DeviceChartDataMinQuery, DeviceChartDataMinQueryVariables>;
export const DeviceDocument = gql`
    query Device($deviceId: UUID!) {
  device(id: $deviceId) {
    name
    description
    deviceModel {
      name
      deviceType {
        name
      }
      deviceModelCapabilities {
        id
        capability {
          name
        }
        technicalMin
        technicalMax
        unit {
          id
          name
          unitSymbol
        }
      }
    }
    deviceIdentifier
    parentDevice {
      name
    }
    serialNo
  }
  placementOfDevice(deviceId: $deviceId) {
    mappingPlacementType
    siteName
    buildingName
    storeyName
    zoneName
    buildingId
    storeyId
    zoneId
  }
}
    `;

/**
 * __useDeviceQuery__
 *
 * To run a query within a React component, call `useDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceQuery(baseOptions: Apollo.QueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
      }
export function useDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
        }
export type DeviceQueryHookResult = ReturnType<typeof useDeviceQuery>;
export type DeviceLazyQueryHookResult = ReturnType<typeof useDeviceLazyQuery>;
export type DeviceQueryResult = Apollo.QueryResult<DeviceQuery, DeviceQueryVariables>;
export const DeviceExportDataDocument = gql`
    query DeviceExportData($deviceId: UUID!, $capabilityIds: [UUID!], $limitPerCapability: Int) {
  latestSensorData(
    deviceId: $deviceId
    deviceModelCapabilityIds: $capabilityIds
    limitPerCapability: $limitPerCapability
  ) {
    deviceId
    deviceModelCapabilityId
    utcTimeMeasured
    valueString
  }
}
    `;

/**
 * __useDeviceExportDataQuery__
 *
 * To run a query within a React component, call `useDeviceExportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceExportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceExportDataQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      capabilityIds: // value for 'capabilityIds'
 *      limitPerCapability: // value for 'limitPerCapability'
 *   },
 * });
 */
export function useDeviceExportDataQuery(baseOptions: Apollo.QueryHookOptions<DeviceExportDataQuery, DeviceExportDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceExportDataQuery, DeviceExportDataQueryVariables>(DeviceExportDataDocument, options);
      }
export function useDeviceExportDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceExportDataQuery, DeviceExportDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceExportDataQuery, DeviceExportDataQueryVariables>(DeviceExportDataDocument, options);
        }
export type DeviceExportDataQueryHookResult = ReturnType<typeof useDeviceExportDataQuery>;
export type DeviceExportDataLazyQueryHookResult = ReturnType<typeof useDeviceExportDataLazyQuery>;
export type DeviceExportDataQueryResult = Apollo.QueryResult<DeviceExportDataQuery, DeviceExportDataQueryVariables>;
export const DeviceTableDataDocument = gql`
    query DeviceTableData($deviceId: UUID!, $capabilityId: UUID!, $readFrom: DateTime, $readUntil: DateTime, $skip: Int, $take: Int) {
  sensorMeasurements(
    where: {readFromUtc: $readFrom, readUntilUtc: $readUntil, deviceId: $deviceId, deviceModelCapabilityIds: [$capabilityId]}
    skip: $skip
    take: $take
  ) {
    items {
      deviceId
      deviceModelCapabilityId
      utcTimeMeasured
      valueString
    }
    pageInfo {
      hasNextPage
    }
    totalCount
  }
}
    `;

/**
 * __useDeviceTableDataQuery__
 *
 * To run a query within a React component, call `useDeviceTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceTableDataQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      capabilityId: // value for 'capabilityId'
 *      readFrom: // value for 'readFrom'
 *      readUntil: // value for 'readUntil'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useDeviceTableDataQuery(baseOptions: Apollo.QueryHookOptions<DeviceTableDataQuery, DeviceTableDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceTableDataQuery, DeviceTableDataQueryVariables>(DeviceTableDataDocument, options);
      }
export function useDeviceTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceTableDataQuery, DeviceTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceTableDataQuery, DeviceTableDataQueryVariables>(DeviceTableDataDocument, options);
        }
export type DeviceTableDataQueryHookResult = ReturnType<typeof useDeviceTableDataQuery>;
export type DeviceTableDataLazyQueryHookResult = ReturnType<typeof useDeviceTableDataLazyQuery>;
export type DeviceTableDataQueryResult = Apollo.QueryResult<DeviceTableDataQuery, DeviceTableDataQueryVariables>;
export const DeviceTableDataMinDocument = gql`
    query DeviceTableDataMin($deviceId: UUID!, $capabilityId: UUID!) {
  sensorMeasurementsInfo(
    where: {deviceId: $deviceId, deviceModelCapabilityId: $capabilityId}
  ) {
    minUtcTimeMeasured
  }
}
    `;

/**
 * __useDeviceTableDataMinQuery__
 *
 * To run a query within a React component, call `useDeviceTableDataMinQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceTableDataMinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceTableDataMinQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      capabilityId: // value for 'capabilityId'
 *   },
 * });
 */
export function useDeviceTableDataMinQuery(baseOptions: Apollo.QueryHookOptions<DeviceTableDataMinQuery, DeviceTableDataMinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceTableDataMinQuery, DeviceTableDataMinQueryVariables>(DeviceTableDataMinDocument, options);
      }
export function useDeviceTableDataMinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceTableDataMinQuery, DeviceTableDataMinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceTableDataMinQuery, DeviceTableDataMinQueryVariables>(DeviceTableDataMinDocument, options);
        }
export type DeviceTableDataMinQueryHookResult = ReturnType<typeof useDeviceTableDataMinQuery>;
export type DeviceTableDataMinLazyQueryHookResult = ReturnType<typeof useDeviceTableDataMinLazyQuery>;
export type DeviceTableDataMinQueryResult = Apollo.QueryResult<DeviceTableDataMinQuery, DeviceTableDataMinQueryVariables>;
export const DevicesDocument = gql`
    query Devices($skip: Int, $take: Int, $where: DevicesByBuildingFilterInput!, $order: [DevicesByBuildingSortInput!]) {
  devicesByBuildings(skip: $skip, take: $take, where: $where, order: $order) {
    items {
      deviceId
      storeyId
      zoneId
      device {
        name
        id
        serialNo
        description
        deviceIdentifier
        parentDevice {
          name
        }
        deviceModel {
          name
          deviceType {
            name
          }
          deviceModelCapabilities {
            id
            capability {
              name
            }
            unit {
              unitSymbol
            }
          }
        }
        deviceAttributeValues {
          id: attributeId
          value
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDevicesQuery(baseOptions: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
      }
export function useDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<DevicesQuery, DevicesQueryVariables>;
export const DevicesBuildingAndAttributesDocument = gql`
    query DevicesBuildingAndAttributes($buildingId: UUID!) {
  attributesByBuildings(where: {buildingId: {eq: $buildingId}}) {
    items {
      deviceAttributes {
        id
        name
        dataType
      }
    }
  }
}
    `;

/**
 * __useDevicesBuildingAndAttributesQuery__
 *
 * To run a query within a React component, call `useDevicesBuildingAndAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesBuildingAndAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesBuildingAndAttributesQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useDevicesBuildingAndAttributesQuery(baseOptions: Apollo.QueryHookOptions<DevicesBuildingAndAttributesQuery, DevicesBuildingAndAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesBuildingAndAttributesQuery, DevicesBuildingAndAttributesQueryVariables>(DevicesBuildingAndAttributesDocument, options);
      }
export function useDevicesBuildingAndAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesBuildingAndAttributesQuery, DevicesBuildingAndAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesBuildingAndAttributesQuery, DevicesBuildingAndAttributesQueryVariables>(DevicesBuildingAndAttributesDocument, options);
        }
export type DevicesBuildingAndAttributesQueryHookResult = ReturnType<typeof useDevicesBuildingAndAttributesQuery>;
export type DevicesBuildingAndAttributesLazyQueryHookResult = ReturnType<typeof useDevicesBuildingAndAttributesLazyQuery>;
export type DevicesBuildingAndAttributesQueryResult = Apollo.QueryResult<DevicesBuildingAndAttributesQuery, DevicesBuildingAndAttributesQueryVariables>;
export const DevicesBuildingDocument = gql`
    query DevicesBuilding($buildingId: UUID!) {
  building(id: $buildingId) {
    id
    name
    storeys {
      id
      name
      zones {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useDevicesBuildingQuery__
 *
 * To run a query within a React component, call `useDevicesBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesBuildingQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useDevicesBuildingQuery(baseOptions: Apollo.QueryHookOptions<DevicesBuildingQuery, DevicesBuildingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesBuildingQuery, DevicesBuildingQueryVariables>(DevicesBuildingDocument, options);
      }
export function useDevicesBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesBuildingQuery, DevicesBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesBuildingQuery, DevicesBuildingQueryVariables>(DevicesBuildingDocument, options);
        }
export type DevicesBuildingQueryHookResult = ReturnType<typeof useDevicesBuildingQuery>;
export type DevicesBuildingLazyQueryHookResult = ReturnType<typeof useDevicesBuildingLazyQuery>;
export type DevicesBuildingQueryResult = Apollo.QueryResult<DevicesBuildingQuery, DevicesBuildingQueryVariables>;
export const ChartDataForDeviceDocument = gql`
    query ChartDataForDevice($deviceId: UUID!, $deviceModelCapabilityIds: [DeviceModelCapabilityIdsInput], $timeSpan: TimeSpans, $intervalSize: AggregationIntervals) {
  multipleDeviceSensorDataByViews(
    where: {queryFiltersMultipleDeviceAndCapability: [{deviceId: $deviceId, deviceModelCapabilityIds: $deviceModelCapabilityIds}], aggregationIntervalTypeActive: $intervalSize, timeSpan: $timeSpan, readUntilNow: true}
  ) {
    aggregationIntervalActive
    deviceAndCapabilityInfos {
      deviceModelCapabilityId
      telemetryRecords {
        utcTimeMeasured
        valueString
      }
    }
  }
}
    `;

/**
 * __useChartDataForDeviceQuery__
 *
 * To run a query within a React component, call `useChartDataForDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartDataForDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartDataForDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceModelCapabilityIds: // value for 'deviceModelCapabilityIds'
 *      timeSpan: // value for 'timeSpan'
 *      intervalSize: // value for 'intervalSize'
 *   },
 * });
 */
export function useChartDataForDeviceQuery(baseOptions: Apollo.QueryHookOptions<ChartDataForDeviceQuery, ChartDataForDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartDataForDeviceQuery, ChartDataForDeviceQueryVariables>(ChartDataForDeviceDocument, options);
      }
export function useChartDataForDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartDataForDeviceQuery, ChartDataForDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartDataForDeviceQuery, ChartDataForDeviceQueryVariables>(ChartDataForDeviceDocument, options);
        }
export type ChartDataForDeviceQueryHookResult = ReturnType<typeof useChartDataForDeviceQuery>;
export type ChartDataForDeviceLazyQueryHookResult = ReturnType<typeof useChartDataForDeviceLazyQuery>;
export type ChartDataForDeviceQueryResult = Apollo.QueryResult<ChartDataForDeviceQuery, ChartDataForDeviceQueryVariables>;
export const LastCapabilityValueForDeviceDocument = gql`
    query LastCapabilityValueForDevice($deviceId: UUID!, $deviceModelCapabilityId: UUID!) {
  sensorMeasurements(
    where: {limit: 1, deviceId: $deviceId, deviceModelCapabilityIds: [$deviceModelCapabilityId]}
  ) {
    items {
      valueString
      utcTimeMeasured
    }
  }
}
    `;

/**
 * __useLastCapabilityValueForDeviceQuery__
 *
 * To run a query within a React component, call `useLastCapabilityValueForDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastCapabilityValueForDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastCapabilityValueForDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceModelCapabilityId: // value for 'deviceModelCapabilityId'
 *   },
 * });
 */
export function useLastCapabilityValueForDeviceQuery(baseOptions: Apollo.QueryHookOptions<LastCapabilityValueForDeviceQuery, LastCapabilityValueForDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastCapabilityValueForDeviceQuery, LastCapabilityValueForDeviceQueryVariables>(LastCapabilityValueForDeviceDocument, options);
      }
export function useLastCapabilityValueForDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastCapabilityValueForDeviceQuery, LastCapabilityValueForDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastCapabilityValueForDeviceQuery, LastCapabilityValueForDeviceQueryVariables>(LastCapabilityValueForDeviceDocument, options);
        }
export type LastCapabilityValueForDeviceQueryHookResult = ReturnType<typeof useLastCapabilityValueForDeviceQuery>;
export type LastCapabilityValueForDeviceLazyQueryHookResult = ReturnType<typeof useLastCapabilityValueForDeviceLazyQuery>;
export type LastCapabilityValueForDeviceQueryResult = Apollo.QueryResult<LastCapabilityValueForDeviceQuery, LastCapabilityValueForDeviceQueryVariables>;
export const PropertiesDocument = gql`
    query Properties($where: SiteFilterInput) {
  properties: sites(where: $where, order: {name: ASC}) {
    items {
      id
      name
      location {
        id
        street
        no
        zip
        city
        lat
        lon
      }
      buildings {
        name
        id
        location {
          id
          street
          no
          zip
          city
          lat
          lon
        }
      }
    }
    totalCount
  }
}
    `;

/**
 * __usePropertiesQuery__
 *
 * To run a query within a React component, call `usePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePropertiesQuery(baseOptions?: Apollo.QueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
      }
export function usePropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesQuery, PropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesQuery, PropertiesQueryVariables>(PropertiesDocument, options);
        }
export type PropertiesQueryHookResult = ReturnType<typeof usePropertiesQuery>;
export type PropertiesLazyQueryHookResult = ReturnType<typeof usePropertiesLazyQuery>;
export type PropertiesQueryResult = Apollo.QueryResult<PropertiesQuery, PropertiesQueryVariables>;