import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { useAuth, Permissions } from '@kp/react-sdk';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../../../contexts/notifications-context';
import { useWidgetLastValueOfDeviceQuery } from '../../../../__generated__/types';
import { LastValueCard } from './LastValueCard';
import { DashboardCardLastValue } from '../../../../api/building-insights';

export interface LastValueCardContainerProps {
  id: string;
  title: string;
  subtitle?: string;
  configuration?: DashboardCardLastValue['configuration'];
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selected?: boolean;
}

export const LastValueCardContainer: React.FC<LastValueCardContainerProps> = ({
  id,
  title,
  subtitle,
  configuration,
  onEdit,
  onDelete,
  selected,
}) => {
  const { add } = useNotifications();
  const { hasPermission } = useAuth();
  const { t } = useTranslation();

  const { data, loading } = useWidgetLastValueOfDeviceQuery({
    variables: {
      deviceId: configuration?.deviceId ?? '0',
      deviceModelCapabilityId: configuration?.capabilityId ?? '0',
    },
    skip: !configuration,
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const [value, subtitleCalculate] = useMemo(() => {
    if (
      data?.sensorMeasurements?.items &&
      data?.sensorMeasurements?.items.length > 0
    ) {
      const plainValue = parseFloat(
        data?.sensorMeasurements?.items[0]?.valueString || '',
      ).toFixed(2);
      const plainUnit =
        data?.sensorMeasurements?.items[0]?.deviceModelCapability?.unit
          ?.unitSymbol;

      return [
        `${plainValue}${plainUnit}`,
        subtitle === '[time]'
          ? format(
              new Date(data?.sensorMeasurements?.items[0]?.utcTimeMeasured),
              'P p',
            )
          : subtitle,
      ];
    }

    if (!hasPermission(Permissions.WidgetsWrite) && !configuration)
      return ['0.00', t('dashboards.card.empty')];

    return ['0.00', subtitle !== '[time]' && subtitle];
  }, [data, configuration, hasPermission, t, subtitle]);

  return (
    <LastValueCard
      selected={selected}
      loading={loading}
      value={value}
      subtitle={subtitleCalculate}
      empty={!configuration}
      id={id}
      title={title}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};
