import { createClasses, LogoKP } from '@kp/react-ui';
import React, { useCallback } from 'react';
import { ImageCardEmpty } from './ImageCardEmpty';
import { DashboardCard } from '../common/DashboardCard';
import { DashboardCardImage } from '../../../../api/building-insights';

const classes = createClasses({
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      width: 'auto',
      height: '100%',
    },
  },
});

export interface ImageCardProps {
  id: string;
  title: string;
  configuration?: DashboardCardImage['configuration'];
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selected?: boolean;
}

export const ImageCard: React.FC<ImageCardProps> = ({
  id,
  title,
  configuration,
  onEdit,
  onDelete,
  selected,
}) => {
  const handleEdit = useCallback(() => {
    onEdit && onEdit(id);
  }, [onEdit, id]);

  const handleDelete = useCallback(() => {
    onDelete && onDelete(id);
  }, [onDelete, id]);

  return (
    <DashboardCard
      title={title}
      onDelete={onDelete ? handleDelete : undefined}
      onEdit={onEdit ? handleEdit : undefined}
      selected={selected}
    >
      <div className={classes.container}>
        {!configuration && (
          <ImageCardEmpty
            onAddDataPointClick={onEdit ? handleEdit : undefined}
          />
        )}
        {configuration && <LogoKP />}
      </div>
    </DashboardCard>
  );
};
