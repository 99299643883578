import React, { useState } from 'react';
import {
  ContextMenu,
  ContextMenuItem,
  createClasses,
  Icons,
} from '@kp/react-ui';
import { format, parseISO } from 'date-fns';
import slugify from 'slugify';
import { useParams } from 'react-router';
import CsvDownloader from 'react-csv-downloader';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../../contexts/notifications-context';
import { useDeviceExportDataQuery } from '../../../__generated__/types';
import { useDevice } from '../device-context';
import { Device, DeviceLocation } from '../Device.types';

const classes = createClasses({
  root: {},
  action: {
    marginRight: '0px',
  },
  link: {
    textDecoration: 'none',
  },
});

interface DeviceExportProps {
  device?: Device;
  deviceLocation?: DeviceLocation;
  loading?: boolean;
}

export const DeviceExport: React.FC<DeviceExportProps> = ({
  device,
  deviceLocation,
  loading,
}) => {
  const { add, dismissGroup } = useNotifications();
  const { deviceId } = useParams();
  const { t, i18n } = useTranslation();
  const { capabilities } = useDevice();

  const [internalLoading, setInternalLoading] = useState(false);

  const { refetch } = useDeviceExportDataQuery({
    // Query will only get executed with `refetch`.
    skip: true,
    variables: {
      deviceId: deviceId || '',
      capabilityIds: capabilities.map((x) => x.id),
      limitPerCapability:
        capabilities.length > 0 ? 100000 / capabilities.length : 1,
    },
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const getCSV = (
    values: Array<{
      deviceId?: string | null;
      deviceModelCapabilityId?: string | null;
      utcTimeMeasured?: any;
      valueString?: string | null;
    } | null>,
  ): string[][] => [
    [
      'deviceId',
      'deviceName',
      'serialNo',
      'floorId',
      'floorName',
      'zoneId',
      'zoneName',
      'description',
      'unitId',
      'unitName',
      'unitSymbol',
      'capabilityId',
      'capabilityName',
      'timestamp',
      'value',
    ],
    ...values
      .filter((value) => value !== null)
      .map((value) => {
        const dmc = device?.capabilities.find(
          (cap) => cap.id === value?.deviceModelCapabilityId,
        );
        return [
          value?.deviceId ?? '',
          device?.name ?? '',
          device?.serialNo ?? '',
          deviceLocation?.storeyId ?? '',
          deviceLocation?.storeyName ?? '',
          deviceLocation?.zoneId ?? '',
          deviceLocation?.zoneName ?? '',
          device?.description ?? '',
          dmc?.unitId ?? '',
          dmc?.unitName ?? '',
          dmc?.unitSymbol ?? '',
          value?.deviceModelCapabilityId ?? '',
          dmc?.name ?? '',
          value?.utcTimeMeasured
            ? format(parseISO(value.utcTimeMeasured), 'yyyy-MM-dd HH:mm:ss')
            : '',
          value?.valueString ?? '',
        ];
      }),
  ];

  const fetchCSV = () => {
    const startTime = Date.now();
    const groupName = `deviceExport_${startTime}`;
    add({
      type: 'loading',
      id: `deviceExport_${Date.now()}`,
      content: `Loading data, please wait...`,
      group: groupName,
    });
    setInternalLoading(true);
    return refetch()
      .then((value) => getCSV(value.data?.latestSensorData ?? []))
      .catch((error) => {
        add({
          type: 'danger',
          id: `deviceExportError_${Date.now()}`,
          content: `${error}`,
        });
        return [];
      })
      .finally(() => {
        setInternalLoading(false);
        dismissGroup(groupName);
        setTimeout(() => {
          add({
            type: 'default',
            id: `deviceExport_${Date.now()}`,
            content: `Download finished`,
            dismissTimeout: 3000,
          });
        }, 200);
      });
  };

  return (
    <div className={classes.action} data-testid="export-actions">
      <ContextMenu
        position="right"
        icon={Icons.IconExport}
        data-testid="export"
      >
        <CsvDownloader
          filename={`${slugify(deviceId || 'deviceExport', {
            lower: true,
            locale: i18n.language,
          })}.csv`}
          datas={fetchCSV}
          wrapColumnChar={'"'}
          disabled={internalLoading || loading}
          data-testid="exportDevice-CsvDownloader"
        >
          <ContextMenuItem
            className={classes.link}
            disabled={internalLoading || loading}
            icon={Icons.IconCsv}
            fullWidth
            title={t('widgets.export.csv') || ''}
            data-testid="exportDevice-csv"
          />
        </CsvDownloader>
      </ContextMenu>
    </div>
  );
};
