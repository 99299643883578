import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import { Sidebar } from './components/Sidebar';
import { Layout } from './components/Layout';
import { PropertiesContainer } from './features/properties';
import { BuildingContainer } from './features/building';
import { useLayout } from './contexts/layout-context';
import { NotFoundHandler } from './features/NotFoundHandler';
import { MissingPermissionsHandler } from './features/MissingPermissionsHandler';

const App: React.FC = () => {
  const location = useLocation();

  const isWidgets =
    location.pathname.includes('/buildings') &&
    location.pathname.includes('/widgets');

  const isProperties = location.pathname === '/';

  const { isSidebarCollapsed } = useLayout();

  return (
    <Layout noBreadcrumb={isWidgets || isProperties} noSidebar={isProperties}>
      {!isProperties && (
        <Routes>
          <Route
            path="/buildings/:buildingId/devices"
            element={
              <ProtectedRoute
                element={
                  <Sidebar isCollapsed={isSidebarCollapsed} level="device" />
                }
              />
            }
          />
          <Route
            path="/buildings/:buildingId/qanteon"
            element={
              <ProtectedRoute
                element={
                  <Sidebar isCollapsed={isSidebarCollapsed} level="qanteon" />
                }
              />
            }
          />
          <Route
            path="/buildings/:buildingId/devices/:deviceId/*"
            element={
              <ProtectedRoute
                element={
                  <Sidebar isCollapsed={isSidebarCollapsed} level="device" />
                }
              />
            }
          />
          <Route
            path="/buildings/:buildingId/dashboards"
            element={
              <ProtectedRoute
                element={
                  <Sidebar isCollapsed={isSidebarCollapsed} level="dashboard" />
                }
              />
            }
          />
          <Route
            path="/buildings/:buildingId/dashboards/*"
            element={
              <ProtectedRoute
                element={
                  <Sidebar isCollapsed={isSidebarCollapsed} level="dashboard" />
                }
              />
            }
          />
          <Route
            path="/buildings/:buildingId/*"
            element={
              <ProtectedRoute
                element={
                  <Sidebar isCollapsed={isSidebarCollapsed} level="building" />
                }
              />
            }
          />
          <Route path="*" element={<span />} />
        </Routes>
      )}
      <Routes>
        <Route
          path="/"
          element={<ProtectedRoute element={<PropertiesContainer />} />}
        />
        <Route
          path="/buildings/:buildingId/*"
          element={<ProtectedRoute element={<BuildingContainer />} />}
        />
        <Route
          path="/missing-permissions"
          element={<MissingPermissionsHandler />}
        />
        <Route path="*" element={<NotFoundHandler />} />
      </Routes>
    </Layout>
  );
};

export default App;
