import React, { ChangeEventHandler, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier } from 'dnd-core';
import { useTranslation } from 'react-i18next';
import 'chartjs-adapter-date-fns';
import {
  Colors,
  Typography,
  StarButton,
  createClasses,
  classNames,
} from '@kp/react-ui';
import { animations } from '../../../../utils/css';
import { TimeInterval } from '../../../../api/building-insights/common';
import { timeIntervals } from '../../../../constants/TimeIntervals';
import { useLocaleDateFns } from '../../../../hooks/date-fns';

const classes = createClasses({
  root: {
    width: '100%',
  },
  card: {
    borderRadius: '6px',
    height: '100%',
    border: `1px solid ${Colors.Neutral.borderMedium}`,
    backgroundColor: Colors.Neutral.background,
  },
  isOver: {
    marginLeft: '100%',
  },
  isDragging: {
    opacity: 0,
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: 'calc(100% - 68px)',
    padding: '24px 24px 12px 24px',
    overflow: 'auto',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px 24px',
    height: '68px',
    borderTop: `1px solid ${Colors.Neutral.borderMedium}`,
  },
  infoTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    color: Colors.Neutral.text,
    marginBottom: '4px',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timeInterval: {
    color: Colors.Neutral.text,
  },
  favIcon: {
    marginLeft: '4px',
  },
  info: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  creationDate: {
    color: Colors.Neutral.textWeak,
    marginLeft: '4px',
  },
  separator: {
    color: Colors.Neutral.textWeak,
    marginLeft: '4px',
  },
  nameSkeleton: {
    height: '12px',
    width: '120px',
    margin: '4px 0',
    borderRadius: 2,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `
      linear-gradient(
        90deg, 
        rgba(0, 0, 0, 0) 0, 
        rgba(0, 0, 0, .04) 50%, 
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(${Colors.Neutral.backgroundHoverStrong} 100%, transparent 0);
    `,
    backgroundSize: `
      80% 12px,
      100% 100%;
    `,
    backgroundPosition: `
      -550% 0,
      0 0;
    `,
    animation: `${animations.slideRight} 1.5s infinite`,
  },
  infoSkeleton: {
    height: '12px',
    width: '200px',
    marginTop: '6px',
    borderRadius: 2,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `
      linear-gradient(
        90deg, 
        rgba(0, 0, 0, 0) 0, 
        rgba(0, 0, 0, .04) 50%, 
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(${Colors.Neutral.backgroundHoverStrong} 100%, transparent 0);
    `,
    backgroundSize: `
      80% 12px,
      100% 100%;
    `,
    backgroundPosition: `
      -550% 0,
      0 0;
    `,
    animation: `${animations.slideRight} 1.5s infinite`,
  },
  favSkeleton: {
    height: '24px',
    width: '24px',
    marginTop: '0px',
    borderRadius: 2,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `
      linear-gradient(
        90deg, 
        rgba(0, 0, 0, 0) 0, 
        rgba(0, 0, 0, .04) 50%, 
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(${Colors.Neutral.backgroundHoverStrong} 100%, transparent 0);
    `,
    backgroundSize: `
      80% 12px,
      100% 100%;
    `,
    backgroundPosition: `
      -550% 0,
      0 0;
    `,
    animation: `${animations.slideRight} 1.5s infinite`,
  },
});

interface InfoWidgetCardProps {
  id?: string;
  className?: string;
  loading?: boolean;
  name?: string;
  timeInterval?: TimeInterval;
  createdAt?: string;
  skeleton?: boolean;
  index?: number;
  moveWidget?: (dragIndex: number, hoverIndex: number) => void;
  reorderWidget?: (id: string, index: number) => void;
  children?: React.ReactNode;
  isFavourite?: boolean;
  favouriteSave: (id: string | undefined, isFav: boolean | undefined) => void;
}

interface DragItem {
  index: number;
  id: string;
  position: number;
  type: string;
}

export const InfoWidgetCard: React.FC<InfoWidgetCardProps> = ({
  id,
  className,
  timeInterval,
  name,
  createdAt,
  children,
  skeleton,
  index,
  moveWidget,
  reorderWidget,
  isFavourite,
  favouriteSave,
}) => {
  const { t } = useTranslation();
  const { format } = useLocaleDateFns();
  const ItemTypes = {
    WIDGET: 'widget',
  };

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId, isOver }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: ItemTypes.WIDGET,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index || 0;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveWidget && moveWidget(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      /* eslint-disable no-param-reassign */
      item.index = hoverIndex;
      /* eslint-enable no-param-reassign */
    },
    drop(item: DragItem) {
      reorderWidget && reorderWidget(item.id, item.index);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.WIDGET,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => {
      return { id, index };
    },
  });

  const [favouriteIcon, setFavouriteIcon] = useState(isFavourite);

  const clickFavouriteIcon: ChangeEventHandler<HTMLInputElement> = (event) => {
    favouriteSave(id, !favouriteIcon);
    event.preventDefault();
    setFavouriteIcon(!favouriteIcon);
  };

  if (moveWidget) {
    drag(drop(ref));
  }

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      data-testid={`widget-${id}`}
      className={classNames(className, classes.root)}
    >
      <div
        className={classNames(
          className,
          classes.card,
          isOver && classes.isOver,
          isDragging && classes.isDragging,
        )}
      >
        <div className={classes.chart}>{children}</div>
        <div className={classes.infoWrapper}>
          <div className={classes.infoTextWrapper}>
            {skeleton ? (
              <div className={classes.nameSkeleton} />
            ) : (
              <Typography variant="labelMedium" className={classes.name}>
                {name}
              </Typography>
            )}
            {skeleton ? (
              <div className={classes.infoSkeleton} />
            ) : (
              <div className={classes.info}>
                <div className={classes.info}>
                  <Typography
                    variant="labelSmall"
                    className={classes.timeInterval}
                  >
                    {timeInterval && timeIntervals(t)[timeInterval]}
                  </Typography>
                  <Typography variant="bodySmall" className={classes.separator}>
                    ·
                  </Typography>
                  <Typography
                    variant="bodySmall"
                    className={classes.creationDate}
                  >
                    {createdAt &&
                      `${t('info.monitoring.createdAt')} ${format(
                        new Date(createdAt),
                        'P',
                      )}`}
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <div className={classes.actions}>
            {skeleton ? (
              <div className={classes.favSkeleton} />
            ) : (
              <StarButton
                checked={favouriteIcon}
                onChange={clickFavouriteIcon}
                onLabelClick={(event) => event.stopPropagation()}
                data-testid={`favourite-widget-button-${id}`}
                className={classes.favIcon}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
