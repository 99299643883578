import {
  TableBody,
  TableCell,
  TableRow,
  createClasses,
  Icons,
  Button,
  classNames,
} from '@kp/react-ui';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { formatValue } from '../../../utils/helpers';
import { DataTypes } from '../../../__generated__/types';
import { Device } from '../Devices.types';
import { DevicesColumn } from '../DevicesColumnsDropdown';
import { PreviewDeviceCapabilityContainer } from './PreviewDeviceCapabilityContainer';
import { PreviewDeviceChartContainer } from './PreviewDeviceChartContainer';
import { DevicePreviewDetail } from '../../../components/DevicePreview/DevicePreviewDetail';

const classes = createClasses({
  preview: {
    paddingTop: '11px',
    paddingBottom: '19px',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    justifyContent: 'space-between',
  },
  hideBottomBorder: {
    borderBottom: 'transparent',
  },
});

interface DeviceRowProps {
  buildingId: string;
  device: Device;
  attributes: Array<{ id: string; name: string; dataType: DataTypes }>;
  selectedColumns: DevicesColumn[];
  showPreview: boolean;
  handlePreviewClick: (id: string) => void;
}

export const DeviceRow: React.FC<DeviceRowProps> = ({
  buildingId,
  device,
  attributes,
  selectedColumns,
  showPreview,
  handlePreviewClick,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const renderValue = (id: string) => {
    if (id.startsWith('attribute')) {
      const value =
        device.attributes.find((attribute) => `attribute${attribute.id}` === id)
          ?.value || '';
      const dataType =
        attributes.find((attribute) => `attribute${attribute.id}` === id)
          ?.dataType || DataTypes.NotSpecified;
      if (value === '') {
        return '-';
      }
      return formatValue(value, dataType);
    }

    switch (id) {
      case 'deviceType':
        return device.type;
      case 'deviceName':
        return device.name;
      case 'serialNumber':
        return device.serialNo;
      case 'floor':
        return device.storeyName;
      case 'zone':
        return device.zoneName;
      case 'description':
        return device.description;
      case 'deviceModel':
        return device.deviceModelName;
      case 'gateway':
        return device.gatewayName || '-';
      case 'deviceIdentifier':
        return device.deviceIdentifier;
      default:
        return null;
    }
  };

  const devicePreviewData = [
    {
      key: t('device.preview.deviceName'),
      value: device.name,
    },
    {
      key: t('device.preview.deviceType'),
      value: device.type,
    },
    {
      key: t('device.preview.serialNumber'),
      value: device.serialNo,
    },
    {
      key: t('device.preview.deviceIdentifier'),
      value: device.deviceIdentifier,
    },
  ];

  return (
    <TableBody key={device.id}>
      <TableRow
        onClick={() =>
          navigate(`/buildings/${buildingId}/devices/${device.id}`)
        }
      >
        {selectedColumns.map((column) => (
          <TableCell
            className={classNames(showPreview && classes.hideBottomBorder)}
            key={column.id}
          >
            {renderValue(column.id)}
          </TableCell>
        ))}
        <TableCell
          className={classNames(showPreview && classes.hideBottomBorder)}
        >
          {device.capabilities.length > 0 && (
            <Button
              data-testid={`preview-button-${device.id}`}
              variant="tertiary"
              icon={showPreview ? Icons.IconChevronUp : Icons.IconChevronDown}
              onClick={(event) => {
                event.stopPropagation();
                handlePreviewClick(device.id);
              }}
            />
          )}
        </TableCell>
      </TableRow>
      {showPreview && (
        <TableRow data-testid={'preview'}>
          <TableCell colSpan={selectedColumns.length + 1}>
            <div className={classes.preview}>
              <PreviewDeviceChartContainer
                deviceId={device.id}
                deviceModelCapabilities={device.capabilities}
              />
              <DevicePreviewDetail data={devicePreviewData} />
              <PreviewDeviceCapabilityContainer
                deviceId={device.id}
                deviceModelCapabilities={device.capabilities}
              />
            </div>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
