import React, { useCallback, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  Dropdown,
  ListItem,
  Button,
  Icons,
  TableRow,
  ColorPicker,
  TableCell,
  Checkbox,
  createClasses,
} from '@kp/react-ui';
import {
  Entry,
  FormCapability,
  FormCapabilityIdentifier,
} from '../widgetFormDevicesTypes';

const classes = createClasses({
  root: {
    cursor: 'move',
  },
  dropdown: {
    flexGrow: 1,
    width: '100%',
  },
  actions: {
    display: 'flex',
  },
  dropdownWrapper: {
    flexGrow: 1,
  },
  action: {
    marginLeft: '8px',
  },
});

export interface WidgetFormDevicesAddedDeviceRowProps {
  entry: Entry;
  index: number;
  onMove: (from: number, to: number) => void;
  onChange: (
    capability: FormCapabilityIdentifier,
    updates: Partial<FormCapability>,
  ) => void;
  onRemove?: (capability: FormCapabilityIdentifier) => void;
  onDuplicate?: (capability: FormCapabilityIdentifier) => void;
}

export const WidgetFormDevicesAddedDeviceRow: React.FC<
  WidgetFormDevicesAddedDeviceRowProps
> = ({ entry, onRemove, onChange, onDuplicate, onMove, index }) => {
  const handleChangeCapability = useCallback(
    (capabilityId: string) => {
      onChange(
        { deviceId: entry.id, capabilityId: entry.capabilityId },
        { capabilityId },
      );
    },
    [onChange, entry],
  );

  const handleChangeColor = useCallback(
    (color: string) => {
      onChange(
        { deviceId: entry.id, capabilityId: entry.capabilityId },
        { color },
      );
    },
    [onChange, entry],
  );

  const handleRemove = useCallback(() => {
    onRemove &&
      onRemove({ deviceId: entry.id, capabilityId: entry.capabilityId });
  }, [entry, onRemove]);

  const handleChangeSelected = useCallback(() => {
    onChange(
      { deviceId: entry.id, capabilityId: entry.capabilityId },
      { selected: !entry.selected },
    );
  }, [onChange, entry]);

  const availableCapability = useMemo(
    () =>
      entry.capabilities.find(
        (capability) =>
          !capability.disabled && capability.id !== entry.capabilityId,
      ),
    [entry],
  );

  const handleDuplicate = useCallback(() => {
    if (availableCapability) {
      onDuplicate &&
        onDuplicate({
          deviceId: entry.id,
          capabilityId: availableCapability.id,
        });
    }
  }, [entry, availableCapability, onDuplicate]);

  const ref = useRef<HTMLTableSectionElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'WIDGET_FORM_DEVICES_ADDED_DEVICE_ROW',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    item: () => ({ index }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'WIDGET_FORM_DEVICES_ADDED_DEVICE_ROW',
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: (item: { index: number }) =>
      onMove(item.index, item.index < index ? index - 1 : index),
  });

  drag(drop(ref));

  return (
    <tbody ref={ref}>
      {isOver && (
        <tr>
          <TableCell colSpan={7} />
        </tr>
      )}
      {!isDragging && (
        <TableRow className={classes.root}>
          <TableCell>
            <div className={classes.actions}>
              <Checkbox
                onChange={handleChangeSelected}
                checked={entry.selected}
                data-testid={`change-device-${entry.id}-capability-${entry.capabilityId}-selected`}
              />
              <ColorPicker
                value={entry.color}
                onChange={handleChangeColor}
                data-testid={`change-device-${entry.id}-capability-${entry.capabilityId}-color`}
              />
              {onRemove && (
                <Button
                  icon={Icons.IconTrash}
                  variant="tertiary"
                  size="default"
                  onClick={handleRemove}
                  data-testid={`remove-device-${entry.id}-capability-${entry.capabilityId}`}
                />
              )}
            </div>
          </TableCell>
          <TableCell>{entry.type}</TableCell>
          <TableCell>{entry.name}</TableCell>
          <TableCell>{entry.serialNo}</TableCell>
          <TableCell>{entry.storeyName}</TableCell>
          <TableCell>{entry.description}</TableCell>
          <TableCell>
            <div className={classes.actions}>
              {onDuplicate && onRemove ? (
                <Dropdown
                  value={entry.capabilityId}
                  className={classes.dropdown}
                  onChange={handleChangeCapability}
                  wrapperClassName={classes.dropdownWrapper}
                  data-testid={`change-device-${entry.id}-capability-${entry.capabilityId}-dropdown`}
                >
                  {entry.capabilities.map(({ id, name, disabled }) => (
                    <ListItem
                      key={id}
                      value={id}
                      title={name}
                      disabled={disabled}
                    />
                  ))}
                </Dropdown>
              ) : (
                entry.capabilities.find(({ id }) => id === entry.capabilityId)
                  ?.name
              )}
              {onDuplicate && (
                <Button
                  icon={Icons.IconDuplicate}
                  variant="tertiary"
                  size="default"
                  disabled={!availableCapability}
                  className={classes.action}
                  onClick={handleDuplicate}
                  data-testid={`duplicate-device-${entry.id}-capability-${entry.capabilityId}`}
                />
              )}
            </div>
          </TableCell>
        </TableRow>
      )}
    </tbody>
  );
};
