import React, { useCallback, useMemo } from 'react';
import { Colors, RangeSlider, createClasses } from '@kp/react-ui';
import { differenceInDays, intervalToDuration } from 'date-fns';
import { WIDGETS_SLIDER_HEIGHT } from '../../../constants/UI';
import { useLocaleDateFns } from '../../../hooks/date-fns';
import { TimeInterval } from '../../../api/building-insights/common';
import { useWidget } from './widget-context';

const classes = createClasses({
  root: {
    display: 'flex',
    height: `${WIDGETS_SLIDER_HEIGHT}px`,
    alignItems: 'center',
    background: Colors.Neutral.background,
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
    padding: '0 24px',
    flexShrink: 0,
  },
  slider: {
    width: '100%',
  },
});

export interface WidgetFormRangeSliderProps {
  minDate: Date;
}

export const WidgetFormRangeSlider: React.FC<WidgetFormRangeSliderProps> =
  React.memo(({ minDate }) => {
    const { format, formatDuration } = useLocaleDateFns();
    const { now, dateFrom, dateUntil, setWidgetValues } = useWidget();

    const { label, sliderStart, sliderEnd } = useMemo(() => {
      const startDate = dateFrom === 'min' ? minDate : dateFrom;
      const endDate = dateUntil === 'max' ? now : dateUntil;
      const minInterval = (now.getTime() - minDate.getTime()) * 0.01;
      const currentInterval = endDate.getTime() - startDate.getTime();
      return {
        sliderStart:
          minInterval > currentInterval
            ? startDate.getTime() - minInterval
            : startDate.getTime(),
        sliderEnd: endDate.getTime(),
        label: formatDuration(
          {
            hours: intervalToDuration({
              start: startDate,
              end: endDate,
            }).hours,
            days: differenceInDays(endDate, startDate),
          },
          {
            format: ['days', 'hours'],
          },
        )
          // Replaces "1 year 2 months 3 weeks 4 days 5 hours 6 minutes 7 seconds"
          // to "1y 2m 3w 4d 5h 6m 7s"
          .replace(
            /(\d+)\s(\w)\w+/g,
            (str, count, letter) => `${count}${letter}`,
          ),
      };
    }, [dateFrom, dateUntil, minDate, formatDuration, now]);

    const handleChange = useCallback(
      (values: number | number[]) => {
        // Since it's a range slider we can't really have only a number as callback,
        // however the typings are suggesting we may, so this helps us compile
        if (typeof values === 'number') {
          return;
        }
        const valueStartDate = new Date(values[0]);
        const valueEndDate = new Date(values[1]);
        const isLatest = valueEndDate.getTime() === now.getTime();
        setWidgetValues(
          {
            dateFrom: valueStartDate,
            dateUntil: isLatest ? 'max' : valueEndDate,
            timeInterval: TimeInterval.customInterval,
          },
          { debounce: true },
        );
      },
      [now, setWidgetValues],
    );

    return (
      <div className={classes.root}>
        <RangeSlider
          data-testid="range-slider"
          className={classes.slider}
          minLabel={format(minDate, 'p P')}
          maxLabel={format(now, 'p P')}
          min={minDate.getTime()}
          max={now.getTime()}
          label={label}
          pushable={(now.getTime() - minDate.getTime()) * 0.01}
          onChange={handleChange}
          draggableTrack
          value={[sliderStart, sliderEnd]}
        />
      </div>
    );
  });

WidgetFormRangeSlider.displayName = 'WidgetFormRangeSlider';
