import { Permissions } from '@kp/react-sdk';

export const requiredPermissions = [
  Permissions.SitesRead,
  Permissions.BuildingsRead,
  Permissions.StoreysRead,
  Permissions.ZonesRead,
  Permissions.DevicesRead,
  Permissions.DeviceDataRead,
];
