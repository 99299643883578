import React from 'react';
import { classNames, createClasses, Colors } from '@kp/react-ui';
import { Header } from '../Header';
import { Notifications } from '../Notifications';
import { useLayout } from '../../contexts/layout-context';
import { HEADER_HEIGHT, BREADCRUMB_HEIGHT } from '../../constants/UI';
import { HeaderNavigation } from '../HeaderNavigation';

const classes = createClasses({
  root: {
    display: 'flex',
    overflowX: 'hidden',
  },
  content: {
    backgroundColor: Colors.Neutral.backgroundMedium,
    marginTop: HEADER_HEIGHT + BREADCRUMB_HEIGHT,
    flexGrow: 1,
    position: 'relative',
    transition: 'max-width 0.2s, margin-left 0.2s',
  },
  noBreadcrumb: {
    marginTop: HEADER_HEIGHT,
  },
  smallMargin: {
    marginLeft: 76,
    maxWidth: 'calc(100% - 76px)',
  },
  defaultMargin: {
    marginLeft: 240,
    maxWidth: 'calc(100% - 240px)',
  },
  noSidebar: {
    marginLeft: 0,
    maxWidth: '100%',
  },
  breadcrumbNoSidebar: {
    left: 0,
  },
  breadcrumb: {
    top: HEADER_HEIGHT,
    position: 'fixed',
    zIndex: 99,
    left: '240px',
    right: 0,
    transition: 'left 0.2s',
  },
  breadcrumbCollapsedSidebar: {
    left: '76px',
  },
});

interface LayoutProps {
  noBreadcrumb: boolean;
  children?: React.ReactNode;
  noSidebar?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  noBreadcrumb,
  noSidebar,
}) => {
  const { isSidebarCollapsed, toggleSidebar } = useLayout();

  return (
    <div className={classes.root}>
      <Header
        onCollapseClick={noSidebar ? undefined : toggleSidebar}
        sidebarOpen={!isSidebarCollapsed}
      />
      {!noBreadcrumb && (
        <HeaderNavigation
          className={classNames(
            classes.breadcrumb,
            isSidebarCollapsed && classes.breadcrumbCollapsedSidebar,
          )}
        />
      )}
      <div
        className={classNames(
          classes.content,
          noBreadcrumb && classes.noBreadcrumb,
          isSidebarCollapsed ? classes.smallMargin : classes.defaultMargin,
          noSidebar && classes.noSidebar,
        )}
      >
        {children}
      </div>
      <Notifications />
    </div>
  );
};
