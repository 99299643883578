import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { IntervalDropdown } from '../../../components/IntervalDropdown';
import { Interval } from '../../../components/IntervalSelector';
import { DashboardFormSubmit } from './Dashboard.types';

interface DashboardIntervalProps {
  submit: DashboardFormSubmit;
}

export const DashboardInterval: React.FC<DashboardIntervalProps> = ({
  submit,
}) => {
  const { watch, setValue } = useFormContext();
  const timeInterval = watch('timeInterval');
  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');

  const handleChangeInterval = useCallback(
    (selectedInterval: Interval) => {
      setValue('timeInterval', selectedInterval.timeInterval);
      setValue('dateFrom', selectedInterval.dateFrom);
      setValue('dateTo', selectedInterval.dateUntil);
      setValue('aggregationInterval', undefined);
      submit();
    },
    [setValue, submit],
  );

  return (
    <IntervalDropdown
      timeInterval={timeInterval}
      dateFrom={dateFrom}
      dateTo={dateTo}
      onChangeInterval={handleChangeInterval}
    />
  );
};
