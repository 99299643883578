import React from 'react';
import { ToggleCheckbox, ToggleCheckboxProps } from '@kp/react-ui';
import { FieldProps } from 'formik';

export interface ToggleCheckboxFieldProps
  extends FieldProps,
    Omit<ToggleCheckboxProps, 'value' | 'onChange' | 'form'> {}

const fieldToToggleCheckbox = ({
  field: { onChange: _onChange, ...field },
  form,
  ...props
}: ToggleCheckboxFieldProps): ToggleCheckboxProps => ({
  onChange: (event) => {
    form.setFieldValue(field.name, event.target.checked);
  },
  ...field,
  ...props,
});

export const ToggleCheckboxField = (props: ToggleCheckboxFieldProps) => {
  const toogleCheckboxField = fieldToToggleCheckbox(props);
  return (
    <ToggleCheckbox
      {...toogleCheckboxField}
      checked={!!toogleCheckboxField.value}
    />
  );
};

ToggleCheckboxField.displayName = 'FormikToggleCheckbox';
