import React, {
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import {
  Viewer,
  XKTLoaderPlugin,
  NavCubePlugin,
  StoreyViewsPlugin,
  MetaModel,
  math,
  Scene,
  MetaObject,
} from '@xeokit/xeokit-sdk';
import noop from '@stdlib/utils-noop';
import debounce from 'lodash.debounce';
import { ModelIFCObjectColors } from './constants';

export type NavMode = 'orbit' | 'firstPerson' | 'planView';

const defaultRender = () => () => {};

interface XeoKitRenderOptions {
  xktFile: string;
  metaFile?: string;
  viewerElement: HTMLCanvasElement;
  navCubeElement: HTMLCanvasElement;
}

interface XeoKitContextValue {
  loading: boolean;
  render: (options: XeoKitRenderOptions) => () => void;
  resetView: () => void;
  selectStoreys: (storeyIds: string[]) => void;
  showEverything: () => void;
  showStoreyFloorPlan: (storeyId: string) => void;
  setNavMode: (value: NavMode) => void;
  visibleMetaObjectIds: string[];
  model: MetaModel | null;
  scene: Scene | null;
  viewer: Viewer | null;
}

export const XeoKitContext = createContext<XeoKitContextValue>({
  loading: false,
  render: defaultRender,
  resetView: noop,
  selectStoreys: noop,
  showEverything: noop,
  showStoreyFloorPlan: noop,
  setNavMode: noop,
  visibleMetaObjectIds: [],
  model: null,
  scene: null,
  viewer: null,
});

export const useXeoKitControls = (): Pick<
  XeoKitContextValue,
  | 'loading'
  | 'resetView'
  | 'selectStoreys'
  | 'showEverything'
  | 'showStoreyFloorPlan'
  | 'setNavMode'
  | 'model'
> => {
  const {
    loading,
    resetView,
    selectStoreys,
    showEverything,
    showStoreyFloorPlan,
    setNavMode,
    model,
  } = useContext(XeoKitContext);
  return {
    loading,
    resetView,
    selectStoreys,
    showEverything,
    showStoreyFloorPlan,
    setNavMode,
    model,
  };
};

const resetCamera = (viewer: Viewer, callback?: () => void) => {
  const { aabb } = viewer.scene;
  const center = math.getAABB3Center(aabb);
  const diag = math.getAABB3Diag(aabb);
  const dist = Math.abs(diag / Math.tan(45 * math.DEGTORAD));
  const dir = math.normalizeVec3(
    viewer.scene.camera.yUp ? [-0.5, -0.7071, -0.5] : [-1, 1, -1],
  );
  const up = math.normalizeVec3(
    viewer.scene.camera.yUp ? [-0.5, 0.7071, -0.5] : [-1, 1, 1],
  );
  // eslint-disable-next-line no-param-reassign
  viewer.cameraControl.pivotPos = center;

  viewer.cameraFlight.flyTo(
    {
      look: center,
      eye: [
        center[0] - dist * dir[0],
        center[1] - dist * dir[1],
        center[2] - dist * dir[2],
      ],
      up,
    },
    callback,
  );
};

const showStoreys = (storeyPlugin: StoreyViewsPlugin, ids: string[]) => {
  if (ids.length === 0) {
    return;
  }
  const [firstFloor, ...others] = ids;
  storeyPlugin.showStoreyObjects(firstFloor, { hideOthers: true });
  others.forEach((id) => storeyPlugin.showStoreyObjects(id));
};

const getVisibleMetaObjectIds = (
  metaObject: MetaObject,
  visibleObjectIds: string[],
  result: string[] = [],
): string[] => {
  const children = metaObject.children || [];

  const childrenResult = children
    .map((child) => getVisibleMetaObjectIds(child, visibleObjectIds, result))
    .flat();

  const hasVisibleChild = children.some((child) =>
    childrenResult.includes(String(child.id)),
  );

  const visible =
    hasVisibleChild || visibleObjectIds.includes(String(metaObject.id));

  return visible
    ? childrenResult.concat([String(metaObject.id)])
    : childrenResult;
};

interface XeoKitProviderProps {
  children?: React.ReactNode;
}

export const XeoKitProvider: React.FC<XeoKitProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [viewer, setViewer] = useState<Viewer | null>(null);
  const [storeys, setStoreys] = useState<StoreyViewsPlugin | null>(null);
  const [model, setModel] = useState<MetaModel | null>(null);
  const [scene, setScene] = useState<Scene | null>(null);
  const [visibleMetaObjectIds, setVisibleMetaObjectIds] = useState<string[]>(
    [],
  );

  const resetView = useCallback(() => {
    if (!viewer) {
      return;
    }
    resetCamera(viewer);
  }, [viewer]);

  const selectStoreys = useCallback(
    (storeyIds: string[]) => {
      if (!storeys) {
        return;
      }
      showStoreys(storeys, storeyIds);
    },
    [storeys],
  );

  const showEverything = useCallback(() => {
    if (!viewer) {
      return;
    }
    viewer.scene.setObjectsVisible(viewer.scene.objectIds, true);
  }, [viewer]);

  const setNavMode = useCallback(
    (value: NavMode) => {
      if (!viewer) {
        return;
      }
      viewer.cameraControl.navMode = value;
    },
    [viewer],
  );

  const showStoreyFloorPlan = useCallback(
    (storeyId: string) => {
      if (!storeys) {
        return;
      }
      storeys.gotoStoreyCamera(storeyId, {
        done: () => {},
      });
    },
    [storeys],
  );

  const render = useCallback(
    ({
      navCubeElement,
      viewerElement,
      xktFile,
      metaFile,
    }: XeoKitRenderOptions) => {
      setLoading(true);

      const myViewer = new Viewer({
        saoEnabled: true,
        canvasElement: viewerElement,
      });

      const xktLoader = new XKTLoaderPlugin(myViewer);

      const myEntity = xktLoader.load({
        src: xktFile,
        metaModelSrc: metaFile,
        objectDefaults: ModelIFCObjectColors,
        edges: true,
      });

      // eslint-disable-next-line no-new
      new NavCubePlugin(myViewer, {
        canvasElement: navCubeElement,
      });

      const myStoreys = new StoreyViewsPlugin(myViewer);

      myViewer.scene.on('modelLoaded', (modelId: string) => {
        setModel(myViewer.metaScene.metaModels[modelId]);
      });

      myEntity.on('loaded', () => {
        resetCamera(myViewer, () => {
          setLoading(false);
          setViewer(myViewer);
          setStoreys(myStoreys);
          setScene(myViewer.scene);
        });
      });

      return () => {
        myViewer.destroy();
        xktLoader.destroy();
        setViewer(null);
        setStoreys(null);
        setModel(null);
        setScene(null);
      };
    },
    [],
  );

  const updateVisibleObjects = useCallback(() => {
    if (!model || !scene) {
      return;
    }
    setVisibleMetaObjectIds(
      getVisibleMetaObjectIds(model.rootMetaObject, scene.visibleObjectIds),
    );
  }, [scene, model]);

  const debounceUpdateVisibleObjectIds = useMemo(
    () => debounce(updateVisibleObjects, 100),
    [updateVisibleObjects],
  );

  /**
   * Gets all objects visibility
   */
  useEffect(() => {
    if (!scene) {
      return () => {};
    }
    updateVisibleObjects();
    const ref = scene.on('objectVisibility', debounceUpdateVisibleObjectIds);
    return () => scene.off(ref);
  }, [scene, debounceUpdateVisibleObjectIds, updateVisibleObjects]);

  const value = useMemo(
    () => ({
      loading,
      render,
      resetView,
      selectStoreys,
      showEverything,
      showStoreyFloorPlan,
      setNavMode,
      model,
      visibleMetaObjectIds,
      scene,
      viewer,
    }),
    [
      loading,
      render,
      resetView,
      selectStoreys,
      showEverything,
      showStoreyFloorPlan,
      visibleMetaObjectIds,
      setNavMode,
      model,
      scene,
      viewer,
    ],
  );

  return (
    <XeoKitContext.Provider value={value}>{children}</XeoKitContext.Provider>
  );
};
