import React, { useCallback, useState } from 'react';
import { Colors, Typography, createClasses, classNames } from '@kp/react-ui';
import { InteractiveCanvas } from './InteractiveCanvas';
import { InteractiveHeaderContainer } from './InteractiveHeaderContainer';
import { useBuilding } from '../../../contexts/building-context';
import { InteractiveSidebar } from './InteractiveSidebar';
import { INTERACTIVE_RIGHT_SIDEBAR_WIDTH } from '../../../constants/UI';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'relative',
  },
  header: {
    height: '48px',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    padding: '0 24px',
    background: Colors.Neutral.backgroundMedium,
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
    transition: 'max-width 0.2s',
    maxWidth: '100%',
  },
  canvas: {
    flexGrow: 1,
    height: 'calc(100vh - 152px)',
    position: 'relative',
    transition: 'max-width 0.2s',
    maxWidth: '100%',
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  sidebarOpen: {
    maxWidth: `calc(100% - ${INTERACTIVE_RIGHT_SIDEBAR_WIDTH}px)`,
  },
});

export interface InteractiveProps {
  xktFile: string;
  metaFile?: string;
}

export const Interactive: React.FC<InteractiveProps> = React.memo(
  ({ xktFile, metaFile }) => {
    const { name: buildingName } = useBuilding();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleFilterClick = useCallback(() => {
      setSidebarOpen((open) => !open);
    }, [setSidebarOpen]);

    return (
      <div className={classes.root}>
        <div
          className={classNames(
            classes.header,
            sidebarOpen && classes.sidebarOpen,
          )}
        >
          <Typography variant="titleLarge">{buildingName}</Typography>
          <InteractiveHeaderContainer
            className={classes.actions}
            onFilterClick={handleFilterClick}
            filterSelected={sidebarOpen}
          />
        </div>
        <div
          className={classNames(
            classes.canvas,
            sidebarOpen && classes.sidebarOpen,
          )}
        >
          <InteractiveCanvas xktFile={xktFile} metaFile={metaFile} />
        </div>
        <InteractiveSidebar open={sidebarOpen} />
      </div>
    );
  },
);

Interactive.displayName = 'Interactive';
