import { AggregationIntervals } from '../__generated__/types';
import { TimeInterval } from '../api/building-insights';

export const DEFAULT_INTERVAL_SIZE = AggregationIntervals.M_15;
export const DEFAULT_CHART_INTERVAL_SIZE = AggregationIntervals.H_06;

export const defaultIntervalSizeByTimeSpan = (timeInterval: TimeInterval) => {
  switch (timeInterval) {
    case TimeInterval.last15Minutes:
      return AggregationIntervals.S_04;
    case TimeInterval.last30Minutes:
      return AggregationIntervals.S_06;
    case TimeInterval.lastHour:
      return AggregationIntervals.S_12;
    case TimeInterval.last2Hours:
      return AggregationIntervals.S_20;
    case TimeInterval.last4Hours:
      return AggregationIntervals.M_02;
    case TimeInterval.last12Hours:
      return AggregationIntervals.M_04;
    case TimeInterval.lastDay:
      return AggregationIntervals.M_06;
    case TimeInterval.last7Days:
      return AggregationIntervals.M_30;
    case TimeInterval.last30Days:
      return AggregationIntervals.H_03;
    case TimeInterval.last90Days:
      return AggregationIntervals.H_06;
    default:
      return undefined;
  }
};
