import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  Button,
  Icons,
  Colors,
  UI,
  Dropdown,
  ListItem,
  Card,
  createClasses,
  classNames,
} from '@kp/react-ui';
import { Permissions, useAuth } from '@kp/react-sdk';
import {
  WidgetEntity,
  WidgetListType,
  postWidgetsFavourite,
  deleteWidgetsFavourite,
} from '../../../../api/building-insights';
import { InfoWidgetEmptyCard } from './InfoWidgetEmpty';
import { InfoWidgetCardContainer } from './InfoWidgetCardContainer';
import { InfoWidgetCard } from './InfoWidgetCard';
import { usePrepareRequest } from '../../../../hooks/useRequest';
import { useNotifications } from '../../../../contexts/notifications-context';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    marginTop: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  button: {
    textDecoration: 'none',
    marginRight: '12px',
  },
  header: {
    paddingRight: '12px',
  },
  text: {
    marginTop: '36px',
    color: Colors.Neutral.textWeak,
    textAlign: 'center',
    width: '200px',
  },
  cardWrapper: {
    display: 'flex',
    textDecoration: 'none',
    overflow: 'auto',
    marginBottom: '12px',
    marginRight: '12px',
  },
  card: {
    width: '100%',
  },
  actions: {
    display: 'flex',
  },
  [WidgetListType.TWO_COLUMNS]: {
    height: '437px',
    flexBasis: 'calc(50% - 8px)',
    '&:nth-child(2n)': {
      marginRight: '0',
    },
  },
  [WidgetListType.THREE_COLUMNS]: {
    height: '288px',
    flexBasis: 'calc(33.33% - 8px)',
    '&:nth-child(3n)': {
      marginRight: '0',
    },
  },
});

export interface InfoWidgetsProps {
  loading?: boolean;
  widgets: WidgetEntity[];
  buildingId: string;
  columns: WidgetListType;
  onChangeColumns: (value: string) => void;
  moveWidget: (dragIndex: number, hoverIndex: number) => void;
}

interface LocationState {
  scrollTo: string;
}

export const InfoWidgets: React.FC<InfoWidgetsProps> = ({
  loading,
  widgets,
  buildingId,
  columns,
  onChangeColumns,
  moveWidget,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [setIsFavourite] = usePrepareRequest(postWidgetsFavourite);
  const [unsetIsFavourite] = usePrepareRequest(deleteWidgetsFavourite);
  const { add, dismissGroup } = useNotifications();
  const { hasPermission } = useAuth();

  useEffect(() => {
    if (
      ref?.current?.scrollIntoView &&
      ref?.current?.getBoundingClientRect &&
      (location.state as LocationState)?.scrollTo === 'widgets' &&
      !loading
    ) {
      const top =
        ref.current.getBoundingClientRect().top +
        window.scrollY -
        // Remove the header since it's on top and add 10px margin
        UI.HEADER_HEIGHT -
        10;
      window.scrollTo({ top });
    }
  }, [ref, location, loading]);

  const handleFavouriteSave = async (
    id: string | undefined,
    isFav: boolean | undefined,
  ) => {
    if (isFav === undefined || id === undefined) {
      return;
    }

    const widgetToSaveList = widgets.filter((item) => {
      return item.id === id;
    });

    const widgetToSave = widgetToSaveList[0];

    const updateResponse = isFav
      ? await setIsFavourite(widgetToSave.id)
      : await unsetIsFavourite(widgetToSave.id);

    if (updateResponse.error) {
      add({
        type: 'danger',
        id: updateResponse.error.message,
        content: updateResponse.error.message,
      });
    } else {
      dismissGroup('widget-info');
      add({
        type: 'default',
        id: new Date().getTime().toString(),
        group: 'widget-info',
        content: isFav
          ? t('info.notification.widgetFavouriteAdd')
          : t('info.notification.widgetFavouriteRemove'),
        dismissTimeout: 3000,
      });
    }
  };

  return (
    <div className={classes.root} ref={ref}>
      <Card
        title={t('info.monitoring.title') || ''}
        headerClassName={classes.header}
        actions={
          hasPermission(Permissions.WidgetsWrite) ? (
            <>
              <Button
                variant="tertiary"
                icon={Icons.IconAdd}
                className={classes.button}
                component={Link}
                to={`/buildings/${buildingId}/widgets/new`}
              >
                {t('info.button.createWidget')}
              </Button>
              <Dropdown
                value={columns}
                onChange={onChangeColumns}
                variant="tertiary"
                data-testid="change-columns-dropdown"
              >
                <ListItem
                  icon={Icons.IconGrid3}
                  value={WidgetListType.THREE_COLUMNS}
                />
                <ListItem
                  icon={Icons.IconGrid2}
                  value={WidgetListType.TWO_COLUMNS}
                />
              </Dropdown>
            </>
          ) : undefined
        }
      />
      <div className={classes.content}>
        {loading && (
          <>
            <div className={classNames(classes.cardWrapper, classes[columns])}>
              <InfoWidgetCard skeleton favouriteSave={handleFavouriteSave} />
            </div>
            <div className={classNames(classes.cardWrapper, classes[columns])}>
              <InfoWidgetCard skeleton favouriteSave={handleFavouriteSave} />
            </div>
            <div className={classNames(classes.cardWrapper, classes[columns])}>
              <InfoWidgetCard skeleton favouriteSave={handleFavouriteSave} />
            </div>
          </>
        )}
        {!loading && widgets.length === 0 && (
          <InfoWidgetEmptyCard className={classes.card} />
        )}
        {!loading &&
          widgets.map((widget, index) => (
            <Link
              to={`/buildings/${buildingId}/widgets/${widget.id}`}
              key={widget.id}
              className={classNames(classes.cardWrapper, classes[columns])}
            >
              <InfoWidgetCardContainer
                id={widget.id}
                name={widget.name}
                timeInterval={widget.timeInterval}
                chartType={widget.chartType}
                dateFrom={widget.dateFrom}
                dateTo={widget.dateTo}
                intervalSize={widget.aggregationInterval}
                collapsed={widget.collapsed}
                createdAt={widget.createdAt}
                capabilities={widget.capabilities}
                moveWidget={
                  hasPermission(Permissions.WidgetsWrite)
                    ? moveWidget
                    : undefined
                }
                isFavourite={widget.favourite}
                favouriteSave={handleFavouriteSave}
                index={index}
              />
            </Link>
          ))}
      </div>
    </div>
  );
};
