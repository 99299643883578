import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { createClasses } from '@kp/react-ui';
import { Notification } from '../Notification';
import { Color } from '../../constants/Colors';

const classes = createClasses({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  wrapper: {
    textAlign: 'center',
  },
  error: {
    marginTop: '20px',
    color: 'red',
    fontWeight: 700,
  },
});

export interface LoaderProps {
  /** Show an error instead, e.g. if loading has failed. */
  error?: Error | null;
}

export const Loader: React.FC<LoaderProps> = ({ error }) => (
  <div className={classes.root}>
    {error && <Notification type={'danger'}>{error.message}</Notification>}
    <div className={classes.wrapper}>
      {!error && <TailSpin color={Color.grey3} height={100} width={100} />}
    </div>
  </div>
);
