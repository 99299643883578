import React, { useCallback } from 'react';
import { DashboardCardImage } from '../../../../../api/building-insights';
import { ImageEditPanel } from './ImageEditPanel';
import { DashboardFormSubmit } from '../../Dashboard.types';

export interface ImageEditPanelContainerProps {
  card: DashboardCardImage;
  onUpdate: (card: Partial<DashboardCardImage>) => void;
  onSubmit: DashboardFormSubmit;
}

export const ImageEditPanelContainer: React.FC<
  ImageEditPanelContainerProps
> = ({ card, onUpdate, onSubmit }) => {
  const handleChangeTitle = useCallback(
    (title: string) => onUpdate({ title }),
    [onUpdate],
  );

  return (
    <ImageEditPanel
      title={card.title}
      onChangeTitle={handleChangeTitle}
      onSubmit={onSubmit}
    />
  );
};
