export const DEFAULT_PAGE_SIZE = 15;
export const HEADER_HEIGHT = 56;
export const BREADCRUMB_HEIGHT = 48;
export const INTERACTIVE_RIGHT_SIDEBAR_WIDTH = 400;
export const WIDGETS_HEADER_HEIGHT = 48 + 1; // Calculating the bottom border
export const WIDGETS_TABS_HEIGHT = 48 + 1;
export const WIDGETS_TOOLBAR_HEIGHT = 48;
export const WIDGETS_SLIDER_HEIGHT = 56 + 1;
export const WIDGETS_RIGHT_SIDEBAR_WIDTH = 240;
export const WIDGETS_CHART_HEIGHT_PERCENT = 55;
export const DEVICES_LEFT_SIDEBAR_WIDTH = 200;
export const DEVICE_RIGHT_SIDEBAR_WIDTH = 318;
export const PROPERTYSIDEBAR_WIDTH = 320;
export const DEVICE_SLIDER_HEIGHT = 56 + 1;
