import { Colors, createClasses, Typography } from '@kp/react-ui';
import React, { useCallback } from 'react';
import { LastValueCardEmpty } from './LastValueCardEmpty';
import { DashboardCard } from '../common/DashboardCard';
import { animations } from '../../../../utils/css';

const classes = createClasses({
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'end',
    paddingBottom: '16px',
  },
  skeleton: {
    borderRadius: '6px',
    width: '80px',
    background: Colors.Neutral.backgroundHoverStrong,
    height: '28px',
    animationDuration: '2s',
    animationName: animations.slideRight,
    animationIterationCount: 'infinite',
  },
  value: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export interface LastValueProps {
  loading?: boolean;
  empty?: boolean;
  id: string;
  title: string;
  subtitle?: string;
  value?: string;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selected?: boolean;
}

export const LastValueCard: React.FC<LastValueProps> = ({
  loading,
  empty,
  id,
  title,
  subtitle,
  value,
  onEdit,
  onDelete,
  selected,
}) => {
  const handleEdit = useCallback(() => {
    onEdit && onEdit(id);
  }, [onEdit, id]);

  const handleDelete = useCallback(() => {
    onDelete && onDelete(id);
  }, [onDelete, id]);

  return (
    <DashboardCard
      title={title}
      subtitle={subtitle}
      onDelete={onDelete ? handleDelete : undefined}
      onEdit={onEdit ? handleEdit : undefined}
      selected={selected}
    >
      <div className={classes.container}>
        {empty && (
          <LastValueCardEmpty
            onAddDataPointClick={onEdit ? handleEdit : undefined}
          />
        )}
        {loading && <div className={classes.skeleton} />}
        {!empty && !loading && (
          <Typography variant="displaySmall" className={classes.value}>
            {value}
          </Typography>
        )}
      </div>
    </DashboardCard>
  );
};
