import { useContext, useCallback } from 'react';
import { SpriteMarker, math } from '@xeokit/xeokit-sdk';
import noop from '@stdlib/utils-noop';
import { XeoKitContext } from './xeokit-context';

const getMarkerImageOfDeviceType = (type: 'gateway' | 'sensor') => {
  switch (type) {
    case 'gateway':
      return '/assets/markers/gateway.svg';
    case 'sensor':
      return '/assets/markers/sensor.svg';
    default:
      return '';
  }
};

/**
 * Used to manipulate visibility of xeokit objects
 */
export const useXeoKitMarkers = (): {
  addDevice: (ifcId: string, type: 'gateway' | 'sensor') => () => void;
} => {
  const { scene } = useContext(XeoKitContext);

  const addDevice = useCallback(
    (ifcId: string, type: 'gateway' | 'sensor') => {
      if (!scene) {
        return noop;
      }
      const object = scene.objects[ifcId];
      if (!object) {
        return noop;
      }
      const [x, y, z] = math.getAABB3Center(object.aabb);
      const marker = new SpriteMarker(scene, {
        // Note that we use `y + 4` for adding additional 4 meters to the marker,
        // just because we want to render it on top of the room ( space )
        worldPos: [x, y + 4, z],
        src: getMarkerImageOfDeviceType(type),
      });
      return () => marker.destroy();
    },
    [scene],
  );

  return { addDevice };
};
