import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import usePersistedStateSync from '@hoeks/use-persisted-state-sync';
import {
  Table,
  TableHead,
  TableHeadCell,
  TableRow,
  TableCell,
  Colors,
  SearchField,
  Typography,
  TableHeadCellSortable,
  SearchFieldFilterCategory,
  Button,
  Icons,
  createClasses,
  classNames,
  TableBody,
} from '@kp/react-ui';
import { useInViewRef } from 'rooks';
import {
  DevicesColumn,
  DevicesColumnsDropdown,
} from './DevicesColumnsDropdown';
import { DataTypes } from '../../__generated__/types';
import { Device, DevicesFilter } from './Devices.types';
import { DevicesSidebar } from './DevicesSidebar';
import { DEVICES_LEFT_SIDEBAR_WIDTH } from '../../constants/UI';
import { TableRowNoResults } from '../../components/TableRowNoResults';
import { DeviceRow } from './row/DeviceRow';

const classes = createClasses({
  root: {
    height: 'calc(100vh - 104px)',
    background: Colors.Neutral.background,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '7px 24px 7px 18px',
    background: Colors.Neutral.background,
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
  },
  headerActions: {
    display: 'flex',
    gap: '12px',
    zIndex: '70',
  },
  headerTitleAndButton: {
    display: 'flex',
    paddingTop: '1px',
    gap: '14px',
  },
  headerTitle: {
    paddingTop: '3px',
  },
  table: {
    display: 'flex',
    flexGrow: 1,
    overflowX: 'auto',
    position: 'absolute',
    right: 0,
    transition: 'max-width 0.2s',
    maxHeight: 'calc(100% - 48px)',
    width: '100%',
    maxWidth: '100%',
    '& table tbody': {
      '&:hover': {
        background: Colors.Neutral.backgroundHoverWeak,
      },
    },
    '& table tr:first-child': {
      cursor: 'pointer',
    },
  },
  sidebarOpen: {
    maxWidth: `calc(100% - ${DEVICES_LEFT_SIDEBAR_WIDTH}px)`,
  },
  scrollDetector: {
    height: '40px',
  },
});

export interface Columns extends Omit<DevicesColumn, 'label' | 'selected'> {
  label: (t: TFunction) => string;
  sortKey?: string;
}

const predefinedColumns: Columns[] = [
  {
    id: 'deviceType',
    label: (t) => t(`devices.table.header.type`),
    disabled: true,
    sortKey: 'deviceTypeName',
  },
  {
    id: 'deviceName',
    label: (t) => t(`devices.table.header.name`),
    disabled: true,
    sortKey: 'deviceName',
  },
  { id: 'serialNumber', label: (t) => t(`devices.table.header.serialNo`) },
  {
    id: 'floor',
    label: (t) => t(`devices.table.header.floor`),
    sortKey: 'storeyId',
  },
  // { id: 'status', label: (t) => t(`devices.table.header.status`) },
  {
    id: 'zone',
    label: (t) => t(`devices.table.header.zone`),
    sortKey: 'zoneId',
  },
  { id: 'description', label: (t) => t(`devices.table.header.description`) },
  { id: 'deviceModel', label: (t) => t(`devices.table.header.model`) },
  { id: 'gateway', label: (t) => t(`devices.table.header.gateway`) },
  {
    id: 'deviceIdentifier',
    label: (t) => t(`devices.table.header.deviceIdentifier`),
  },
];

interface DevicesProps {
  loading?: boolean;
  devices: Device[];
  buildingId: string;
  attributes: Array<{ id: string; name: string; dataType: DataTypes }>;
  onScrollDown?: () => void;
  loadingMore?: boolean;
  onChangeSort: (direction: 'ASC' | 'DESC', field: string) => void;
  sortField: string | null;
  sortDirection: 'ASC' | 'DESC' | null;
  query: string;
  onChangeQuery: (query: string) => void;
  onAddFilter: (filterId: string, categoryId: string) => void;
  onRemoveFilter: (filterId: string, categoryId: string) => void;
  onStoryFilter: (filterId?: string) => void;
  onResetFilters: () => void;
  filters: DevicesFilter[];
  categories: SearchFieldFilterCategory[];
  storeyId: string | null;
}

export const Devices: React.FC<DevicesProps> = ({
  loading,
  devices,
  attributes,
  buildingId,
  onScrollDown,
  loadingMore,
  onChangeSort,
  sortField,
  sortDirection,
  query,
  onChangeQuery,
  onAddFilter,
  onRemoveFilter,
  onStoryFilter,
  onResetFilters,
  filters,
  categories,
  storeyId,
}) => {
  const { t } = useTranslation();
  const [activeColumns, setActiveColumns] = usePersistedStateSync<
    Array<string>
  >(['serialNumber', 'floor'], `activeColumns-${buildingId}`);

  const [scrollDetectorRef] = useInViewRef((entries) => {
    if (onScrollDown && entries[0].isIntersecting) {
      onScrollDown();
    }
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleFloorSelectionClick = useCallback(() => {
    setSidebarOpen((open) => !open);
  }, [setSidebarOpen]);

  const hasEmptyResults = !loading && devices.length === 0;

  const columns: DevicesColumn[] = useMemo(
    () =>
      predefinedColumns
        .map((column) => ({
          id: column.id,
          label: column.label(t),
          disabled: column.disabled,
          selected: column.disabled || activeColumns.includes(column.id),
          sortKey: column.sortKey,
        }))
        .concat(
          attributes.map((attribute) => ({
            id: `attribute${attribute.id}`,
            label: attribute.name,
            disabled: false,
            selected: activeColumns.includes(`attribute${attribute.id}`),
            sortKey: undefined,
          })),
        ),
    [attributes, activeColumns, t],
  );

  const [previewRowDeviceId, setPreviewRowDeviceId] = useState('');

  const handleColumnChange = useCallback(
    (id: string, value: boolean) => {
      if (value && !activeColumns.includes(id)) {
        setActiveColumns([id, ...activeColumns]);
      }
      if (!value && activeColumns.includes(id)) {
        setActiveColumns(
          activeColumns.filter((activeColumn) => activeColumn !== id),
        );
      }
    },
    [activeColumns, setActiveColumns],
  );

  const selectedColumns = columns.filter((column) => column.selected);

  const handlePreviewClick = useCallback(
    (id: string) => {
      id === previewRowDeviceId
        ? setPreviewRowDeviceId('')
        : setPreviewRowDeviceId(id);
    },
    [previewRowDeviceId],
  );

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.headerTitleAndButton}>
          <Button
            variant="tertiary"
            selected={sidebarOpen}
            data-testid="floor-selection-button"
            onClick={handleFloorSelectionClick}
            icon={Icons.IconMenu}
          />
          <Typography className={classes.headerTitle} variant="titleLarge">
            {t('devices.title')}
          </Typography>
        </div>
        <div className={classes.headerActions}>
          <SearchField
            fullWidth
            data-testid="device-search-input"
            onResetFilters={onResetFilters}
            categories={categories}
            onChange={onChangeQuery}
            onAddFilter={onAddFilter}
            onRemoveFilter={onRemoveFilter}
            filterButtonLabel={t('devices.search.filter') || ''}
            filters={filters}
            value={query}
          />
          <DevicesColumnsDropdown
            columns={columns}
            onChange={handleColumnChange}
          />
        </div>
      </header>
      <div>
        <DevicesSidebar
          open={sidebarOpen}
          storeyId={storeyId}
          onStoryFilter={onStoryFilter}
        />
        <div
          className={classNames(
            classes.table,
            sidebarOpen && classes.sidebarOpen,
          )}
        >
          <Table data-testid="table-view" scrollable>
            <TableHead data-testid="table-head">
              <TableRow>
                {selectedColumns.map((column) =>
                  column.sortKey ? (
                    <TableHeadCellSortable
                      key={column.id}
                      value={column.sortKey}
                      onClick={onChangeSort}
                      sort={sortField === column.sortKey ? sortDirection : null}
                    >
                      {column.label}
                    </TableHeadCellSortable>
                  ) : (
                    <TableHeadCell key={column.id}>
                      {column.label}
                    </TableHeadCell>
                  ),
                )}
                <TableHeadCell />
              </TableRow>
            </TableHead>
            {loading && (
              <TableBody>
                <TableRow loading>
                  {selectedColumns.map((column) => (
                    <TableCell key={column.id} loading />
                  ))}
                  <TableCell loading />
                </TableRow>
                <TableRow loading>
                  {selectedColumns.map((column) => (
                    <TableCell key={column.id} loading />
                  ))}
                  <TableCell loading />
                </TableRow>
                <TableRow loading>
                  {selectedColumns.map((column) => (
                    <TableCell key={column.id} loading />
                  ))}
                  <TableCell loading />
                </TableRow>
              </TableBody>
            )}
            {hasEmptyResults && (
              <TableBody>
                <TableRowNoResults label={t('devices.table.empty.text')} />
              </TableBody>
            )}
            {!loading &&
              devices.length > 0 &&
              devices?.map((device) => (
                <DeviceRow
                  selectedColumns={selectedColumns}
                  buildingId={buildingId}
                  device={device}
                  attributes={attributes}
                  key={device.id}
                  showPreview={previewRowDeviceId === device.id}
                  handlePreviewClick={handlePreviewClick}
                />
              ))}
            {loadingMore && (
              <TableBody>
                <TableRow loading>
                  {selectedColumns.map((column) => (
                    <TableCell key={column.id} loading />
                  ))}
                </TableRow>
              </TableBody>
            )}
            {onScrollDown && (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div
                      className={classes.scrollDetector}
                      ref={scrollDetectorRef}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};
