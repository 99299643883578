import React from 'react';
import {
  createClasses,
  TableHead,
  Table,
  TableRow,
  TableHeadCell,
  TableBody,
  TableCell,
  Tabs,
  Tab,
  classNames,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import { format } from 'date-fns';
import { DeviceContentLayout } from '../common/DeviceContentLayout';
import { Device, DeviceLocation } from '../Device.types';
import { DeviceToolbarActions } from '../common/DeviceToolbarActions';
import { DeviceInfoSidebar } from '../common/DeviceInfoSidebar';
import { createRangeString } from '../../../utils/helpers';
import { TableRowNoResults } from '../../../components/TableRowNoResults';
import { DeviceDatePicker } from '../common/DeviceDatePicker';

const classes = createClasses({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  table: {
    flexGrow: 1,
    overflowX: 'auto',
    position: 'absolute',
    right: 0,
    minHeight: '200px',
    maxHeight: '100%',
    width: '100%',
    '& table thead th': {
      zIndex: 60,
    },
  },
  tableWithTabs: {
    maxHeight: 'calc(100% - 48px)',
  },
  scrollDetector: {
    height: '40px',
  },
});

interface DeviceTableProps {
  device?: Device;
  location?: DeviceLocation;
  capabilityName?: string;
  data: Array<{ timestamp: Date; value: string }>;
  unitSymbol?: string;
  technicalMin?: number;
  technicalMax?: number;
  loading?: boolean;
  capabilityId: string;
  onScrollDown?: () => void;
  loadingMore?: boolean;
  onChangeCapability: (capabilityId: string) => void;
}

export const DeviceTable: React.FC<DeviceTableProps> = ({
  device,
  data,
  unitSymbol,
  capabilityName,
  technicalMin,
  technicalMax,
  location,
  loading,
  capabilityId,
  loadingMore,
  onScrollDown,
  onChangeCapability,
}) => {
  const { t } = useTranslation();
  const [scrollDetectorRef] = useInViewRef((entries) => {
    if (onScrollDown && entries[0].isIntersecting) {
      onScrollDown();
    }
  });

  const hasEmptyResults = !loading && data.length === 0;

  const hasManyCapabilities =
    device?.capabilities && device?.capabilities.length > 1;

  return (
    <DeviceContentLayout
      title={`${device?.name || ''}${
        !hasManyCapabilities && capabilityName ? ` – ${capabilityName}` : ''
      }`}
      actions={
        <>
          <DeviceDatePicker />
          <DeviceToolbarActions
            capabilityId={capabilityId}
            device={device}
            deviceLocation={location}
            loading={loading}
          />
        </>
      }
      sidebars={
        <DeviceInfoSidebar
          device={device}
          location={location}
          loading={loading}
        />
      }
    >
      <div data-testid="device-table-container" className={classes.root}>
        {hasManyCapabilities && (
          <Tabs value={capabilityId} onChange={onChangeCapability}>
            {device.capabilities.map((capability) => (
              <Tab
                key={capability.id}
                value={capability.id}
                label={capability.name}
              />
            ))}
          </Tabs>
        )}
        <div
          className={classNames(
            classes.table,
            hasManyCapabilities && classes.tableWithTabs,
          )}
        >
          <Table scrollable>
            <TableHead data-testid="table-head">
              <TableRow>
                <TableHeadCell>{t('device.table.date')}</TableHeadCell>
                <TableHeadCell>{t('device.table.time')}</TableHeadCell>
                <TableHeadCell>{t('device.table.value')}</TableHeadCell>
                <TableHeadCell>{t('device.table.range')}</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="table-view">
              {loading && (
                <>
                  <TableRow loading>
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                  </TableRow>
                  <TableRow loading>
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                  </TableRow>
                  <TableRow loading>
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                  </TableRow>
                </>
              )}
              {hasEmptyResults && (
                <TableRowNoResults label={t('device.table.empty.text')} />
              )}
              {!loading &&
                data.length > 0 &&
                data?.map((point) => (
                  <TableRow key={`${capabilityId}-${point.timestamp}`}>
                    <TableCell>{format(point.timestamp, 'P')}</TableCell>
                    <TableCell>{format(point.timestamp, 'p')}</TableCell>
                    <TableCell>
                      {point.value}
                      {unitSymbol}
                    </TableCell>
                    <TableCell>
                      {createRangeString({
                        unitSymbol,
                        technicalMin,
                        technicalMax,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              {loadingMore && (
                <TableRow loading>
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                </TableRow>
              )}
              {onScrollDown && (
                <TableRow>
                  <TableCell>
                    <div
                      className={classes.scrollDetector}
                      ref={scrollDetectorRef}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </DeviceContentLayout>
  );
};
