import React from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../../utils/env';
import { Loader } from '../Loader';

export const GoogleMapsLoader: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  return <>{isLoaded ? children : <Loader />}</>;
};
