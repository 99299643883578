import React, {
  useCallback,
  ChangeEventHandler,
  useState,
  MouseEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonBlock,
  createClasses,
  Icons,
  Input,
  InputButton,
  Label,
} from '@kp/react-ui';
import { DashboardSelectCapabilitiesContainer } from '../../../DashboardSelectCapabilities/DashboardSelectCapabilitiesContainer';
import { PointPreviewContainer } from '../../pointPreview';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },
  input: {
    maxWidth: '296px',
  },
  capability: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: '8px',
  },
  capabilityName: {
    maxWidth: '600px',
    flexGrow: 1,
  },
  capabilityActions: {
    alignSelf: 'end',
    flexGrow: 1,
  },
});

interface LastValueEditPanelProp {
  loading: boolean;
  title?: string;
  subtitle?: string;
  capability?: {
    capabilityId: string;
    deviceId: string;
    deviceName: string;
    capabilityName: string;
    unitName: string;
    unitSymbol: string;
  };
  onChangeTitle: (title: string) => void;
  onChangeSubtitle: (subtitle: string) => void;
  onChangeCapability: (capability: {
    deviceId: string;
    capabilityId: string;
  }) => void;
  onSubmit: () => void;
}

export const LastValueEditPanel: React.FC<LastValueEditPanelProp> = ({
  loading,
  title,
  subtitle,
  capability,
  onChangeTitle,
  onChangeSubtitle,
  onChangeCapability,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [selectCapabilityModalOpen, setSelectCapabilityModalOpen] =
    useState(false);

  const [showCapabilityPreview, setShowCapabilityPreview] = useState(false);

  const handleTitleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => onChangeTitle(event.target.value),
    [onChangeTitle],
  );

  const handleSubtitleChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((event) => onChangeSubtitle(event.target.value), [onChangeSubtitle]);

  const handleSelectCapability = useCallback(
    (selectedCapability: { deviceId: string; capabilityId: string }) => {
      setSelectCapabilityModalOpen(false);
      onChangeCapability({
        deviceId: selectedCapability.deviceId,
        capabilityId: selectedCapability.capabilityId,
      });
    },
    [onChangeCapability],
  );

  const handleSubtitleSelect = useCallback(() => {
    onChangeSubtitle('[time]');
    onSubmit();
  }, [onChangeSubtitle, onSubmit]);

  const handleSubtitleReset = useCallback(() => {
    onChangeSubtitle('');
  }, [onChangeSubtitle]);

  const handleShowPreview = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    setShowCapabilityPreview(!showCapabilityPreview);
  }, [showCapabilityPreview, setShowCapabilityPreview]);

  return (
    <div className={classes.root}>
      <DashboardSelectCapabilitiesContainer
        onAdd={handleSelectCapability}
        selectedCapability={capability}
        onClose={() => setSelectCapabilityModalOpen(false)}
        open={selectCapabilityModalOpen}
      />
      <div className={classes.input}>
        <Input
          placeholder={t('dashboards.card.untitled') || ''}
          label={t('dashboards.card.editPanel.fields.title') || ''}
          value={title}
          onChange={handleTitleChange}
          onBlur={onSubmit}
          fullWidth
        />
      </div>
      <div className={classes.input}>
        <InputButton
          selected={subtitle === '[time]'}
          label={t('dashboards.card.editPanel.fields.subtitle') || ''}
          icon={Icons.IconCalendarTime}
          value={
            subtitle === '[time]'
              ? t('dashboards.card.editPanel.fields.showTimestamp') || ''
              : subtitle
          }
          onSelect={handleSubtitleSelect}
          onReset={handleSubtitleReset}
          onChange={handleSubtitleChange}
          placeholder={t('dashboards.card.editPanel.fields.placeholder') || ''}
          onBlur={onSubmit}
        />
      </div>
      <div>
        <div className={classes.capability}>
          <div className={classes.capabilityName}>
            <Label
              title={t('dashboards.card.editPanel.capability.label') || ''}
            >
              <ButtonBlock
                label={
                  capability?.deviceName ||
                  t('dashboards.card.editPanel.capability.placeholder') ||
                  ''
                }
                chipLabel={
                  capability &&
                  `${capability?.capabilityName}, ${capability?.unitSymbol}`
                }
                onClick={() => setSelectCapabilityModalOpen(true)}
                leftIcon={!capability ? Icons.IconPlusCircle : undefined}
              />
            </Label>
          </div>
          <div className={classes.capabilityActions}>
            {capability && (
              <Button
                selected={showCapabilityPreview}
                icon={Icons.IconPreview}
                variant="secondary"
                size="default"
                onClick={handleShowPreview}
              />
            )}
          </div>
        </div>
        {showCapabilityPreview && capability && (
          <PointPreviewContainer
            deviceId={capability.deviceId}
            capabilityId={capability.capabilityId}
          />
        )}
      </div>
    </div>
  );
};
