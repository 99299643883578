import { useState } from 'react';
import { useIntervalWhen } from 'rooks';

export const useNow = () => {
  const [now, setNow] = useState(new Date());
  useIntervalWhen(
    () => setNow(new Date()),
    1000 * (60 - now.getSeconds()),
    true,
  );

  return now;
};
