import React, { useCallback, useState } from 'react';
import { FieldArray } from 'formik';
import { createClasses, classNames, css } from '@kp/react-ui';
import { WidgetFormHeader } from './WidgetFormHeader';
import { WidgetFormSidebar } from './WidgetFormSidebar';
import { WidgetFormToolbar } from './WidgetFormToolbar';
import {
  WIDGETS_CHART_HEIGHT_PERCENT,
  WIDGETS_RIGHT_SIDEBAR_WIDTH,
  WIDGETS_TABS_HEIGHT,
} from '../../../constants/UI';
import { WidgetFormChartContainer } from './WidgetFormChart';
import { WidgetFormDevices } from './WidgetFormDevices';
import { WidgetProvider } from './widget-context';
import {
  AggregationIntervals,
  WidgetFormDataQuery,
  WidgetFormDevicesQuery,
} from '../../../__generated__/types';
import { WidgetFormRangeSlider } from './WidgetFormRangeSlider';
import { useHorizontalResize } from '../../../hooks/useHorizontalResize';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexGrow: 1,
    height: 'calc(100vh - 104px)',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: 'max-width 0.2s',
    maxWidth: '100%',
  },
  canvas: {
    flexGrow: 1,
  },
  sidebarOpen: {
    maxWidth: `calc(100% - ${WIDGETS_RIGHT_SIDEBAR_WIDTH}px)`,
  },
  chart: {
    display: 'flex',
    flexShrink: 0,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  devices: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
  },
  resizing: {
    cursor: 'ns-resize',
  },
});

export interface WidgetFormProps {
  loading?: boolean;
  loadingData?: boolean;
  loadingDevices?: boolean;
  type?: 'edit' | 'create' | 'view';
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit?: () => void;
  availableIntervals: AggregationIntervals[];
  chartData?: WidgetFormDataQuery;
  devicesData?: WidgetFormDevicesQuery;
  csv: Array<Array<string>>;
  minDate: Date;
}

export const WidgetForm: React.FC<WidgetFormProps> = ({
  loading,
  loadingData,
  loadingDevices,
  onCancel,
  onDelete,
  onSubmit,
  availableIntervals,
  type,
  chartData,
  minDate,
  devicesData,
  csv,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSettingsClick = useCallback(() => {
    setSidebarOpen((open) => !open);
  }, [setSidebarOpen]);

  const getOffsets = useCallback(
    (height: number) => ({
      // The offset when the user is not in view mode
      default: height * (WIDGETS_CHART_HEIGHT_PERCENT / 100),
      // The offset when the user is in view mode
      collapsed: height - WIDGETS_TABS_HEIGHT - 1,
    }),
    [],
  );

  const [
    [chartHeight, devicesHeight],
    { setOffset, startResizing, isResizing, containerRef },
  ] = useHorizontalResize({
    defaultOffset: (height) => {
      const offsets = getOffsets(height);
      return type === 'view' ? offsets.collapsed : offsets.default;
    },
    minTopOffset: 150,
    minBottomOffset: WIDGETS_TABS_HEIGHT + 150,
  });

  const handleDevicesToggle = useCallback(() => {
    setOffset((currentOffset, height) => {
      const offsets = getOffsets(height);
      return currentOffset < offsets.collapsed
        ? offsets.collapsed
        : offsets.default;
    });
  }, [setOffset, getOffsets]);

  const handleTabClick = useCallback(() => {
    setOffset((currentOffset, height) => {
      const offsets = getOffsets(height);
      return currentOffset === offsets.collapsed
        ? offsets.default
        : currentOffset;
    });
  }, [setOffset, getOffsets]);

  return (
    <div
      className={classNames(classes.root, isResizing && classes.resizing)}
      data-testid="widget-form-wrapper"
      data-offset={chartHeight}
    >
      <WidgetFormHeader
        loading={loading}
        onSave={type !== 'view' ? onSubmit : undefined}
        onCancel={onCancel}
      />
      <div className={classes.wrapper}>
        <div
          className={classNames(
            classes.content,
            sidebarOpen && classes.sidebarOpen,
          )}
        >
          <WidgetProvider>
            <WidgetFormToolbar
              onSettingsClick={handleSettingsClick}
              settingsSelected={sidebarOpen}
              type={type}
              csv={csv}
            />
            {type === 'view' && <WidgetFormRangeSlider minDate={minDate} />}
          </WidgetProvider>
          <div
            ref={containerRef}
            className={classes.canvas}
            data-testid="resizable-container"
          >
            <div
              className={classNames(
                classes.chart,
                css({ height: chartHeight }),
              )}
            >
              <WidgetFormChartContainer
                loading={loading || loadingData}
                data={chartData}
              />
            </div>
            <FieldArray
              name="capabilities"
              render={({ push, form, remove, replace, move }) => (
                <WidgetFormDevices
                  className={classNames(
                    classes.devices,
                    css({ height: devicesHeight }),
                  )}
                  move={move}
                  push={push}
                  form={form}
                  remove={remove}
                  replace={replace}
                  type={type}
                  loadingDevices={loadingDevices}
                  devicesData={devicesData}
                  onToggle={handleDevicesToggle}
                  onMouseDown={startResizing}
                  onClickTab={handleTabClick}
                />
              )}
            />
          </div>
        </div>
        <WidgetFormSidebar
          availableIntervals={availableIntervals}
          open={sidebarOpen}
          onDelete={onDelete}
        />
      </div>
    </div>
  );
};
