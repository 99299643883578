import React, { useCallback, useEffect, useState } from 'react';
import {
  Dropdown,
  ListItem,
  Button,
  Icons,
  TableRow,
  TableCell,
  createClasses,
} from '@kp/react-ui';
import { Device, FormCapabilityIdentifier } from '../widgetFormDevicesTypes';

const classes = createClasses({
  dropdown: {
    width: '100%',
  },
});

export interface WidgetFormDevicesSearchDeviceRowProps {
  device: Device;
  onAdd: (capability: FormCapabilityIdentifier) => void;
}

export const WidgetFormDevicesSearchDeviceRow: React.FC<
  WidgetFormDevicesSearchDeviceRowProps
> = ({ device, onAdd }) => {
  const firstAvailableCapability = device.capabilities.find(
    ({ disabled }) => !disabled,
  );
  const [capabilityId, setCapabilityId] = useState(
    firstAvailableCapability?.id || '',
  );

  // In case the REST API returns later than the device search, there will be a
  // change of the selected capability id, which won't be handled by the `useState`
  useEffect(() => {
    setCapabilityId(
      device.capabilities.find(({ disabled }) => !disabled)?.id || '',
    );
  }, [device]);

  const handleClick = useCallback(() => {
    onAdd({ deviceId: device.id, capabilityId });
  }, [device, capabilityId, onAdd]);

  return (
    <TableRow>
      <TableCell>{device.type}</TableCell>
      <TableCell>{device.name}</TableCell>
      <TableCell>{device.serialNo}</TableCell>
      <TableCell>{device.storeyName}</TableCell>
      <TableCell>{device.description}</TableCell>
      <TableCell>
        <Dropdown
          value={capabilityId}
          className={classes.dropdown}
          onChange={setCapabilityId}
        >
          {device.capabilities.map(({ id, name, disabled }) => (
            <ListItem key={id} value={id} title={name} disabled={disabled} />
          ))}
        </Dropdown>
      </TableCell>
      <TableCell>
        <Button
          icon={Icons.IconAddchart}
          data-testid={`add-device-${device.id}`}
          variant="tertiary"
          size="default"
          onClick={handleClick}
        />
      </TableCell>
    </TableRow>
  );
};
