import { createClasses } from '@kp/react-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DevicePreviewDetail } from '../../../../components/DevicePreview';
import { PointPreviewChartContainer } from './PointPreviewChartContainer';
import {
  PreviewDevice,
  PreviewDeviceCapability,
  PreviewDeviceLocation,
} from './PreviewDevice.types';

const classes = createClasses({
  preview: {
    paddingBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
  },
});

interface PointPreviewProps {
  device: PreviewDevice;
  location: PreviewDeviceLocation;
  capability?: PreviewDeviceCapability;
}

export const PointPreview: React.FC<PointPreviewProps> = ({
  device,
  capability,
  location,
}) => {
  const { t } = useTranslation();

  const devicePreviewData = [
    {
      key: t('dashboards.card.editPanel.preview.details.serialNo'),
      value: device.serialNo,
    },
    {
      key: t('dashboards.card.editPanel.preview.details.identifier'),
      value: device.identifier,
    },
    {
      key: t('dashboards.card.editPanel.preview.details.floor'),
      value: location.storeyName,
    },
    {
      key: t('dashboards.card.editPanel.preview.details.zone'),
      value: location.zoneName,
    },
  ];

  return (
    <div className={classes.preview}>
      {device && capability && (
        <PointPreviewChartContainer device={device} capability={capability} />
      )}
      <DevicePreviewDetail data={devicePreviewData} />
    </div>
  );
};
