import { useCallback, useMemo } from 'react';
import {
  Button,
  Colors,
  createClasses,
  Icon,
  Icons,
  Typography,
} from '@kp/react-ui';
import * as React from 'react';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { DashboardForm, DashboardFormSubmit } from './Dashboard.types';
import {
  DashboardCardChart,
  DashboardCardImage,
  DashboardCardClock,
  DashboardCardLastValue,
  DashboardCardType,
} from '../../../api/building-insights';
import {
  ChartEditPanelContainer,
  LastValueEditPanelContainer,
  ClockEditPanelContainer,
  ImageEditPanelContainer,
} from './cardPanels';

const classes = createClasses({
  root: {
    borderTop: `1px solid ${Colors.Neutral.borderStrong}`,
    backgroundColor: Colors.Neutral.background,
    height: '100%',
  },
  header: {
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
    padding: '8px 12px 8px 23px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: '22px 24px',
    overflow: 'scroll',
    height: `calc(100% - 52px)`,
  },
  title: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  icon: {
    color: Colors.Neutral.icon,
  },
  handle: {
    height: '2px',
    width: '100%',
    '&:hover': {
      cursor: 'ns-resize',
      background: Colors.Selected.border,
    },
  },
});

interface DashboardEditPanelProp {
  onClose: () => void;
  onSubmit: DashboardFormSubmit;
  onMouseDown: () => void;
  cardId: string;
}

export const DashboardEditPanel: React.FC<DashboardEditPanelProp> = ({
  cardId,
  onClose,
  onMouseDown,
  onSubmit,
}) => {
  const { watch, setValue } = useFormContext<DashboardForm>();
  const cards = watch('cards', []);

  const card = useMemo(
    () => cards?.find(({ id }) => cardId === id),
    [cards, cardId],
  );

  const icon = useMemo(() => {
    switch (card?.type) {
      case DashboardCardType.chart:
        return Icons.IconStatistics;
      case DashboardCardType.lastValue:
        return Icons.IconSinglestat;
      case DashboardCardType.image:
        return Icons.IconPlantImage;
      case DashboardCardType.clock:
        return Icons.IconTime;
      default:
        return Icons.IconStatistics;
    }
  }, [card]);

  const handleUpdateChartCard = useCallback(
    (updatedCard: Partial<DashboardCardChart>) => {
      setValue(
        'cards',
        cards?.map((oldCard) => {
          if (cardId !== oldCard.id) {
            return oldCard;
          }
          if (oldCard.type !== DashboardCardType.chart) {
            return oldCard;
          }
          return {
            ...oldCard,
            ...updatedCard,
            configuration: updatedCard.configuration
              ? {
                  ...oldCard.configuration,
                  ...updatedCard.configuration,
                }
              : oldCard.configuration,
          };
        }),
      );
    },
    [cards, cardId, setValue],
  );

  const handleUpdateLastValueCard = useCallback(
    (updatedCard: Partial<DashboardCardLastValue>) => {
      setValue(
        'cards',
        cards?.map((oldCard) => {
          if (cardId !== oldCard.id) {
            return oldCard;
          }
          if (oldCard.type !== DashboardCardType.lastValue) {
            return oldCard;
          }
          return {
            ...oldCard,
            ...updatedCard,
            configuration: updatedCard.configuration
              ? {
                  ...oldCard.configuration,
                  ...updatedCard.configuration,
                }
              : oldCard.configuration,
          };
        }),
      );
    },
    [cards, cardId, setValue],
  );

  const handleUpdateImageCard = useCallback(
    (updatedCard: Partial<DashboardCardImage>) => {
      setValue(
        'cards',
        cards?.map((oldCard) => {
          if (cardId !== oldCard.id) {
            return oldCard;
          }
          if (oldCard.type !== DashboardCardType.image) {
            return oldCard;
          }
          return {
            ...oldCard,
            ...updatedCard,
            configuration: updatedCard.configuration
              ? {
                  ...oldCard.configuration,
                  ...updatedCard.configuration,
                }
              : oldCard.configuration,
          };
        }),
      );
    },
    [cards, cardId, setValue],
  );

  const handleUpdateClockCard = useCallback(
    (updatedCard: Partial<DashboardCardClock>) => {
      setValue(
        'cards',
        cards?.map((oldCard) => {
          if (cardId !== oldCard.id) {
            return oldCard;
          }
          if (oldCard.type !== DashboardCardType.clock) {
            return oldCard;
          }
          return {
            ...oldCard,
            ...updatedCard,
            configuration: updatedCard.configuration
              ? {
                  ...oldCard.configuration,
                  ...updatedCard.configuration,
                }
              : oldCard.configuration,
          };
        }),
      );
    },
    [cards, cardId, setValue],
  );

  return (
    <div className={classes.root}>
      <div
        className={classes.handle}
        data-testid="drag-border"
        onMouseDown={onMouseDown}
      />
      <div className={classes.header}>
        <div className={classes.title}>
          <Icon icon={icon} size={24} className={classes.icon} />
          <Typography variant="titleMedium">
            {t('dashboards.card.editPanel.title')}
          </Typography>
        </div>
        <Button
          variant="tertiary"
          component={'div'}
          icon={Icons.IconClear}
          onClick={onClose}
        />
      </div>
      <div className={classes.content}>
        {card?.type === DashboardCardType.chart && (
          <ChartEditPanelContainer
            card={card}
            onUpdate={handleUpdateChartCard}
            onSubmit={onSubmit}
          />
        )}
        {card?.type === DashboardCardType.lastValue && (
          <LastValueEditPanelContainer
            card={card}
            onUpdate={handleUpdateLastValueCard}
            onSubmit={onSubmit}
          />
        )}
        {card?.type === DashboardCardType.image && (
          <ImageEditPanelContainer
            card={card}
            onUpdate={handleUpdateImageCard}
            onSubmit={onSubmit}
          />
        )}
        {card?.type === DashboardCardType.clock && (
          <ClockEditPanelContainer
            card={card}
            onUpdate={handleUpdateClockCard}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  );
};
