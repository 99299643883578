import React, { useMemo } from 'react';
import { useInViewRef } from 'rooks';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableRow,
  TableCell,
  SearchField,
  TableHeadCellSortable,
  Modal,
  classNames,
  createClasses,
} from '@kp/react-ui';
import { DashboardSelectCapabilitiesDeviceRow } from './DashboardSelectCapabilitiesDeviceRow';
import {
  DashboardSelectCapabilitiesFilter,
  DashboardSelectCapabilitiesFilterCategory,
  Device,
} from './DashboardSelectCapabilities.types';
import { TableRowNoResults } from '../../../components/TableRowNoResults';

const classes = createClasses({
  root: {
    width: 'calc(100vw - 240px)',
  },
  content: {
    // That's actually a magic number. Don't know, but it looks good with that height
    height: 'calc(100vh - 336px)',
  },
  input: {
    zIndex: 200,
    padding: '24px 24px 16px 24px',
  },
  table: {
    flexGrow: 1,
    overflow: 'scroll',
    position: 'relative',
    height: 'calc(100% - 72px)',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    '& table thead th': {
      zIndex: 101,
    },
  },
  emptyTable: {
    // Increase CSS specificity
    '$table &': {
      position: 'static',
    },
  },
  headCell: {
    whiteSpace: 'nowrap',
  },
  actions: {
    width: '68px',
  },
  capabilities: {
    width: '20%',
  },
  scrollDetector: {
    height: '40px',
  },
});

export interface DashboardSelectCapabilitiesProps {
  query: string;
  onChangeQuery: (query: string) => void;
  onChangeSort: (direction: 'ASC' | 'DESC', field: string) => void;
  sortField: string | null;
  sortDirection: 'ASC' | 'DESC' | null;
  onAdd: (capability: {
    deviceId: string;
    capabilityId: string;
    capabilityName: string;
  }) => void;
  loading?: boolean;
  devices: Device[];
  selectedCapability?: Device;
  filters: DashboardSelectCapabilitiesFilter[];
  onScrollDown?: () => void;
  loadingMore?: boolean;
  onAddFilter: (filterId: string) => void;
  onRemoveFilter: (filterId: string) => void;
  onResetFilters: () => void;
  onClose?: () => void;
  open: boolean;
}

export const DashboardSelectCapabilities: React.FC<
  DashboardSelectCapabilitiesProps
> = ({
  query,
  onChangeQuery,
  onChangeSort,
  sortField,
  sortDirection,
  filters,
  onAdd,
  onScrollDown,
  onResetFilters,
  onAddFilter,
  onRemoveFilter,
  loading,
  loadingMore,
  devices,
  onClose,
  selectedCapability,
  open,
}) => {
  const { t } = useTranslation();
  const [scrollDetectorRef] = useInViewRef((entries) => {
    if (onScrollDown && entries[0].isIntersecting) {
      onScrollDown();
    }
  });
  const hasEmptyResults = !loading && devices.length === 0;

  const categories = useMemo(
    () => [
      {
        id: DashboardSelectCapabilitiesFilterCategory.DeviceType,
        label: t('widgets.filters.tab.deviceType'),
      },
      {
        id: DashboardSelectCapabilitiesFilterCategory.Floor,
        label: t('widgets.filters.tab.floor'),
      },
      {
        id: DashboardSelectCapabilitiesFilterCategory.Zone,
        label: t('widgets.filters.tab.zone'),
      },
    ],
    [t],
  );

  return (
    <Modal
      title={t('dashboards.modal.capabilities.title')}
      open={open}
      onClose={onClose}
      className={classes.root}
    >
      <div className={classes.content}>
        <div className={classes.input}>
          <SearchField
            fullWidth
            data-testid="device-search-input"
            onResetFilters={onResetFilters}
            categories={categories}
            onChange={onChangeQuery}
            onAddFilter={onAddFilter}
            onRemoveFilter={onRemoveFilter}
            filterButtonLabel={t('widgets.filters.button.filter') || ''}
            filters={filters}
            value={query}
          />
        </div>
        <div className={classes.table} data-testid="device-search">
          <Table
            scrollable
            className={classNames(hasEmptyResults && classes.emptyTable)}
          >
            <TableHead>
              <TableRow>
                <TableHeadCellSortable
                  onClick={onChangeSort}
                  sort={sortField === 'deviceName' ? sortDirection : null}
                  value="deviceName"
                  className={classes.headCell}
                >
                  {t('dashboards.modal.capabilities.table.columns.deviceName')}
                </TableHeadCellSortable>
                <TableHeadCellSortable
                  onClick={onChangeSort}
                  sort={sortField === 'serialNumber' ? sortDirection : null}
                  value="serialNumber"
                  className={classes.headCell}
                >
                  {t(
                    'dashboards.modal.capabilities.table.columns.serialNumber',
                  )}
                </TableHeadCellSortable>
                <TableHeadCellSortable
                  onClick={onChangeSort}
                  sort={sortField === 'deviceType' ? sortDirection : null}
                  value="deviceType"
                  className={classes.headCell}
                >
                  {t('dashboards.modal.capabilities.table.columns.deviceType')}
                </TableHeadCellSortable>
                <TableHeadCellSortable
                  onClick={onChangeSort}
                  sort={sortField === 'storey' ? sortDirection : null}
                  value="storey"
                  className={classes.headCell}
                >
                  {t('dashboards.modal.capabilities.table.columns.storey')}
                </TableHeadCellSortable>
                <TableHeadCellSortable
                  onClick={onChangeSort}
                  sort={sortField === 'description' ? sortDirection : null}
                  value="description"
                  className={classes.headCell}
                >
                  {t('dashboards.modal.capabilities.table.columns.description')}
                </TableHeadCellSortable>
                <TableHeadCell
                  className={classes.capabilities}
                  onClick={onScrollDown}
                >
                  {t('dashboards.modal.capabilities.table.columns.capability')}
                </TableHeadCell>
                <TableHeadCell className={classes.actions} />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <>
                  <TableRow loading>
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                  </TableRow>
                  <TableRow loading>
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                  </TableRow>
                  <TableRow loading>
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                  </TableRow>
                </>
              )}
              {hasEmptyResults && (
                <TableRowNoResults label={t('widgets.table.empty.text')} />
              )}
              {!loading && selectedCapability && (
                <DashboardSelectCapabilitiesDeviceRow
                  device={selectedCapability}
                  selected
                  onAdd={onAdd}
                />
              )}
              {!loading &&
                devices.length > 0 &&
                devices.map((device) => (
                  <DashboardSelectCapabilitiesDeviceRow
                    key={device.id}
                    device={device}
                    onAdd={onAdd}
                  />
                ))}
              {loadingMore && (
                <TableRow loading>
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                </TableRow>
              )}
              {onScrollDown && (
                <TableRow>
                  <TableCell>
                    <div
                      className={classes.scrollDetector}
                      ref={scrollDetectorRef}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </Modal>
  );
};
