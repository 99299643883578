import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Permissions, ProtectedRoute } from '@kp/react-sdk';
import { DevicesContainer } from '../devices';
import { InfoContainer } from './info';
import { InteractiveContainer } from './interactive';
import { IndoorMapContainer } from './indoorMap/IndoorMapContainer';
import { QanteonContainer } from './qanteon';
import { WidgetEditContainer } from './widgets/WidgetEditContainer';
import { WidgetCreateContainer } from './widgets/WidgetCreateContainer';
import { BuildingProvider } from '../../contexts/building-context';
import { NotFoundHandler } from '../NotFoundHandler';
import { DeviceContainer } from '../device';
import { DashboardsContainer } from '../dashboards';

export const BuildingContainer: React.FC = () => (
  <BuildingProvider>
    <Routes>
      <Route path="/interactive" element={<InteractiveContainer />} />
      <Route path="/map" element={<IndoorMapContainer />} />
      <Route path="/info" element={<InfoContainer />} />
      <Route
        path="/qanteon"
        element={
          <ProtectedRoute
            permission={Permissions.QanteonProvisioningRead}
            element={<QanteonContainer />}
          />
        }
      />
      <Route path="/widgets/:widgetId" element={<WidgetEditContainer />} />
      <Route path="/widgets/new" element={<WidgetCreateContainer />} />
      <Route path="/dashboards/*" element={<DashboardsContainer />} />
      <Route path="/devices" element={<DevicesContainer />} />
      <Route path="/devices/:deviceId/*" element={<DeviceContainer />} />
      <Route path="*" element={<NotFoundHandler />} />
    </Routes>
  </BuildingProvider>
);
