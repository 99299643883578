import { request } from '../utils/request';
import { Response } from '../../hooks/useRequest';
import { url, WidgetSettings } from './common';
import { EntityResponse } from '../common';

/**
 * List settings
 *
 * @param buildingId      The building id to get the settings
 */
export const getWidgetSettings = async (
  buildingId?: string,
): Promise<Response<EntityResponse<WidgetSettings>>> => {
  return request<EntityResponse<WidgetSettings>>(
    'GET',
    url(`/widget-settings/${buildingId}`),
  );
};

/**
 * Update widget settings
 *
 * @param buildingId     The id of a widget that will be updated
 * @param data           The data of the widget to be updated
 */
export const updateWidgetSettings = async (
  buildingId: string,
  data: WidgetSettings,
): Promise<Response<EntityResponse<WidgetSettings>>> => {
  return request<EntityResponse<WidgetSettings>>(
    'PUT',
    url(`/widget-settings/${buildingId}`),
    data,
  );
};
