import React, { useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { DeviceChartContainer } from './chart/DeviceChartContainer';
import { DeviceTableContainer } from './table/DeviceTableContainer';
import { NotFoundHandler } from '../NotFoundHandler';
import { useDeviceQuery } from '../../__generated__/types';
import { useNotifications } from '../../contexts/notifications-context';
import { Device, DeviceLocation } from './Device.types';
import { DeviceProvider } from './device-context';
import { DeviceEmptyContainer } from './empty/DeviceEmptyContainer';

export const DeviceContainer: React.FC = () => {
  const { deviceId } = useParams();
  const { add } = useNotifications();

  const { data, loading } = useDeviceQuery({
    variables: { deviceId },
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const [device, location, defaultCapabilityId] = useMemo<
    [Device | undefined, DeviceLocation | undefined, string | undefined]
  >(
    () => [
      data && data.device
        ? {
            name: data.device.name,
            description: data.device.description || '',
            deviceModelName: data.device.deviceModel.name,
            serialNo: data.device.serialNo || '',
            gatewayName: data.device.parentDevice?.name || '',
            deviceTypeName: data.device.deviceModel.deviceType.name,
            identifier: data.device.deviceIdentifier || '',
            capabilities: data.device.deviceModel.deviceModelCapabilities.map(
              (capability) => ({
                id: capability.id,
                name: capability.capability.name,
                technicalMin: capability.technicalMin || undefined,
                technicalMax: capability.technicalMax || undefined,
                unitId: capability.unit.id,
                unitName: capability.unit.name,
                unitSymbol: capability.unit.unitSymbol || undefined,
              }),
            ),
          }
        : undefined,
      data && data.placementOfDevice
        ? {
            siteName: data.placementOfDevice.siteName || '',
            buildingName: data.placementOfDevice.buildingName || '',
            storeyName: data.placementOfDevice.storeyName || undefined,
            zoneName: data.placementOfDevice.zoneName || undefined,
            storeyId: data.placementOfDevice.storeyId || undefined,
            zoneId: data.placementOfDevice.zoneId || undefined,
          }
        : undefined,
      data?.device?.deviceModel?.deviceModelCapabilities?.[0]?.id || undefined,
    ],
    [data],
  );

  return (
    <DeviceProvider capabilities={device?.capabilities || []}>
      {!defaultCapabilityId && !loading ? (
        <DeviceEmptyContainer device={device} location={location} />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <DeviceChartContainer
                device={device}
                location={location}
                loading={loading}
                defaultCapabilityId={defaultCapabilityId || ''}
              />
            }
          />
          <Route
            path="/capabilities/:capabilityId"
            element={
              <DeviceTableContainer
                device={device}
                location={location}
                loading={loading}
              />
            }
          />
          <Route path="*" element={<NotFoundHandler />} />
        </Routes>
      )}
    </DeviceProvider>
  );
};
