import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createClasses, Icon, Icons } from '@kp/react-ui';
import { Color } from '../../../constants/Colors';
import { useBuilding } from '../../../contexts/building-context';

const classes = createClasses({
  root: {
    display: 'flex',
    height: 'calc(100vh - 185px);',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  header: {
    display: 'flex',
    background: 'rgba(246, 247, 249, 0.94)',
    backdropFilter: 'blur(4px)',
    position: 'sticky',
    marginLeft: '-24px',
    top: '158px',
    marginRight: '-24px',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 9,
    padding: '0 24px',
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '25px',
    color: Color.black,
  },
  headerActions: {
    color: Color.black,
  },
  headerActionLink: {
    color: Color.black,
  },
  headerActionIcon: {
    color: Color.black,
  },
  wrapper: {
    display: 'block',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    position: 'relative',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 0,
  },
});

export interface IndoorMapProps {}

export const IndoorMap: React.FC<IndoorMapProps> = () => {
  const { id: buildingId } = useBuilding();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <h2 className={classes.headerTitle}>{t('indoorMap.title')}</h2>
        <div className={classes.headerActions}>
          <RouterLink
            to={`/buildings/${buildingId}/interactive`}
            className={classes.headerActionLink}
          >
            <Icon
              icon={Icons.IconModelEditor}
              size={24}
              className={classes.headerActionIcon}
            />
          </RouterLink>
        </div>
      </header>
      <div className={classes.wrapper}>
        <iframe
          id="IndoorMap"
          title="Indoor Map"
          src="https://kieback-peter-3d.iv.navvis.com/"
          className={classes.iframe}
        />
      </div>
    </div>
  );
};
