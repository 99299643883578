import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createClasses,
  IconButton,
  Typography,
  Icons,
  Colors,
  Tooltip,
} from '@kp/react-ui';
import { ChartType } from '../../api/building-insights';

const classes = createClasses({
  chartTypeArea: {
    padding: '5px 0 0 0',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
  },
  label: {
    color: Colors.Neutral.textWeak,
  },
});

interface ChartTypeSelectorProps {
  title: string;
  type: ChartType;
  onChange: (type: ChartType) => void;
}

export const ChartTypeSelector: React.FC<ChartTypeSelectorProps> = ({
  title,
  type = ChartType.line,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="labelSmall" className={classes.label}>
        {title}
      </Typography>
      <div className={classes.chartTypeArea}>
        <Tooltip
          placement="bottom"
          content={
            <Typography variant="labelSmall">
              {t('widgets.chartType.line')}
            </Typography>
          }
        >
          <IconButton
            variant="secondary"
            component="button"
            selected={type === ChartType.line}
            size="default"
            icon={Icons.IconLineChart2}
            onClick={() => onChange(ChartType.line)}
            data-testid={'button-line-chart'}
          />
        </Tooltip>
        <Tooltip
          content={
            <Typography variant="labelSmall">
              {t('widgets.chartType.bar')}
            </Typography>
          }
          placement="bottom"
        >
          <IconButton
            variant="secondary"
            component="button"
            selected={type === ChartType.bar}
            size="default"
            icon={Icons.IconBarChart2}
            onClick={() => onChange(ChartType.bar)}
            data-testid={'button-bar-chart'}
          />
        </Tooltip>
      </div>
    </div>
  );
};
