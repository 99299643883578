export const DASHBOARD_LEFT_SIDEBAR_WIDTH = 260;
export const DASHBOARD_RIGHT_SIDEBAR_WIDTH = 318;
export const DASHBOARD_RIGHT_SIDEBAR_COLLAPSED_WIDTH = 72;

export const CARD_SIZE_LIMITS = {
  lastValue: {
    minH: 2,
    minW: 3,
    maxH: 4,
    maxW: 8,
  },
  chart: {
    minH: 3,
    minW: 4,
    maxH: 8,
    maxW: 99,
  },
  image: {
    minH: 2,
    minW: 3,
    maxH: 12,
    maxW: 12,
  },
  clock: {
    minH: 2,
    minW: 3,
    maxH: 4,
    maxW: 8,
  },
};
