import { Colors, ListItem, createClasses } from '@kp/react-ui';
import React, { useCallback, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeInterval } from '../../api/building-insights/common';
import { timeIntervals } from '../../constants/TimeIntervals';
import { IntervalCustom } from './IntervalCustom';
import { Interval as hookInterval } from '../../hooks/date-interval';

const classes = createClasses({
  root: {},
  intervals: {
    display: 'flex',
  },
  intervalColumn: {
    width: '160px',
    '& + &': {
      borderLeft: `1px solid ${Colors.Neutral.borderMedium}`,
    },
    padding: '7px 0',
  },
  customInterval: {
    borderTop: `1px solid ${Colors.Neutral.borderMedium}`,
  },
  actions: {
    borderTop: `1px solid ${Colors.Neutral.borderMedium}`,
    padding: '11px',
    display: 'flex',
    gap: '12px',
    justifyContent: 'flex-end',
  },
  button: {
    width: '88px',
  },
  custom: {
    display: 'flex',
  },
  fieldWrapper: {
    paddingBottom: '6px',
    paddingTop: '3px',
  },
  time: {
    marginLeft: '12px',
  },
  padding: {
    paddingTop: '5px',
  },
  endTime: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  errorText: {
    color: Colors.Danger.text,
  },
});

export interface Interval {
  timeInterval: TimeInterval;
  dateFrom: Date | undefined | null;
  dateUntil: Date | undefined | null | 'max';
}

export interface IntervalSelectorProps {
  interval: Interval;
  onCancel: () => void;
  onChange: (interval: Interval) => void;
}

export const IntervalSelector: React.FC<IntervalSelectorProps> = ({
  interval,
  onCancel,
  onChange,
}) => {
  const { t } = useTranslation();

  const [timeInterval, setTimeInterval] = useState(interval.timeInterval);

  const handleChangeInterval = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const clickedInterval = (event.currentTarget as HTMLButtonElement)
        .value as TimeInterval;

      if (clickedInterval !== TimeInterval.customInterval) {
        onChange({
          timeInterval: clickedInterval,
          dateFrom: null,
          dateUntil: null,
        });
        onCancel();
      } else {
        setTimeInterval(clickedInterval);
      }
    },
    [setTimeInterval, onChange, onCancel],
  );

  const handleCustomDateChange = useCallback(
    (changedInterval: hookInterval) => {
      onChange({
        timeInterval: TimeInterval.customInterval,
        dateFrom: changedInterval.dateFrom,
        dateUntil: changedInterval.dateUntil,
      });
      onCancel();
    },
    [onChange, onCancel],
  );

  return (
    <div className={classes.root}>
      <div className={classes.intervals}>
        <div className={classes.intervalColumn}>
          {[
            TimeInterval.last15Minutes,
            TimeInterval.last30Minutes,
            TimeInterval.lastHour,
            TimeInterval.last2Hours,
            TimeInterval.last4Hours,
            TimeInterval.last12Hours,
          ].map((item) => (
            <ListItem
              key={item}
              value={item}
              title={timeIntervals(t)[item]}
              selected={item === timeInterval}
              fullWidth
              trailingIcon={item === timeInterval}
              onClick={handleChangeInterval}
            />
          ))}
        </div>
        <div className={classes.intervalColumn}>
          {[
            TimeInterval.lastDay,
            TimeInterval.last7Days,
            TimeInterval.last30Days,
            TimeInterval.last90Days,
            TimeInterval.customInterval,
          ].map((item) => (
            <ListItem
              key={item}
              value={item}
              title={timeIntervals(t)[item]}
              selected={item === timeInterval}
              fullWidth
              trailingIcon={item === timeInterval}
              onClick={handleChangeInterval}
            />
          ))}
        </div>
      </div>
      {timeInterval === TimeInterval.customInterval && (
        <IntervalCustom
          wrapperClassName={classes.customInterval}
          onCancel={onCancel}
          onChange={handleCustomDateChange}
          dateFrom={interval.dateFrom ?? new Date()}
          dateUntil={interval.dateUntil ?? new Date()}
        />
      )}
    </div>
  );
};
