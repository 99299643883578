import React from 'react';
import { Colors, classNames, createClasses } from '@kp/react-ui';
import { Breadcrumbs } from '../Breadcrumbs';
import { BREADCRUMB_HEIGHT } from '../../constants/UI';

const classes = createClasses({
  root: {
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.background,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 24px',
    height: BREADCRUMB_HEIGHT,
  },
});

export interface HeaderNavigationProps {
  className?: string;
}

export const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  className,
}) => (
  <div className={classNames(classes.root, className)}>
    <Breadcrumbs />
  </div>
);
