import React from 'react';
import { createClasses, Icons, Typography } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { DashboardCardType } from '../../../../../api/building-insights';
import { DashboardRightSidebarNewCardItem } from './DashboardRightSidebarNewCardItem';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
});

interface DashboardRightSidebarNewCardProps {}

export const DashboardRightSidebarNewCard: React.FC<
  DashboardRightSidebarNewCardProps
> = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant="labelLarge">
        {t('dashboards.rightSidebar.newCard.title')}
      </Typography>
      <div className={classes.items}>
        <DashboardRightSidebarNewCardItem
          type={DashboardCardType.lastValue}
          title={t('dashboards.rightSidebar.newCard.lastValue.title')}
          image="/assets/single-stat-thumb.svg"
          icon={Icons.IconSinglestat}
          description={t(
            'dashboards.rightSidebar.newCard.lastValue.description',
          )}
        />
        <DashboardRightSidebarNewCardItem
          type={DashboardCardType.chart}
          title={t('dashboards.rightSidebar.newCard.chart.title')}
          image="/assets/graph-thumb.svg"
          icon={Icons.IconStatistics}
          description={t('dashboards.rightSidebar.newCard.chart.description')}
        />
        <DashboardRightSidebarNewCardItem
          type={DashboardCardType.clock}
          title={t('dashboards.rightSidebar.newCard.clock.title')}
          image="/assets/clock-thumb.svg"
          icon={Icons.IconTime}
          description={t('dashboards.rightSidebar.newCard.clock.description')}
        />
        <DashboardRightSidebarNewCardItem
          type={DashboardCardType.image}
          title={t('dashboards.rightSidebar.newCard.image.title')}
          image="/assets/image-thumb.svg"
          icon={Icons.IconPlantImage}
          description={t('dashboards.rightSidebar.newCard.image.description')}
        />
      </div>
    </div>
  );
};
