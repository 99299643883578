import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonBlock,
  createClasses,
  Icons,
  Input,
  Label,
} from '@kp/react-ui';
import { ChangeEventHandler, useCallback, useState } from 'react';
import {
  ChartType,
  DashboardCardChartItem,
} from '../../../../../api/building-insights';
import { ChartTypeSelector } from '../../../../../components/ChartTypeSelector';
import { ChartEditPanelCapability } from './ChartEditPanelCapability';
import { DashboardSelectCapabilitiesContainer } from '../../../DashboardSelectCapabilities/DashboardSelectCapabilitiesContainer';
import { PointPreviewContainer } from '../../pointPreview';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },
  input: {
    maxWidth: '296px',
  },
  createDataPointButton: {
    width: '600px',
  },
});

interface ChartEditPanelProp {
  loading: boolean;
  title?: string;
  subtitle?: string;
  type: ChartType;
  items: Array<
    DashboardCardChartItem & {
      id: string;
      deviceName: string;
      name: string;
      capabilityName: string;
      unitName: string;
    }
  >;
  onChangeTitle: (title: string) => void;
  onChangeSubtitle: (subtitle: string) => void;
  onChangeType: (type: ChartType) => void;
  onAddItem: (
    item: Pick<DashboardCardChartItem, 'capabilityId' | 'deviceId' | 'name'>,
  ) => void;
  onRemoveItem: (id: string) => void;
  onChangeItem: (id: string, item: Partial<DashboardCardChartItem>) => void;
  onSubmit: () => void;
}

export const ChartEditPanel: React.FC<ChartEditPanelProp> = ({
  loading,
  title,
  subtitle,
  type,
  items,
  onChangeSubtitle,
  onChangeTitle,
  onChangeType,
  onAddItem,
  onRemoveItem,
  onChangeItem,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [selectCapabilityModalOpen, setSelectCapabilityModalOpen] =
    useState(false);

  const [selectedPreviewCapabilityId, setSelectedPreviewCapabilityId] =
    useState<undefined | string>(undefined);

  const handleTitleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => onChangeTitle(event.target.value),
    [onChangeTitle],
  );

  const handleSubtitleChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((event) => onChangeSubtitle(event.target.value), [onChangeSubtitle]);

  const handleSelectCapability = useCallback(
    (selectedCapability: {
      deviceId: string;
      capabilityId: string;
      capabilityName: string;
    }) => {
      setSelectCapabilityModalOpen(false);
      onAddItem({
        deviceId: selectedCapability.deviceId,
        capabilityId: selectedCapability.capabilityId,
        name: selectedCapability.capabilityName,
      });
    },
    [onAddItem],
  );

  const handleCapabilityPreview = useCallback(
    (capabilityId: string) => {
      if (selectedPreviewCapabilityId === capabilityId) {
        setSelectedPreviewCapabilityId(undefined);
      } else {
        setSelectedPreviewCapabilityId(capabilityId);
      }
    },
    [selectedPreviewCapabilityId, setSelectedPreviewCapabilityId],
  );

  return (
    <div className={classes.root}>
      <DashboardSelectCapabilitiesContainer
        onAdd={handleSelectCapability}
        onClose={() => setSelectCapabilityModalOpen(false)}
        open={selectCapabilityModalOpen}
      />
      <ChartTypeSelector
        type={type}
        onChange={onChangeType}
        title={t('dashboards.card.editPanel.fields.visualisation')}
      />
      <div className={classes.input}>
        <Input
          placeholder={t('dashboards.card.untitled') || ''}
          label={t('dashboards.card.editPanel.fields.title') || ''}
          value={title}
          onChange={handleTitleChange}
          fullWidth
          onBlur={onSubmit}
        />
      </div>
      <div className={classes.input}>
        <Input
          placeholder={t('dashboards.card.editPanel.fields.placeholder') || ''}
          label={t('dashboards.card.editPanel.fields.subtitle') || ''}
          value={subtitle}
          fullWidth
          onChange={handleSubtitleChange}
          onBlur={onSubmit}
        />
      </div>
      <Label
        title={t('dashboards.card.editPanel.fields.capabilities') || ''}
        component="div"
      >
        {items.map((item) => (
          <div key={item.id}>
            <ChartEditPanelCapability
              loading={loading}
              capability={item}
              onChange={onChangeItem}
              onDelete={onRemoveItem}
              onPreview={handleCapabilityPreview}
              selectedPreviewCapabilityId={selectedPreviewCapabilityId}
            />
            {selectedPreviewCapabilityId === item.id && (
              <PointPreviewContainer
                deviceId={item.deviceId}
                capabilityId={item.capabilityId}
              />
            )}
          </div>
        ))}
        {items && items?.length > 0 ? (
          <Button
            variant="secondary"
            onClick={() => {
              setSelectCapabilityModalOpen(true);
            }}
            icon={Icons.IconPlusCircle}
          />
        ) : (
          <ButtonBlock
            className={classes.createDataPointButton}
            label={t('dashboards.card.editPanel.capability.placeholder') || ''}
            onClick={() => setSelectCapabilityModalOpen(true)}
            leftIcon={Icons.IconPlusCircle}
          />
        )}
      </Label>
    </div>
  );
};
