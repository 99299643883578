interface ModelMapping {
  id: string;
  ifcId: string;
}

interface ZoneMapping {
  id: string;
  ifcId?: string;
}

export interface LightMapping {
  ifcId: string;
  x: number;
  y: number;
}

interface Model {
  tenantId: string;
  buildingId: string;
  xktFile: string;
  metaFile?: string;
  mappings: {
    storeys: Array<ModelMapping & { zones?: ZoneMapping[] }>;
    lights?: LightMapping[];
  };
}

const tw50Lights = [
  // 2nd Floor
  { ifcId: '3wf9aBqLv9jAIkxvXo_A$z', x: 2, y: 3 },
  { ifcId: '0l_5JaDA9BqxCGYRHEo1GF', x: 3, y: 3 },
  { ifcId: '19KoR6xW17MAZCryzVIWbZ', x: 4, y: 3 },
  { ifcId: '2Gzw_OIPf9mu0MqmHWj1K_', x: 5, y: 3 },
  { ifcId: '3YilkuhRr7WgId3qXXHZsF', x: 6, y: 3 },
  { ifcId: '0a8UUWAjD8c9FGqW95v56n', x: 7, y: 3 },
  { ifcId: '20rI4QkYr6FP7ELSeJE$_5', x: 8, y: 3 },
  { ifcId: '2nH_8yfWPAZeVCfxyt7_Aw', x: 9, y: 3 },
  { ifcId: '3fUu9T3jD1YAqvUWH_tnDv', x: 10, y: 3 },
  { ifcId: '1J1Yfu34r5WPiaX_2LDEaX', x: 11, y: 3 },
  { ifcId: '1xL8_gyZL9VA3kpaEDNUiv', x: 26, y: 3 },
  { ifcId: '224sGzNXnBAfHVU1f$HfOB', x: 27, y: 3 },
  { ifcId: '30OozYXz5B89bIVM8OLYx4', x: 28, y: 3 },
  { ifcId: '35Vg25sCnBdeRx3oL7Mw35', x: 29, y: 3 },
  { ifcId: '25Sh0Pb$zFGvqMz9cZCSf1', x: 30, y: 3 },
  { ifcId: '28ehoHJnT9ohFkaGQy9uov', x: 31, y: 3 },
  { ifcId: '0IwcgLB3r5uR2J33$BZum3', x: 32, y: 3 },
  { ifcId: '286SlcHb18QwvnNb8BWsM9', x: 33, y: 3 },
  { ifcId: '1ZFaobqH1DFRpkTRoPDj0G', x: 34, y: 3 },
  { ifcId: '0$NvdeCBz3VeE6JBtDEFKY', x: 35, y: 3 },
  // 3nd Floor
  { ifcId: '2OpwyUqev6muZW22Kopzut', x: 2, y: 2 },
  { ifcId: '0rNfLt74T3PgvowCkFI9wI', x: 3, y: 2 },
  { ifcId: '0B7qiEX3f90QA4BrsM6tGR', x: 4, y: 2 },
  { ifcId: '1iBUvuPDv8wvwVJ80VvFx1', x: 5, y: 2 },
  { ifcId: '1Fckppfsz6RwrY5VicIwH$', x: 6, y: 2 },
  { ifcId: '3ZJiuUaEf3j9zKWMhsG_an', x: 7, y: 2 },
  { ifcId: '0nhYYOL3X9yeaoVD2s85Nm', x: 8, y: 2 },
  { ifcId: '36LwhlZer6488tYOVKCnkL', x: 9, y: 2 },
  { ifcId: '2dN7iMxQ9B$QBleSSMaO6R', x: 10, y: 2 },
  { ifcId: '34oActFYz5qxdaoeA$Qj02', x: 11, y: 2 },
  { ifcId: '3NQJeinCf2FOxA5Wmf1nLN', x: 26, y: 2 },
  { ifcId: '1iysLdnS96Wxi9we93TWSR', x: 27, y: 2 },
  { ifcId: '2ZvGVYCE51XxDlkEOgqaSL', x: 28, y: 2 },
  { ifcId: '27XPCQ_QTFof7lFjCdasU_', x: 29, y: 2 },
  { ifcId: '0SLDcedCP8Yf0v0CmXqEa0', x: 30, y: 2 },
  { ifcId: '122iyTop50tut_dsh$tQX6', x: 31, y: 2 },
  { ifcId: '1S8agFxx18ff_wtDdrr$ao', x: 32, y: 2 },
  { ifcId: '2hv9_eSs92YBonD67sO1bP', x: 33, y: 2 },
  { ifcId: '3GZZtA3l9CchZ3z79d4KRG', x: 34, y: 2 },
  { ifcId: '2Rr3V0GAbBfBfLk4bV6RBF', x: 35, y: 2 },
  // 4nd Floor
  { ifcId: '0D46TBrlr7JfjK8a_rxv0L', x: 2, y: 1 },
  { ifcId: '21xGMG1MHFBugdcfCGFPg2', x: 3, y: 1 },
  { ifcId: '2hil36xY97tww2scUAmuR$', x: 4, y: 1 },
  { ifcId: '1TCf4prMT2jPBuMTXwh4Y6', x: 5, y: 1 },
  { ifcId: '3YC_t$Yv9D4eNdY0IDW2$Y', x: 6, y: 1 },
  { ifcId: '3v24KUiOv2GfOXUVTLbD$B', x: 7, y: 1 },
  { ifcId: '1c5UfXxjz9y8GkdCDUGmz0', x: 8, y: 1 },
  { ifcId: '2KTwwNDZ99o918alCtMmRL', x: 9, y: 1 },
  { ifcId: '08_BTsjJr6I8u5MXJQ4j_I', x: 10, y: 1 },
  { ifcId: '0DYEmdLuX5Qv0wFDxZkp_K', x: 11, y: 1 },
  { ifcId: '217KwN1Hf3IBxQot$6Ku5B', x: 26, y: 1 },
  { ifcId: '2dO4cNidv8Ju1XS0Td3smF', x: 27, y: 1 },
  { ifcId: '0Nne_dMij4vv0aMOnew$Pd', x: 28, y: 1 },
  { ifcId: '3Ckh5LgGLEXANh9eThcchT', x: 29, y: 1 },
  { ifcId: '2C2Lnu86j1lej0RsGxHru4', x: 30, y: 1 },
  { ifcId: '3fmQoRwv56hP2irYYKTuXb', x: 31, y: 1 },
  { ifcId: '1qxbbTlRz4VvTCDEgNZvOt', x: 32, y: 1 },
  { ifcId: '2kN2hjkVL6LhF6Pp$vEiRB', x: 33, y: 1 },
  { ifcId: '2OjvdSJKrAtAyGgKnX0K55', x: 34, y: 1 },
  { ifcId: '1QspeXo8D1$Rcaw79iXjrZ', x: 35, y: 1 },
  // 5nd Floor
  { ifcId: '0oDXwNP5z9BAkmFMn8cphu', x: 2, y: 0 },
  { ifcId: '1OIPnZkHzDFAOFQAVDJzE9', x: 3, y: 0 },
  { ifcId: '3ECChSrMnEkOEJ3mqagB$a', x: 4, y: 0 },
  { ifcId: '0g0eWCeGP8IxvQ3rYMQFee', x: 5, y: 0 },
  { ifcId: '0lA6V039L9fugLdlcsJUWu', x: 6, y: 0 },
  { ifcId: '2GeImXgJbFHvTynYIMKenT', x: 7, y: 0 },
  { ifcId: '1u87aRu0z6_RqEuNRYh3VR', x: 8, y: 0 },
  { ifcId: '2OCgkkuFn62QMM8NZc5ZO9', x: 9, y: 0 },
  { ifcId: '0XXnpCz2f2jOBHFswC9x_u', x: 10, y: 0 },
  { ifcId: '2S2nEBK5z93BjXqAGMVhSI', x: 11, y: 0 },
  { ifcId: '1PFghbF9T7Z8q5g_nK0_db', x: 26, y: 0 },
  { ifcId: '1L_JexmCbDcuRA4Dl$ZXT1', x: 27, y: 0 },
  { ifcId: '1YmWefBuDDNAIuUsyioeJ$', x: 28, y: 0 },
  { ifcId: '2W_u3nECP2GeZKykRR6eeL', x: 29, y: 0 },
  { ifcId: '1ZUenQcj13URCdCh7RYcbD', x: 30, y: 0 },
  { ifcId: '3ENgiJjov1b9dYALPeHVE_', x: 31, y: 0 },
  { ifcId: '3Di5MiJCv3Qwi8HG$5soGL', x: 32, y: 0 },
  { ifcId: '0Ry$KYphD2jOpV53cw_8gB', x: 33, y: 0 },
  { ifcId: '3QbONQcZr658LR$XLgKZFd', x: 34, y: 0 },
  { ifcId: '2gg_OwCVz0084rv1PDF4ve', x: 35, y: 0 },
];

export const InteractiveModelEnabled: Model[] = [
  //
  // Test tenant
  //
  {
    tenantId: 'ca43a1c7-d388-4b79-9d4a-8cbd8594e470',
    buildingId: 'd5391158-e287-417d-a244-28a5c0900f67',
    xktFile: '/assets/tw50.xkt',
    metaFile: '/assets/tw50.json',
    mappings: {
      lights: tw50Lights,
      storeys: [
        {
          id: '3b81bfed-9f75-4f14-9a64-6e8a4ff0d2ee', // 2.UG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQke',
        },
        {
          id: 'c291a2dc-5a5a-480b-a1ee-5ee45e0d8ec3', // 1.UG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQlC',
        },
        {
          id: 'f053620a-5557-497a-a5ab-49c572f8abca', // EG
          ifcId: '0K8IC2Dhn3xfuKgsfMrIV1',
        },
        {
          id: 'f8cde26a-42a6-4636-9c1b-91335cac8e0c', // 1.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMrIH0',
        },
        {
          id: '32c29f38-b4d4-4b16-94ee-1586ec03fc4c', // 2.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQgp',
        },
        {
          id: '2112d9d8-88a2-4d8b-b3a0-e4dd96e0d48d', // 3.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQgV',
        },
        {
          id: 'baf001ad-b668-42d0-a71d-b09a6bce18a7', // 4.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQeu',
        },
        {
          id: 'dba9ea86-fbd8-4a5a-b2d6-f822dd616397', // 5.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQe4',
        },
        {
          id: '64a54f54-09c8-42cd-bf56-c5ead171e144', // 6.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQlW',
        },
      ],
    },
  },
  //
  // Ivan's Development Tenant
  //
  {
    tenantId: '13a9ed3b-d34c-4ced-80d9-651a2d056da8',
    buildingId: 'f5c9cc0b-6c44-4b13-ac27-a868812ee24c',
    xktFile: '/assets/tw50.xkt',
    metaFile: '/assets/tw50.json',
    mappings: {
      lights: tw50Lights,
      storeys: [
        {
          id: '6d11140b-9df5-4326-b3ea-e0eb6909dbf7', // 2.UG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQke',
        },
        {
          id: '24adc9a3-a9c8-4d5f-bb03-cec54a6272ae', // 1.UG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQlC',
        },
        {
          id: '3362e769-09aa-4739-a76d-dbdf7183db72', // EG
          ifcId: '0K8IC2Dhn3xfuKgsfMrIV1',
        },
        {
          id: '50fe0e5f-6000-4df3-99c9-1b5bc1d5fa17', // 1.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMrIH0',
        },
        {
          id: '474a6d4c-e47e-4c57-ba90-fcd1825b3a2c', // 2.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQgp',
          zones: [
            {
              id: '8a874968-9523-4dab-ada0-4a9307e64220', // 2.21
            },
            {
              id: '8a9dd550-48e6-4505-9378-0c771df42e00', // 2.22
            },
            {
              id: '4417da5b-09c5-4b65-b649-a35efcda1ac0', // 2.23
            },
            {
              id: '45001ce4-6168-4dfd-ad13-0ec3f8f5b10a', // 2.24
            },
            {
              id: 'bffdc85a-fd5e-476e-9e38-8ffea9bb7661', // 2.25
            },
            {
              id: 'f9aa9026-22f3-4499-8f2f-b04a9358d924', // 2.26
            },
            {
              id: '52ff8b06-e01b-44a7-a8bf-e7051f801e42', // 2.27
            },
            {
              id: '53f5d751-f5a4-4137-abc8-ee1ad382f4a2', // 2.28
            },
            {
              id: '858adb4e-73cc-4d43-9766-7f7cfe0dc903', // 2.29
            },
          ],
        },
        {
          id: 'ec3e83ee-43b1-4a43-ba59-27d20187ea36', // 3.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQgV',
        },
        {
          id: 'f93aa996-b954-4a2d-a12c-cc0f51b34673', // 4.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQeu',
        },
        {
          id: '60caee27-31b8-41a4-bf69-a9ad873c8ad2', // 5.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQe4',
        },
        {
          id: '6ca54670-0b81-42cf-a1b7-1178ff7a4e89', // 6.OG
          ifcId: '0K8IC2Dhn3xfuKgsfMqQlW',
        },
      ],
    },
  },
  {
    tenantId: '13a9ed3b-d34c-4ced-80d9-651a2d056da8',
    buildingId: 'a69b4864-c6dd-4cac-bf13-4d1db29b4f08',
    xktFile: '/assets/OTCConferenceCenter.xkt',
    mappings: {
      storeys: [
        {
          id: '6fe5b3d9-0b2d-4954-b888-b601d9073011', // 'B2 Basement'
          ifcId: '3lMSBJ1J9AUQZulZNJlLzr',
        },
        {
          id: '3207d73e-c365-4eee-b686-33571dbf94da', // 'B1 Basement',
          ifcId: '3lMSBJ1J9AUQZulZNJlLpR',
          zones: [
            {
              id: '01436230-2c0f-464a-8f11-3d01f19ebd4b', // 'B141',
              ifcId: '2gt9UqKnT4DvHlrgGjUXdu',
            },
            {
              id: '0531d911-3eb2-4f0e-8bd9-5cbf125427fe', // 'B111',
              ifcId: '0uGek424j05BaSi7k8quP2',
            },
            {
              id: '0fe7bbe1-d88d-40d1-bd2f-e45d1dee5476', // 'B154',
              ifcId: '2jnwSeFHz9bAhfugdwq2v3',
            },
            {
              id: '1606cadb-30ff-4c85-9861-12fab947d33d', // 'B124',
              ifcId: '39xeVIdXb0Oeui8c6ndGDN',
            },
            {
              id: '16430e70-78a6-4242-a6b7-9d3b2351f419', // 'B149',
              ifcId: '16W6cLvsb3jBoxMDVIAJX6',
            },
            {
              id: '19c92197-a470-4492-8260-bb792efbf6be', // 'B120',
              ifcId: '0uGek424j05BaSi7k8quVb',
            },
            {
              id: '1d14f6aa-62ac-41da-a8d7-ff3311cb2551', // 'B142',
              ifcId: '0jdEFTa3r2xhGxvrW4$OxF',
            },
            {
              id: '203414df-83b9-495e-985c-026e00010264', // 'B127',
              ifcId: '39xeVIdXb0Oeui8c6ndGDD',
            },
            {
              id: '22027288-050e-4525-be80-b9e637fca6f2', // 'B119',
              ifcId: '1oeLNWdyvD2xBpFQTGTp9W',
            },
            {
              id: '22200c5a-84ba-4045-b65e-0c3d786192f2', // 'B151',
              ifcId: '24KhfkJI5FJ9d$VCsZuwRp',
            },
            {
              id: '2a3d8840-7541-4cd8-9d4d-9821fac434b9', // 'B134',
              ifcId: '0PeKSAvI59PfDaeCq6ElAJ',
            },
            {
              id: '349e5884-d1ec-4000-94c7-7e198b99c5b4', // 'B115',
              ifcId: '0uGek424j05BaSi7k8quPC',
            },
            {
              id: '3f0a0720-f653-481f-ab79-ec895151caed', // 'B125',
              ifcId: '39xeVIdXb0Oeui8c6ndGD9',
            },
            {
              id: '419d1b9f-a876-46ec-a64f-6b2ea80c212f', // 'B109',
              ifcId: '0uGek424j05BaSi7k8quU_',
            },
            {
              id: '47fa2b02-e914-4eb8-943b-2ca6f965afc3', // 'B126',
              ifcId: '39xeVIdXb0Oeui8c6ndGDB',
            },
            {
              id: '48347d3a-936a-46b1-a2f5-8cce5876f258', // 'B128',
              ifcId: '39xeVIdXb0Oeui8c6ndGDF',
            },
            {
              id: '4904d070-08a7-4960-858d-180ddfe9fb09', // 'B123',
              ifcId: '39xeVIdXb0Oeui8c6ndGDL',
            },
            {
              id: '5e0bb93f-4c26-42e5-8a7c-272cdaa72c1e', // 'B136',
              ifcId: '2gt9UqKnT4DvHlrgGjUXdt',
            },
            {
              id: '5f15fcf5-fe1b-42af-994e-e839633401a1', // 'B131',
              ifcId: '39xeVIdXb0Oeui8c6ndGD5',
            },
            {
              id: '6394606b-a55a-4917-8945-ba6774a435d9', // 'B113',
              ifcId: '0JaVFNY8150g9wOWKQH9Vm',
            },
            {
              id: '68158b1c-fb75-408f-b36a-3629ecbb201b', // 'B122',
              ifcId: '39xeVIdXb0Oeui8c6ndGDJ',
            },
            {
              id: '7029563f-d24b-4512-9a6e-26fb9407783c', // 'B106',
              ifcId: '0uGek424j05BaSi7k8quP4',
            },
            {
              id: '761a91ed-cd3c-4bda-931f-a3b31184be6d', // 'B112',
              ifcId: '0uGek424j05BaSi7k8quP6',
            },
            {
              id: '79443665-3db3-41fd-8efb-fb8e8caecc31', // 'B137',
              ifcId: '0jdEFTa3r2xhGxvrW4$OxT',
            },
            {
              id: '7fd0d246-90ec-4afd-a0e4-369610012604', // 'B147',
              ifcId: '0AVVlLIDbCvOgtmmM8Bw76',
            },
            {
              id: '8748eae3-9875-40a3-91fe-08041366e4c5', // 'B140',
              ifcId: '0jdEFTa3r2xhGxvrW4$Ox5',
            },
            {
              id: '8fd7565b-1d52-4ef3-9154-75ca760f1710', // 'B150',
              ifcId: '2gt9UqKnT4DvHlrgGjUXd6',
            },
            {
              id: '925a0c9f-7873-4860-bba6-e5cfd36e9567', // 'B114',
              ifcId: '0uGek424j05BaSi7k8quP8',
            },
            {
              id: '9ecb101e-9dad-4e0f-892c-ff865dbe9521', // 'B148',
              ifcId: '16W6cLvsb3jBoxMDVIAJX9',
            },
            {
              id: 'a4fff3aa-9fc9-4bee-a98a-7237d3442236', // 'B143',
              ifcId: '0jdEFTa3r2xhGxvrW4$OxD',
            },
            {
              id: 'a65bdae5-b9df-45ad-b7a5-53e96f43c0f4', // 'B145',
              ifcId: '0jdEFTa3r2xhGxvrW4$OwF',
            },
            {
              id: 'a665c5a6-2d0b-4d2e-951d-0e13d7808b60', // 'B129',
              ifcId: '39xeVIdXb0Oeui8c6ndGD1',
            },
            {
              id: 'ae83a40c-f314-4c6a-b29d-6606be2da3a1', // 'B144',
              ifcId: '0jdEFTa3r2xhGxvrW4$OxB',
            },
            {
              id: 'b5569c7f-4729-4ee3-a7cc-8446f408eecd', // 'B139',
              ifcId: '0jdEFTa3r2xhGxvrW4$OxP',
            },
            {
              id: 'b593b748-eb43-4128-9e00-2fb582e25384', // 'B132',
              ifcId: '0PeKSAvI59PfDaeCq6ElAN',
            },
            {
              id: 'be6536b7-b311-4f8f-ae4b-cbd7124c686d', // 'B108',
              ifcId: '0uGek424j05BaSi7k8quUy',
            },
            {
              id: 'beb64731-ed84-4e92-9a84-56a9afda1472', // 'B121',
              ifcId: '39xeVIdXb0Oeui8c6ndGDG',
            },
            {
              id: 'c3c44361-e7ac-49ba-9bba-e5f9b344b939', // 'B135',
              ifcId: '0PeKSAvI59PfDaeCq6ElAH',
            },
            {
              id: 'cc176042-5324-4153-b176-cb186fcce38e', // 'B118',
              ifcId: '1oeLNWdyvD2xBpFQTGTp9a',
            },
            {
              id: 'cc5ea8bd-c5a2-44d5-96a5-b05825502185', // 'B110',
              ifcId: '0uGek424j05BaSi7k8quP0',
            },
            {
              id: 'd44c6ab4-43e3-47c7-a51a-c5895fcf8861', // 'B146',
              ifcId: '15E8uh5azAGPtKsV0k1U2l',
            },
            {
              id: 'd601f791-4129-4e9b-8db5-3c8013b0ac2c', // 'B152',
              ifcId: '2gt9UqKnT4DvHlrgGjUXd4',
            },
            {
              id: 'd777e6f9-23f0-4da0-8755-464934bec9b1', // 'B133',
              ifcId: '0SmY8owzL2ReIRyR3Lic4i',
            },
            {
              id: 'db3df34f-84a5-49fa-839b-04477a1b2b13', // 'B130',
              ifcId: '39xeVIdXb0Oeui8c6ndGD3',
            },
            {
              id: 'e8fff363-dd4d-44a4-9f18-bec6a68406cc', // 'B138',
              ifcId: '0jdEFTa3r2xhGxvrW4$OxR',
            },
            {
              id: 'fa6ec250-e2b7-4e14-8fec-fc6635dde9ac', // 'B116',
              ifcId: '0uGek424j05BaSi7k8quPA',
            },
            {
              id: 'fd4f009c-bfdc-4dff-ad06-32607788c2aa', // 'B117',
              ifcId: '1oeLNWdyvD2xBpFQTGTp9Y',
            },
          ],
        },
        {
          id: '631a2a8f-4d9d-4f68-8311-6c3be5c44e96', // 'B1 Mezzanine',
          ifcId: '3lMSBJ1J9AUQZulZNJlLny',
        },
        {
          id: '3946c977-21d1-4548-8bc4-3426cf68b54c', // '1st Floor',
          ifcId: '3lMSBJ1J9AUQZulZNJl9KP',
        },
        {
          id: 'af60f880-f209-454d-ab85-b86c6af95946', // '2nd Floor',
          ifcId: '3lMSBJ1J9AUQZulZNJlBFT',
        },
        {
          id: 'b9b09be6-5052-4958-84db-181213f8878f', // '3rd Floor',
          ifcId: '3lMSBJ1J9AUQZulZNJlupQ',
        },
        {
          id: '014ed077-2093-4be4-85f3-ed155623e6aa', // '4th Floor',
          ifcId: '3lMSBJ1J9AUQZulZNJlu_M',
        },
        {
          id: '22e7e7b8-18b7-4d5d-befe-9744fb45ce72', // '5th Floor',
          ifcId: '3lMSBJ1J9AUQZulZNJluu1',
        },
        {
          id: '11fd16ed-cbbb-43aa-a311-177103bb7738', // '6th Floor',
          ifcId: '3lMSBJ1J9AUQZulZNJltCS',
        },
        {
          id: '8ffc9646-a9ec-4918-abb5-d1df11efd772', // '7th Floor',
          ifcId: '3lMSBJ1J9AUQZulZNJlNAm',
        },
        {
          id: '9dee38b3-d531-42a9-ac34-77a1bdaea056', // '8th Floor',
          ifcId: '3lMSBJ1J9AUQZulZNJlN8U',
        },
      ],
    },
  },
];
