import React, { useCallback } from 'react';
import { Typography } from '@kp/react-ui';
import { FormProvider } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useTranslation } from 'react-i18next';
import { DashboardCard, DashboardEntity } from '../../../api/building-insights';
import { DashboardsLayout } from '../DashboardsLayout';
import { DashboardForm, DashboardFormSubmit } from './Dashboard.types';
import { DashboardName } from './DashboardName';
import { DashboardRightSidebar } from './rightSidebar';
import { DashboardInterval } from './DashboardInterval';
import { Dashboard } from './Dashboard';

interface DashboardFormWrapperProps {
  loading?: boolean;
  initialized?: boolean;
  dashboards: DashboardEntity[];
  selectedCardId?: string;
  onSelectCard: (cardId: string | undefined) => void;
  onSubmit: (data: DashboardForm) => Promise<void | undefined | DashboardCard>;
  onSubmitDebounced: (data: DashboardForm) => void;
  onSubmitCard: (
    data: DashboardForm,
  ) => Promise<void | undefined | DashboardCard>;
  onSubmitCardDebounced: (data: DashboardForm) => void;
  onDelete: () => void;
  onCloseEditPanel: () => void;
  methods: UseFormReturn<DashboardForm>;
}

export const DashboardFormWrapper: React.FC<DashboardFormWrapperProps> = ({
  loading,
  initialized,
  dashboards,
  selectedCardId,
  onSelectCard,
  onSubmit,
  onSubmitDebounced,
  onSubmitCard,
  onSubmitCardDebounced,
  onDelete,
  onCloseEditPanel,
  methods,
}) => {
  const { hasPermission } = useAuth();
  const { t } = useTranslation();

  const { handleSubmit, getValues } = methods;

  const submit = useCallback<DashboardFormSubmit>(
    ({ debounce } = { debounce: false }) =>
      handleSubmit(debounce ? onSubmitDebounced : onSubmit)(),
    [handleSubmit, onSubmit, onSubmitDebounced],
  );

  const submitCard = useCallback(
    ({ debounce } = { debounce: false }) =>
      handleSubmit(debounce ? onSubmitCardDebounced : onSubmitCard)(),
    [onSubmitCardDebounced, onSubmitCard, handleSubmit],
  );

  return (
    <FormProvider {...methods}>
      <DashboardsLayout
        dashboards={dashboards}
        loading={loading}
        title={
          hasPermission(Permissions.WidgetsWrite) ? (
            <DashboardName loading={loading} submit={submit} />
          ) : (
            <Typography variant="titleLarge">
              {getValues('name') || t('dashboards.template.untitled')}
            </Typography>
          )
        }
        actions={<DashboardInterval submit={submit} />}
        rightSidebar={
          hasPermission(Permissions.WidgetsWrite) &&
          initialized && (
            <DashboardRightSidebar submit={submit} onDelete={onDelete} />
          )
        }
      >
        {initialized && (
          <Dashboard
            submitCard={submitCard}
            submit={submit}
            onSelectCard={onSelectCard}
            selectedCardId={selectedCardId}
            onCloseEditPanel={onCloseEditPanel}
          />
        )}
      </DashboardsLayout>
    </FormProvider>
  );
};
