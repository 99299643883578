import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  classNames,
  Colors,
  createClasses,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@kp/react-ui';
import { DeviceChartTableRow } from './DeviceChartTableRow';
import { useDevice } from '../device-context';

const classes = createClasses({
  root: {
    borderTop: `1px solid ${Colors.Neutral.borderStrong}`,
    overflow: 'scroll',
    height: '100%',
    '& table thead th': {
      zIndex: 101,
    },
  },
  actions: {
    width: '68px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  capabilities: {
    width: '20%',
  },
  handle: {
    height: '2px',
    width: '100%',
    '&:hover': {
      cursor: 'ns-resize',
      background: Colors.Selected.border,
    },
  },
});

export interface DeviceChartTableProps {
  loading?: boolean;
  className?: string;
  onMouseDown: () => void;
}

export const DeviceChartTable: React.FC<DeviceChartTableProps> = ({
  loading,
  className,
  onMouseDown,
}) => {
  const { t } = useTranslation();
  const {
    capabilities,
    setCapabilityColor,
    setCapabilitySelected,
    setAllCapabilitiesSelected,
  } = useDevice();

  const allSelected = useMemo(
    () => !capabilities.some(({ selected }) => !selected),
    [capabilities],
  );

  const handleChangeSelected = useCallback(() => {
    setAllCapabilitiesSelected(!allSelected);
  }, [allSelected, setAllCapabilitiesSelected]);

  return (
    <div className={classNames(classes.root, className)}>
      <div
        className={classes.handle}
        data-testid="drag-border"
        onMouseDown={onMouseDown}
      />
      <Table scrollable data-testid="chart-table">
        <TableHead>
          <TableRow>
            <TableHeadCell className={classes.actions}>
              {capabilities.length > 1 && (
                <Checkbox
                  onChange={handleChangeSelected}
                  checked={allSelected}
                  data-testid={`change-all-capability-selected`}
                />
              )}
            </TableHeadCell>

            <TableHeadCell>
              {t('device.info.table.columns.capability')}
            </TableHeadCell>
            <TableHeadCell>{t('device.info.table.columns.unit')}</TableHeadCell>
            <TableHeadCell>
              {t('device.info.table.columns.range')}
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              <TableRow loading>
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
              </TableRow>
              <TableRow loading>
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
              </TableRow>
              <TableRow loading>
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
                <TableCell loading />
              </TableRow>
            </>
          ) : (
            capabilities.map((capability) => (
              <DeviceChartTableRow
                key={capability.id}
                id={capability.id}
                name={capability.name}
                unitName={capability.unitName}
                unitSymbol={capability.unitSymbol}
                technicalMin={capability.technicalMin}
                technicalMax={capability.technicalMax}
                color={capability.color}
                selected={capability.selected}
                onChangeSelected={setCapabilitySelected}
                onChangeColor={setCapabilityColor}
              />
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
};
