import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { MissingPermissions, NotFound } from '../features/errorScreens';
import { GenericPageLayout } from '../components/Layout';

interface GenericPageContextValue {
  activateNotFound: () => void;
  activateMissingPermissions: () => void;
  navigateToHome: () => void;
}

const GenericPageContext = createContext<GenericPageContextValue>({
  activateNotFound: () => {},
  activateMissingPermissions: () => {},
  navigateToHome: () => {},
});

export const useGenericPages = (): GenericPageContextValue => {
  const values = useContext(GenericPageContext);
  if (!values.activateNotFound) {
    throw new Error(
      `useGenericPageContext must be used within a GenericPageProvider`,
    );
  }
  return values;
};

interface GenericPageProviderProps {
  children?: React.ReactNode;
}

export const GenericPageProvider: React.FC<GenericPageProviderProps> = ({
  children,
}) => {
  const [page, setPage] = useState<'not-found' | 'missing-permissions' | null>(
    null,
  );
  const navigate = useNavigate();

  const reset = useCallback(() => {
    setPage(null);
    navigate('/');
  }, [navigate]);

  const value = useMemo(
    () => ({
      activateNotFound: () => setPage('not-found'),
      activateMissingPermissions: () => setPage('missing-permissions'),
      navigateToHome: reset,
    }),
    [reset],
  );

  return (
    <GenericPageContext.Provider value={value}>
      {page === null ? (
        children
      ) : (
        <GenericPageLayout>
          {page === 'not-found' && <NotFound navigateToHome={reset} />}
          {page === 'missing-permissions' && <MissingPermissions />}
        </GenericPageLayout>
      )}
    </GenericPageContext.Provider>
  );
};
