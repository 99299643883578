import React from 'react';
import { Colors, createClasses, Typography } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { DeviceContentLayout } from '../common/DeviceContentLayout';
import { Device, DeviceLocation } from '../Device.types';
import { DeviceToolbarActions } from '../common/DeviceToolbarActions';
import { DeviceInfoSidebar } from '../common/DeviceInfoSidebar';
import emptyStateUrl from '../../../images/empty-state.svg';

const classes = createClasses({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    color: Colors.Neutral.textWeak,
  },
  image: {
    marginBottom: '12px',
  },
});

interface DeviceEmptyProps {
  device?: Device;
  location?: DeviceLocation;
}

export const DeviceEmpty: React.FC<DeviceEmptyProps> = ({
  device,
  location,
}) => {
  const { t } = useTranslation();
  return (
    <DeviceContentLayout
      slider={false}
      title={device?.name || ''}
      actions={<DeviceToolbarActions tabs={false} />}
      sidebars={<DeviceInfoSidebar device={device} location={location} />}
    >
      <div className={classes.root}>
        <img
          alt="no-capabilities"
          src={emptyStateUrl}
          className={classes.image}
        />
        <Typography variant="titleTiny">
          {t('device.info.empty.title')}
        </Typography>
      </div>
    </DeviceContentLayout>
  );
};
