import React from 'react';
import { classNames, createClasses, css } from '@kp/react-ui';
import { DashboardGrid } from './DashboardGrid';
import { DashboardEditPanel } from './DashboardEditPanel';
import { useHorizontalResize } from '../../../hooks/useHorizontalResize';
import { DashboardFormSubmit } from './Dashboard.types';

const classes = createClasses({
  canvas: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  dashboard: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    overflowY: 'auto',
    padding: '12px',
  },
  panel: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  resizing: {
    cursor: 'ns-resize',
  },
});

interface DashboardProps {
  loading?: boolean;
  selectedCardId?: string;
  submitCard: DashboardFormSubmit;
  submit: DashboardFormSubmit;
  onCloseEditPanel: () => void;
  onSelectCard: (cardId: string | undefined) => void;
}

export const Dashboard: React.FC<DashboardProps> = ({
  loading,
  selectedCardId,
  onCloseEditPanel,
  onSelectCard,
  submit,
  submitCard,
}) => {
  const [
    [dashboardHeight, panelHeight],
    { isResizing, containerRef, startResizing },
  ] = useHorizontalResize({
    defaultOffset: (height) => height - 400,
    minTopOffset: 150,
    minBottomOffset: 200,
  });

  return (
    <div className={classNames(classes.canvas, isResizing && classes.resizing)}>
      <div
        data-testid="dashboard-resize-container"
        className={classes.overlay}
        ref={containerRef}
      />
      <div
        data-testid="dashboard-grid-container"
        data-height={selectedCardId ? dashboardHeight : '100%'}
        className={classNames(
          classes.dashboard,
          css({ height: selectedCardId ? dashboardHeight : '100%' }),
        )}
      >
        <DashboardGrid
          selectedCardId={selectedCardId}
          submit={submit}
          loading={loading}
          onSelectCard={onSelectCard}
        />
      </div>
      {selectedCardId && (
        <div
          data-testid="dashboard-panel-container"
          data-height={panelHeight}
          className={classNames(classes.panel, css({ height: panelHeight }))}
        >
          <DashboardEditPanel
            onMouseDown={startResizing}
            onClose={onCloseEditPanel}
            cardId={selectedCardId}
            onSubmit={submitCard}
          />
        </div>
      )}
    </div>
  );
};
