import React, { useMemo, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TimeScaleDataset } from '@kp/react-ui/src/charts/common/types';
import { DeviceChart } from './DeviceChart';
import { Device, DeviceLocation } from '../Device.types';
import { useDevice } from '../device-context';
import {
  useDeviceChartDataMinQuery,
  useDeviceChartDataQuery,
} from '../../../__generated__/types';
import { useNotifications } from '../../../contexts/notifications-context';
import { DEFAULT_CHART_INTERVAL_SIZE } from '../../../constants/Defaults';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';

interface DeviceChartContainerProps {
  device?: Device;
  location?: DeviceLocation;
  loading?: boolean;
  defaultCapabilityId: string;
}

export const DeviceChartContainer: React.FC<DeviceChartContainerProps> = ({
  device,
  location,
  loading,
  defaultCapabilityId,
}) => {
  const {
    dateFrom,
    dateUntil,
    setMinDate,
    capabilities,
    collapsedChart,
    setCollapsedChart,
    chartType,
  } = useDevice();
  const { add } = useNotifications();
  const { deviceId, buildingId } = useParams();
  const [intervalSize, setIntervalSize] = useState(DEFAULT_CHART_INTERVAL_SIZE);
  const { t } = useTranslation();

  useBreadcrumb([
    {
      title: location?.buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    {
      title: t('page.devices'),
      location: `/buildings/${buildingId}/devices`,
    },
    {
      title: device?.name || '',
      selected: true,
      location: `/buildings/${buildingId}/devices/${deviceId}`,
    },
  ]);

  const {
    data,
    loading: loadingData,
    previousData,
  } = useDeviceChartDataQuery({
    variables: {
      deviceId,
      deviceModelCapabilityIds: capabilities.map((capability) => ({
        deviceModelCapabilityId: capability.id,
      })),
      intervalSize,
      readFrom: format(dateFrom, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"),
      readUntil:
        dateUntil !== 'max'
          ? format(dateUntil, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")
          : null,
      readUntilNow: dateUntil === 'max',
    },
    skip: capabilities.length === 0,
    onError: (err) =>
      add({ type: 'danger', id: err.message, content: err.message }),
    errorPolicy: 'all',
  });

  const { data: dataMin, loading: loadingMin } = useDeviceChartDataMinQuery({
    variables: {
      deviceId,
      deviceModelCapabilityIds: capabilities.map((capability) => ({
        deviceModelCapabilityId: capability.id,
      })),
    },
    skip: capabilities.length === 0,
    onError: (err) =>
      add({ type: 'danger', id: err.message, content: err.message }),
    errorPolicy: 'all',
  });

  useEffect(() => {
    const readFromUtcMin =
      dataMin?.multipleDeviceSensorDataInfo?.minUtcTimeMeasured;
    if (readFromUtcMin) {
      setMinDate(parseISO(readFromUtcMin));
    }
  }, [dataMin, setMinDate]);

  const realInterval = useMemo(
    () =>
      data?.multipleDeviceSensorDataByViews?.[0]?.aggregationIntervalActive ||
      previousData?.multipleDeviceSensorDataByViews?.[0]
        ?.aggregationIntervalActive,
    [data, previousData],
  );

  useEffect(() => {
    const realIntervalSize =
      data?.multipleDeviceSensorDataByViews?.[0]?.aggregationIntervalActive;
    const previousIntervalSize =
      previousData?.multipleDeviceSensorDataByViews?.[0]
        ?.aggregationIntervalActive;

    if (previousIntervalSize === realIntervalSize) {
      return;
    }
    if (realIntervalSize && realIntervalSize !== intervalSize) {
      setIntervalSize(realIntervalSize);
    }
  }, [data, previousData, setIntervalSize, intervalSize]);

  const availableIntervals = useMemo(
    () =>
      data?.multipleDeviceSensorDataByViews?.[0]?.aggregationIntervalsList ||
      previousData?.multipleDeviceSensorDataByViews?.[0]
        ?.aggregationIntervalsList || [DEFAULT_CHART_INTERVAL_SIZE],
    [data, previousData],
  );

  const chartData: TimeScaleDataset[] = useMemo(() => {
    if (!data?.multipleDeviceSensorDataByViews?.[0]?.deviceAndCapabilityInfos) {
      return [];
    }
    const { deviceAndCapabilityInfos } =
      data.multipleDeviceSensorDataByViews[0];
    return capabilities
      .filter((capability) => capability.selected)
      .map((capability) => {
        const info = deviceAndCapabilityInfos.find(
          (entry) => entry?.deviceModelCapabilityId === capability.id,
        );
        return {
          color: capability.color,
          unit: capability.unitSymbol,
          label: capability.name,
          data: (info?.telemetryRecords || []).map((record) => ({
            timestamp: record?.utcTimeMeasured || new Date(),
            value: record?.valueString ? parseFloat(record.valueString) : 0,
          })),
        };
      });
  }, [data, capabilities]);

  return (
    <DeviceChart
      type={chartType}
      collapsed={collapsedChart}
      intervalSize={intervalSize}
      availableIntervals={availableIntervals}
      setIntervalSize={setIntervalSize}
      setCollapsed={setCollapsedChart}
      chartData={chartData}
      defaultCapabilityId={defaultCapabilityId}
      device={device}
      location={location}
      loading={loading || loadingMin}
      loadingData={loadingData || realInterval !== intervalSize}
      dateFrom={dateFrom}
      dateUntil={dateUntil === 'max' ? new Date() : dateUntil}
    />
  );
};
