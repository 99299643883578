import urljoin from 'url-join';
import { QANTEON_SERVICE_URI } from '../../utils/env';

export enum InstanceSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

/**
 * Provisioning status
 */
export enum Status {
  pending = 'pending',
  available = 'available',
  failed = 'failed',
}

/**
 * Qanteon entity
 */
export interface QanteonEntity {
  status: Status;
  name: string;
  description?: string;
  activatedAt: string;
  activatedBy: string;
  instanceSize: InstanceSize;
  url: string;
}

export enum ErrorCodes {
  NO_PROVISION_FOUND = 'NO_PROVISION_FOUND',
}

/**
 * Create url that points to the qanteon service
 *
 * @param segment  The segment path of the url
 */
export const url = (segment: string): string =>
  urljoin(QANTEON_SERVICE_URI, segment);
