import React, { ChangeEventHandler, useCallback } from 'react';
import {
  classNames,
  createClasses,
  StarButton,
  Typography,
  TypographyVariant,
} from '@kp/react-ui';

const classes = createClasses({
  root: {
    border: 'none',
    padding: '0px',
    background: 'none',
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    textAlign: 'left',
    paddingLeft: '52px',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  titleLocation: {
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  buildingIcon: {
    marginLeft: 'auto',
    paddingTop: '4px',
    paddingRight: '20px',
    width: '28',
    color: 'yellow',
  },
});

export interface PropertySidebarItemProps
  extends Omit<React.ComponentProps<'div'>, 'onClick' | 'value'> {
  value: string;
  selected?: boolean;
  building: {
    isFavorite: boolean;
    id: string;
    name: string;
    location: {
      street: string;
      city: string;
      no: string;
      zip: string;
      coordinates?: { lat: number; lng: number };
    };
  };
  onClick?: (value: string) => void;
  onFavClick?: (value: string) => void;
  setIsMouseOver?: (value: string) => void;
}

export const PropertySidebarItem: React.FC<PropertySidebarItemProps> = ({
  className,
  value,
  onFavClick,
  onClick,
  selected,
  building,
  setIsMouseOver,
  ...rest
}) => {
  const handleFavClick: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.stopPropagation();
    if (onFavClick) onFavClick(building.id);
  };

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(building.id);
    }
  }, [building, onClick]);

  return (
    <div
      className={classNames(classes.root, className)}
      onClick={handleClick}
      data-testid={`propertySidebarItem-${building.id}`}
      {...rest}
    >
      <div
        className={classes.root}
        onMouseEnter={
          setIsMouseOver ? () => setIsMouseOver(building.id) : undefined
        }
        onMouseLeave={setIsMouseOver ? () => setIsMouseOver('') : undefined}
      >
        <div className={classes.title}>
          <Typography variant={TypographyVariant.LabelMedium}>
            {building.name}
          </Typography>
          <div className={classes.titleLocation}>
            <Typography variant={TypographyVariant.BodySmall}>
              {building.location.street} {building.location.no}
            </Typography>
            <Typography variant={TypographyVariant.BodySmall}>
              {building.location.zip} {building.location.city}
            </Typography>
          </div>
        </div>
        <div className={classes.buildingIcon}>
          <StarButton
            checked={building.isFavorite}
            onChange={handleFavClick}
            onLabelClick={(event) => event.stopPropagation()}
            data-testid={`favourite-building-${building.id}`}
            // className={classes.favIcon}
          />
        </div>
      </div>
    </div>
  );
};
