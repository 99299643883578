import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Permissions, useAuth } from '@kp/react-sdk';
import { useForm } from 'react-hook-form';
import { debounce } from '../../../utils/debounce';
import {
  DashboardEntity,
  deleteDashboard,
  getDashboard,
  updateDashboard,
  AggregationInterval,
  TimeInterval,
} from '../../../api/building-insights';
import { useNotifications } from '../../../contexts/notifications-context';
import { usePrepareRequest } from '../../../hooks/useRequest';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { useBuilding } from '../../../contexts/building-context';
import { DashboardForm } from './Dashboard.types';
import { useDashboard } from '../dashboard-context';
import { updateCard } from '../../../api/building-insights/cards';
import { DashboardFormWrapper } from './DashboardFormWrapper';

interface DashboardContainerProps {
  dashboards: DashboardEntity[];
}

export const DashboardContainer: React.FC<DashboardContainerProps> = ({
  dashboards,
}) => {
  const { dashboardId } = useParams();
  const { id: buildingId, name: buildingName } = useBuilding();
  const { t } = useTranslation();
  const { add } = useNotifications();
  const { hasPermission } = useAuth();
  const navigate = useNavigate();
  const { loading, refresh, setRightSidebarOpen } = useDashboard();

  const [selectedCardId, setSelectedCardId] = useState<string | undefined>();
  const [initialized, setInitialized] = useState(false);

  const [updateCardRequest] = usePrepareRequest(updateCard, {
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const [update, { loading: updateLoading }] = usePrepareRequest(
    updateDashboard,
    {
      onError: (err) =>
        add({
          type: 'danger',
          id: err.message,
          content: err.message,
        }),
    },
  );

  const [remove] = usePrepareRequest(deleteDashboard, {
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });

  const methods = useForm<DashboardForm>();

  const { setValue, getValues, reset } = methods;

  useBreadcrumb([
    {
      title: buildingName || '',
      location: `/buildings/${buildingId}/info`,
    },
    {
      title: t('page.dashboards'),
      location: '',
      selected: true,
    },
    {
      title: getValues('name') || t('dashboards.template.untitled'),
      location: '',
      selected: true,
    },
  ]);

  useEffect(() => {
    if (!dashboardId) {
      return;
    }
    setInitialized(false);
    getDashboard(dashboardId)
      .then((dashboard) => {
        setInitialized(true);
        return reset({
          name: dashboard?.data?.data?.name,
          cards: dashboard?.data?.data?.cards || [],
          timeInterval:
            dashboard?.data?.data?.timeInterval || TimeInterval.lastDay,
          dateFrom: dashboard?.data?.data?.dateFrom,
          dateTo: dashboard?.data?.data?.dateTo,
          aggregationInterval:
            dashboard?.data?.data?.aggregationInterval ||
            AggregationInterval.none,
        });
      })
      .catch(console.warn);
  }, [reset, dashboardId]);

  const handleSubmit = useCallback(
    async (data: DashboardForm) => {
      if (
        !initialized ||
        !dashboardId ||
        !buildingId ||
        !hasPermission(Permissions.WidgetsWrite)
      ) {
        // In case the dashboard is not loaded
        // Or we don't have "save" permission
        return;
      }

      await update(dashboardId, {
        buildingId,
        id: dashboardId,
        ...data,
        name: data.name || '',
      }).then((res) => {
        if (res?.response?.data?.cards) {
          setValue('cards', res.response.data.cards);
        }
        return null;
      });
    },
    [update, dashboardId, initialized, buildingId, hasPermission, setValue],
  );

  const handleSubmitDebounced = debounce(handleSubmit, 1000);

  const handleSubmitCard = useCallback(
    async (data: DashboardForm) => {
      if (!selectedCardId || !initialized) {
        return;
      }
      const updatedCard = (data.cards || []).find(
        ({ id }) => id === selectedCardId,
      );

      if (!updatedCard) {
        return;
      }

      await updateCardRequest(dashboardId, selectedCardId, updatedCard).then(
        (res) =>
          setValue(
            'cards',
            data.cards.map((card) =>
              card.id === selectedCardId ? res.response?.data || card : card,
            ),
          ),
      );
    },
    [selectedCardId, dashboardId, initialized, updateCardRequest, setValue],
  );

  const handleSubmitCardDebounced = debounce(handleSubmitCard, 1000);

  const handleDelete = useCallback(() => {
    if (!dashboardId) return;

    remove(dashboardId)
      .then(() => refresh())
      .then(() => navigate(`/buildings/${buildingId}/dashboards`))
      .then(() => setRightSidebarOpen(false))
      .catch(() => {});
  }, [remove, dashboardId, buildingId, refresh, navigate, setRightSidebarOpen]);

  const handleCloseEditPanel = useCallback(() => {
    setSelectedCardId(undefined);
  }, [setSelectedCardId]);

  return (
    <DashboardFormWrapper
      onSubmit={handleSubmit}
      onSubmitDebounced={handleSubmitDebounced}
      onSubmitCard={handleSubmitCard}
      onSubmitCardDebounced={handleSubmitCardDebounced}
      onSelectCard={setSelectedCardId}
      onDelete={handleDelete}
      dashboards={dashboards}
      loading={loading || updateLoading}
      initialized={initialized}
      selectedCardId={selectedCardId}
      onCloseEditPanel={handleCloseEditPanel}
      methods={methods}
    />
  );
};
