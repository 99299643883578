import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useFormikContext } from 'formik';
import noop from '@stdlib/utils-noop';
import { useDebounce } from 'rooks';
import {
  TimeInterval,
  getDateFromOfTimeInterval,
} from '../../../api/building-insights';
import { useNow } from '../../../hooks/now';

interface WidgetContextValue {
  dateFrom: Date | 'min';
  dateUntil: Date | 'max';
  timeInterval: TimeInterval;
  setWidgetValues: (
    state: Partial<
      Pick<WidgetContextValue, 'dateFrom' | 'dateUntil' | 'timeInterval'>
    >,
    options?: { debounce?: boolean },
  ) => void;
  now: Date;
}

export const WidgetContext = createContext<WidgetContextValue>({
  dateFrom: 'min',
  dateUntil: 'max',
  timeInterval: TimeInterval.customInterval,
  setWidgetValues: noop,
  now: new Date(),
});

type WidgetProviderData = {
  dateFrom: Date | undefined;
  dateUntil: Date | undefined;
  timeInterval: TimeInterval;
};

export const useWidget = (): WidgetContextValue => useContext(WidgetContext);

export const WidgetProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { values, setFieldValue } = useFormikContext<WidgetProviderData>();

  const [dateFrom, setDateFrom] = useState<Date | 'min'>(
    values.dateFrom || 'min',
  );
  const [dateUntil, setDateUntil] = useState<Date | 'max'>(
    values.dateUntil || 'max',
  );
  const [timeInterval, setTimeInterval] = useState<TimeInterval>(
    values.timeInterval || TimeInterval.customInterval,
  );
  const now = useNow();

  const setFieldDateFrom = useCallback(
    (value: Date | 'min', interval: TimeInterval) =>
      setFieldValue(
        'dateFrom',
        value === 'min' || interval !== TimeInterval.customInterval
          ? undefined
          : value,
      ),
    [setFieldValue],
  );

  const setFieldDateUntil = useCallback(
    (value: Date | 'max', interval: TimeInterval) =>
      setFieldValue(
        'dateUntil',
        value === 'max' || interval !== TimeInterval.customInterval
          ? undefined
          : value,
      ),
    [setFieldValue],
  );

  const setFieldTimeInterval = useCallback(
    (value: TimeInterval) => setFieldValue('timeInterval', value),
    [setFieldValue],
  );

  const setFieldValues = useCallback(
    (
      state: Pick<
        WidgetContextValue,
        'dateFrom' | 'dateUntil' | 'timeInterval'
      >,
    ) => {
      setFieldDateFrom(state.dateFrom, state.timeInterval);
      setFieldDateUntil(state.dateUntil, state.timeInterval);
      setFieldTimeInterval(state.timeInterval);
    },
    [setFieldDateUntil, setFieldDateFrom, setFieldTimeInterval],
  );

  const setFieldValuesDebounced = useDebounce(setFieldValues, 500);

  const setWidgetValues = useCallback(
    (
      state: Partial<
        Pick<WidgetContextValue, 'dateFrom' | 'dateUntil' | 'timeInterval'>
      >,
      options: { debounce?: boolean } = {},
    ) => {
      const newDateUntil = state.dateUntil || dateUntil;
      const newDateFrom = state.dateFrom || dateFrom;
      const newTimeInterval = state.timeInterval || timeInterval;
      setDateUntil(newDateUntil);
      setDateFrom(newDateFrom);
      setTimeInterval(newTimeInterval);
      if (options.debounce) {
        setFieldValuesDebounced({
          dateFrom: newDateFrom,
          dateUntil: newDateUntil,
          timeInterval: newTimeInterval,
        });
      } else {
        setFieldValues({
          dateFrom: newDateFrom,
          dateUntil: newDateUntil,
          timeInterval: newTimeInterval,
        });
      }
    },
    [
      dateFrom,
      dateUntil,
      timeInterval,
      setFieldValues,
      setFieldValuesDebounced,
    ],
  );

  useEffect(() => {
    if (timeInterval !== TimeInterval.customInterval) {
      setDateFrom(getDateFromOfTimeInterval(timeInterval, now));
      setDateUntil('max');
    }
  }, [timeInterval, now, setDateFrom]);

  useEffect(() => {
    setTimeInterval(values.timeInterval);
  }, [values.timeInterval, setTimeInterval]);

  useEffect(() => {
    if (values.dateUntil) {
      setDateUntil(values.dateUntil);
    }
  }, [values.dateUntil, setDateUntil]);

  useEffect(() => {
    if (values.dateFrom) {
      setDateFrom(values.dateFrom);
    }
  }, [values.dateFrom, setDateUntil]);

  return (
    <WidgetContext.Provider
      value={{
        now,
        dateFrom,
        dateUntil,
        timeInterval,
        setWidgetValues,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};
