import React, { useMemo } from 'react';
import { ClockCard } from './ClockCard';
import { useLocaleDateFns } from '../../../../hooks/date-fns';
import { useNow } from '../../../../hooks/now';

interface ClockCardContainerProps {
  id: string;
  title: string;
  subtitle?: string;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selected?: boolean;
}

export const ClockCardContainer: React.FC<ClockCardContainerProps> = ({
  id,
  title,
  subtitle,
  onEdit,
  onDelete,
  selected,
}) => {
  const now = useNow();
  const { format } = useLocaleDateFns();

  const [time, timePostfix] = useMemo(() => {
    const formattedTime = format(now, 'p').split(' ');
    return [
      formattedTime[0],
      formattedTime.length === 2 ? formattedTime[1] : undefined,
    ];
  }, [now, format]);

  return (
    <ClockCard
      id={id}
      time={time}
      timePostfix={timePostfix}
      title={title}
      subtitle={subtitle === '[date]' ? format(now, 'P') : subtitle}
      onDelete={onDelete}
      onEdit={onEdit}
      selected={selected}
    />
  );
};
