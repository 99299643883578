import React, { useRef } from 'react';
import { createClasses } from '@kp/react-ui';
import { useXeoKit } from '../../../contexts/xeokit';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  cube: {
    position: 'absolute',
    bottom: '30px',
    right: '30px',
    width: '250px',
    height: '250px',
  },
});

export interface InteractiveCanvasProps {
  xktFile: string;
  metaFile?: string;
}

export const InteractiveCanvas: React.FC<InteractiveCanvasProps> = React.memo(
  ({ xktFile, metaFile }) => {
    const viewerRef = useRef<HTMLCanvasElement>(null);
    const cubeRef = useRef<HTMLCanvasElement>(null);
    useXeoKit({ viewerRef, cubeRef, xktFile, metaFile });
    return (
      <div className={classes.root}>
        <canvas ref={viewerRef} className={classes.canvas} />
        <canvas ref={cubeRef} className={classes.cube} />
      </div>
    );
  },
);

InteractiveCanvas.displayName = 'InteractiveCanvas';
